import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button, Col, Statistic as Label, Row,
} from 'antd';
import Card from '../../components/Card';
import ReactTable from '../../components/ReactTable';
import Spacer from '../../components/Spacer';

class OutboundResult extends Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.outboundRedirect = this.outboundRedirect.bind(this);
  }

  outboundRedirect() {
    const { match, history } = this.props;
    history.push(`/outbound/${match.params.id}`);
  }

  render() {
    const { history } = this.props;
    const { created, unsaved, deleted } = history.location.state;
    const totalResultCount = [
      ...created,
      ...unsaved,
    ];
    const results = [
      ...deleted,
      ...created,
      ...unsaved,
    ];

    return (
      <Card title="Outbound Packages result">
        <Card title="">
          <Row>
            <center>
              <Col xs={24} sm={24} lg={6}>
                <Label title="Added" value={created.length} />
              </Col>
              <Col xs={24} sm={24} lg={6}>
                <Label title="Failed" value={unsaved.length} />
              </Col>
              <Col xs={24} sm={24} lg={6}>
                <Label title="Deleted" value={deleted.length} />
              </Col>
              <Col xs={24} sm={24} lg={6}>
                <Label title="Total Scans" value={totalResultCount.length} />
              </Col>
            </center>
          </Row>
        </Card>
        <Spacer />
        <ReactTable
        // eslint-disable-next-line no-unused-vars
          getTrProps={(state, rowData, instance) => {
            if (typeof rowData !== 'undefined') {
              if (rowData.row.status === 'failed'
              || rowData.row.status === 'deleted'
              ) {
                return {
                  style: {
                    background: '#ffccc7',
                  },
                };
              }

              if (rowData.row.status === 'updated') {
                return {
                  style: {
                    background: '#bae7ff',
                  },
                };
              }

              if (rowData.row.status === 'added') {
                return {
                  style: {
                    background: '#b3ff99',
                  },
                };
              }
            }

            return {
              style: {
                background: 'white',
              },
            };
          }}
          data={results}
          columns={[
            {
              id: 'tracking_number',
              Header: 'Tracking Number',
              accessor: data => data.package_details.tracking_number,
            },
            {
              id: 'item_count',
              Header: 'Item Count',
              accessor: data => data.package_details.item_count,
            },
            {
              id: 'package_type',
              Header: 'Package Type',
              accessor: data => data.package_details.package_type,
            },
            {
              id: 'remarks',
              Header: 'Package Remarks',
              accessor: data => data.package_details.remarks,
            },
            {
              Header: 'Status',
              accessor: 'status',
            },
            {
              Header: 'Message',
              accessor: 'message',
              width: 320,

            }]}
        />
        <Spacer />
        <Button onClick={this.outboundRedirect} block type="primary">View Outbound</Button>
      </Card>

    );
  }
}

OutboundResult.propTypes = {
  match: PropTypes.oneOfType([PropTypes.object]).isRequired,
  history: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default connect(null, {})(OutboundResult);
