import React from 'react';
import { PageHeader as AntPageHeader } from 'antd';
import PropTypes from 'prop-types';


const PageHeader = ({ title }) => <AntPageHeader title={title} />;

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
};

export default PageHeader;
