import React from 'react';
import { Link } from 'react-router-dom';
import { Result, Button } from 'antd';

const Missing = () => (
  <Result
    status="404"
    title="404"
    subTitle="The page you are looking for doesn't exist!"
    extra={<Button type="primary"><Link to="/">Go Home</Link></Button>}
  />
);

export default Missing;
