import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Col, Form, Input, Button, Icon,
  Statistic as Label, message, Popconfirm, Modal,
  DatePicker, Progress, Spin, notification,
  Upload,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTimes, faEdit, faSave, faPrint, faFileExport, faBox, faBan, faFileDownload, faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import FileDownload from 'js-file-download';
import moment from 'moment';
import Select from '../../components/Select';
import GroupSelect from '../../components/GroupSelect';
import Row from '../../components/Row';
import PageHeader from '../../components/PageHeader';
import Card from '../../components/Card';
import Container from '../../components/Container';
import ReactTable from '../../components/ReactTable';
import Spacer from '../../components/Spacer';
import {
  getInbound, updateInboundDetails, finalizeInbound, exportInbound,
  cancelInbound, getOrigins, printInbound, getShippingTypes, getInboundAreas,
  uploadInboundFiles, downloadInboundFile, deleteInboundFile,
} from './ducks';
import { getHaulerSelect } from '../hauler/ducks';
import { getBranchGates } from '../gate/ducks';
import { getVehiclesLike } from '../vehicle/ducks';

class InboundView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inboundDetails: {},
      gateOptions: {},
      acceptedPackages: [],
      refusedPackages: [],
      haulerOptions: [],
      plateNumberOptions: [],
      deletedPackages: [],
      originOptions: [],
      shippingTypeOptions: [],
      inboundAreaOptions: [],
      fileList: [],
      isDisabled: true,
      isFinalized: false,
      isCancelled: false,
      isLoading: true,
      deletedOnly: false,
      isFetchingPackages: false,
      uploadModalVisibility: false,
      truckUtilization: 0,
      type: '',
      loadMessage: 'Fetching Inbound details. . .',
    };
    this.editDetails = this.editDetails.bind(this);
    this.cancelEdit = this.cancelEdit.bind(this);
    this.saveDetails = this.saveDetails.bind(this);
    this.editRedirect = this.editRedirect.bind(this);
    this.finalizeInbound = this.finalizeInbound.bind(this);
    this.exportPackages = this.exportPackages.bind(this);
    this.addTruckUtilization = this.addTruckUtilization.bind(this);
    this.minusTruckUtilization = this.minusTruckUtilization.bind(this);
    this.inputTruckUtilization = this.inputTruckUtilization.bind(this);
    this.setPlateNumber = this.setPlateNumber.bind(this);
    this.searchPlateNumber = this.searchPlateNumber.bind(this);
    this.cancelInbound = this.cancelInbound.bind(this);
    this.filterPackages = this.filterPackages.bind(this);
    this.getOrigins = this.getOrigins.bind(this);
    this.getShippingTypes = this.getShippingTypes.bind(this);
    this.beforeUploadHandler = this.beforeUploadHandler.bind(this);
    this.removeUploadHandler = this.removeUploadHandler.bind(this);
    this.submitUploadHandler = this.submitUploadHandler.bind(this);
    this.downloadMovementFile = this.downloadMovementFile.bind(this);
  }

  componentDidMount() {
    this.getInbound();
    this.getGates();
    this.getHaulers();
    this.getOrigins();
    this.getShippingTypes();
    this.getInboundAreas();
  }

  setPlateNumber(e) {
    const { plateNumberOptions } = this.state;
    const { form } = this.props;
    const selected = plateNumberOptions.filter(item => item.value === e);

    form.setFieldsValue({ truck_type: selected[0].type });
  }

  getOrigins() {
    const { doGetOrigins } = this.props;
    doGetOrigins().then((action) => {
      this.setState({ originOptions: action.payload.data });
    });
  }

  getShippingTypes() {
    const { doGetShippingTypes } = this.props;
    doGetShippingTypes().then((action) => {
      this.setState({ shippingTypeOptions: action.payload.data });
    });
  }

  getInboundAreas() {
    const { doGetInboundAreas } = this.props;
    doGetInboundAreas().then((action) => {
      this.setState({ inboundAreaOptions: action.payload.data });
    });
  }

  getInbound() {
    const { deletedOnly } = this.state;
    const {
      doGetInbound,
      history,
      branch,
    } = this.props;
    doGetInbound(history.location.pathname, branch, deletedOnly).then((action) => {
      const timeDiff = moment(
        action.payload.data.scan_time, 'YYYY-MM-DD HH:mm:ss',
      ).diff(
        moment(action.payload.data.start, 'YYYY-MM-DD HH:mm:ss'),
      );

      this.setState({
        isFinalized: action.payload.data.status === 'finalized',
        isCancelled: action.payload.data.status === 'cancelled',
        isFetchingPackages: false,
        isLoading: false,
        inboundDetails: {
          ...action.payload.data,
          gate: {
            label: action.payload.data.gate_details.gate_number,
            value: action.payload.data.gate_details.id,
          },
          hauler: {
            label: action.payload.data.hauler_details.name,
            value: action.payload.data.hauler_details.id,
          },
          truck_type: action.payload.data.truck_type.replace(/_/g, ' '),
          plate_number: {
            label: action.payload.data.vehicle.plate_number,
            value: action.payload.data.vehicle.id,
          },
          location: action.payload.data.location.name,
          packages: action.payload.data.packages,
          user: action.payload.data.user.full_name,
          scannedPackages: [],
          gate_time: moment.utc(moment.duration(timeDiff, 'ms').asMilliseconds()).format('HH:mm:ss'),
        },
        truckUtilization: parseInt(action.payload.data.truck_utilization, 0),
        acceptedPackages: [
          ...action.payload.data.packages.filter((acceptedPackageDetails) => {
            if (acceptedPackageDetails.remarks === 'good') {
              return acceptedPackageDetails;
            }
            return null;
          }),
        ],
        refusedPackages: [
          ...action.payload.data.packages.filter((refusedPackageDetails) => {
            if (refusedPackageDetails.remarks !== 'good') {
              return refusedPackageDetails;
            }
            return null;
          }),
        ],
        plateNumberOptions: [
          {
            label: `${action.payload.data.vehicle.plate_number} - ${action.payload.data.vehicle.truck_type}`,
            value: action.payload.data.vehicle.id,
          },
        ],
        deletedPackages: action.payload.data.deleted_packages,
      });
    }).catch(() => {
      history.push('/404');
    });
  }

  getGates() {
    const { branch, doGetBranchGates } = this.props;
    doGetBranchGates(branch.id, 'inbound').then((action) => {
      const usedGates = [];
      const availableGates = [];
      action.payload.data.forEach((gate) => {
        if (
          (
            gate.outbound.length
            || gate.inbound.length
          )
          && (
            gate.inbound.some(inbound => inbound.status === 'fresh')
            || gate.outbound.some(outbound => outbound.status === 'fresh')
          )
        ) {
          usedGates.push({
            value: gate.id,
            label: `${gate.gate_number} - #${gate.inbound.length ? gate.inbound[0].id : gate.outbound[0].id}`,
            disabled: true,
          });
        } else {
          availableGates.push({
            value: gate.id,
            label: gate.gate_number,
            disabled: false,
          });
        }
      });
      const gateOptions = {
        Available: {
          options: [
            ...availableGates,
          ],
        },
        Used: {
          options: [
            ...usedGates,
          ],
        },
      };

      this.setState({ gateOptions });
    });
  }

  getHaulers() {
    const { doGetHaulerSelect } = this.props;

    doGetHaulerSelect().then((action) => {
      this.setState({
        haulerOptions: action.payload.data.map(hauler => ({
          label: hauler.label,
          value: hauler.value,
          disabled: !hauler.is_active,
        })),
      });
    });
  }

  searchPlateNumber(e) {
    const { doGetVehiclesLike } = this.props;

    doGetVehiclesLike(e).then((action) => {
      this.setState({
        plateNumberOptions: action.payload.data.map(plateNumber => ({
          ...plateNumber,
          label: `${plateNumber.label} - ${plateNumber.type}`,
        })),
      });
    });
  }


  editRedirect() {
    const { history, match } = this.props;
    history.push(`/inbound/${match.params.id}/package`);
  }

  editDetails() {
    this.setState({ isDisabled: false });
  }

  cancelEdit() {
    this.setState({ isDisabled: true, type: '' });
    const { form } = this.props;
    form.resetFields();
  }

  addTruckUtilization() {
    this.setState(prevState => ({
      truckUtilization: prevState.truckUtilization + 5 > 100 ? 100 : prevState.truckUtilization + 5,
    }));
  }

  minusTruckUtilization() {
    this.setState(prevState => ({
      truckUtilization: prevState.truckUtilization - 5 < 0 ? 0 : prevState.truckUtilization - 5,
    }));
  }

  inputTruckUtilization(e) {
    if (e.target.value > 100) {
      e.target.value = 100;
    }
    if (e.target.value < 0) {
      e.target.value = 0;
    }
    this.setState({ truckUtilization: e.target.value === '' ? 0 : parseInt(e.target.value, 0) });
  }

  saveDetails() {
    const {
      form, history, doUpdateInboundDetails, match, branch,
    } = this.props;
    const { truckUtilization } = this.state;
    const { validateFields } = form;
    validateFields((err, values) => {
      if (!err) {
        doUpdateInboundDetails(history.location.pathname, {
          ...values,
          truck_type: values.truck_type,
          location: branch.id,
          id: match.params.id,
          truck_utilization: truckUtilization,
          merchant: values.merchant,
        }).then(() => {
          this.setState({ isDisabled: true });
          message.success('Inbound details successfully updated!');
        }).catch((action) => {
          let errorMessage;
          if (action.error && action.error.response.status === 422) {
            const index = Object.values(action.error.response.data);
            // eslint-disable-next-line prefer-destructuring
            errorMessage = index[0][0];
          } else {
            errorMessage = 'Something went wrong, please try again later.';
          }
          message.error(errorMessage, 3);
        });
      }
    });
  }

  finalizeInbound() {
    const { doFinalizeInbound, match } = this.props;
    const { acceptedPackages, refusedPackages } = this.state;
    if (acceptedPackages.length || refusedPackages.length) {
      this.setState({
        isLoading: true,
        loadMessage: 'Finalizing movement, please don`t refresh or leave this page. . .',
      });
      doFinalizeInbound(match.params.id).then(() => {
        this.setState({
          isLoading: false,
          isFinalized: true,
        }, () => {
          notification.success({
            message: 'Inbound successfully finalized!',
            duration: 0,
          });
        });
      }).catch((action) => {
        let errorMessage;
        if (action.error && action.error.response.status === 422) {
          const index = Object.values(action.error.response.data);
          // eslint-disable-next-line prefer-destructuring
          errorMessage = index[0][0];
        } else {
          errorMessage = 'Something went wrong, please try again later.';
        }
        notification.error({
          message: errorMessage,
          duration: 0,
        });
        this.setState({ isLoading: false });
      });
    } else {
      notification.error({
        message: 'Unable to finalize an empty movement!',
        duration: 5,
      });
      this.setState({
        isLoading: false,
      });
    }
  }

  print(printType) {
    const { doPrintInbound, match } = this.props;
    this.setState({ isLoading: true, loadMessage: 'Generating manifest print format. . . ' });
    doPrintInbound(match.params.id, printType).then((res) => {
      const file = new Blob(
        [res.payload.data],
        { type: 'application/pdf' },
      );
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      this.setState({ isLoading: false });
    });
  }

  exportPackages() {
    const { doExportInbound, match } = this.props;
    doExportInbound(match.params.id).then((response) => {
      FileDownload(new Blob([response.payload.data]), `INBOUND-${match.params.id}-PACKAGES.xlsx`);
    });
  }

  cancelInbound() {
    const { confirm } = Modal;
    const { doCancelInbound, match, history } = this.props;
    confirm({
      title: (<strong>Are you sure?</strong>),
      content: 'Cancelling this movement will remove the movement and all the packages saved.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        doCancelInbound(match.params.id).then(() => {
          message.success('Inbound successfully cancelled');
          history.push('/inbound');
        });
      },
    });
  }

  filterPackages(e) {
    this.setState({
      deletedOnly: e,
      isFetchingPackages: true,
    }, () => {
      this.getInbound();
    });
  }

  beforeUploadHandler(file) {
    this.setState(prevState => ({
      fileList: [
        ...prevState.fileList,
        file,
      ],
    }));
    return false;
  }

  removeUploadHandler(e) {
    this.setState(prevState => ({
      fileList: prevState.fileList.filter(file => file.name !== e.name),
    }));
  }

  submitUploadHandler() {
    const { fileList } = this.state;
    const { match, doUploadInboundFiles } = this.props;
    const formData = new FormData();
    let fileCount = 0;
    fileList.forEach((file) => {
      formData.set(`uploadedFiles[${fileCount}][file]`, file);
      fileCount += 1;
    });
    this.setState({ isLoading: true });
    doUploadInboundFiles(match.params.id, formData).then((res) => {
      const { success } = res.payload.data;
      const { failed } = res.payload.data;

      success.forEach((file) => {
        message.success(`File ${file.upload_file_name} successfully uploaded.`);
      });
      failed.forEach((file) => {
        message.error(`File ${file.filename} failed to upload.`);
      });
      this.setState({ uploadModalVisibility: false, fileList: [] });
      this.getInbound();
    }).catch(() => {
      this.setState({
        isLoading: false,
      });
    });
  }

  downloadMovementFile(fileId, filename) {
    const { doDownloadInboundFile } = this.props;
    this.setState({ isLoading: true, loadMessage: 'Downloading file. . .' });
    doDownloadInboundFile(fileId).then((res) => {
      this.setState({ isLoading: false });
      FileDownload(new Blob([res.payload.data]), filename);
    });
  }

  deleteMovementFile(fileId) {
    const { doDeleteInboundFile } = this.props;
    this.setState({ isLoading: true });
    doDeleteInboundFile(fileId).then(() => {
      message.success('file successfully deleted');
      this.getInbound();
    }).catch(() => {
      this.setState({
        isLoading: false,
      });
    });
  }

  render() {
    const {
      isDisabled,
      gateOptions,
      type,
      inboundDetails,
      isFinalized,
      refusedPackages,
      acceptedPackages,
      truckUtilization,
      isLoading,
      loadMessage,
      haulerOptions,
      plateNumberOptions,
      isCancelled,
      deletedPackages,
      deletedOnly,
      originOptions,
      isFetchingPackages,
      shippingTypeOptions,
      inboundAreaOptions,
      uploadModalVisibility,
      fileList,
    } = this.state;
    const { Dragger } = Upload;
    const InputGroup = Input.Group;
    const {
      form,
      userId,
      match,
      userRole,
    } = this.props;
    const { getFieldDecorator } = form;
    const packagesFilter = [
      {
        label: 'Scanned',
        value: 0,
      },
      {
        label: 'Deleted',
        value: 1,
      },
    ];
    const accepted = acceptedPackages.filter(
      acceptedPackageDetails => typeof acceptedPackageDetails !== 'undefined'
      && acceptedPackageDetails !== null,
    );
    const refused = refusedPackages.filter(
      refusedPackageDetails => typeof refusedPackageDetails !== 'undefined'
      && refusedPackageDetails !== null,
    );
    const dateFormat = 'YYYY-MM-DD HH:mm:ss';
    const timeDiff = moment(inboundDetails.end, 'YYYY-MM-DD HH:mm:ss').diff(moment(inboundDetails.start, 'YYYY-MM-DD HH:mm:ss'));
    const inboundTime = moment.utc(moment.duration(timeDiff, 'ms').asMilliseconds()).format('HH:mm:ss');

    return (
      <div className="InboundView">
        <Spin spinning={isLoading} tip={loadMessage}>
          <PageHeader title={`Inbound #${match.params.id}`} />
          <Container>
            <Card
              title={(
                <React.Fragment>
                Inbound Details
                  {isDisabled && !isFinalized && !isCancelled ? (
                    <Button type="link" onClick={this.editDetails} disabled={isLoading}>
                      <Icon viewBox="0 0 1024 1024">
                        <FontAwesomeIcon icon={faEdit} fixedWidth />
                      </Icon>
                        Edit
                    </Button>
                  ) : ''}
                </React.Fragment>
              )}
              extra={(
                <React.Fragment>
                  <Row>

                    {!isDisabled && !isFinalized ? (
                      <Col xs={24} sm={12} lg={12}>
                        <Button type="primary" onClick={this.saveDetails} block disabled={isLoading}>
                          <Icon viewBox="0 0 1024 1024">
                            <FontAwesomeIcon icon={faSave} fixedWidth />
                          </Icon>
                          Save
                        </Button>
                      </Col>
                    ) : ''}
                    {!isDisabled ? (
                      <Col xs={24} sm={12} lg={12}>
                        <Button type="primary" onClick={this.cancelEdit} block>
                          <Icon viewBox="0 0 1024 1024">
                            <FontAwesomeIcon icon={faTimes} fixedWidth />
                          </Icon>
                          Cancel
                        </Button>
                      </Col>
                    ) : (
                      ''
                    )}
                    {isDisabled && (!isCancelled || isFinalized) ? (
                      <Col xs={24} sm={12} lg={isFinalized ? 12 : 8}>
                        <Button type="primary" block disabled={isLoading} onClick={this.exportPackages}>
                          <Icon viewBox="0 0 1024 1024">
                            <FontAwesomeIcon icon={faFileExport} fixedWidth />
                          </Icon>
                            Export
                        </Button>
                      </Col>
                    ) : ''}
                    {isDisabled && isFinalized ? (
                      <Col xs={24} sm={12} lg={12}>
                        <Popconfirm
                          placement="leftBottom"
                          title="Please select what package summary you want to print."
                          okText="Approved"
                          cancelText="Refused"
                          onConfirm={() => this.print('approved')}
                          onCancel={() => this.print('refused')}
                        >
                          <Button type="primary" block disabled={isLoading}>
                            <Icon viewBox="0 0 1024 1024">
                              <FontAwesomeIcon icon={faPrint} fixedWidth />
                            </Icon>
                        Print
                          </Button>
                        </Popconfirm>
                      </Col>
                    ) : ''}
                    {(userId === inboundDetails.created_by || ['admin', 'super_admin'].includes(userRole))
                  && isDisabled
                  && !isFinalized
                  && !isCancelled ? (
                    <Col xs={24} sm={12} lg={8}>
                      <Button type="danger" onClick={this.cancelInbound} block disabled={isLoading}>
                        <Icon viewBox="0 0 1024 1024">
                          <FontAwesomeIcon icon={faBan} fixedWidth />
                        </Icon>
                        Cancel
                      </Button>
                    </Col>
                      ) : ''}
                    {!isFinalized && isDisabled && !isCancelled ? (
                      <Col xs={24} sm={12} lg={8}>
                        <Popconfirm
                          placement="leftBottom"
                          title="Are you sure you want to finalize this inbound?"
                          okText="Yes"
                          onConfirm={this.finalizeInbound}
                          cancelText="No"
                        >
                          <Button className="btn-success" block disabled={isLoading}>
                            <Icon viewBox="0 0 1024 1024">
                              <FontAwesomeIcon icon={faSave} fixedWidth disabled={isLoading} />
                            </Icon>
                                Finalize
                          </Button>
                        </Popconfirm>
                      </Col>
                    ) : ''}
                  </Row>
                </React.Fragment>
            )}
            >
              <Row>
                <Form layout="horizontal">
                  <Row>
                    <Col xs={24} sm={24} lg={6}>
                      <Form.Item label="Origin">
                        {getFieldDecorator('origin', {
                          rules: [{ required: true, message: 'Origin cannot be blank' }],
                          initialValue: inboundDetails.origin,
                        })(<Select options={originOptions} disabled={isDisabled} />)}
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} lg={6}>
                      <Form.Item label="Shipping Type">
                        {getFieldDecorator('shipping_type', {
                          initialValue: inboundDetails.shipping_type,
                        })(<Select options={shippingTypeOptions} disabled={isDisabled} />)}
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} lg={6}>
                      <Form.Item label="Gate">
                        {getFieldDecorator('gate', {
                          rules: [{ required: true, message: 'Gate cannot be blank' }],
                          initialValue: inboundDetails.gate ? inboundDetails.gate.value : '',
                        })(<GroupSelect
                          options={gateOptions}
                          disabled={isDisabled}
                        />)}
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} lg={6}>
                      <Form.Item label="Driver">
                        {getFieldDecorator('driver', {
                          rules: [{ required: true, message: 'Driver cannot be blank' }],
                          initialValue: inboundDetails.driver,
                        })(<Input disabled={isDisabled} />)}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={24} sm={24} lg={6}>
                      <Form.Item label="Merchant">
                        {getFieldDecorator('merchant', {
                          rules: [{ required: false }],
                          initialValue: inboundDetails.merchant,
                        })(<Input disabled={isDisabled} />)}
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} lg={6}>
                      <Form.Item label="Plate Number">
                        {getFieldDecorator('plate_number', {
                          rules: [{ required: true, message: 'Plate number cannot be blank' }],
                          initialValue: inboundDetails.plate_number ? inboundDetails.plate_number.value : '',
                        })(<Select
                          disabled={isDisabled}
                          key="plateNumber"
                          options={plateNumberOptions}
                          placeholder="Please type to search plate number"
                          filterOption={false}
                          onSearch={this.searchPlateNumber}
                          onChange={this.setPlateNumber}
                          showSearch
                        />)}

                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} lg={6}>
                      <Form.Item label="Truck Type">
                        {getFieldDecorator('truck_type', {
                          rules: [{ required: true, message: 'Truck type cannot be blank' }],
                          initialValue: type === '' ? inboundDetails.truck_type : type,
                        })(<Input disabled />)}

                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} lg={6}>
                      <Form.Item label="Hauler">
                        {getFieldDecorator('hauler', {
                          rules: [{ required: true, message: 'Hauler cannot be blank' }],
                          initialValue: inboundDetails.hauler ? inboundDetails.hauler.value : '',
                        })(<Select placeholder="Select hauler" options={haulerOptions} disabled={isDisabled} />)}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={24} sm={24} lg={6}>
                      <Form.Item label="Arrival Time">
                        {getFieldDecorator('arrival_time', {
                          rules: [{ required: true, message: 'Arrival time cannot be blank' }],
                          initialValue: inboundDetails.arrival_time ? moment(inboundDetails.arrival_time, 'YYYY-MM-DD HH:mm:ss') : null,
                        })(<DatePicker style={{ width: '100%' }} format={dateFormat} showTime={{ dateFormat: 'HH:mm' }} disabled={isDisabled} disabledDate={date => new Date() <= date} />)}

                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} lg={6}>
                      <Form.Item label="Pickup Date">
                        {getFieldDecorator('pickup_date', {
                          rules: [{ required: true, message: 'Pickup date cannot be blank' }],
                          initialValue: inboundDetails.pickup_date ? moment(inboundDetails.pickup_date, 'YYYY-MM-DD HH:mm:ss') : null,
                        })(<DatePicker style={{ width: '100%' }} format={dateFormat} showTime={{ dateFormat: 'HH:mm' }} disabled={isDisabled} disabledDate={date => new Date() <= date} />)}
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} lg={6}>
                      <Form.Item label="Inbound Area">
                        {getFieldDecorator('inbound_area', {
                          initialValue: inboundDetails.inbound_area,
                        })(<Select options={inboundAreaOptions} disabled={isDisabled} />)}
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} lg={6}>
                      <Form.Item label="Created By">
                        {getFieldDecorator('created_by', {
                          initialValue: type !== 'undefined' && type !== null
                            ? inboundDetails.user : null,
                        })(<Input disabled />)}
                      </Form.Item>
                    </Col>

                  </Row>
                  <Row>
                    <Col xs={24} sm={24} lg={6}>
                      <Form.Item label="Truck Utilization">
                        <Progress percent={truckUtilization} successPercent={0} />
                        {
                          isDisabled ? '' : (
                            <InputGroup compact>
                              <Button style={{ width: '10%' }} icon="minus" onClick={this.minusTruckUtilization} disabled={isDisabled} />
                              <Input style={{ width: '80%' }} type="number" onChange={this.inputTruckUtilization} min="0" max="100" value={truckUtilization} disabled={isDisabled} />
                              <Button style={{ width: '10%' }} icon="plus" onClick={this.addTruckUtilization} disabled={isDisabled} />
                            </InputGroup>
                          )
                        }
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Row>
            </Card>
            <Spacer />
            <Card>
              <Row>
                <center>
                  <Row style={{ marginBottom: 50 }}>
                    <Col xs={24} sm={24} lg={12}>
                      <Label title="Inbound Start Time" value={inboundDetails.start} />
                    </Col>
                    <Col xs={24} sm={24} lg={12}>
                      <Label title="Gate Time Initial Checkout" value={inboundDetails.scan_time ? inboundDetails.scan_time : '---'} />
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: 50 }}>
                    <Col xs={24} sm={24} lg={12}>
                      <Label title="Inbound End Time" value={inboundDetails.end ? inboundDetails.end : '---'} />
                    </Col>
                    <Col xs={24} sm={24} lg={12}>
                      <Label title="Initial Gate Time Duration" value={inboundDetails.scan_time ? inboundDetails.gate_time : '---'} />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={24} sm={24} lg={12}>
                      <Label title="Inbound Total Hours" value={inboundDetails.end ? inboundTime : '---'} />
                    </Col>
                  </Row>
                </center>
              </Row>
            </Card>
            <Spacer />
            <Modal
              title="Upload Inbound File"
              visible={uploadModalVisibility}
              onCancel={() => this.setState({ fileList: [], uploadModalVisibility: false })}
              onOk={this.submitUploadHandler}
              okText={isLoading ? 'Uploading . . .' : 'Submit'}
              okButtonProps={{
                disabled: isLoading,
              }}
            >
              <Form encType="multipart/form-data">
                <Dragger
                  multiple
                  fileList={fileList}
                  beforeUpload={this.beforeUploadHandler}
                  onRemove={this.removeUploadHandler}
                  accept=".doc,.docx,.pdf"
                >
                  <p className="ant-upload-drag-icon" />
                  <p className="ant-upload-text">Click or drag file to this area to upload</p>
                </Dragger>
              </Form>
            </Modal>
            <Card
              title="Files / Documents"
              extra={(
                <Button
                  type="primary"
                  onClick={() => this.setState({ uploadModalVisibility: true })}
                >
                  Upload
                </Button>
              )}
            >
              <ReactTable
                data={inboundDetails.files}
                columns={[
                  {
                    Header: 'Filename',
                    accessor: 'upload_file_name',
                  },
                  {
                    Header: 'Uploaded At',
                    accessor: 'created_at',
                  },
                  {
                    Header: 'Uploaded By',
                    accessor: 'created_by',
                  },
                  {
                    Header: 'Actions',
                    Cell: data => (
                      <Row>
                        <Col span={12}>
                          <Button type="link" onClick={() => this.downloadMovementFile(data.original.id, data.original.upload_file_name)}>
                            <Icon viewBox="0 0 1024 1024">
                              <FontAwesomeIcon icon={faFileDownload} fixedWidth />
                            </Icon>
                            Download
                          </Button>
                        </Col>
                        <Col span={12}>
                          <Popconfirm
                            placement="leftBottom"
                            title={`Are you sure you want to delete ${data.row.upload_file_name}?`}
                            okText="Yes"
                            onConfirm={() => this.deleteMovementFile(data.original.id)}
                          >
                            <Button type="link" block>
                              <Icon viewBox="0 0 1024 1024">
                                <FontAwesomeIcon icon={faTrash} fixedWidth />
                              </Icon>
                            Delete
                            </Button>
                          </Popconfirm>

                        </Col>
                      </Row>
                    ),
                  },
                ]}
                minRows={2}
              />
            </Card>
            <Card>
              <Row>
                <center>
                  <Col xs={24} sm={8} lg={8}>
                    <Label title="Good" value={accepted.length} />
                  </Col>
                  <Col xs={24} sm={8} lg={8}>
                    <Label title="Refused" value={refused.length} />
                  </Col>
                  <Col xs={24} sm={8} lg={8}>
                    <Label title="Total Scans" value={accepted.length + refused.length} />
                  </Col>
                </center>
              </Row>
            </Card>
            <Spacer />
            <Card
              title="Inbound Packages"
              extra={(
                <React.Fragment>
                  <Row>
                    <Col xs={24} sm={12} lg={12}>
                      {getFieldDecorator('packageFilter', {
                        initialValue: 0,
                      })(<Select
                        options={packagesFilter}
                        style={{ width: '150px' }}
                        onChange={this.filterPackages}
                      />)}
                    </Col>
                    {!isFinalized && !isCancelled ? (
                      <Col xs={24} sm={12} lg={12}>
                        <Button type="primary" block onClick={this.editRedirect} disabled={isLoading}>
                          <Icon viewBox="0 0 1024 1024">
                            <FontAwesomeIcon icon={faBox} fixedWidth />
                          </Icon>
                        Edit Packages
                        </Button>
                      </Col>
                    ) : ''}
                  </Row>
                </React.Fragment>
              )}
            >
              <Spin spinning={isFetchingPackages} tip="Fetching packages...">
                <ReactTable
                  data={deletedOnly ? deletedPackages : inboundDetails.packages}
                  columns={[
                    {
                      Header: 'Tracking Number',
                      accessor: 'tracking_number',
                    },
                    {
                      Header: 'Set of.',
                      accessor: 'item_count',
                    },
                    {
                      Header: 'Package Type',
                      accessor: 'package_type',
                    },
                    {
                      Header: 'Remarks',
                      accessor: 'remarks',
                    },
                    {
                      Header: 'Client',
                      accessor: 'client',
                    },
                    {
                      Header: 'Section',
                      accessor: 'section',
                    },
                    {
                      Header: 'Created By',
                      accessor: 'user.full_name',
                    },
                    {
                      Header: 'Created On',
                      accessor: 'created_at',
                    },
                  ]}
                />
              </Spin>
            </Card>
          </Container>
        </Spin>
      </div>
    );
  }
}

InboundView.defaultProps = {
  branch: {
    id: null,
    name: null,
  },
};

InboundView.propTypes = {
  doGetInbound: PropTypes.func.isRequired,
  doGetHaulerSelect: PropTypes.func.isRequired,
  doGetBranchGates: PropTypes.func.isRequired,
  doGetVehiclesLike: PropTypes.func.isRequired,
  doFinalizeInbound: PropTypes.func.isRequired,
  doUpdateInboundDetails: PropTypes.func.isRequired,
  doExportInbound: PropTypes.func.isRequired,
  doCancelInbound: PropTypes.func.isRequired,
  doPrintInbound: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired,
  userRole: PropTypes.string.isRequired,
  doGetOrigins: PropTypes.func.isRequired,
  doGetShippingTypes: PropTypes.func.isRequired,
  doGetInboundAreas: PropTypes.func.isRequired,
  doUploadInboundFiles: PropTypes.func.isRequired,
  doDownloadInboundFile: PropTypes.func.isRequired,
  doDeleteInboundFile: PropTypes.func.isRequired,
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
  history: PropTypes.oneOfType([PropTypes.object]).isRequired,
  match: PropTypes.oneOfType([PropTypes.object]).isRequired,
  branch: PropTypes.oneOfType([PropTypes.object]),
};

const mapDispatchToProps = {
  doGetInbound: getInbound,
  doGetHaulerSelect: getHaulerSelect,
  doGetBranchGates: getBranchGates,
  doGetVehiclesLike: getVehiclesLike,
  doUpdateInboundDetails: updateInboundDetails,
  doFinalizeInbound: finalizeInbound,
  doExportInbound: exportInbound,
  doCancelInbound: cancelInbound,
  doPrintInbound: printInbound,
  doGetOrigins: getOrigins,
  doGetShippingTypes: getShippingTypes,
  doGetInboundAreas: getInboundAreas,
  doUploadInboundFiles: uploadInboundFiles,
  doDownloadInboundFile: downloadInboundFile,
  doDeleteInboundFile: deleteInboundFile,
};

const mapStateToProps = state => ({
  branch: state.auth.branch,
  userId: state.auth.user.sub,
  userRole: state.auth.user.role,
});

const WrappedInboundView = Form.create({ name: 'InboundCreate' })(InboundView);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WrappedInboundView));
