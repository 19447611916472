import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Col, Form, Input, Button, Icon, message,
} from 'antd';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import Select from '../../components/Select';
import Row from '../../components/Row';
import PageHeader from '../../components/PageHeader';
import Card from '../../components/Card';
import Container from '../../components/Container';
import { create, getUsername } from './ducks';

import { getOutsourceSelect } from '../outsource/ducks';

class UserCreate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmitting: false,
      isFetching: false,
      outsourceOptions: [],

    };

    this.formSubmit = this.formSubmit.bind(this);
    this.usernameValidate = this.usernameValidate.bind(this);
  }

  componentDidMount() {
    const { doGetOutsourceSelect } = this.props;
    doGetOutsourceSelect().then((action) => {
      this.setState({
        outsourceOptions: action.payload.data,
      });
    });
  }

  usernameValidate(rule, value, callback) {
    const { findUsername } = this.props;

    this.setState({
      isFetching: true,
    });

    findUsername(value)
      .then(res => (res.payload.data.exists
        ? callback('Username is already taken.') : callback()));
  }

  formSubmit(e) {
    e.preventDefault();
    const { form, doCreateUser, branch } = this.props;
    const { validateFields } = form;

    this.setState({
      isSubmitting: true,
    });

    validateFields((err, values) => {
      if (!err) {
        const params = {
          ...values,
          branch_id: branch.id,
        };
        doCreateUser(params).then(() => {
          this.setState({
            isSubmitting: false,
          });
          message.success('Successfully created user!');
          form.resetFields();
        }).catch((action) => {
          let errorMessage;
          if (action.error.response.status === 422) {
            const index = Object.values(action.error.response.data);
            // eslint-disable-next-line prefer-destructuring
            errorMessage = index[0][0];
          } else {
            errorMessage = 'Something went wrong, please try again later.';
          }
          message.error(errorMessage, 3);
        });
      } else {
        this.setState({
          isSubmitting: false,
        });
      }
    });
  }

  render() {
    const {
      form,
    } = this.props;

    const {
      isSubmitting,
      isFetching,
      outsourceOptions,
    } = this.state;

    const { getFieldDecorator } = form;

    const roleOptions = [
      {
        value: 'admin',
        label: 'Admin',
      },
      {
        value: 'documentation',
        label: 'Dcoumentation',
      },
      {
        value: 'encoder',
        label: 'Encoder',
      },
    ];

    return (
      <div className="UserCreate">
        <PageHeader title="User" />
        <Container>
          <Card title="Create User">
            <Form layout="horizontal">
              <Row>
                <Col xs={24} sm={12} lg={12}>
                  <Form.Item label="First Name">
                    {getFieldDecorator('first_name', {
                      rules: [{ required: true, message: 'First Name cannot be blank.' }],
                    })(<Input placeholder="Enter first name" autoComplete="off" />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={12}>
                  <Form.Item label="Last Name">
                    {getFieldDecorator('last_name', {
                      rules: [{ required: true, message: 'Last Name cannot be blank' }],
                    })(<Input placeholder="Enter last name" autoComplete="off" />)}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={12} lg={12}>
                  <Form.Item
                    label="Username"
                    hasFeedback={isFetching}
                  >
                    {getFieldDecorator('username', {
                      rules: [
                        { required: true, message: 'Username cannot be blank.' },
                        { validator: this.usernameValidate },
                        {
                          min: 5, message: 'Username must have 5 or more characters.',
                        },
                      ],
                    })(<Input placeholder="Enter username" autoComplete="off" />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={12}>
                  <Form.Item label="Password">
                    {getFieldDecorator('password', {
                      rules: [
                        { required: true, message: 'Password cannot be blank' },
                        {
                          min: 7, message: 'Username must have 7 or more characters.',
                        },
                      ],
                    })(<Input.Password placeholder="Enter password" />)}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={12} lg={12}>
                  <Form.Item label="Role">
                    {getFieldDecorator('role', {
                      rules: [{ required: true, message: 'Role cannot be blank' }],
                      initialValue: 'encoder',
                    })(<Select placeholder="Select role" options={roleOptions} />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={12}>
                  <Form.Item label="Outsource Group">
                    {getFieldDecorator('outsource_group_id', {
                      rules: [{ required: true, message: 'Outsource group cannot be blank' }],
                    })(<Select placeholder="Select outsource group" options={outsourceOptions} />)}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={12} lg={12}>
                  <Form.Item label="Address">
                    {getFieldDecorator('address', {
                      rules: [{ required: false, message: 'Address cannot be blank' }],
                    })(<Input placeholder="Enter address" autoComplete="off" />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={12}>
                  <Form.Item label="Contact">
                    {getFieldDecorator('contact', {
                      rules: [
                        { required: false, message: 'Contact cannot be blank' },
                        { pattern: '^[0-9]*$', message: 'Contact must contain numbers only' },
                      ],
                    })(<Input placeholder="Enter contact" autoComplete="off" />)}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Item>
                    <Button type="primary" onClick={this.formSubmit} disabled={isSubmitting} block>
                      <Icon viewBox="0 0 1024 1024">
                        <FontAwesomeIcon icon={faPlus} fixedWidth />
                      </Icon>
                        Create
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </Container>
      </div>
    );
  }
}

UserCreate.propTypes = {
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
  doCreateUser: PropTypes.func.isRequired,
  findUsername: PropTypes.func.isRequired,
  doGetOutsourceSelect: PropTypes.func.isRequired,
  branch: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const mapDispatchToProps = {
  doCreateUser: create,
  findUsername: getUsername,
  doGetOutsourceSelect: getOutsourceSelect,
};

const mapStateToProps = state => ({
  branch: state.auth.branch,
});

const WrappedUserCreate = Form.create({ name: 'UserCreate' })(UserCreate);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WrappedUserCreate);
