import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink, withRouter } from 'react-router-dom';
import {
  Col, Form, Button, Icon, Descriptions, Table, Spin, Popconfirm, message,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlusSquare, faMinusSquare } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import Row from '../../components/Row';
import PageHeader from '../../components/PageHeader';
import Card from '../../components/Card';
import Container from '../../components/Container';
import { getIncident, updateStatus } from './ducks';

class IncidentReportView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      irDetails: null,
      isLoading: true,
    };

    this.update = this.update.bind(this);
  }

  componentDidMount() {
    const {
      match, doGetIncident, history, branch,
    } = this.props;

    doGetIncident(match.params.id, branch).then((action) => {
      this.setState({
        isLoading: false,
        irDetails: action.payload.data,
      });
    }).catch(() => {
      history.push('/404');
    });
  }

  update(status) {
    const { match, doUpdateStatus, doGetIncident } = this.props;

    const data = {
      status,
    };

    this.setState({
      isLoading: true,
    });

    doUpdateStatus(data, match.params.id).then(() => {
      doGetIncident(match.params.id).then((action) => {
        this.setState({
          isLoading: false,
          irDetails: action.payload.data,
        });
      });
    }).catch(() => {
      message.error('Something went wrong, please try again later.');
    });
  }

  render() {
    const { irDetails, isLoading } = this.state;
    const { match, user } = this.props;
    const extra = [];

    if (irDetails) {
      if (irDetails.user.username === user.username && irDetails.status === 'pending') {
        extra.push(
          <Row key="owner">
            <Col xs={24} sm={24} lg={user.role === 'admin' ? 8 : 24}>
              <NavLink to={`/incidentReport/view/${match.params.id}/edit`}>
                <Button type="default">
                  <Icon viewBox="0 0 1024 1024">
                    <FontAwesomeIcon icon={faEdit} fixedWidth />
                  </Icon>
                  Update
                </Button>
              </NavLink>
            </Col>
            {user.role === 'admin' && irDetails.status === 'pending'
              ? (
                <React.Fragment key="admin">
                  <Col xs={24} sm={24} lg={user.role === 'admin' ? 8 : 24}>
                    <Popconfirm
                      placement="topRight"
                      title="Are you sure you want to approve this record?"
                      onConfirm={() => { this.update('approved'); }}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button type="primary">
                        <Icon viewBox="0 0 1024 1024">
                          <FontAwesomeIcon icon={faPlusSquare} fixedWidth />
                        </Icon>
                              Approve
                      </Button>
                    </Popconfirm>
                  </Col>
                  <Col xs={24} sm={24} lg={user.role === 'admin' ? 8 : 24}>
                    <Popconfirm
                      placement="topRight"
                      title="Are you sure you want to disapprove this record?"
                      onConfirm={() => { this.update('disapproved'); }}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button type="danger">
                        <Icon viewBox="0 0 1024 1024">
                          <FontAwesomeIcon icon={faMinusSquare} fixedWidth />
                        </Icon>
                              Disapprove
                      </Button>
                    </Popconfirm>
                  </Col>
                </React.Fragment>
              )
              : ''}
          </Row>,
        );
      } else if (irDetails.user.username !== user.username) {
        if (user.role === 'admin' && irDetails.status === 'pending') {
          extra.push(
            <Row key="not-owner">
              <Col xs={24} sm={24} lg={12}>
                <Popconfirm
                  placement="topRight"
                  title="Are you sure you want to approve this record?"
                  onConfirm={() => { this.update('approved'); }}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button type="primary">
                    <Icon viewBox="0 0 1024 1024">
                      <FontAwesomeIcon icon={faPlusSquare} fixedWidth />
                    </Icon>
                              Approve
                  </Button>
                </Popconfirm>
              </Col>
              <Col xs={24} sm={24} lg={12}>
                <Popconfirm
                  placement="topRight"
                  title="Are you sure you want to disapprove this record?"
                  onConfirm={() => { this.update('disapproved'); }}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button type="danger">
                    <Icon viewBox="0 0 1024 1024">
                      <FontAwesomeIcon icon={faMinusSquare} fixedWidth />
                    </Icon>
                              Disapprove
                  </Button>
                </Popconfirm>
              </Col>
            </Row>,

          );
        }
      }
    }
    return (

      <div className="IncidentReportView">
        <Spin spinning={isLoading} tip="Fetching report details...">
          <PageHeader title="Incident Report" />
          <Container>
            <Card
              title="Incident Report Details"
              extra={extra}
            >
              <Row>
                <Col>
                  <Descriptions bordered>
                    <Descriptions.Item label="Status" span={2}>{irDetails ? irDetails.status : ''}</Descriptions.Item>
                    <Descriptions.Item label="Incident Date" span={1}>{irDetails ? irDetails.incident_report_date : ''}</Descriptions.Item>
                    <Descriptions.Item label="Incident Details" span={3}>{irDetails ? irDetails.description : ''}</Descriptions.Item>
                    <Descriptions.Item label="Tracking Numbers">
                      <Table
                        size="small"
                        dataSource={irDetails ? irDetails.incident_report_package : []}
                        rowKey={record => record.id}
                        columns={[
                          {
                            title: 'Tracking Number',
                            dataIndex: 'tracking_number',
                            key: 'tracking_number',
                            align: 'center',

                          },
                          {
                            title: 'Description',
                            dataIndex: 'description',
                            key: 'desription',
                            align: 'center',
                          },
                        ]}
                      />
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
              </Row>

            </Card>
          </Container>
        </Spin>

      </div>
    );
  }
}

IncidentReportView.propTypes = {
  match: PropTypes.oneOfType([PropTypes.object]).isRequired,
  user: PropTypes.oneOfType([PropTypes.object]).isRequired,
  history: PropTypes.oneOfType([PropTypes.object]).isRequired,
  branch: PropTypes.oneOfType([PropTypes.object]).isRequired,
  doGetIncident: PropTypes.func.isRequired,
  doUpdateStatus: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.auth.user,
  branch: state.auth.branch,
});

const mapDispatchToProps = {
  doGetIncident: getIncident,
  doUpdateStatus: updateStatus,
};


const WrappedIncidentReportView = Form.create({ name: 'IncidentReportView' })(IncidentReportView);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WrappedIncidentReportView));
