import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button, Col, Statistic as Label, Row,
} from 'antd';
import Card from '../../components/Card';
import ReactTable from '../../components/ReactTable';
import Spacer from '../../components/Spacer';

class InboundReprocessResult extends Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.inboundReprocessRedirect = this.inboundReprocessRedirect.bind(this);
  }

  inboundReprocessRedirect() {
    const { history } = this.props;
    history.push(`/reprocess/inbound/${history.location.state.id}`);
  }

  render() {
    const { history } = this.props;
    const totalResult = [
      ...history.location.state.created,
      ...history.location.state.updated,
      ...history.location.state.unsaved,
      ...history.location.state.deleted,
    ];

    return (
      <Card title="Inbound Packages result">
        <Card title="">
          <Row>
            <center>
              <Col xs={24} sm={24} lg={4}>
                <Label title="Created" value={history.location.state.created.length} />
              </Col>
              <Col xs={24} sm={24} lg={4} offset={1}>
                <Label title="Updated" value={history.location.state.updated.length} />
              </Col>
              <Col xs={24} sm={24} lg={4} offset={1}>
                <Label title="Failed" value={history.location.state.unsaved.length} />
              </Col>
              <Col xs={24} sm={24} lg={4} offset={1}>
                <Label title="Deleted" value={history.location.state.deleted.length} />
              </Col>
              <Col xs={24} sm={24} lg={4} offset={1}>
                <Label title="Total Scans" value={totalResult.length} />
              </Col>
            </center>
          </Row>
        </Card>
        <Spacer />
        <ReactTable
        // eslint-disable-next-line no-unused-vars
          getTrProps={(state, rowData, instance) => {
            if (typeof rowData !== 'undefined') {
              if (rowData.row.status === 'refused'
              || rowData.row.status === 'failed'
              || rowData.row.status === 'deleted'
              ) {
                return {
                  style: {
                    background: '#ffccc7',
                  },
                };
              }

              if (rowData.row.status === 'updated') {
                return {
                  style: {
                    background: '#bae7ff',
                  },
                };
              }

              if (rowData.row.status === 'added') {
                return {
                  style: {
                    background: '#b3ff99',
                  },
                };
              }
            }

            return {
              style: {
                background: 'white',
              },
            };
          }}
          data={totalResult}
          columns={[
            {
              id: 'tracking_number',
              Header: 'Tracking Number',
              accessor: data => data.package_details.tracking_number,
            },
            {
              id: 'item_count',
              Header: 'Item Count',
              accessor: data => data.package_details.item_count,
            },
            {
              id: 'package_type',
              Header: 'Package Type',
              accessor: data => data.package_details.package_type,
            },
            {
              id: 'remarks',
              Header: 'Package Remarks',
              accessor: data => data.package_details.remarks,
            },
            {
              id: 'section',
              Header: 'Section',
              accessor: data => data.package_details.section,
            },
            {
              Header: 'Status',
              accessor: 'status',
            },
            {
              Header: 'Message',
              accessor: 'message',
              width: 320,

            }]}
        />
        <Spacer />
        <Button onClick={this.inboundReprocessRedirect} block type="primary">View Inbound Reprocess</Button>
      </Card>

    );
  }
}

InboundReprocessResult.propTypes = {
  history: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default connect(null, null)(InboundReprocessResult);
