import React, { Component } from 'react';
import { Select as AntSelect } from 'antd';
import PropTypes from 'prop-types';


export class Select extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.renderOptions = this.renderOptions.bind(this);
  }

  renderOptions() {
    const { options } = this.props;
    const { Option } = AntSelect;
    const selection = options.map(item => (
      <Option key={`${item.label}-${item.value}`} value={item.value} disabled={item.disabled}>{item.label}</Option>
    ));

    return selection;
  }

  render() {
    const { mode, labelInValue } = this.props;
    return (
      <AntSelect {...this.props} mode={mode} labelInValue={labelInValue}>
        {this.renderOptions()}
      </AntSelect>
    );
  }
}

Select.propTypes = {
  options: PropTypes.oneOfType([PropTypes.array]),
  mode: PropTypes.string,
  labelInValue: PropTypes.bool,
};

Select.defaultProps = {
  options: [],
  mode: 'default',
  labelInValue: false,
};

export default Select;
