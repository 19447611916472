import moment from 'moment';
import {
  all,
  takeLatest,
  select,
  put,
} from 'redux-saga/effects';
import {
  ADD_INBOUND_SUCCESS,
  UPDATE_INBOUNDS_FROM_STATE,
  CANCEL_INBOUND_SUCCESS,
  ADD_INBOUND_PACKAGES_SUCCESS,
  FETCH_INBOUND_LIST_SUCCESS,
  GET_INBOUND_SUCCESS,
} from './ducks';

function checkStateAge(state) {
  const dateDiff = moment(moment(new Date(), 'YYYY-MM-DD HH:mm:ss')).diff(state.lastPackageScanDate);
  const age = moment.utc(moment.duration(dateDiff, 'ms').asMilliseconds()).format('H');
  // delete if age > 24 hours
  if (age > 24) {
    return null;
  }
  return state;
}

function* cleanState() {
  const domain = state => state.inbound;
  const inbounds = yield select(domain);
  const ids = Object.keys(inbounds);
  const updatedInbounds = [];
  ids.forEach((id) => {
    if (checkStateAge(inbounds[id]) !== null) {
      updatedInbounds[id] = checkStateAge(inbounds[id]);
    }
  });
  yield put({
    type: UPDATE_INBOUNDS_FROM_STATE,
    payload: {
      inbounds: updatedInbounds,
    },
  });
}

export default function* () {
  yield all([
    takeLatest(ADD_INBOUND_SUCCESS, cleanState),
    takeLatest(CANCEL_INBOUND_SUCCESS, cleanState),
    takeLatest(ADD_INBOUND_PACKAGES_SUCCESS, cleanState),
    takeLatest(FETCH_INBOUND_LIST_SUCCESS, cleanState),
    takeLatest(GET_INBOUND_SUCCESS, cleanState),
  ]);
}
