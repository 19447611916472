import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Form, Col, Input, Button, Icon, Popconfirm, message,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus, faSave, faEdit, faTrash, faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import Select from '../../components/Select';
import Row from '../../components/Row';
import PageHeader from '../../components/PageHeader';
import Card from '../../components/Card';
import Container from '../../components/Container';
import ReactTable from '../../components/ServerSideTable';
import Spacer from '../../components/Spacer';

import {
  addVehicle, getVehicles, updateVehicle, deletVehicle, getTruckTypes,
} from './ducks';

class Vehicle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vehicleList: {
        rows: [],
        pages: 0,
        total: 0,
      },
      isFetching: false,
      fetchMessage: null,
      editedVehicle: null,
      currentTable: {
        page: null,
        pageSize: null,
        filters: [],
        sorts: [],
      },
      truckTypes: [],
    };

    this.formSubmit = this.formSubmit.bind(this);
    this.onFetchData = this.onFetchData.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this.setToEdit = this.setToEdit.bind(this);
    this.cancelEdit = this.cancelEdit.bind(this);
    this.onUpdateSubmit = this.onUpdateSubmit.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
  }

  componentDidMount() {
    const { doGetTruckTypes } = this.props;
    doGetTruckTypes().then((action) => {
      this.setState({ truckTypes: action.payload.data });
    });
  }

  onFetchData(state) {
    const { doGetVehicles, branch } = this.props;

    const params = {
      page: state.page + 1,
      pageSize: state.pageSize,
      filters: state.filtered,
      sorts: state.sorted,
      branchId: branch.id,
    };

    this.setState({
      currentTable: params,
      isFetching: true,
      editedVehicle: null,
      fetchMessage: 'Fetching vehicle list . . .',
    });

    doGetVehicles(params)
      .then((action) => {
        this.setState({
          vehicleList: {
            rows: action.payload.data.data,
            pages: action.payload.data.last_page,
            total: action.payload.data.total,
          },
          isFetching: false,
          fetchMessage: null,
        });
      })
      .catch(() => {
        this.setState({
          isFetching: false,
          fetchMessage: null,
        });
      });
  }

  onEdit(cellInfo) {
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const { vehicleList, editedVehicle, truckTypes } = this.state;

    if (editedVehicle === cellInfo.original.id) {
      if (cellInfo.column.id === 'truck_type') {
        return (
          <Form.Item style={{ margin: '0px' }}>
            {getFieldDecorator(`${cellInfo.column.id}_update`, {
              rules: [{ required: false, message: 'Truck type cannot be blank' }],
              initialValue: vehicleList.rows[cellInfo.index][cellInfo.column.id],
            })(<Select options={truckTypes} placeholder="E.g. ABC 123" />)}
          </Form.Item>
        );
      }
      return (
        <Form.Item style={{ margin: '0px' }}>
          {getFieldDecorator(`${cellInfo.column.id}_update`, {
            rules: [{ required: true, message: `${cellInfo.column.Header} is required.` }],
            initialValue: vehicleList.rows[cellInfo.index][cellInfo.column.id],
          })(<Input autoComplete="off" />)}
        </Form.Item>
      );
    }

    return (
      <div>
        {cellInfo.column.id === 'truck_type'
          ? vehicleList.rows[cellInfo.index][cellInfo.column.id].replace(/_/g, ' ')
          : vehicleList.rows[cellInfo.index][cellInfo.column.id]}
      </div>
    );
  }

  onUpdateSubmit(e) {
    e.preventDefault();
    const { form, doUpdateVehicle } = this.props;
    const { validateFields } = form;
    const { editedVehicle } = this.state;

    validateFields(['plate_number_update', 'truck_type_update'], (err, values) => {
      const data = {
        plate_number: values.plate_number_update,
        truck_type: values.truck_type_update,
      };

      if (!err) {
        doUpdateVehicle(data, editedVehicle)
          .then(() => {
            const { currentTable } = this.state;

            const params = {
              page: currentTable.page - 1,
              pageSize: currentTable.pageSize,
              filtered: currentTable.filters,
              sorted: currentTable.sorts,
            };

            this.setState({
              editedVehicle: null,
            });

            message.success('Successfully updated vehicle!', 3);
            this.onFetchData(params);
          })
          .catch((action) => {
            let errorMessage;
            if (action.error.response.status === 422) {
              const index = Object.values(action.error.response.data);
              // eslint-disable-next-line prefer-destructuring
              errorMessage = index[0][0];
            } else {
              errorMessage = 'Something went wrong, please try again later.';
            }
            message.error(errorMessage, 3);
          });
      }
    });
  }

  setToEdit(vehicleId) {
    this.setState({
      editedVehicle: vehicleId,
    });
  }

  formSubmit(e) {
    e.preventDefault();

    const { form, doAddVehicle, branch } = this.props;
    const { validateFields } = form;

    validateFields((err, values) => {
      if (!err) {
        const data = {
          truck_type: values.truck_type,
          plate_number: values.plate_number,
          branch_id: branch.id,
        };
        doAddVehicle(data)
          .then(() => {
            const { currentTable } = this.state;

            const params = {
              page: currentTable.page - 1,
              pageSize: currentTable.pageSize,
              filtered: currentTable.filters,
              sorted: currentTable.sorts,
            };

            this.setState({
              editedVehicle: null,
            });

            message.success('Successfully created vehicle!', 3);
            this.onFetchData(params);

            form.resetFields();
          })
          .catch((action) => {
            let errorMessage;
            if (action.error.response.status === 422) {
              const index = Object.values(action.error.response.data);
              // eslint-disable-next-line prefer-destructuring
              errorMessage = index[0][0];
            } else {
              errorMessage = 'Something went wrong, please try again later.';
            }
            message.error(errorMessage, 3);
          });
      }
    });
  }

  cancelEdit() {
    this.setState({
      editedVehicle: null,
    });
  }

  confirmDelete(vehicleId) {
    const { doDeleteVehicle } = this.props;
    doDeleteVehicle(vehicleId)
      .then((action) => {
        if (action.payload.data) {
          const { currentTable } = this.state;
          const params = {
            page: currentTable.page - 1,
            pageSize: currentTable.pageSize,
            filtered: currentTable.filters,
            sorted: currentTable.sorts,
          };
          this.setState({
            editedVehicle: null,
          });
          message.success('Successfully deleted vehicle.', 3);
          this.onFetchData(params);
        } else {
          message.error('Unable to delete data that has 1 or more reference(s).', 3);
        }
      })
      .catch(() => {
        message.error('Something went wrong, please try again later.', 3);
      });
  }

  render() {
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const {
      fetchMessage, isFetching, vehicleList, editedVehicle,
      truckTypes,
    } = this.state;

    const truckTypeLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
    };

    const plateNumberLayout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
    };

    const buttonLayout = {
      wrapperCol: { span: 14 },
    };

    return (
      <div className="vehicle">
        <PageHeader title="Vehicle" />
        <Container>
          <Card title="Vehicle Information">
            <Form layout="horizontal" onSubmit={this.formSubmit}>
              <Row>
                <Col xs={24} sm={12} lg={8}>
                  <Form.Item label="Truck Type" {...truckTypeLayout}>
                    {getFieldDecorator('truck_type', {
                      rules: [{ required: true, message: 'Truck type cannot be blank' }],
                    })(<Select options={truckTypes} placeholder="E.g. 10WH" />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={8}>
                  <Form.Item label="Plate Number" {...plateNumberLayout}>
                    {getFieldDecorator('plate_number', {
                      rules: [{ required: true, message: 'Plate number cannot be blank.' }],
                    })(<Input autoComplete="off" placeholder="E.g. ABC 123" />)}
                  </Form.Item>
                </Col>

                <Col xs={24} sm={12} lg={8}>
                  <Form.Item {...buttonLayout}>
                    <Button type="primary" onClick={this.formSubmit} block>
                      <Icon viewBox="0 0 1024 1024">
                        <FontAwesomeIcon icon={faPlus} fixedWidth />
                      </Icon>
                      Create
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
          <Spacer />
          <Card title="Vehicle List">
            <Form onSubmit={this.onUpdateSubmit}>
              <ReactTable
                data={vehicleList.rows}
                pages={vehicleList.pages}
                columns={[
                  {
                    Header: 'Id',
                    accessor: 'id',
                  },
                  {
                    Header: 'Truck Type',
                    accessor: 'truck_type',
                    Cell: this.onEdit,
                  },
                  {
                    Header: 'Plate Number',
                    accessor: 'plate_number',
                    Cell: this.onEdit,
                  },
                  {
                    id: 'created_by',
                    Header: 'Created by',
                    accessor: data => data.user.full_name,
                  },
                  {
                    Header: 'Options',
                    sortable: false,
                    filterable: false,
                    width: 215,
                    Cell: data => (
                      <Row>
                        {editedVehicle !== data.row.id ? (
                          <Row>
                            <Col span={12}>
                              <Button
                                type="link"
                                onClick={() => this.setToEdit(data.row.id)}
                                block
                              >
                                <Icon viewBox="0 0 1024 1024">
                                  <FontAwesomeIcon icon={faEdit} fixedWidth />
                                </Icon>
                                Edit
                              </Button>
                            </Col>
                            <Col span={12}>
                              <Popconfirm
                                placement="leftBottom"
                                title={`Are you sure you want to delete ${data.row.plate_number} from the list?`}
                                okText="Yes"
                                onConfirm={() => this.confirmDelete(data.row.id)}
                                cancelText="No"
                              >
                                <Button type="link" block>
                                  <Icon viewBox="0 0 1024 1024">
                                    <FontAwesomeIcon icon={faTrash} fixedWidth />
                                  </Icon>
                                  Delete
                                </Button>
                              </Popconfirm>
                            </Col>
                          </Row>
                        ) : (
                          <div>
                            <Col span={12}>
                              <Button type="link" htmlType="submit" block>
                                <Icon viewBox="0 0 1024 1024">
                                  <FontAwesomeIcon icon={faSave} fixedWidth />
                                </Icon>
                                Save
                              </Button>
                            </Col>

                            <Col span={12}>
                              <Button type="link" onClick={this.cancelEdit} block>
                                <Icon viewBox="0 0 1024 1024">
                                  <FontAwesomeIcon icon={faTimes} fixedWidth />
                                </Icon>
                                Cancel
                              </Button>
                            </Col>
                          </div>
                        )}
                      </Row>
                    ),
                  },
                ]}
                loadingText={fetchMessage}
                loading={isFetching}
                onFetchData={this.onFetchData}
              />
            </Form>
          </Card>
        </Container>
      </div>
    );
  }
}

Vehicle.propTypes = {
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
  branch: PropTypes.oneOfType([PropTypes.object]).isRequired,
  doAddVehicle: PropTypes.func.isRequired,
  doGetVehicles: PropTypes.func.isRequired,
  doUpdateVehicle: PropTypes.func.isRequired,
  doDeleteVehicle: PropTypes.func.isRequired,
  doGetTruckTypes: PropTypes.func.isRequired,
};

const mapDispatchtoProps = {
  doAddVehicle: addVehicle,
  doGetVehicles: getVehicles,
  doUpdateVehicle: updateVehicle,
  doDeleteVehicle: deletVehicle,
  doGetTruckTypes: getTruckTypes,
};

const mapStateToProps = state => ({
  branch: state.auth.branch,
});

const WrappedVehicle = Form.create({ name: 'Vehicle' })(Vehicle);
export default connect(
  mapStateToProps,
  mapDispatchtoProps,
)(WrappedVehicle);
