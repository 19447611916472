import React, { Component } from 'react';
import Table from 'react-table';
import 'react-table/react-table.css';
import PropTypes from 'prop-types';

class ReactTable extends Component {
  constructor(props) {
    super(props);

    this.data = [];
  }

  render() {
    const {
      columns, data, getTrProps, showPagination, minRows, SubComponent,
    } = this.props;
    return (
      <Table
        filterable
        data={data}
        columns={columns}
        loading={false}
        className="-highlight"
        defaultPageSize={10}
        getTrProps={getTrProps}
        showPagination={showPagination}
        minRows={minRows}
        SubComponent={SubComponent}
      />
    );
  }
}


ReactTable.defaultProps = {
  data: [],
  getTrProps: () => [],
  showPagination: true,
  minRows: 10,
  SubComponent: undefined,
};

ReactTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  SubComponent: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.object),
  getTrProps: PropTypes.func,
  showPagination: PropTypes.bool,
  minRows: PropTypes.number,
};

export default ReactTable;
