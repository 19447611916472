import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Form, Col, Input, Button, Icon,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSearch,
} from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import _ from 'lodash';
import Row from '../../components/Row';
import PageHeader from '../../components/PageHeader';
import Card from '../../components/Card';
import Container from '../../components/Container';
import ReactTable from '../../components/ServerSideTable';
import Spacer from '../../components/Spacer';
import {
  getMotorcycle,
} from './ducks';

class MotorcycleList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      motorcycleList: {
        rows: [],
        pages: 0,
        total: 0,
      },
      isFetching: false,
      fetchMessage: null,
      currentTable: {
        page: null,
        pageSize: null,
        filters: [],
        sorts: [],
      },
      formFilters: [],
    };
    this.onFetchData = this.onFetchData.bind(this);
    this.fetchMotorcycle = this.fetchMotorcycle.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }


  // eslint-disable-next-line no-unused-vars
  onFetchData(state, instance) {
    const { branch } = this.props;
    const { formFilters } = this.state;
    const params = {
      page: state.page + 1,
      pageSize: state.pageSize,
      filters: state.filtered,
      sorts: state.sorted,
      branchId: branch.id,
      formFilters,
    };

    this.setState({
      currentTable: params,
      isFetching: true,
      fetchMessage: 'Fetching motorcycle list . . .',
    }, () => {
      this.fetchMotorcycle();
    });
  }

  onSubmit(e) {
    e.preventDefault();
    const { form } = this.props;
    const { validateFields } = form;

    validateFields((err, values) => {
      const parsedFormFilter = [];
      _.forEach(values, (value, key) => {
        if (value) {
          if (key === 'reference') {
            parsedFormFilter.push({ id: key, value: values.reference.split('\n').filter(ref => ref !== '') });
          } else {
            parsedFormFilter.push({ id: key, value });
          }
        }
      });
      this.setState({ formFilters: parsedFormFilter }, () => {
        if (!err) {
          this.fetchMotorcycle();
          this.setState({
            isFetching: true,
            fetchMessage: 'Fetching Motorcycle list . . .',
          });
        }
      });
    });
  }

  fetchMotorcycle() {
    const { doGetMotorcycle } = this.props;
    const { currentTable, formFilters } = this.state;
    doGetMotorcycle({ ...currentTable, formFilters }).then((action) => {
      this.setState({
        motorcycleList: {
          rows: action.payload.data.data,
          pages: action.payload.data.last_page,
          total: action.payload.data.total,
        },
        isFetching: false,
        fetchMessage: null,
      });
      // eslint-disable-next-line no-unused-vars
    }).catch((action) => {
      this.setState({
        isFetching: false,
        fetchMessage: null,
      });
    });
  }

  render() {
    const { form } = this.props;
    const { TextArea } = Input;
    const { getFieldDecorator } = form;

    const {
      fetchMessage, isFetching, motorcycleList,
    } = this.state;

    const formItemLayout = {
      labelCol: { span: 2 },
      wrapperCol: { span: 22 },
    };
    return (
      <div className="motorcycle">
        <PageHeader title="Motorcycle" />
        <Container>
          <Card title="Motorcycle Information">
            <Form onSubmit={this.onSubmit} className="ant-advanced-search-form">
              <Row gutter={24}>
                <Col xs={24} sm={24} lg={24}>
                  <Form.Item label="Reference" {...formItemLayout}>
                    {getFieldDecorator('reference', {
                      rules: [{ required: false }],
                      initialValue: [],
                    })(<TextArea />)}
                  </Form.Item>
                </Col>
              </Row>
              <Button type="primary" htmlType="submit" block>
                <Icon viewBox="0 0 1024 1024">
                  <FontAwesomeIcon icon={faSearch} fixedWidth />
                </Icon>
                    Search
              </Button>
            </Form>
          </Card>
          <Spacer />
          <Card title="Motorcycle List">
            <Form onSubmit={this.onUpdateSubmit}>
              <ReactTable
                data={motorcycleList.rows}
                pages={motorcycleList.pages}
                columns={[
                  {
                    Header: 'Id',
                    accessor: 'id',
                  },
                  {
                    Header: 'Engine Number',
                    accessor: 'engine_number',
                  },
                  {
                    Header: 'Chassis Number',
                    accessor: 'chassis_number',
                  },
                  {
                    Header: 'Uploaded by',
                    accessor: 'user.full_name',
                  },
                ]}

                loadingText={fetchMessage}
                loading={isFetching}
                onFetchData={this.onFetchData}
              />
            </Form>
          </Card>

        </Container>
      </div>
    );
  }
}

MotorcycleList.propTypes = {
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
  branch: PropTypes.oneOfType([PropTypes.object]).isRequired,
  doGetMotorcycle: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  doGetMotorcycle: getMotorcycle,
};

const mapStateToProps = state => ({
  branch: state.auth.branch,
});

const WrappedMotorcycle = Form.create({ name: 'Motorcycle' })(MotorcycleList);
export default connect(mapStateToProps, mapDispatchToProps)(WrappedMotorcycle);
