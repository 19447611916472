import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Col, Form, Input, Popconfirm, Spin,
  Button, Icon, Radio, message, Statistic as Label,
  Slider,
} from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus, faCheckCircle, faEdit, faTrash, faSave, faTimes,
} from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import moment from 'moment';
import Select from '../../components/Select';
import Row from '../../components/Row';
import PageHeader from '../../components/PageHeader';
import Card from '../../components/Card';
import Container from '../../components/Container';
import ReactTable from '../../components/ReactTable';
import Spacer from '../../components/Spacer';
import { validatePackage, storePackages, getRemarks } from './ducks';

import {
  getSectionSelect,
} from '../section/ducks';

class InboundReprocessCreate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sectionOptions: [],
      packagesSuccess: [],
      packagesErrors: [],
      remarks: {},
      lastPackageScannedSize: 24,
      editedTrackingNumber: null,
      isEditable: false,
      editableRow: true,
      isFinalizing: false,
    };

    this.addPackage = this.addPackage.bind(this);
    this.setToEdit = this.setToEdit.bind(this);
    this.cancelEdit = this.cancelEdit.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this.updatePackage = this.updatePackage.bind(this);
    this.confirmRemove = this.confirmRemove.bind(this);
    this.savePackages = this.savePackages.bind(this);
    this.zoomLastPackageScanned = this.zoomLastPackageScanned.bind(this);
  }

  componentDidMount() {
    const { doGetRemarks, doGetSectionSelect } = this.props;
    doGetRemarks().then((action) => {
      this.setState({ remarks: action.payload.data });
    });
    doGetSectionSelect().then((action) => {
      this.setState({
        sectionOptions: action.payload.data,
      });
    });
  }

  onEdit(cellInfo) {
    const {
      packagesSuccess,
      editableRow,
      sectionOptions,
    } = this.state;

    const { form } = this.props;

    const { getFieldDecorator } = form;

    const packageTypes = [
      { value: 'pouch', label: 'Pouch' },
      { value: 'bulky', label: 'Bulky' },
      { value: 'm-bulky', label: 'M-Bulky' },
    ];

    if (editableRow === cellInfo.index) {
      if (cellInfo.column.id === 'package_type') {
        return (
          <Form.Item style={{ margin: '0px' }}>
            {getFieldDecorator(`${cellInfo.column.id}_update`, {
              rules: [{ required: true, message: `${cellInfo.column.Header} is required.` }],
              initialValue: packagesSuccess[cellInfo.index][cellInfo.column.id],
            })(<Select options={packageTypes} />)}
          </Form.Item>
        );
      }
      if (cellInfo.column.id === 'item_count') {
        return (
          <Form.Item style={{ margin: '0px' }}>
            {getFieldDecorator(`${cellInfo.column.id}_update`, {
              rules: [{ required: true, message: `${cellInfo.column.Header} is required.` }],
              initialValue: packagesSuccess[cellInfo.index][cellInfo.column.id],
            })(<Input />)}
          </Form.Item>
        );
      } if (cellInfo.column.id === 'section') {
        return (
          <Form.Item style={{ margin: '0px' }}>
            {getFieldDecorator(`${cellInfo.column.id}_update`, {
              // eslint-disable-next-line max-len
              initialValue: packagesSuccess[cellInfo.index][cellInfo.column.id],
            })(<Select options={sectionOptions} />)}
          </Form.Item>
        );
      }
    }

    return (
      <div>
        {packagesSuccess[cellInfo.index][cellInfo.column.id]}
      </div>
    );
  }

  setToEdit(rowId, rowTN) {
    this.setState({
      isEditable: true,
      editableRow: rowId,
      editedTrackingNumber: rowTN,
    });
  }

  cancelEdit() {
    this.setState({
      editableRow: null,
      isEditable: false,
      editedTrackingNumber: null,
    });
  }

  updatePackage(e) {
    e.preventDefault();
    const { form, branch } = this.props;
    const { validateFields } = form;
    const { editedTrackingNumber, packagesSuccess } = this.state;
    validateFields(['item_count_update', 'package_type_update', 'section_update'], (err, values) => {
      if (!err) {
        const params = {
          location: branch.id,
          remarks: 'good',
          package_type: values.package_type_update,
          item_count: values.item_count_update,
          section: values.section_update,
        };
        const newPackages = packagesSuccess.map((item) => {
          if (editedTrackingNumber !== item.tracking_number) {
            return item;
          }
          return {
            ...item,
            ...params,
          };
        });
        message.success('Package updated successfully!', 5);
        this.setState({
          packagesSuccess: newPackages,
          editableRow: null,
          isEditable: false,
          editedTrackingNumber: null,
        });
      }
    });
  }

  addPackage(e) {
    e.preventDefault();
    const { form, doValidatePackage, branch } = this.props;
    const { validateFields } = form;
    const { packagesSuccess, packagesErrors } = this.state;

    validateFields(['item_count', 'package_type', 'tracking_number', 'section'], (err, values) => {
      if (!err) {
        // get input tracking numbers
        let trackingNumbers = values.tracking_number.split('\n').filter(tn => tn !== '');
        trackingNumbers = trackingNumbers.map(trackingNumber => trackingNumber.toUpperCase());
        // remove duplicates with scanned packages
        const filteredTrackingNumbers = trackingNumbers.map((trackingNumber) => {
          if (!_.find(packagesSuccess, { tracking_number: trackingNumber })) {
            return trackingNumber;
          }
          return null;
        });

        // get duplicates on input packages
        const groupped = _.groupBy(trackingNumbers, (tn => tn));
        const duplicatesInput = _.uniq(_.flatten(_.filter(groupped, (tn => tn.length > 1))));

        // get duplicates on scanned packages
        const duplicatesScanned = _.intersection(
          trackingNumbers,
          packagesSuccess.map((packageDetails => packageDetails.tracking_number)),
        );

        // chunk input to 100
        const batchTrackingNumbers = _.chunk(_.uniq(_.compact(filteredTrackingNumbers)), 100);

        const duplicates = _.concat(duplicatesInput, duplicatesScanned);
        if (duplicates.length) {
          const duplicatedTn = packagesErrors;
          duplicates.forEach((trackingNumber) => {
            duplicatedTn.push({
              message: 'Package already scanned',
              tracking_number: trackingNumber,
              issued_at: moment().format('YYYY-MM-DD HH:mm:ss'),
            });
          });
          message.error(`${duplicates.length} packages are already scanned!`);
          this.setState({
            packagesErrors: duplicatedTn,
          });
        }

        batchTrackingNumbers.forEach((batch) => {
          doValidatePackage({
            tracking_number: batch,
            movement_id: null,
            branch_id: branch.id,
            origin: null,
            remarks: null,
          }).then((action) => {
            const { success, errors } = action.payload.data;
            if (success) {
              let packages = Object.keys(success);

              // revalidate response for duplicate packages
              const revalidatedPackages = _.intersection(
                packages,
                packagesSuccess.map((packageDetails => packageDetails.tracking_number)),
              );
              if (revalidatedPackages.length) {
                const duplicatedTn = packagesErrors;
                revalidatedPackages.forEach((trackingNumber) => {
                  duplicatedTn.push({
                    message: 'Package already scanned',
                    tracking_number: trackingNumber,
                    issued_at: moment().format('YYYY-MM-DD HH:mm:ss'),
                  });
                });
                packages = _.difference(packages, revalidatedPackages);
                message.error(`${revalidatedPackages.length} packages are already scanned!`);
                this.setState({
                  packagesErrors: duplicatedTn,
                });
              }

              // parse validated packages
              const parsedSuccessPackages = packages.map((trackingNumber) => {
                const params = {
                  location: branch.id,
                  ...values,
                  tracking_number: trackingNumber,
                  remarks: 'good',
                  is_refused: false,
                };
                return params;
              });
              if (packages.length) {
                this.setState(prevState => ({
                  packagesSuccess: parsedSuccessPackages.concat(prevState.packagesSuccess),
                }));

                message.success(`Successfully inserted ${packages.length} packages `);
              }
            }

            if (errors) {
              const packages = Object.keys(errors);
              const parsedErrorPackages = packages.map(trackingNumber => ({
                message: errors[trackingNumber].message,
                tracking_number: trackingNumber,
                issued_at: moment().format('YYYY-MM-DD HH:mm:ss'),
              }));
              this.setState(prevState => ({
                packagesErrors: parsedErrorPackages.concat(prevState.packagesErrors),
              }));
              message.error(`Failed to insert ${_.values(errors).length} packages`);
            }
          });
        });

        form.resetFields(['tracking_number']);
      }
    });
  }

  savePackages() {
    const { packagesSuccess } = this.state;
    const {
      branch,
      doStorePackages,
      history,
      form,
    } = this.props;
    const { validateFields } = form;

    validateFields(['released_by'], (err, values) => {
      if (!err) {
        if (packagesSuccess.length === 0) {
          message.warning('No scanned data found!', 2);
        } else {
          this.setState({
            isFinalizing: true,
          }, window.scrollTo(0, 0));

          const params = {
            details: {
              location: branch.id,
              released_by: values.released_by,
            },
            reprocessed_packages: {
              create: _.uniqBy(packagesSuccess, 'tracking_number'),
              update: [],
              delete: [],
              branch_id: branch.id,
              origin: null,
            },
          };
          doStorePackages(params).then((action) => {
            history.push('/reprocess/inbound/result', action.payload.data);
          }).catch(() => {
            this.setState({
              isFinalizing: false,
            });
          });
        }
      }
    });
  }

  confirmRemove(rowTN) {
    const { packagesSuccess } = this.state;
    const newPackage = packagesSuccess.filter(item => item.tracking_number !== rowTN);
    this.setState({
      packagesSuccess: newPackage,
    });
    message.success('Package removed successfully!', 3);
  }

  zoomLastPackageScanned(e) {
    this.setState({
      lastPackageScannedSize: 24 + e,
    });
  }

  render() {
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const { TextArea } = Input;
    const {
      packagesSuccess, packagesErrors, isEditable,
      // eslint-disable-next-line no-unused-vars
      editableRow, isFinalizing, remarks, lastPackageScannedSize,
      sectionOptions,
    } = this.state;

    return (
      <div className="InboundReprocessCreate">
        <Spin spinning={isFinalizing} tip="Preparing to checkout packages, please dont't refresh or leave this page. . .">
          <PageHeader title="Inbound Reprocess" />
          <Container>
            <Card title="Inbound-reprocess package details">
              <Row>
                <Col>
                  <Form layout="horizontal" onSubmit={this.addPackage}>
                    <Row>
                      <Col>
                        <Form.Item label="Section">
                          {getFieldDecorator('section', {
                          })(<Select options={sectionOptions} />)}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={24} sm={12} lg={12}>
                        <Form.Item label="Set of.">
                          {getFieldDecorator('item_count', {
                            rules: [{ required: true, message: 'Set of. cannot be blank.' }],
                            initialValue: 1,
                          })(<Input />)}
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12} lg={12}>
                        <Form.Item label="Package Type">
                          {getFieldDecorator('package_type', {
                            rules: [{ required: true, message: 'Package Type cannot be blank.' }],
                            initialValue: 'bulky',
                          })(
                            <Radio.Group>
                              <Radio value="bulky">BULKY</Radio>
                              <Radio value="m-bulky">M-BULKY</Radio>
                              <Radio value="pouch">POUCH</Radio>
                            </Radio.Group>,
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Form.Item label="Tracking Number">
                      {getFieldDecorator('tracking_number', {
                        rules: [{ required: true, message: 'Tracking Number cannot be blank.' }],
                      })(<TextArea autoComplete="off" onPressEnter={this.addPackage} placeholder="E.g. X0123" />)}
                    </Form.Item>
                    <Row>
                      <Col>
                        <Form.Item>
                          <Button type="primary" htmlType="submit" disabled={isFinalizing} block>
                            <Icon viewBox="0 0 1024 1024">
                              <FontAwesomeIcon icon={faPlus} fixedWidth />
                            </Icon>
                        Add Tracking Number
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </Card>
            <Spacer />
            <Card title="">
              <Row>
                <center>
                  <Col
                    xs={24}
                    sm={lastPackageScannedSize > 44 ? 24 : 12}
                    lg={lastPackageScannedSize > 44 ? 24 : 12}
                  >
                    <Label
                      title={(
                        <React.Fragment>
                          Last Package Scanned
                          <Slider
                            defaultValue={0}
                            onAfterChange={this.zoomLastPackageScanned}
                            max={35}
                          />
                        </React.Fragment>
                      )}
                      value={packagesSuccess.length === 0
                        ? ''
                        : packagesSuccess[0].tracking_number
                    }
                      groupSeparator=""
                      valueStyle={{ fontSize: lastPackageScannedSize, wordWrap: 'break-word' }}
                    />
                  </Col>
                  <Col
                    xs={24}
                    sm={lastPackageScannedSize > 44 ? 24 : 12}
                    lg={lastPackageScannedSize > 44 ? 24 : 12}
                  >
                    <Label
                      title="Total Scanned Packages"
                      value={packagesSuccess.length}
                      valueStyle={{ fontSize: lastPackageScannedSize, wordWrap: 'break-word' }}
                    />
                  </Col>
                </center>
              </Row>
            </Card>
            <Spacer />
            <Card title="Inbound Reprocess Packages">
              <Form onSubmit={this.updatePackage}>
                <ReactTable
                  data={packagesSuccess}
                  columns={[
                    {
                      Header: 'Tracking Number',
                      accessor: 'tracking_number',
                    },
                    {
                      Header: 'Set of.',
                      accessor: 'item_count',
                      Cell: this.onEdit,
                    },
                    {
                      Header: 'Package Type',
                      accessor: 'package_type',
                      Cell: this.onEdit,
                    },
                    {
                      Header: 'Remarks',
                      accessor: 'remarks',
                      Cell: this.onEdit,
                    },
                    {
                      Header: 'Section',
                      accessor: 'section',
                      Cell: this.onEdit,
                    },
                    {
                      Header: 'Options',
                      sortable: false,
                      filterable: false,
                      Cell: data => (
                        <Row>
                          {
                 (!isEditable || data.index !== editableRow)
                   ? (
                     <Row>
                       <Col span={12}>
                         <Button type="link" onClick={() => this.setToEdit(data.index, data.row.tracking_number)} block>
                           <Icon viewBox="0 0 1024 1024">
                             <FontAwesomeIcon icon={faEdit} fixedWidth />
                           </Icon>
                      Edit
                         </Button>
                       </Col>
                       <Col span={12}>
                         <Popconfirm
                           placement="leftBottom"
                           title={`Are you sure you want to delete ${data.row.tracking_number} from the list?`}
                           okText="Yes"
                           onConfirm={() => this.confirmRemove(data.row.tracking_number)}
                           cancelText="No"
                         >
                           <Button type="link" block>
                             <Icon viewBox="0 0 1024 1024">
                               <FontAwesomeIcon icon={faTrash} fixedWidth />
                             </Icon>
                      Remove
                           </Button>
                         </Popconfirm>
                       </Col>
                     </Row>
                   )
                   : (
                     <div>
                       <Col span={12}>
                         <Button type="link" htmlType="submit" disabled={isFinalizing} block>
                           <Icon viewBox="0 0 1024 1024">
                             <FontAwesomeIcon icon={faSave} fixedWidth />
                           </Icon>
                           Save
                         </Button>
                       </Col>
                       <Col span={12}>
                         <Button type="link" onClick={this.cancelEdit} block>
                           <Icon viewBox="0 0 1024 1024">
                             <FontAwesomeIcon icon={faTimes} fixedWidth />
                           </Icon>
                           Cancel
                         </Button>
                       </Col>
                     </div>
                   )
                  }
                        </Row>
                      ),
                    },
                  ]}
                />
              </Form>
            </Card>
            <Spacer />
            {packagesErrors.length !== 0
              ? (
                <Card title="Error List">
                  <ReactTable
                    data={packagesErrors}
                    columns={[
                      {
                        Header: 'Tracking Number',
                        accessor: 'tracking_number',
                      },
                      {
                        Header: 'Error Message',
                        accessor: 'message',
                      },
                      {
                        Header: 'Issued At',
                        accessor: 'issued_at',
                      },
                    ]}
                  />
                </Card>
              ) : ''}

            <Spacer />
            <Card title="Inbound-reprocess details">
              <Row>
                <Form>
                  <Col>
                    <Form.Item label="Released By: ">
                      {getFieldDecorator('released_by', {
                        rules: [{ required: true, message: 'Released By cannot be blank.' }],
                      })(<Input placeholder="Enter released by" />)}
                    </Form.Item>
                  </Col>
                  <Col>
                    <Popconfirm
                      placement="top"
                      title="Are you sure you want to finalize scanning for this movement?"
                      okText="Yes"
                      onConfirm={this.savePackages}
                      cancelText="No"
                      disabled={isFinalizing || !packagesSuccess.length}
                    >
                      <Button type="primary" disabled={isFinalizing || !packagesSuccess.length} block>
                        <Icon viewBox="0 0 1024 1024">
                          <FontAwesomeIcon icon={faCheckCircle} fixedWidth />
                        </Icon>
                           Checkout
                      </Button>
                    </Popconfirm>
                  </Col>
                </Form>
              </Row>
            </Card>
          </Container>
        </Spin>
      </div>
    );
  }
}

InboundReprocessCreate.defaultProps = {
  branch: {
    id: null,
    name: null,
  },
};

const mapStateToProps = state => ({
  branch: state.auth.branch,
});

InboundReprocessCreate.propTypes = {
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
  history: PropTypes.oneOfType([PropTypes.object]).isRequired,
  branch: PropTypes.oneOfType([PropTypes.object]),
  doValidatePackage: PropTypes.func.isRequired,
  doStorePackages: PropTypes.func.isRequired,
  doGetRemarks: PropTypes.func.isRequired,
  doGetSectionSelect: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  doValidatePackage: validatePackage,
  doStorePackages: storePackages,
  doGetRemarks: getRemarks,
  doGetSectionSelect: getSectionSelect,
};

const WrappedInboundReprocessCreate = Form.create(
  { name: 'InboundReprocessCreate' },
)(InboundReprocessCreate);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(WrappedInboundReprocessCreate),
);
