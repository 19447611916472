import {
  apiAddMerchant, apiGetMerchants, apiUpdateMerchant, apiDeleteMerchant,
  apiGetMerchantsLike,
} from '../../services/apiEndpoints';

export const ADD_MERCHANT = 'merchant/ADD_MERCHANT';
export const addMerchant = merchant => ({
  type: ADD_MERCHANT,
  payload: {
    client: 'secure',
    request: {
      ...apiAddMerchant,
      data: merchant,
    },
  },
});

export const FETCH_MERCHANT = 'merchant/FETCH_MERCHANT';
export const getMerchant = data => ({
  type: FETCH_MERCHANT,
  payload: {
    client: 'secure',
    request: {
      ...apiGetMerchants,
      params: {
        ...data,
        filters: JSON.stringify(data.filters),
        sorts: JSON.stringify(data.sorts),
      },
    },
  },
});

export const UPDATE_MERCHANT = 'merchant/UPDATE_MERCHANT';
export const updateMerchant = (params, merchantId) => ({
  type: UPDATE_MERCHANT,
  payload: {
    client: 'secure',
    request: {
      ...apiUpdateMerchant,
      url: `/merchant/${merchantId}`,
      params,
    },
  },
});

export const DELETE_MERCHANT = 'merchant/DELETE_MERCHANT';
export const deleteMerchant = merchantId => ({
  type: DELETE_MERCHANT,
  payload: {
    client: 'secure',
    request: {
      ...apiDeleteMerchant,
      url: `/merchant/${merchantId}`,
    },
  },
});

export const GET_MERCHANT_SELECT = 'merchant/GET_MERCHANT_SELECT';
export const getMerchantsLike = name => ({
  type: GET_MERCHANT_SELECT,
  payload: {
    client: 'secure',
    request: {
      ...apiGetMerchantsLike,
      url: `/merchant/like/${name}`,
    },
  },
});

const initialState = {};

function reducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}

export default reducer;
