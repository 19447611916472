import {
  all, put, call, takeLatest, race, take,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import jwtDecode from 'jwt-decode';
import { message } from 'antd';

import {
  LOGIN_SUCCESS,
  REFRESH_TOKEN,
  REFRESH_TOKEN_SUCCESS,
  REFRESH_TOKEN_FAIL,
  REFRESH_TOKEN_REQUEST,
  REFRESH_TOKEN_REQUEST_SUCCESS,
  REFRESH_TOKEN_REQUEST_FAIL,
  LOGOUT,
  LOGOUT_SUCCESS,
  LOGOUT_FAIL,
  logout as logoutAction,
} from './ducks';
import { store, get, remove } from '../../services/localStorage';
import { apiRefresh } from '../../services/apiEndpoints';

/**
 * Login Procedure
 *
 * User logs in using credentials
 * API receives request
 * API provides refresh_token
 * App stores refresh_token
 * App calls refresh endpoint using stored token
 * If app fails refresh, logout user
 * If app succeeds refresh, store token in memory (redux store)
 */
function* success(action) {
  /* eslint-disable camelcase */
  const { refresh_token } = action.payload.data;
  yield call(store, 'refresh_token', refresh_token);
  yield put({ type: REFRESH_TOKEN });

  yield take(REFRESH_TOKEN_SUCCESS);
  yield put(push('/'));
  /* eslint-enable camelcase */
}

/**
 * Refresh Access Token Procedure
 *
 * Fetch new token from API
 * API processes request
 * If app fails refresh, logout user
 * If app succeeds refresh, store token in memory (redux store)
 * Additional refresh_token payload should update storage
 */
function* refreshToken() {
  const userRefreshToken = yield call(get, 'refresh_token');

  if (!userRefreshToken) {
    yield put(logoutAction());
    yield put({ type: REFRESH_TOKEN_FAIL });
    return false;
  }

  yield put({
    type: REFRESH_TOKEN_REQUEST,
    payload: {
      client: 'open',
      request: {
        ...apiRefresh,
        data: {
          user_id: jwtDecode(userRefreshToken).sub,
          refresh_token: userRefreshToken,
        },
      },
    },
  });

  const { response, error } = yield race({
    response: take(REFRESH_TOKEN_REQUEST_SUCCESS),
    error: take(REFRESH_TOKEN_REQUEST_FAIL),
  });

  if (error) {
    yield put(logoutAction());
    yield put({ type: REFRESH_TOKEN_FAIL });
    return false;
  }

  if (typeof response.payload.data.refresh_token !== 'undefined') {
    yield call(store, 'refresh_token', response.payload.data.refresh_token);
  }

  yield put({
    type: REFRESH_TOKEN_SUCCESS,
    payload: {
      accessToken: response.payload.data.access_token,
      user: jwtDecode(response.payload.data.access_token),
    },
  });
  return true;
}

function* logout() {
  yield take(LOGOUT_SUCCESS);
  yield call(remove, 'refresh_token');
  yield put(push('/login'));
}

function* checkLogoutFailure(action) {
  if (action.error.response && action.error.response.status === 409) {
    yield put({ type: LOGOUT_SUCCESS });
    return true;
  }

  yield put(push('/'));
  yield call(message.error, 'Server unavailable! Please try again later.');
  return false;
}

export default function* () {
  yield all([
    takeLatest(LOGIN_SUCCESS, success),
    takeLatest(REFRESH_TOKEN, refreshToken),
    takeLatest(LOGOUT, logout),
    takeLatest(LOGOUT_FAIL, checkLogoutFailure),
  ]);
}
