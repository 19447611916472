import {
  apiAddClient, apiGetClients, apiUpdateClient, apiDeleteClient, apiGetClientSelect,
} from '../../services/apiEndpoints';

export const ADD_CLIENT = 'client/ADD_CLIENT';

export const addClient = client => ({
  type: ADD_CLIENT,
  payload: {
    client: 'secure',
    request: {
      ...apiAddClient,
      data: client,
    },
  },
});


export const GET_CLIENTS = 'client/GET_CLIENTS';
export const getClients = data => ({
  type: GET_CLIENTS,
  payload: {
    client: 'secure',
    request: {
      ...apiGetClients,
      params: {
        ...data,
        filters: JSON.stringify(data.filters),
        sorts: JSON.stringify(data.sorts),
      },
    },
  },
});

export const UPDATE_CLIENT = 'client/UPDATE_CLIENT';
export const updateClient = (params, clientId) => ({
  type: UPDATE_CLIENT,
  payload: {
    client: 'secure',
    request: {
      ...apiUpdateClient,
      url: `/client/${clientId}`,
      params,
    },
  },
});

export const DELETE_CLIENT = 'client/DELETE_CLIENT';
export const deleteClient = clientId => ({
  type: DELETE_CLIENT,
  payload: {
    client: 'secure',
    request: {
      ...apiDeleteClient,
      url: `/client/${clientId}`,
    },
  },
});

export const GET_CLIENT_SELECT = 'client/GET_CLIENT_SELECT';
export const getClientSelect = () => ({
  type: GET_CLIENT_SELECT,
  payload: {
    client: 'secure',
    request: {
      ...apiGetClientSelect,
    },
  },
});

const initialState = {};

function reducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}

export default reducer;
