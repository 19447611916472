import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Spin, Button, Row, Col,
} from 'antd';
import PropTypes from 'prop-types';
import FileDownload from 'js-file-download';
import Container from '../../components/Container';
import PageHeader from '../../components/PageHeader';
import BarChart from '../../components/BarChart';
import Card from '../../components/Card';
import Spacer from '../../components/Spacer';
import ReactTable from '../../components/ReactTable';
import Select from '../../components/Select';
import { fetchAgeingSummary, exportAgeingSummary } from './ducks';

class Ageing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ageChartData: {},
      ageSummaryData: [],
      labels: {
        '0-6': 0,
        '7-12': 0,
        '13-23': 0,
        '1D': 0,
        '2D': 0,
        '3D++': 0,
      },
      isLoading: true,
      mode: 'short_term',
    };

    this.exportAgeing = this.exportAgeing.bind(this);
    this.getAgeingSummary = this.getAgeingSummary.bind(this);
    this.changeMode = this.changeMode.bind(this);
  }

  componentDidMount() {
    this.getAgeingSummary();
  }

  getAgeingSummary() {
    const { doFetchAgeingSummary, branch } = this.props;
    const { labels, mode } = this.state;
    doFetchAgeingSummary(branch.id, mode).then((action) => {
      const summary = action.payload.data.result;
      const inboundAgeingData = { ...labels, type: 'inbound' };
      const inboundReprocessAgeingData = { ...labels, type: 'inbound_reprocess' };
      summary.forEach((data) => {
        if (data.status === 'inbound') {
          inboundAgeingData[data.ageing] = data.package_count;
        }

        if (data.status === 'inbound_reprocess') {
          inboundReprocessAgeingData[data.ageing] = data.package_count;
        }
      });

      this.setState({
        ageChartData: {
          labels: Object.keys(labels),
          datasets: [
            {
              label: 'Inbound',
              backgroundColor: 'rgb(77, 255, 77)',
              data: Object.values(inboundAgeingData),
            },
            {
              label: 'Inbound Reprocess',
              backgroundColor: 'rgb(255, 166, 77)',
              data: Object.values(inboundReprocessAgeingData),
            },
          ],
        },
        ageSummaryData: [inboundAgeingData, inboundReprocessAgeingData],
        isLoading: false,
      });
    });
  }

  exportAgeing(movementReference, age) {
    const { doExportAgeingSummary, branch } = this.props;
    doExportAgeingSummary(movementReference, age, branch.id).then((action) => {
      FileDownload(new Blob([action.payload.data]), `${movementReference}-${age}.xlsx`);
    });
  }

  changeMode(e) {
    let labels = {};

    if (e === 'short_term') {
      labels = {
        '0-6': 0,
        '7-12': 0,
        '13-23': 0,
        '1D': 0,
        '2D': 0,
        '3D++': 0,
      };
    } else {
      labels = {
        '1-7D': 0,
        '8-15D': 0,
        '16-29D': 0,
        '30D++': 0,
      };
    }
    this.setState({
      mode: e,
      isLoading: true,
      labels,
    }, () => {
      this.getAgeingSummary();
    });
  }

  render() {
    const {
      ageChartData, isLoading, ageSummaryData, mode,
    } = this.state;

    const modeOptions = [
      {
        label: 'Short Term',
        value: 'short_term',
      },
      {
        label: 'Long Term',
        value: 'long_term',
      },
    ];
    let columns = [];

    if (mode === 'short_term') {
      columns = [
        {
          Header: 'Movement Type',
          accessor: 'type',
          filterable: false,
        },
        {
          Header: '0-6 hrs',
          accessor: '0-6',
          filterable: false,
          Cell: data => (
            <Button type="link" onClick={() => (this.exportAgeing(data.row.type, '0-6'))}>
              {data.row['0-6']}
            </Button>
          ),
        },
        {
          Header: '7-12 hrs',
          accessor: '7-12',
          filterable: false,
          Cell: data => (
            <Button type="link" onClick={() => (this.exportAgeing(data.row.type, '7-12'))}>
              {data.row['7-12']}
            </Button>
          ),
        },
        {
          Header: '13-23 hrs',
          accessor: '13-23',
          filterable: false,
          Cell: data => (
            <Button type="link" onClick={() => (this.exportAgeing(data.row.type, '13-23'))}>
              {data.row['13-23']}
            </Button>
          ),
        },
        {
          Header: '1D',
          accessor: '1D',
          filterable: false,
          Cell: data => (
            <Button type="link" onClick={() => (this.exportAgeing(data.row.type, '1D'))}>
              {data.row['1D']}
            </Button>
          ),
        },
        {
          Header: '2D',
          accessor: '2D',
          filterable: false,
          Cell: data => (
            <Button type="link" onClick={() => (this.exportAgeing(data.row.type, '2D'))}>
              {data.row['2D']}
            </Button>
          ),
        },
        {
          Header: '3D++',
          accessor: '3D++',
          filterable: false,
          Cell: data => (
            <Button type="link" onClick={() => (this.exportAgeing(data.row.type, '3D'))}>
              {data.row['3D++']}
            </Button>
          ),
        },
      ];
    } else {
      columns = [
        {
          Header: 'Movement Type',
          accessor: 'type',
          filterable: false,
        },
        {
          Header: '1-7D',
          accessor: '1-7D',
          filterable: false,
          Cell: data => (
            <Button type="link" onClick={() => (this.exportAgeing(data.row.type, '1-7D'))}>
              {data.row['1-7D']}
            </Button>
          ),
        },
        {
          Header: '8-15D',
          accessor: '8-15D',
          filterable: false,
          Cell: data => (
            <Button type="link" onClick={() => (this.exportAgeing(data.row.type, '8-15D'))}>
              {data.row['8-15D']}
            </Button>
          ),
        },
        {
          Header: '16-29D',
          accessor: '16-29D',
          filterable: false,
          Cell: data => (
            <Button type="link" onClick={() => (this.exportAgeing(data.row.type, '16-29D'))}>
              {data.row['16-29D']}
            </Button>
          ),
        },
        {
          Header: '30D++',
          accessor: '30D++',
          filterable: false,
          Cell: data => (
            <Button type="link" onClick={() => (this.exportAgeing(data.row.type, '30D'))}>
              {data.row['30D++']}
            </Button>
          ),
        },
      ];
    }


    return (
      <div className="Inventory">
        <PageHeader title="Ageing" />
        <Spin spinning={isLoading} size="large" tip="Generating Summary . . . ">
          <Container>
            <Card
              title="Package Ageing Graph"
              extra={(
                <React.Fragment>
                  <Row>
                    <Col span={24}>
                      <Select
                        style={{ width: '200px' }}
                        defaultValue="short_term"
                        onChange={this.changeMode}
                        options={modeOptions}
                      />
                    </Col>
                  </Row>
                </React.Fragment>
              )}
            >
              <div style={{ width: '90%', margin: 'auto' }}>
                <BarChart data={ageChartData} />
                <Spacer />
                <ReactTable
                  data={ageSummaryData}
                  columns={columns}
                  showPagination={false}
                  minRows={2}
                />
              </div>
            </Card>
          </Container>
        </Spin>
      </div>
    );
  }
}

Ageing.propTypes = {
  doFetchAgeingSummary: PropTypes.func.isRequired,
  doExportAgeingSummary: PropTypes.func.isRequired,
  branch: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const mapDispatchToProps = {
  doFetchAgeingSummary: fetchAgeingSummary,
  doExportAgeingSummary: exportAgeingSummary,
};

const mapStateToProps = state => ({
  branch: state.auth.branch,
});

export default connect(mapStateToProps, mapDispatchToProps)(Ageing);
