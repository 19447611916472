import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Form, Button, Icon, Col, Input, Checkbox, message,
} from 'antd';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUserPlus, faUserTimes, faEdit, faSave, faTimes,
} from '@fortawesome/free-solid-svg-icons';
import Row from '../../components/Row';
import Select from '../../components/Select';
import PageHeader from '../../components/PageHeader';
import Card from '../../components/Card';
import Container from '../../components/Container';
import ServerSideTable from '../../components/ServerSideTable';
import Spacer from '../../components/Spacer';
import { getPendingUsers, updateUser, updateUserStatus } from './ducks';
import { getOutsourceSelect } from '../outsource/ducks';

class UserApproval extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userList: {
        rows: [],
        pages: 0,
        total: 0,
      },

      currentTable: {
        page: null,
        pageSize: null,
        filters: [],
        sorts: [],
      },

      isFetching: false,
      fetchMessage: null,
      isEditable: false,
      editableRow: null,
      editedUser: null,
      selectAll: false,
      checked: {
        isChecked: [],
        userId: [],
      },
      outsourceOptions: [],

    };

    this.onFetchData = this.onFetchData.bind(this);
    this.setToEdit = this.setToEdit.bind(this);
    this.cancelEdit = this.cancelEdit.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onSelectAll = this.onSelectAll.bind(this);
    this.onSingleSelect = this.onSingleSelect.bind(this);
    this.onChangeStatus = this.onChangeStatus.bind(this);
  }

  componentDidMount() {
    const { doGetOutsourceSelect } = this.props;

    doGetOutsourceSelect().then((action) => {
      this.setState({
        outsourceOptions: action.payload.data,
      });
    });
  }

  // eslint-disable-next-line no-unused-vars
  onFetchData(state, instance) {
    const { dofetchPending, branch } = this.props;

    const params = {
      page: state.page + 1,
      pageSize: state.pageSize,
      filters: state.filtered,
      sorts: state.sorted,
      branchId: branch.id,
    };

    this.setState({
      currentTable: params,
      isFetching: true,
      fetchMessage: 'Fetching user list . . .',
    });

    dofetchPending(params).then((action) => {
      this.setState({
        userList: {
          rows: action.payload.data.data,
          pages: action.payload.data.last_page,
          total: action.payload.data.total,
        },
        isFetching: false,
        fetchMessage: null,
        isEditable: false,
        editableRow: null,
        editedUser: null,
        selectAll: false,
        checked: {
          isChecked: [],
          userId: [],
        },
      });
    // eslint-disable-next-line no-unused-vars
    }).catch((action) => {
      this.setState({
        isFetching: false,
        fetchMessage: null,
        isEditable: false,
        editableRow: null,
        editedUser: null,
      });
    });
  }

  onSubmit(e) {
    e.preventDefault();
    const { form, doUpdate } = this.props;
    const { validateFields } = form;
    const { editedUser, currentTable } = this.state;

    validateFields((err, values) => {
      if (!err) {
        // eslint-disable-next-line no-unused-vars
        doUpdate(values, editedUser).then((action) => {
          const params = {
            page: currentTable.page - 1,
            pageSize: currentTable.pageSize,
            filtered: currentTable.filters,
            sorted: currentTable.sorts,
          };
          this.setState({
            editableRow: null,
            isEditable: false,
            editedUser: null,
          });

          message.success('Successfully updated user!', 3);
          this.onFetchData(params);
        }).catch((action) => {
          let errorMessage;
          if (action.error.response.status === 422) {
            const index = Object.values(action.error.response.data);
            // eslint-disable-next-line prefer-destructuring
            errorMessage = index[0][0];
          } else {
            errorMessage = 'Something went wrong, please try again later.';
          }
          message.error(errorMessage, 3);
        });
      }
    });
  }

  onEdit(cellInfo) {
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const { editableRow, userList, outsourceOptions } = this.state;

    let validation;

    const roleOptions = [
      {
        value: 'admin',
        label: 'Admin',
      },
      {
        value: 'documentation',
        label: 'Documentation',
      },
      {
        value: 'encoder',
        label: 'Encoder',
      },
    ];

    if (editableRow === cellInfo.index) {
      if (cellInfo.column.id === 'role') {
        return (
          <Form.Item style={{ margin: '0px' }}>
            {getFieldDecorator('role', {
              rules: [{ required: true, message: 'Role cannot be blank' }],
              initialValue: userList.rows[cellInfo.index][cellInfo.column.id],
            })(<Select options={roleOptions} placeholder="Select role" />)}
          </Form.Item>
        );
      }


      if (cellInfo.column.id === 'outsource_group_id') {
        return (
          <Form.Item style={{ margin: '0px' }}>
            {getFieldDecorator('outsource_group_id', {
              rules: [{ required: true, message: 'Outsource Group cannot be blank' }],
              initialValue: userList.rows[cellInfo.index].outsource.id,
            })(<Select options={outsourceOptions} placeholder="Select outsource group" />)}
          </Form.Item>
        );
      }

      if (cellInfo.column.id === 'contact') {
        validation = [{
          required: false, message: 'Contact cannot be blank',
        },
        {
          pattern: '^[0-9]*$', message: 'Contact must contain numbers only',
        }];
      } else if (cellInfo.column.id !== 'address') {
        validation = [{
          required: true, message: `${cellInfo.column.Header} cannot be blank`,
        }];
      }
      return (
        <Form.Item style={{ margin: '0px' }}>
          {getFieldDecorator(`${cellInfo.column.id}`, {
            rules: validation,
            initialValue: userList.rows[cellInfo.index][cellInfo.column.id],
          })(<Input placeholder={`Enter ${cellInfo.column.id}`} />)}
        </Form.Item>
      );
    }

    if (cellInfo.column.id === 'outsource_group_id') {
      return (
        <div>
          {userList.rows[cellInfo.index].outsource === null ? '-'
            : userList.rows[cellInfo.index].outsource.name}
        </div>
      );
    }

    return (
      <div>
        {userList.rows[cellInfo.index][cellInfo.column.id]}
      </div>
    );
  }

  onSelectAll(rows) {
    const { selectAll } = this.state;

    const selected = !selectAll;

    const checkedCopy = [];
    const usersCopy = [];

    this.setState({
      selectAll: selected,
    });

    // eslint-disable-next-line no-unused-vars
    rows.forEach((e, index) => {
      checkedCopy.push(selected);
      usersCopy.push(e.id);
    });

    this.setState({
      checked: {
        isChecked: checkedCopy,
        userId: usersCopy,
      },

    });
  }

  onSingleSelect(data) {
    const { checked } = this.state;

    const checkedCopy = checked.isChecked;
    const userCopy = checked.userId;
    let isUserPushed = false;

    checkedCopy[data.index] = !checked.isChecked[data.index];

    if (checkedCopy[data.index] === false) {
      this.setState({ selectAll: false });
    }

    Object.keys(userCopy).forEach((key) => {
      if (userCopy[key] === data.row.id) {
        isUserPushed = true;
      }
    });


    if (!isUserPushed) {
      userCopy[data.index] = data.row.id;
    }


    this.setState({
      checked: {
        ...checked,
        isChecked: checkedCopy,
        userId: userCopy,
      },
    });
  }

  onChangeStatus(checked, status) {
    const { doupdateUserStatus } = this.props;
    const userHolder = [];

    Object.keys(checked.userId).forEach((objKey) => {
      Object.keys(checked.isChecked).forEach((boolKey) => {
        if (objKey === boolKey) {
          if (checked.isChecked[boolKey]) {
            userHolder.push(checked.userId[objKey]);
          }
        }
      });
    });

    if (checked.userId.length !== 0 && checked.isChecked.length !== 0) {
      // eslint-disable-next-line no-unused-vars
      doupdateUserStatus(userHolder, status).then((res) => {
        this.setState({
          checked: {
            isChecked: [],
            userId: [],
          },
        });

        const { currentTable } = this.state;

        const params = {
          page: currentTable.page - 1,
          pageSize: currentTable.pageSize,
          filtered: currentTable.filters,
          sorted: currentTable.sorts,
        };

        message.success(`Successfully ${status}d users!`, 5);
        this.onFetchData(params);
      }).catch(() => {

      });
    }
  }

  setToEdit(rowId, userId) {
    this.setState({
      editableRow: rowId,
      editedUser: userId,
      isEditable: true,
    });
  }

  cancelEdit() {
    this.setState({
      editableRow: null,
      isEditable: false,
    });
  }

  render() {
    const {
      userList, isFetching, fetchMessage,
      isEditable, editableRow, selectAll,
      checked,
    } = this.state;

    return (
      <div className="UserList">
        <PageHeader title="User Approval" />
        <Container>
          <Card
            title="Pending Users"
            extra={(
              <React.Fragment>
                <Spacer />
                <Row>
                  <Col xs={24} sm={24} lg={12}>
                    <Button type="primary" onClick={() => this.onChangeStatus(checked, 'approve')} block>
                      <Icon viewBox="0 0 1024 1024">
                        <FontAwesomeIcon icon={faUserPlus} fixedWidth />
                      </Icon>
                      Approve
                    </Button>
                  </Col>
                  <Col xs={24} sm={24} lg={12}>
                    <Button type="danger" onClick={() => this.onChangeStatus(checked, 'disapprove')} block>
                      <Icon viewBox="0 0 1024 1024">
                        <FontAwesomeIcon icon={faUserTimes} fixedWidth />
                      </Icon>
                      Disapprove
                    </Button>
                  </Col>
                </Row>
              </React.Fragment>
            )}
          >
            <Spacer />
            <Form onSubmit={this.onSubmit}>
              <ServerSideTable
                data={userList.rows}
                pages={userList.pages}
                columns={[
                  {
                    width: 100,
                    sortable: false,
                    filterable: false,
                    Header: state => (
                      <Checkbox
                        type="checkbox"
                        onChange={() => this.onSelectAll(state.data)}
                        checked={selectAll}
                      />
                    ),
                    Cell: data => (
                      <Row>
                        <Col>
                          <center>
                            <Checkbox
                              type="checkbox"
                              onChange={() => this.onSingleSelect(data)}
                              checked={checked.isChecked[data.index]}
                            />
                          </center>
                        </Col>
                      </Row>

                    ),

                  },
                  {
                    Header: 'Id',
                    accessor: 'id',
                    width: 50,

                  },
                  {
                    Header: 'Username',
                    accessor: 'username',
                  },
                  {
                    Header: 'First Name',
                    accessor: 'first_name',
                    Cell: this.onEdit,
                  },
                  {
                    Header: 'Last Name',
                    accessor: 'last_name',
                    Cell: this.onEdit,
                  },
                  {
                    Header: 'Role',
                    accessor: 'role',
                    Cell: this.onEdit,
                  },
                  {
                    id: 'outsource_group_id',
                    Header: 'Outsource Group',
                    width: 150,
                    accessor: data => (data.outsource === null ? '-' : data.outsource.name),
                    Cell: this.onEdit,
                  },
                  {
                    Header: 'Contact',
                    accessor: 'contact',
                    Cell: this.onEdit,

                  },
                  {
                    Header: 'Address',
                    accessor: 'address',
                    Cell: this.onEdit,

                  },

                  {
                    sortable: false,
                    filterable: false,
                    width: 145,
                    Cell: data => (
                      <div>
                        {
                         (!isEditable || data.index !== editableRow)
                           ? (
                             <Col span={24}>
                               <Button type="link" onClick={() => this.setToEdit(data.index, data.row.id)} block>
                                 <Icon viewBox="0 0 1024 1024">
                                   <FontAwesomeIcon icon={faEdit} fixedWidth />
                                 </Icon>
                              Edit
                               </Button>
                             </Col>
                           )
                           : (
                             <div>
                               <Col span={10}>
                                 <Button type="link" htmlType="submit" block>
                                   <Icon viewBox="0 0 1024 1024">
                                     <FontAwesomeIcon icon={faSave} fixedWidth />
                                   </Icon>
                                   Save
                                 </Button>
                               </Col>

                               <Col span={14}>
                                 <Button type="link" onClick={this.cancelEdit} block>
                                   <Icon viewBox="0 0 1024 1024">
                                     <FontAwesomeIcon icon={faTimes} fixedWidth />
                                   </Icon>
                                   Cancel
                                 </Button>
                               </Col>
                             </div>
                           )
                          }
                      </div>
                    ),
                  },
                ]}

                loadingText={fetchMessage}
                loading={isFetching}
                onFetchData={this.onFetchData}
              />
            </Form>

          </Card>
        </Container>
      </div>
    );
  }
}

UserApproval.propTypes = {
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
  branch: PropTypes.oneOfType([PropTypes.object]).isRequired,
  dofetchPending: PropTypes.func.isRequired,
  doUpdate: PropTypes.func.isRequired,
  doupdateUserStatus: PropTypes.func.isRequired,
  doGetOutsourceSelect: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  dofetchPending: getPendingUsers,
  doUpdate: updateUser,
  doupdateUserStatus: updateUserStatus,
  doGetOutsourceSelect: getOutsourceSelect,
};

const mapStateToProps = state => ({
  branch: state.auth.branch,
});

const WrappedUserApproval = Form.create({ name: 'UserApproval' })(UserApproval);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WrappedUserApproval);
