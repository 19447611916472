import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Card, DatePicker, Col, Row, Spin, Button,
} from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import _ from 'lodash';
import FileDownload from 'js-file-download';
import Container from '../../components/Container';
import PageHeader from '../../components/PageHeader';
import LineChart from '../../components/LineChart';
import ReactTable from '../../components/ReactTable';
import Spacer from '../../components/Spacer';
import { getRequestedPackageSummary, exportRequestedPackageSummary } from './ducks';

class InboundMonitoring extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dateSelected: [moment().subtract(7, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
      isFetching: true,
      plannedMonitoringChartData: {},
      plannedMonitoringTableData: [],
      labels: {
        expected_packages: {
          already_picked_up: 0,
          package_ready_to_be_shipped: 0,
          package_cancelled: 0,
        },
        not_expected_packages: 0,
        movement_date: null,
        upload_status: null,
      },
    };
    this.getSummary = this.getSummary.bind(this);
    this.setRange = this.setRange.bind(this);
  }

  componentDidMount() {
    const { dateSelected } = this.state;
    this.getSummary(dateSelected);
  }

  setRange(selected) {
    this.setState({
      dateSelected: [moment(selected[0]).format('YYYY-MM-DD'), moment(selected[1]).format('YYYY-MM-DD')],
    });
    this.getSummary([moment(selected[0]).format('YYYY-MM-DD'), moment(selected[1]).format('YYYY-MM-DD')]);
  }

  getSummary(dateRange) {
    const { doGetRequestedPackageSummary, branch } = this.props;
    this.setState({
      isFetching: true,
    });
    doGetRequestedPackageSummary(dateRange, branch.id).then((action) => {
      const { labels } = this.state;

      const summaryGraph = [];
      const packageCancelledGraph = [];
      const packageReadyToBeShippedGraph = [];
      const packageAlreadyPickedUpGraph = [];
      const notExpectedPackagesGraph = [];
      const expectedPackagesGraph = [];
      const tableData = [];
      action.payload.data.forEach((summary) => {
        summaryGraph[summary.movement_date] = { ...labels };
      });

      action.payload.data.forEach((summary) => {
        if (summary.expected_packages) {
          summaryGraph[summary.movement_date] = {
            ...summaryGraph[summary.movement_date],
            expected_packages: {
              ...summaryGraph[summary.movement_date].expected_packages,
              [summary.upload_status]: summary.expected_packages,
            },
          };
        }
        if (summary.not_expected_packages) {
          summaryGraph[summary.movement_date].not_expected_packages = summary.not_expected_packages;
        }
      });
      _.forIn(summaryGraph, (value, key) => {
        packageAlreadyPickedUpGraph.push(summaryGraph[key].expected_packages.already_picked_up);
        packageCancelledGraph.push(summaryGraph[key].expected_packages.package_cancelled);
        packageReadyToBeShippedGraph.push(
          summaryGraph[key].expected_packages.package_ready_to_be_shipped,
        );
        notExpectedPackagesGraph.push(summaryGraph[key].not_expected_packages);
        expectedPackagesGraph.push(
          summaryGraph[key].expected_packages.already_picked_up
          + summaryGraph[key].expected_packages.package_cancelled
          + summaryGraph[key].expected_packages.package_ready_to_be_shipped,
        );
        tableData.push({ ...summaryGraph[key], movement_date: key });
      });
      this.setState({
        isFetching: false,
        plannedMonitoringTableData: tableData,
        plannedMonitoringChartData: {
          labels: Object.keys(summaryGraph),
          datasets: [
            {
              label: 'Ready To Be Shipped',
              data: packageReadyToBeShippedGraph,
              backgroundColor: 'rgb(77, 255, 77)',
              borderColor: 'rgb(77, 255, 77)',
              lineTension: 0.1,
              fill: false,
              pointHitRadius: 10,
            },
            {
              label: 'Already Picked Up',
              data: packageCancelledGraph,
              backgroundColor: 'rgb(255, 204, 102)',
              borderColor: 'rgb(255, 204, 102)',
              lineTension: 0.1,
              fill: false,
              pointHitRadius: 10,
            },
            {
              label: 'Package Cancelled',
              data: packageCancelledGraph,
              backgroundColor: 'rgb(140, 26, 255)',
              borderColor: 'rgb(140, 26, 255)',
              lineTension: 0.1,
              fill: false,
              pointHitRadius: 10,
            },
            {
              label: 'Not Expected',
              data: notExpectedPackagesGraph,
              backgroundColor: 'rgb(0, 102, 51)',
              borderColor: 'rgb(0, 102, 51)',
              lineTension: 0.1,
              fill: false,
              pointHitRadius: 10,
            },
            {
              label: 'Expected',
              data: expectedPackagesGraph,
              backgroundColor: 'rgb(77, 148, 255)',
              borderColor: 'rgb(77, 148, 255)',
              lineTension: 0.1,
              fill: false,
              pointHitRadius: 10,
            },
          ],
        },
      });
    });
  }

  exportMonitoring(movementDate, type) {
    const { doExportRequestedPackageSummary } = this.props;
    doExportRequestedPackageSummary(movementDate, type).then((action) => {
      FileDownload(new Blob([action.payload.data]), `${movementDate}-${type}.xlsx`);
    });
  }

  render() {
    const { plannedMonitoringChartData, plannedMonitoringTableData, isFetching } = this.state;
    const { RangePicker } = DatePicker;
    return (
      <div className="InboundMonitoring">
        <PageHeader title="Planned Inbound Monitoring" />
        <Spin spinning={isFetching} size="large" tip="Generating Summary . . . ">
          <Container>
            <Card
              title="Monitoring Graph"
              extra={(
                <React.Fragment>
                  <Row>
                    <Col>
                      <RangePicker
                        size="default"
                        onChange={this.setRange}
                        style={{ width: '100%' }}
                        allowClear={false}
                        defaultValue={[moment().subtract(7, 'days'), moment()]}
                      />
                    </Col>
                  </Row>
                </React.Fragment>
            )}
            >
              <div style={{ width: '90%', margin: 'auto' }}>
                <LineChart data={plannedMonitoringChartData} />
                <Spacer />
                <ReactTable
                  data={plannedMonitoringTableData}
                  columns={[
                    {
                      Header: 'Date',
                      accessor: 'movement_date',
                      filterable: false,
                    },

                    {
                      Header: 'Expected (Total)',
                      Cell: data => (
                        <Button type="link" onClick={() => (this.exportMonitoring(data.row.movement_date, 'expected'))}>
                          {data.original.expected_packages.already_picked_up
                        + data.original.expected_packages.package_cancelled
                        + data.original.expected_packages.package_ready_to_be_shipped}
                        </Button>
                      ),
                      filterable: false,

                    },
                    {
                      Header: 'Not Expected',
                      Cell: data => (
                        <Button type="link" onClick={() => (this.exportMonitoring(data.row.movement_date, 'not_expected'))}>
                          {data.original.not_expected_packages}
                        </Button>
                      ),
                      filterable: false,
                    },
                    {
                      Header: 'Total',
                      Cell: data => (
                        <Button type="link" onClick={() => (this.exportMonitoring(data.row.movement_date, 'total'))}>
                          {data.original.expected_packages.already_picked_up
                      + data.original.expected_packages.package_cancelled
                      + data.original.expected_packages.package_ready_to_be_shipped
                      + data.original.not_expected_packages}
                        </Button>
                      ),
                      filterable: false,
                    },
                  ]}
                  SubComponent={row => (
                    <ReactTable
                      data={_.compact(Object.keys(row.original).map((key) => {
                        if (key === 'expected_packages') {
                          return {
                            expected_packages: row.original[key],
                          };
                        }
                        return null;
                      }))}
                      columns={[
                        {
                          Header: 'Package Cancelled',
                          Cell: data => (
                            <Button type="link" onClick={() => (this.exportMonitoring(row.original.movement_date, 'package_cancelled'))}>
                              {data.original.expected_packages.package_cancelled}
                            </Button>
                          ),
                          filterable: false,
                        },
                        {
                          Header: 'Already Picked Up',
                          Cell: data => (
                            <Button type="link" onClick={() => (this.exportMonitoring(row.original.movement_date, 'already_picked_up'))}>
                              {data.original.expected_packages.already_picked_up}
                            </Button>
                          ),
                          filterable: false,
                        },
                        {
                          Header: 'RTS',
                          Cell: data => (
                            <Button type="link" onClick={() => (this.exportMonitoring(row.original.movement_date, 'rts'))}>
                              {data.original.expected_packages.package_ready_to_be_shipped}
                            </Button>
                          ),
                          filterable: false,
                        },
                        {
                          Header: 'Total Expected',
                          Cell: data => (
                            <Button type="link" onClick={() => (this.exportMonitoring(row.original.movement_date, 'expected'))}>
                              {data.original.expected_packages.already_picked_up
                            + data.original.expected_packages.package_cancelled
                            + data.original.expected_packages.package_ready_to_be_shipped}
                            </Button>
                          ),
                          filterable: false,
                        },
                      ]}
                      minRows={0}
                      showPagination={false}
                    />
                  )}
                />
              </div>
            </Card>
          </Container>
        </Spin>
      </div>
    );
  }
}

InboundMonitoring.propTypes = {
  doGetRequestedPackageSummary: PropTypes.func.isRequired,
  doExportRequestedPackageSummary: PropTypes.func.isRequired,
  branch: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const mapDispatchToProps = {
  doGetRequestedPackageSummary: getRequestedPackageSummary,
  doExportRequestedPackageSummary: exportRequestedPackageSummary,
};

const mapStateToProps = state => ({
  branch: state.auth.branch,
});

export default connect(mapStateToProps, mapDispatchToProps)(InboundMonitoring);
