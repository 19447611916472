import React, { Component } from 'react';
import {
  Spin,
  DatePicker,
  Button,
  Col,
  Row,
  Icon,
} from 'antd';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExport } from '@fortawesome/free-solid-svg-icons';
import FileDownload from 'js-file-download';
import moment from 'moment';
import Container from '../../components/Container';
import PageHeader from '../../components/PageHeader';
import LineChart from '../../components/LineChart';
import ReactTable from '../../components/ReactTable';
import Card from '../../components/Card';
import Spacer from '../../components/Spacer';
import { fetchSummary, exportInventorySummary } from './ducks';

class Inventory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSpinning: false,
      inventoryChartData: {},
      dateSelected: [moment().subtract(7, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
      inventoryTableData: [],
      labels: {
        inbound: {
          bulky: 0,
          mBulky: 0,
          'm-bulky': 0,
          pouch: 0,
        },
        outbound: {
          bulky: 0,
          mBulky: 0,
          'm-bulky': 0,
          pouch: 0,
        },
        inbound_reprocess: 0,
        outbound_reprocess: 0,
        refused: 0,
        balance: 0,
      },
    };
    this.setRange = this.setRange.bind(this);
    this.getSummary = this.getSummary.bind(this);
    this.exportInventory = this.exportInventory.bind(this);
  }

  componentDidMount() {
    const { dateSelected } = this.state;
    this.getSummary(dateSelected);
  }

  setRange(selected) {
    this.setState({
      dateSelected: [moment(selected[0]).format('YYYY-MM-DD'), moment(selected[1]).format('YYYY-MM-DD')],
    });
    this.getSummary([moment(selected[0]).format('YYYY-MM-DD'), moment(selected[1]).format('YYYY-MM-DD')]);
  }

  getSummary(dateRange) {
    const { doFetchSummary, branch } = this.props;
    const { labels } = this.state;
    this.setState({
      isSpinning: true,
    });

    doFetchSummary(dateRange, branch.id).then((action) => {
      const summary = action.payload.data.result;
      const inboundGraph = [];
      const inboundBulkyGraph = [];
      const inboundMbulkyGraph = [];
      const inboundPouchGraph = [];
      const outboundGraph = [];
      const outboundBulkyGraph = [];
      const outboundMbulkyGraph = [];
      const outboundPouchGraph = [];
      const inboundReprocessGraph = [];
      const outboundReprocessGraph = [];
      const refusedGraph = [];
      const balanceGraph = [];

      const parsedInventorySummaryData = [];
      const inventoryTableData = [];

      _.forIn(summary, (value) => {
        parsedInventorySummaryData[value.movement_date] = {
          ...labels,
        };
      });

      _.forIn(summary, (value) => {
        if (value.status === 'inbound' || value.status === 'outbound') {
          parsedInventorySummaryData[value.movement_date] = {
            ...parsedInventorySummaryData[value.movement_date],
            [value.status]: {
              ...parsedInventorySummaryData[value.movement_date][value.status],
              [value.type]: value.total_count,
            },
          };
        } else {
          parsedInventorySummaryData[value.movement_date] = {
            ...parsedInventorySummaryData[value.movement_date],
            [value.status]: value.total_count,
          };
        }
      });

      _.forIn(parsedInventorySummaryData, (value, key) => {
        parsedInventorySummaryData[key].balance = (
          parsedInventorySummaryData[key].inbound.bulky
            + parsedInventorySummaryData[key].inbound.mBulky
            + parsedInventorySummaryData[key].inbound['m-bulky']
            + parsedInventorySummaryData[key].inbound.pouch
            + parsedInventorySummaryData[key].inbound_reprocess
        ) - (
          parsedInventorySummaryData[key].outbound.bulky
            + parsedInventorySummaryData[key].outbound.mBulky
            + parsedInventorySummaryData[key].outbound['m-bulky']
            + parsedInventorySummaryData[key].outbound.pouch
            + parsedInventorySummaryData[key].outbound_reprocess
        );
        inventoryTableData.push({ ...parsedInventorySummaryData[key], date: key });
        inboundGraph.push(
          parsedInventorySummaryData[key].inbound.bulky
          + parsedInventorySummaryData[key].inbound.mBulky
          + parsedInventorySummaryData[key].inbound['m-bulky']
          + parsedInventorySummaryData[key].inbound.pouch
          + parsedInventorySummaryData[key].refused,
        );
        inboundBulkyGraph.push(parsedInventorySummaryData[key].inbound.bulky);
        inboundMbulkyGraph.push(
          parsedInventorySummaryData[key].inbound.mBulky
          + parsedInventorySummaryData[key].inbound['m-bulky'],
        );
        inboundPouchGraph.push(parsedInventorySummaryData[key].inbound.pouch);
        outboundGraph.push(
          parsedInventorySummaryData[key].outbound.bulky
          + parsedInventorySummaryData[key].outbound.mBulky
          + parsedInventorySummaryData[key].outbound['m-bulky']
          + parsedInventorySummaryData[key].outbound.pouch,
        );
        outboundBulkyGraph.push(parsedInventorySummaryData[key].outbound.bulky);
        outboundMbulkyGraph.push(parsedInventorySummaryData[key].outbound.mBulky + parsedInventorySummaryData[key].outbound['m-bulky']);
        outboundPouchGraph.push(parsedInventorySummaryData[key].outbound.pouch);
        inboundReprocessGraph.push(parsedInventorySummaryData[key].inbound_reprocess);
        outboundReprocessGraph.push(parsedInventorySummaryData[key].outbound_reprocess);
        refusedGraph.push(parsedInventorySummaryData[key].refused);
        balanceGraph.push((
          parsedInventorySummaryData[key].inbound.bulky
            + parsedInventorySummaryData[key].inbound.mBulky
            + parsedInventorySummaryData[key].inbound['m-bulky']
            + parsedInventorySummaryData[key].inbound.pouch
            + parsedInventorySummaryData[key].inbound_reprocess
        ) - (
          parsedInventorySummaryData[key].outbound.bulky
            + parsedInventorySummaryData[key].outbound.mBulky
            + parsedInventorySummaryData[key].outbound['m-bulky']
            + parsedInventorySummaryData[key].outbound.pouch
            + parsedInventorySummaryData[key].outbound_reprocess
        ));
      });
      this.setState({
        isSpinning: false,
        inventoryTableData,
        inventoryChartData: {
          labels: Object.keys(parsedInventorySummaryData),
          datasets: [
            {
              label: 'Inbound',
              data: inboundGraph,
              backgroundColor: 'rgb(77, 255, 77)',
              borderColor: 'rgb(77, 255, 77)',
              lineTension: 0.1,
              fill: false,
              pointHitRadius: 10,
            },
            {
              label: 'Inbound (Pouch)',
              data: inboundPouchGraph,
              backgroundColor: 'rgb(255, 204, 102)',
              borderColor: 'rgb(255, 204, 102)',
              lineTension: 0.1,
              fill: false,
              pointHitRadius: 10,
            },
            {
              label: 'Inbound (Mbulky)',
              data: inboundMbulkyGraph,
              backgroundColor: 'rgb(140, 26, 255)',
              borderColor: 'rgb(140, 26, 255)',
              lineTension: 0.1,
              fill: false,
              pointHitRadius: 10,
            },
            {
              label: 'Inbound (Bulky)',
              data: inboundBulkyGraph,
              backgroundColor: 'rgb(0, 102, 51)',
              borderColor: 'rgb(0, 102, 51)',
              lineTension: 0.1,
              fill: false,
              pointHitRadius: 10,
            },
            {
              label: 'Outbound Reprocess',
              data: outboundReprocessGraph,
              backgroundColor: 'rgb(77, 148, 255)',
              borderColor: 'rgb(77, 148, 255)',
              lineTension: 0.1,
              fill: false,
              pointHitRadius: 10,
            },
            {
              label: 'Inbound Reprocess',
              data: inboundReprocessGraph,
              backgroundColor: 'rgb(255, 166, 77)',
              borderColor: 'rgb(255, 166, 77)',
              lineTension: 0.1,
              fill: false,
              pointHitRadius: 10,
            },
            {
              label: 'Outbound',
              data: outboundGraph,
              backgroundColor: 'rgb(77, 255, 255)',
              borderColor: 'rgb(77, 255, 255)',
              lineTension: 0.1,
              fill: false,
              pointHitRadius: 10,
            },
            {
              label: 'Outbound (Pouch)',
              data: outboundPouchGraph,
              backgroundColor: 'rgb(128, 0, 64)',
              borderColor: 'rgb(128, 0, 64)',
              lineTension: 0.1,
              fill: false,
              pointHitRadius: 10,
            },
            {
              label: 'Outbound (Mbulky)',
              data: outboundMbulkyGraph,
              backgroundColor: 'rgb(153, 0, 204)',
              borderColor: 'rgb(153, 0, 204)',
              lineTension: 0.1,
              fill: false,
              pointHitRadius: 10,
            },
            {
              label: 'Outbound (Bulky)',
              data: outboundBulkyGraph,
              backgroundColor: 'rgb(0, 51, 102)',
              borderColor: 'rgb(0, 51, 102)',
              lineTension: 0.1,
              fill: false,
              pointHitRadius: 10,
            },
            {
              label: 'Refused',
              data: refusedGraph,
              backgroundColor: 'rgb(255, 77, 77)',
              borderColor: 'rgb(255, 77, 77)',
              lineTension: 0.1,
              fill: false,
              pointHitRadius: 10,
            },
            {
              label: 'Balance',
              data: balanceGraph,
              backgroundColor: 'rgb(255, 0, 0)',
              borderColor: 'rgb(255, 0, 0)',
              lineTension: 0.1,
              fill: false,
              pointHitRadius: 10,
            },
          ],
          options: {
            responsive: true,
            maintainAspectRatio: false,
          },
        },
      });
    });
  }

  exportInventory() {
    const { doExportInventorySummary, branch } = this.props;
    const { dateSelected } = this.state;
    doExportInventorySummary(dateSelected, branch.id).then((action) => {
      FileDownload(new Blob([action.payload.data]), `${dateSelected[0]}-${dateSelected[1]}-INVENTORY.xlsx`);
    });
  }


  render() {
    const {
      isSpinning,
      inventoryChartData,
      inventoryTableData,
    } = this.state;
    const { RangePicker } = DatePicker;
    return (
      <div className="Inventory">
        <PageHeader title="Inventory" />
        <Spin spinning={isSpinning} size="large" tip="Generating Summary . . . ">
          <Container>
            <Card
              title="Inventory Graph"
              extra={(
                <React.Fragment>
                  <Row>
                    <Col xs={{ span: 13, offset: 1 }} lg={{ span: 13, offset: 1 }}>
                      <RangePicker
                        size="default"
                        onChange={this.setRange}
                        style={{ width: '100%' }}
                        allowClear={false}
                        defaultValue={[moment().subtract(7, 'days'), moment()]}
                      />
                    </Col>
                    <Col xs={{ span: 9, offset: 1 }} lg={{ span: 9, offset: 1 }}>
                      <Button type="primary" onClick={this.exportInventory} block>
                        <Icon viewBox="0 0 1024 1024">
                          <FontAwesomeIcon icon={faFileExport} fixedWidth />
                        </Icon>
                        Export
                      </Button>
                    </Col>
                  </Row>
                </React.Fragment>
              )}
            >
              <div style={{ width: '90%', margin: 'auto' }}>
                <LineChart data={inventoryChartData} />
                <Spacer />
                <ReactTable
                  data={inventoryTableData}
                  columns={[
                    {
                      Header: 'Date',
                      accessor: 'date',
                      filterable: false,
                    },
                    {
                      Header: 'Inbound (Total)',
                      Cell: data => data.original.inbound.bulky
                      + data.original.inbound.mBulky
                      + data.original.inbound['m-bulky']
                      + data.original.inbound.pouch
                      + data.original.refused,
                      filterable: false,
                    },
                    {
                      Header: 'Outbound Reprocess',
                      accessor: 'outbound_reprocess',
                      filterable: false,
                    },
                    {
                      Header: 'Inbound Reprocess',
                      accessor: 'inbound_reprocess',
                      filterable: false,
                    },
                    {
                      Header: 'Outbound (Total)',
                      Cell: data => data.original.outbound.bulky
                      + data.original.outbound.mBulky
                      + data.original.outbound['m-bulky']
                      + data.original.outbound.pouch,
                      filterable: false,
                    },
                    {
                      Header: 'Refused',
                      accessor: 'refused',
                      filterable: false,
                    },
                    {
                      Header: 'Balance',
                      accessor: 'balance',
                      filterable: false,
                    },
                  ]}
                  SubComponent={row => (
                    <ReactTable
                      data={_.compact(Object.keys(row.original).map((key) => {
                        if (key === 'inbound' || key === 'outbound') {
                          return {
                            movement_type: key,
                            packages: row.original[key],
                          };
                        }
                        return null;
                      }))}
                      columns={[
                        {
                          Header: 'Movement',
                          accessor: 'movement_type',
                          filterable: false,
                        },
                        {
                          Header: 'Pouch',
                          accessor: 'packages.pouch',
                          filterable: false,
                        },
                        {
                          Header: 'Mbulky',
                          Cell: data => data.original.packages.mBulky + data.original.packages['m-bulky'],
                          filterable: false,
                        },
                        {
                          Header: 'Bulky',
                          accessor: 'packages.bulky',
                          filterable: false,
                        },
                        {
                          Header: 'Total',
                          Cell: data => data.original.packages.pouch
                            + data.original.packages.bulky
                            + data.original.packages['m-bulky']
                            + data.original.packages.mBulky,
                          filterable: false,
                        },

                      ]}
                      minRows={0}
                      showPagination={false}
                    />
                  )}
                />
              </div>
            </Card>
          </Container>
        </Spin>
      </div>
    );
  }
}

Inventory.propTypes = {
  doFetchSummary: PropTypes.func.isRequired,
  doExportInventorySummary: PropTypes.func.isRequired,
  branch: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const mapDispatchToProps = {
  doFetchSummary: fetchSummary,
  doExportInventorySummary: exportInventorySummary,
};

const mapStateToProps = state => ({
  branch: state.auth.branch,
});

export default connect(mapStateToProps, mapDispatchToProps)(Inventory);
