import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Icon, Button,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import PageHeader from '../../components/PageHeader';
import Card from '../../components/Card';
import Container from '../../components/Container';
import ServerSideTable from '../../components/ServerSideTable';
import { getReports } from './ducks';

class IncidentReportList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      reportList: {
        rows: [],
        pages: null,
        total: null,
      },
    };

    this.onFetchData = this.onFetchData.bind(this);
  }

  // eslint-disable-next-line no-unused-vars
  onFetchData(state, instance) {
    const { doGetReports, user, branch } = this.props;

    const params = {
      page: state.page + 1,
      pageSize: state.pageSize,
      filters: state.filtered,
      sorts: state.sorted,
      sub: user.sub,
      role: user.role,
      branchId: branch.id,
    };

    this.setState({
      isFetching: true,
    });

    doGetReports(params).then((action) => {
      this.setState({
        reportList: {
          rows: action.payload.data.data,
          pages: action.payload.data.last_page,
          total: action.payload.data.total,
        },
        isFetching: false,
      });
    // eslint-disable-next-line no-unused-vars
    }).catch((action) => {
      this.setState({
        isFetching: false,
      });
    });
  }

  render() {
    const { isFetching, reportList } = this.state;
    return (
      <div className="IncidentReportList">
        <PageHeader title="Incident Report" />
        <Container>
          <Card title={`Incident Report List (Total: ${reportList.total})`}>
            <ServerSideTable
              data={reportList.rows}
              pages={reportList.pages}
              columns={[
                {
                  Header: 'Id',
                  accessor: 'id',

                },
                {
                  Header: 'Description',
                  accessor: 'description',
                },
                {
                  Header: 'Status',
                  accessor: 'status',
                  Filter: ({ filter, onChange }) => (
                    <select
                      onChange={event => onChange(event.target.value)}
                      value={filter ? filter.value : 'All'}
                      style={{ width: '100%' }}
                    >
                      <option value="">All</option>
                      <option value="pending">Pending</option>
                      <option value="approved">Approved</option>
                      <option value="disapproved">Disapproved</option>
                    </select>
                  ),
                },
                {
                  id: 'created_by',
                  Header: 'Created By',
                  accessor: data => (data.user ? data.user.full_name : ''),
                },
                {
                  Header: 'Options',
                  sortable: false,
                  filterable: false,
                  Cell: data => (
                    <center>
                      <NavLink to={`/incidentReport/view/${data.row.id}`}>
                        <Button type="link">
                          <Icon viewBox="0 0 1024 1024">
                            <FontAwesomeIcon icon={faEye} fixedWidth />
                          </Icon>
                        View
                        </Button>
                      </NavLink>
                    </center>


                  ),
                },
              ]}
              loadingText="Fetching incident report list. . ."
              loading={isFetching}
              onFetchData={this.onFetchData}
            />
          </Card>
        </Container>
      </div>
    );
  }
}

IncidentReportList.propTypes = {
  user: PropTypes.oneOfType([PropTypes.object]).isRequired,
  branch: PropTypes.oneOfType([PropTypes.object]).isRequired,
  doGetReports: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.auth.user,
  branch: state.auth.branch,
});

const mapDispatchToProps = {
  doGetReports: getReports,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(IncidentReportList);
