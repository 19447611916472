import React, { Component } from 'react';
import {
  Button, Icon,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEye,
} from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import PageHeader from '../../components/PageHeader';
import Card from '../../components/Card';
import Container from '../../components/Container';
import ServerSideTable from '../../components/ServerSideTable';
import { getOutboundReprocessList } from './ducks';


class OutboundReprocessList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      outboundReprocessList: {
        rows: [],
        pages: 0,
        total: 0,
      },
      isFetching: false,
      fetchMessage: null,
    };

    this.onFetchData = this.onFetchData.bind(this);
  }

  // eslint-disable-next-line no-unused-vars
  onFetchData(state, instance) {
    const { doFetchOutboundReprocessList, branch } = this.props;

    const params = {
      page: state.page + 1,
      pageSize: state.pageSize,
      filters: state.filtered,
      sorts: state.sorted,
      branchId: branch.id,
    };

    this.setState({
      isFetching: true,
      fetchMessage: 'Fetching outbound reprocess list . . .',
    });

    doFetchOutboundReprocessList(params).then((action) => {
      this.setState({
        outboundReprocessList: {
          rows: action.payload.data.data,
          pages: action.payload.data.last_page,
          total: action.payload.data.total,
        },
        isFetching: false,
        fetchMessage: null,
      });
    }).catch(() => {

    });
  }

  render() {
    const { fetchMessage, isFetching, outboundReprocessList } = this.state;
    return (
      <div className="OutboundReprocessList">
        <PageHeader title="Outbound Reprocess" />
        <Container>
          <Card title={`Outbound Reprocess List (Total: ${outboundReprocessList.total.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '1,')})`}>
            <ServerSideTable
              data={outboundReprocessList.rows}
              pages={outboundReprocessList.pages}
              columns={[
                {
                  Header: 'Id',
                  accessor: 'id',
                },
                {
                  Header: 'Created By',
                  accessor: 'created_by',
                },
                {
                  Header: 'Created On',
                  accessor: 'created_at',
                },
                {
                  Header: 'Status',
                  accessor: 'status',
                  Filter: ({ filter, onChange }) => (
                    <select
                      onChange={event => onChange(event.target.value)}
                      value={filter ? filter.value : 'All'}
                      style={{ width: '100%' }}
                    >
                      <option value="">All</option>
                      <option value="fresh">fresh</option>
                      <option value="modified">modified</option>
                      <option value="finalized">finalized</option>
                      <option value="cancelled">cancelled</option>
                    </select>
                  ),
                },
                {
                  Header: 'Attached Files',
                  accessor: 'file_count',
                  sortable: false,
                  filterable: false,
                },
                {
                  Header: 'Options',
                  sortable: false,
                  filterable: false,
                  Cell: data => (
                    <center>
                      <NavLink to={`/reprocess/outbound/${data.row.id}`}>
                        <Button type="link">
                          <Icon viewBox="0 0 1024 1024">
                            <FontAwesomeIcon icon={faEye} fixedWidth />
                          </Icon>
                        View
                        </Button>
                      </NavLink>
                    </center>
                  ),
                },
              ]}
              loadingText={fetchMessage}
              loading={isFetching}
              onFetchData={this.onFetchData}
            />
          </Card>
        </Container>
      </div>
    );
  }
}

OutboundReprocessList.propTypes = {
  doFetchOutboundReprocessList: PropTypes.func.isRequired,
  branch: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const mapDispatchToProps = {
  doFetchOutboundReprocessList: getOutboundReprocessList,
};

const mapStateToProps = state => ({
  branch: state.auth.branch,
});

export default connect(mapStateToProps, mapDispatchToProps)(OutboundReprocessList);
