import React, { Component } from 'react';
import {
  Button, Icon, Col, Form, Input, Collapse, Radio, Spin,
  Statistic as Label, Descriptions, Popconfirm, message,
  Slider,
} from 'antd';
import _ from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus, faEdit, faTrash, faSave, faTimes, faCheckCircle,
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import PageHeader from '../../components/PageHeader';
import Card from '../../components/Card';
import Container from '../../components/Container';
import Spacer from '../../components/Spacer';
import ReactTable from '../../components/ReactTable';
import Row from '../../components/Row';
import Select from '../../components/Select';
import {
  validatePackage, storePackageById, getInboundReprocessDetails, getRemarks,
} from './ducks';
import {
  getSectionSelect,
} from '../section/ducks';

class InboundReprocessEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lastPackageScannedSize: 24,
      scannedPackages: [],
      modifiedScannedPackages: [],
      newPackages: [],
      deletedPackages: [],
      packagesErrors: [],
      sectionOptions: [],
      releasedBy: null,
      branch: null,
      isUpdated: false,
      createdBy: null,
      createdAt: null,
      isFinalizing: false,
      editedTrackingNumber: null,
      inboundReprocessId: null,
    };
    this.confirmRemove = this.confirmRemove.bind(this);
    this.addPackage = this.addPackage.bind(this);
    this.savePackages = this.savePackages.bind(this);
    this.setToEdit = this.setToEdit.bind(this);
    this.cancelEdit = this.cancelEdit.bind(this);
    this.onEditNew = this.onEditNew.bind(this);
    this.onEditScanned = this.onEditScanned.bind(this);
    this.updatePackage = this.updatePackage.bind(this);
    this.zoomLastPackageScanned = this.zoomLastPackageScanned.bind(this);
  }

  componentDidMount() {
    const {
      doFetchOutboundReprocessDetails,
      history,
      branch,
      doGetSectionSelect,
    } = this.props;

    doFetchOutboundReprocessDetails((history.location.pathname).replace('edit', ''), branch, false)
      .then((action) => {
        this.setState({
          scannedPackages: action.payload.data.packages,
          releasedBy: action.payload.data.released_by,
          branch: typeof action.payload.data.location_details === 'undefined'
                  || action.payload.data.location_details === null ? '' : action.payload.data.location_details.name,
          createdBy: typeof action.payload.data.user === 'undefined'
                    || action.payload.data.user === null ? '' : action.payload.data.user.full_name,
          createdAt: action.payload.data.created_at,
          inboundReprocessId: action.payload.data.id,
        });
      });

    doGetSectionSelect().then((action) => {
      this.setState({
        sectionOptions: action.payload.data,
      });
    });
  }


  onEditNew(cellInfo) {
    const {
      newPackages,
      editedTrackingNumber,
      sectionOptions,
    } = this.state;

    const { form } = this.props;

    const { getFieldDecorator } = form;

    const packageTypes = [
      { value: 'pouch', label: 'Pouch' },
      { value: 'bulky', label: 'Bulky' },
      { value: 'm-bulky', label: 'M-Bulky' },
    ];

    if (editedTrackingNumber === cellInfo.original.tracking_number) {
      if (cellInfo.column.id === 'package_type') {
        return (
          <Form.Item style={{ margin: '0px' }}>
            {getFieldDecorator(`${cellInfo.column.id}_update`, {
              rules: [{ required: true, message: `${cellInfo.column.Header} is required.` }],
              initialValue: newPackages[cellInfo.index][cellInfo.column.id],
            })(<Select options={packageTypes} />)}
          </Form.Item>
        );
      }
      if (cellInfo.column.id === 'item_count') {
        return (
          <Form.Item style={{ margin: '0px' }}>
            {getFieldDecorator(`${cellInfo.column.id}_update`, {
              rules: [{ required: true, message: `${cellInfo.column.Header} is required.` }],
              initialValue: newPackages[cellInfo.index][cellInfo.column.id],
            })(<Input />)}
          </Form.Item>
        );
      } if (cellInfo.column.id === 'section') {
        return (
          <Form.Item style={{ margin: '0px' }}>
            {getFieldDecorator(`${cellInfo.column.id}_update`, {
              initialValue: newPackages[cellInfo.index][cellInfo.column.id],
            })(<Select options={sectionOptions} />)}
          </Form.Item>
        );
      }
    }

    return (
      <div>
        {newPackages[cellInfo.index][cellInfo.column.id]}
      </div>
    );
  }

  onEditScanned(cellInfo) {
    const {
      scannedPackages,
      editedTrackingNumber,
      sectionOptions,
    } = this.state;

    const { form } = this.props;

    const { getFieldDecorator } = form;

    const packageTypes = [
      { value: 'pouch', label: 'Pouch' },
      { value: 'bulky', label: 'Bulky' },
      { value: 'm-bulky', label: 'M-Bulky' },
    ];

    if (editedTrackingNumber === cellInfo.original.tracking_number) {
      if (cellInfo.column.id === 'package_type') {
        return (
          <Form.Item style={{ margin: '0px' }}>
            {getFieldDecorator(`${cellInfo.column.id}_update`, {
              rules: [{ required: true, message: `${cellInfo.column.Header} is required.` }],
              initialValue: scannedPackages[cellInfo.index][cellInfo.column.id],
            })(<Select options={packageTypes} />)}
          </Form.Item>
        );
      }
      if (cellInfo.column.id === 'item_count') {
        return (
          <Form.Item style={{ margin: '0px' }}>
            {getFieldDecorator(`${cellInfo.column.id}_update`, {
              rules: [{ required: true, message: `${cellInfo.column.Header} is required.` }],
              initialValue: scannedPackages[cellInfo.index][cellInfo.column.id],
            })(<Input />)}
          </Form.Item>
        );
      } if (cellInfo.column.id === 'section') {
        return (
          <Form.Item style={{ margin: '0px' }}>
            {getFieldDecorator(`${cellInfo.column.id}_update`, {
              rules: [{ required: true, message: `${cellInfo.column.Header} is required.` }],
              initialValue: scannedPackages[cellInfo.index][cellInfo.column.id],
            })(<Select options={sectionOptions} />)}
          </Form.Item>
        );
      }
    }

    return (
      <div>
        {scannedPackages[cellInfo.index][cellInfo.column.id]}
      </div>
    );
  }

  setToEdit(rowTN) {
    this.setState({
      editedTrackingNumber: rowTN,
    });
  }

  addPackage(e) {
    e.preventDefault();
    const {
      form, doValidatePackage, branch, match,
    } = this.props;
    const { scannedPackages, newPackages, packagesErrors } = this.state;
    const { validateFields } = form;

    validateFields(['item_count', 'package_type', 'tracking_number', 'section'], (err, values) => {
      if (!err) {
        let trackingNumbers = values.tracking_number.split('\n').filter(tn => tn !== '');
        trackingNumbers = trackingNumbers.map(trackingNumber => trackingNumber.toUpperCase());

        // get scanned packages tracking numbers
        const scannedTrackingNumbers = scannedPackages;

        // get new packages tracking numbers
        const newTrackingNumbers = newPackages;

        // get duplicates on scanned packages
        const duplicatesScanned = _.intersection(
          trackingNumbers,
          scannedTrackingNumbers.map((packageDetails => packageDetails.tracking_number)),
        );

        // get duplicates on new packages
        const duplicatesNew = _.intersection(
          trackingNumbers,
          newTrackingNumbers.map((packageDetails => packageDetails.tracking_number)),
        );

        // get duplicates on input packages
        const groupped = _.groupBy(trackingNumbers, (tn => tn));
        const duplicatesInput = _.uniq(_.flatten(_.filter(groupped, (tn => tn.length > 1))));

        // remove duplicates with scanned packages and new packages
        const filteredTrackingNumbers = trackingNumbers.map((trackingNumber) => {
          if (!_.find(scannedTrackingNumbers, { tracking_number: trackingNumber })
          && !_.find(newTrackingNumbers, { tracking_number: trackingNumber })) {
            return trackingNumber;
          }
          return null;
        });

        // chunk input to 100
        const batchTrackingNumbers = _.chunk(_.uniq(_.compact(filteredTrackingNumbers)), 100);

        const duplicates = _.concat(
          _.concat(duplicatesScanned, duplicatesNew),
          duplicatesInput,
        );

        if (duplicates.length) {
          const duplicatedTn = packagesErrors;
          duplicates.forEach((trackingNumber) => {
            duplicatedTn.push({
              message: 'Package already scanned',
              tracking_number: trackingNumber,
              issued_at: moment().format('YYYY-MM-DD HH:mm:ss'),
            });
          });
          message.error(`${duplicates.length} packages are already scanned!`);
          this.setState({
            packagesErrors: duplicatedTn,
          });
        }

        batchTrackingNumbers.forEach((batch) => {
          doValidatePackage({
            tracking_number: batch,
            movement_id: match.params.id,
            branch_id: branch.id,
            origin: null,
            remarks: null,
          }).then((action) => {
            const { success, errors } = action.payload.data;
            if (success) {
              let packages = Object.keys(success);

              // revalidate response for duplicate packages
              const revalidatedPackages = _.intersection(
                packages,
                newTrackingNumbers.map((packageDetails => packageDetails.tracking_number)),
              );
              if (revalidatedPackages.length) {
                const duplicatedTn = packagesErrors;
                revalidatedPackages.forEach((trackingNumber) => {
                  duplicatedTn.push({
                    message: 'Package already scanned',
                    tracking_number: trackingNumber,
                    issued_at: moment().format('YYYY-MM-DD HH:mm:ss'),
                  });
                });
                packages = _.difference(packages, revalidatedPackages);
                message.error(`${revalidatedPackages.length} packages are already scanned!`);
                this.setState({
                  packagesErrors: duplicatedTn,
                });
              }

              // parse validated packages
              const parsedSuccessPackages = packages.map((trackingNumber) => {
                const params = {
                  location: branch.id,
                  ...values,
                  tracking_number: trackingNumber,
                  remarks: 'good',
                  is_refused: false,
                };
                return params;
              });

              if (packages.length) {
                this.setState(prevState => ({
                  newPackages: parsedSuccessPackages.concat(prevState.newPackages),
                }));
                message.success(`Successfully inserted ${packages.length} packages `);
              }
            }

            if (errors) {
              const packages = Object.keys(errors);
              const parsedErrorPackages = packages.map(trackingNumber => ({
                message: errors[trackingNumber].message,
                tracking_number: trackingNumber,
                issued_at: moment().format('YYYY-MM-DD HH:mm:ss'),
              }));
              this.setState(prevState => ({
                packagesErrors: parsedErrorPackages.concat(prevState.packagesErrors),
              }));
              message.error(`Failed to insert ${_.values(errors).length} packages`);
            }
          });
        });
        form.resetFields(['tracking_number']);
      }
    });
  }

  savePackages() {
    const {
      modifiedScannedPackages,
      newPackages,
      isUpdated,
      deletedPackages,
    } = this.state;

    const {
      doStorePackages,
      history,
      match,
      branch,
    } = this.props;

    if (modifiedScannedPackages.length !== 0 || newPackages.length || isUpdated) {
      this.setState({
        isFinalizing: true,
      }, window.scrollTo(0, 0));

      const params = {
        editDetails: {
          id: match.params.id,
        },
        reprocessed_packages: {
          create: _.uniqBy(newPackages, 'tracking_number'),
          update: modifiedScannedPackages,
          delete: [
            ...deletedPackages,
          ],
          branch_id: branch.id,
          origin: null,
        },
      };

      doStorePackages(params, history.location.pathname).then((action) => {
        history.push('/reprocess/inbound/result', action.payload.data);
        this.setState({
          isFinalizing: false,
        });
      });
    } else {
      message.warning('No changes found!', 2);
    }
  }

  cancelEdit() {
    this.setState({
      editedTrackingNumber: null,
    });
  }

  confirmRemove(rowTN, packageType) {
    const { scannedPackages, newPackages } = this.state;
    if (packageType === 'scanned') {
      const newPackage = scannedPackages.filter(item => item.tracking_number !== rowTN);
      const deletedPackage = scannedPackages.filter(item => item.tracking_number === rowTN);
      this.setState({
        isUpdated: true,
        scannedPackages: newPackage,
      });

      this.setState(prevState => ({
        deletedPackages: [
          deletedPackage[0],
          ...prevState.deletedPackages,
        ],
      }));
      message.info('Package is pending for deletion.', 3);
    } else if (packageType === 'new') {
      const newPackage = newPackages.filter(item => item.tracking_number !== rowTN);
      this.setState({
        newPackages: newPackage,
      });
      message.success('Package removed successfully!', 3);
    }
  }

  updatePackage(e, type) {
    e.preventDefault();
    const { form, branch } = this.props;
    const { validateFields } = form;
    const {
      editedTrackingNumber,
      scannedPackages,
      newPackages,
      modifiedScannedPackages,
    } = this.state;
    validateFields(['item_count_update', 'package_type_update', 'section_update'], (err, values) => {
      if (!err) {
        const params = {
          location: branch.id,
          package_type: values.package_type_update,
          item_count: values.item_count_update,
          section: values.section_update,
          is_refused: false,
        };

        if (type === 'scanned') {
          const updated = scannedPackages.map((item) => {
            if (editedTrackingNumber !== item.tracking_number) {
              return item;
            }
            return {
              ...item,
              ...params,
            };
          });

          const editedScannedPackage = _.find(
            scannedPackages,
            { tracking_number: editedTrackingNumber },
          );

          if (!_.isEqual(editedScannedPackage, {
            ...editedScannedPackage,
            package_type: values.package_type_update.value,
            item_count: values.item_count_update,
            section: values.section_update,
          })) {
            const data = {
              ...editedScannedPackage,
              ...params,
            };

            let packageExistsInModifiedPackages = false;
            const modifiedPackages = modifiedScannedPackages.map((modifiedScannedPackage) => {
              if (modifiedScannedPackage.tracking_number === editedTrackingNumber) {
                packageExistsInModifiedPackages = true;
                return data;
              }
              return modifiedScannedPackage;
            });

            if (!packageExistsInModifiedPackages) {
              modifiedPackages.push(data);
            }
            this.setState({
              scannedPackages: updated,
              modifiedScannedPackages: modifiedPackages,
              isUpdated: true,
            });
            message.success('Package updated successfully!', 5);
          }
          this.setState({
            editedTrackingNumber: null,
          });
        } else if (type === 'new') {
          const updated = newPackages.map((item) => {
            if (editedTrackingNumber !== item.tracking_number) {
              return item;
            }
            return {
              ...item,
              ...params,
            };
          });
          this.setState({
            newPackages: updated,
            editedTrackingNumber: null,
          });
        }
      }
    });
  }

  zoomLastPackageScanned(e) {
    this.setState({
      lastPackageScannedSize: 24 + e,
    });
  }

  render() {
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const { TextArea } = Input;
    const {
      newPackages,
      scannedPackages,
      packagesErrors,
      releasedBy,
      branch,
      createdBy,
      createdAt,
      isFinalizing,
      editedTrackingNumber,
      sectionOptions,
      inboundReprocessId,
      isUpdated,
      lastPackageScannedSize,
    } = this.state;
    const { Panel } = Collapse;

    return (
      <div className="InboundReprocessEdit">
        <Spin spinning={isFinalizing} tip="Preparing to checkout packages, please dont't refresh or leave this page. . .">
          <PageHeader title={`Inbound Reprocess #${inboundReprocessId}`} />
          <Container>
            <Collapse defaultActiveKey={['1']}>
              <Panel
                header="Inbound Reporcess Details"
                key="1"
              >
                <Descriptions
                  bordered
                  column={{
                    xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1,
                  }}
                >
                  <Descriptions.Item label="Branch: ">{branch}</Descriptions.Item>
                  <Descriptions.Item label="Created By: ">{createdBy}</Descriptions.Item>
                  <Descriptions.Item label="Created: ">{createdAt}</Descriptions.Item>
                  <Descriptions.Item label="Released By: ">{releasedBy}</Descriptions.Item>
                </Descriptions>
              </Panel>
            </Collapse>
            <Spacer />
            <Card title="Inbound Reprocess Package Information">
              <Row>
                <Col>
                  <Form layout="horizontal" onSubmit={this.addPackage}>
                    <Row>
                      <Col>
                        <Form.Item label="Section">
                          {getFieldDecorator('section', {
                          })(<Select options={sectionOptions} />)}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={24} sm={12} lg={12}>
                        <Form.Item label="Set of.">
                          {getFieldDecorator('item_count', {
                            rules: [{ required: true, message: 'Set of. cannot be blank.' }],
                            initialValue: 1,
                          })(<Input />)}
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12} lg={12}>
                        <Form.Item label="Package Type">
                          {getFieldDecorator('package_type', {
                            rules: [{ required: true, message: 'Package Type cannot be blank.' }],
                            initialValue: 'bulky',
                          })(
                            <Radio.Group>
                              <Radio value="bulky">BULKY</Radio>
                              <Radio value="m-bulky">M-BULKY</Radio>
                              <Radio value="pouch">POUCH</Radio>
                            </Radio.Group>,
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Form.Item label="Tracking Number">
                      {getFieldDecorator('tracking_number', {
                        rules: [{ required: true, message: 'Tracking Number cannot be blank.' }],
                      })(<TextArea autoComplete="off" onPressEnter={this.addPackage} placeholder="E.g. X0123" />)}
                    </Form.Item>
                    <Row>
                      <Col>
                        <Form.Item>
                          <Button type="primary" htmlType="submit" disabled={isFinalizing} block>
                            <Icon viewBox="0 0 1024 1024">
                              <FontAwesomeIcon icon={faPlus} fixedWidth />
                            </Icon>
                          Add Tracking Number
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </Card>
            <Spacer />
            <Card title="">
              <Row>
                <center>
                  <Col
                    xs={24}
                    sm={lastPackageScannedSize > 44 ? 24 : 12}
                    lg={lastPackageScannedSize > 44 ? 24 : 12}
                  >
                    <Label
                      title={(
                        <React.Fragment>
                          Last Package Scanned
                          <Slider
                            defaultValue={0}
                            onAfterChange={this.zoomLastPackageScanned}
                            max={35}
                          />
                        </React.Fragment>
                      )}
                      value={newPackages.length === 0
                        ? ''
                        : newPackages[0].tracking_number
                    }
                      groupSeparator=""
                      valueStyle={{ fontSize: lastPackageScannedSize, wordWrap: 'break-word' }}
                    />
                  </Col>
                  <Col
                    xs={24}
                    sm={lastPackageScannedSize > 44 ? 24 : 12}
                    lg={lastPackageScannedSize > 44 ? 24 : 12}
                  >
                    <Label
                      title="Total Scanned Packages"
                      value={newPackages.length}
                      valueStyle={{ fontSize: lastPackageScannedSize, wordWrap: 'break-word' }}
                    />
                  </Col>
                </center>
              </Row>
            </Card>
            <Spacer />
            <Card title="Inbound Reprocess Packages">
              <Form onSubmit={e => this.updatePackage(e, 'new')}>
                <ReactTable
                  data={newPackages}
                  columns={[
                    {
                      Header: 'Tracking Number',
                      accessor: 'tracking_number',
                    },
                    {
                      Header: 'Set of.',
                      accessor: 'item_count',
                      Cell: this.onEditNew,
                    },
                    {
                      Header: 'Package Type',
                      accessor: 'package_type',
                      Cell: this.onEditNew,
                    },
                    {
                      Header: 'Remarks',
                      accessor: 'remarks',
                      Cell: this.onEditNew,
                    },
                    {
                      Header: 'Section',
                      accessor: 'section',
                      Cell: this.onEditNew,
                    },
                    {
                      Header: 'Options',
                      sortable: false,
                      filterable: false,
                      Cell: data => (
                        <Row>
                          {
                    (editedTrackingNumber !== data.row.tracking_number)
                      ? (
                        <Row>
                          <Col span={12}>
                            <Button type="link" onClick={() => this.setToEdit(data.row.tracking_number)} block>
                              <Icon viewBox="0 0 1024 1024">
                                <FontAwesomeIcon icon={faEdit} fixedWidth />
                              </Icon>
                          Edit
                            </Button>
                          </Col>
                          <Col span={12}>
                            <Popconfirm
                              placement="leftBottom"
                              title={`Are you sure you want to delete ${data.row.tracking_number} from the list?`}
                              okText="Yes"
                              onConfirm={() => this.confirmRemove(data.row.tracking_number, 'new')}
                              cancelText="No"
                            >
                              <Button type="link" block>
                                <Icon viewBox="0 0 1024 1024">
                                  <FontAwesomeIcon icon={faTrash} fixedWidth />
                                </Icon>
                          Remove
                              </Button>
                            </Popconfirm>
                          </Col>
                        </Row>
                      )
                      : (
                        <div>
                          <Col span={12}>
                            <Button type="link" htmlType="submit" disabled={isFinalizing} block>
                              <Icon viewBox="0 0 1024 1024">
                                <FontAwesomeIcon icon={faSave} fixedWidth />
                              </Icon>
                              Save
                            </Button>
                          </Col>

                          <Col span={12}>
                            <Button type="link" onClick={this.cancelEdit} block>
                              <Icon viewBox="0 0 1024 1024">
                                <FontAwesomeIcon icon={faTimes} fixedWidth />
                              </Icon>
                              Cancel
                            </Button>
                          </Col>
                        </div>
                      )
                      }
                        </Row>
                      ),
                    },
                  ]}
                />
              </Form>
            </Card>
            <Spacer />
            <Card title="Scanned Packages">
              <Form onSubmit={e => this.updatePackage(e, 'scanned')}>
                <ReactTable
                  data={scannedPackages}
                  columns={[
                    {
                      Header: 'Tracking Number',
                      accessor: 'tracking_number',
                    },
                    {
                      Header: 'Set of.',
                      accessor: 'item_count',
                      Cell: this.onEditScanned,
                    },
                    {
                      Header: 'Package Type',
                      accessor: 'package_type',
                      Cell: this.onEditScanned,
                    },
                    {
                      Header: 'Remarks',
                      accessor: 'remarks',
                      Cell: this.onEditScanned,
                    },
                    {
                      Header: 'Section',
                      accessor: 'section',
                      Cell: this.onEditScanned,
                    },
                    {
                      Header: 'Options',
                      sortable: false,
                      filterable: false,
                      Cell: data => (
                        <Row>
                          {
                    (editedTrackingNumber !== data.row.tracking_number)
                      ? (
                        <Row>
                          <Col span={12}>
                            <Button type="link" onClick={() => this.setToEdit(data.row.tracking_number)} block>
                              <Icon viewBox="0 0 1024 1024">
                                <FontAwesomeIcon icon={faEdit} fixedWidth />
                              </Icon>
                          Edit
                            </Button>
                          </Col>
                          <Col span={12}>
                            <Popconfirm
                              placement="leftBottom"
                              title={`Are you sure you want to delete ${data.row.tracking_number} from the list?`}
                              okText="Yes"
                              onConfirm={() => this.confirmRemove(data.row.tracking_number, 'scanned')}
                              cancelText="No"
                            >
                              <Button type="link" block>
                                <Icon viewBox="0 0 1024 1024">
                                  <FontAwesomeIcon icon={faTrash} fixedWidth />
                                </Icon>
                          Remove
                              </Button>
                            </Popconfirm>
                          </Col>
                        </Row>
                      )
                      : (
                        <div>
                          <Col span={12}>
                            <Button type="link" htmlType="submit" disabled={isFinalizing} block>
                              <Icon viewBox="0 0 1024 1024">
                                <FontAwesomeIcon icon={faSave} fixedWidth />
                              </Icon>
                              Save
                            </Button>
                          </Col>

                          <Col span={12}>
                            <Button type="link" onClick={this.cancelEdit} block>
                              <Icon viewBox="0 0 1024 1024">
                                <FontAwesomeIcon icon={faTimes} fixedWidth />
                              </Icon>
                              Cancel
                            </Button>
                          </Col>
                        </div>
                      )
                      }
                        </Row>
                      ),
                    },
                  ]}
                />
              </Form>
            </Card>
            <Spacer />
            {packagesErrors.length !== 0
              ? (
                <Card title="Error List">
                  <ReactTable
                    data={packagesErrors}
                    columns={[
                      {
                        Header: 'Tracking Number',
                        accessor: 'tracking_number',
                      },
                      {
                        Header: 'Error Message',
                        accessor: 'message',
                      },
                      {
                        Header: 'Issued At',
                        accessor: 'issued_at',
                      },
                    ]}
                  />
                </Card>
              ) : ''}

            <Spacer />
            <Row>
              <Popconfirm
                placement="top"
                title="Are you sure you want to finalize scanning for this movement?"
                okText="Yes"
                onConfirm={this.savePackages}
                cancelText="No"
                disabled={isFinalizing || (!newPackages.length && !isUpdated)}
              >
                <Button type="primary" disabled={isFinalizing || (!newPackages.length && !isUpdated)} block>
                  <Icon viewBox="0 0 1024 1024">
                    <FontAwesomeIcon icon={faCheckCircle} fixedWidth />
                  </Icon>
                      Checkout
                </Button>
              </Popconfirm>
            </Row>
          </Container>
        </Spin>
      </div>
    );
  }
}

InboundReprocessEdit.defaultProps = {
  branch: {
    id: null,
    name: null,
  },
};

InboundReprocessEdit.propTypes = {
  doFetchOutboundReprocessDetails: PropTypes.func.isRequired,
  doGetSectionSelect: PropTypes.func.isRequired,
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
  history: PropTypes.oneOfType([PropTypes.object]).isRequired,
  branch: PropTypes.oneOfType([PropTypes.object]),
  match: PropTypes.oneOfType([PropTypes.object]).isRequired,
  doValidatePackage: PropTypes.func.isRequired,
  doStorePackages: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  doFetchOutboundReprocessDetails: getInboundReprocessDetails,
  doValidatePackage: validatePackage,
  doStorePackages: storePackageById,
  doGetRemarks: getRemarks,
  doGetSectionSelect: getSectionSelect,

};

const mapStateToProps = state => ({
  branch: state.auth.branch,
});

const WrappedInboundReprocessEdit = Form.create(
  { name: 'InboundReprocessEdit' },
)(InboundReprocessEdit);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(WrappedInboundReprocessEdit),
);
