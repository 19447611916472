import {
  apiValidateMotorcycleUpload,
  apiUploadMotorcycle,
  apiGetMotorcycles,
} from '../../services/apiEndpoints';

export const VALIDATE_UPLOAD_MOTORCYCLE = 'motorcycle/VALIDATE_UPLOAD_MOTORCYCLE';
export const validateMotorcycleUpload = uploadFile => ({
  type: VALIDATE_UPLOAD_MOTORCYCLE,
  payload: {
    client: 'secure',
    request: {
      ...apiValidateMotorcycleUpload,
      data: uploadFile,
    },
  },
});

export const UPLOAD_MOTORCYCLE = 'motorcycle/UPLOAD_MOTORCYCLE';
export const uploadMotorcycle = parsedContent => ({
  type: UPLOAD_MOTORCYCLE,
  payload: {
    client: 'secure',
    request: {
      ...apiUploadMotorcycle,
      data: {
        valid_motorcycles: parsedContent,
      },
    },
  },
});

export const FETCH_MOTORCYCLE = 'motorcycle/FETCH_MOTORCYCLE';
export const getMotorcycle = data => ({
  type: FETCH_MOTORCYCLE,
  payload: {
    client: 'secure',
    request: {
      ...apiGetMotorcycles,
      params: {
        ...data,
        filters: JSON.stringify(data.filters),
        sorts: JSON.stringify(data.sorts),
        formFilters: JSON.stringify(data.formFilters),
      },
    },
  },
});

const initialState = {};

function reducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}

export default reducer;
