import { apiLogin, apiLogout, apiGetBranchLogin } from '../../services/apiEndpoints';

export const LOGIN = 'auth/LOGIN';
export const LOGIN_SUCCESS = 'auth/LOGIN_SUCCESS';
export const LOGIN_FAIL = 'auth/LOGIN_FAIL';
export const login = values => ({
  type: LOGIN,
  payload: {
    client: 'open',
    request: {
      ...apiLogin,
      data: {
        ...values,
      },
    },
  },
});

export const LOGOUT = 'auth/LOGOUT';
export const LOGOUT_SUCCESS = 'auth/LOGOUT_SUCCESS';
export const LOGOUT_FAIL = 'auth/LOGOUT_FAIL';
export const logout = () => ({
  type: LOGOUT,
  payload: {
    client: 'secure',
    request: {
      ...apiLogout,
    },
  },
});

export const FETCH_BRANCH_SELECT_LOGIN = 'auth/FETCH_BRANCH_SELECT_LOGIN';
export const FETCH_BRANCH_SELECT_LOGIN_SUCCESS = 'auth/FETCH_BRANCH_SELECT_LOGIN_SUCCESS';
export const FETCH_BRANCH_SELECT_LOGIN_FAIL = 'auth/FETCH_BRANCH_SELECT_LOGIN_FAIL';
export const getBranchLogin = () => ({
  type: FETCH_BRANCH_SELECT_LOGIN,
  payload: {
    client: 'open',
    request: {
      ...apiGetBranchLogin,
    },
  },
});

export const REFRESH_TOKEN = 'auth/REFRESH_TOKEN';
export const REFRESH_TOKEN_SUCCESS = 'auth/REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_FAIL = 'auth/REFRESH_TOKEN_FAIL';
export const REFRESH_TOKEN_REQUEST = 'auth/REFRESH_TOKEN_REQUEST';
export const REFRESH_TOKEN_REQUEST_SUCCESS = 'auth/REFRESH_TOKEN_REQUEST_SUCCESS';
export const REFRESH_TOKEN_REQUEST_FAIL = 'auth/REFRESH_TOKEN_REQUEST_FAIL';

const initialState = {
  accessToken: null,
  user: null,
  branch: {
    id: null,
    name: null,
  },
  error: false,
  branchList: {},
};


function reducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        error: false,
        branch: {
          id: action.payload.request.data.branch.value,
          name: action.payload.request.data.branch.label,
        },
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        error: false,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        error: 'Invalid user credentials',
      };
    case REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        accessToken: action.payload.accessToken,
      };
    case LOGOUT_SUCCESS:
      return initialState;
    case FETCH_BRANCH_SELECT_LOGIN_SUCCESS:
      return {
        ...state,
        branchList: action.payload.data,
      };
    case FETCH_BRANCH_SELECT_LOGIN_FAIL:
      return initialState;
    default:
      return state;
  }
}

export default reducer;
