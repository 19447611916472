import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Form, Row, Col, Input, Card, Button, Alert,
} from 'antd';
import Container from '../../components/Container';
import { register, getUsername } from './ducks';
import { getOutsourceSelect } from '../outsource/ducks';
import { getBranchSelect } from '../branch/ducks';
import Select from '../../components/Select';

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
      isFetching: false,
      errors: null,
      success: null,
      outsourceOptions: [],
      branchOptions: [],
    };

    this.formSubmit = this.formSubmit.bind(this);
    this.usernameValidate = this.usernameValidate.bind(this);
  }

  componentDidMount() {
    const { doGetOutsourceSelect, doGetBranchSelect } = this.props;
    doGetOutsourceSelect().then((action) => {
      this.setState({
        outsourceOptions: action.payload.data,
      });
    });
    doGetBranchSelect().then((action) => {
      this.setState({
        branchOptions: action.payload.data,
      });
    });
  }

  formSubmit(e) {
    e.preventDefault();
    const { form, doRegister } = this.props;
    const { validateFieldsAndScroll } = form;

    this.setState({
      isSubmitting: true,
    });

    validateFieldsAndScroll((err, values) => {
      if (!err) {
        window.scrollTo(0, 0);
        doRegister(values).then((action) => {
          this.setState({
            errors: null,
            success: action.payload.data.message,
            isSubmitting: false,
          });
          form.resetFields();
        }).catch((action) => {
          this.setState({
            success: null,
            errors: action.error.response.data,
            isSubmitting: false,
          });
        });
      } else {
        this.setState({
          isSubmitting: false,
        });
      }
    });
  }

  usernameValidate(rule, value, callback) {
    const { findUsername } = this.props;
    this.setState({
      isFetching: true,
    });
    findUsername(value)
      .then(res => (res.payload.data.exists ? callback('Username is already taken.') : callback()));
  }

  render() {
    const {
      form,
    } = this.props;

    const {
      errors, success, isFetching, isSubmitting,
      outsourceOptions, branchOptions,
    } = this.state;

    const { getFieldDecorator } = form;

    const roles = [
      {
        value: 'documentation',
        label: 'Dcoumentation',
      },
      {
        value: 'encoder',
        label: 'Encoder',
      },
    ];

    return (
      <Container>
        <Row>
          <Col span={9} offset={8}>
            <Card>
              <div style={{ textAlign: 'center', marginBottom: '25px' }}>
                <img src="/img/logo-colored.png" alt="Logo" width="120" />
              </div>
              {errors !== null ? (

                <Alert
                  message="Registration Failed!"
                  description="Something went wrong, Please try again later"
                  type="error"
                  showIcon
                />
              ) : (
                ''
              )}
              {success !== null ? (

                <Alert
                  message="Successfully Registed!"
                  description="Note: You will be able to login after the system administrator has approved you account."
                  type="success"
                  showIcon
                />
              ) : (
                ''
              )}
              <Form onSubmit={this.formSubmit}>
                <Form.Item label="First Name">
                  {getFieldDecorator('first_name', {
                    rules: [{ required: true, message: 'First Name cannot be blank.' }],
                  })(<Input autoComplete="off" placeholder="Enter first name" />)}
                </Form.Item>

                <Form.Item label="Last Name">
                  {getFieldDecorator('last_name', {
                    rules: [{ required: true, message: 'Last Name cannot be blank.' }],
                  })(<Input autoComplete="off" placeholder="Enter last name" />)}
                </Form.Item>

                <Form.Item
                  label="Username"
                  hasFeedback={isFetching}

                >
                  {getFieldDecorator('username', {
                    rules: [
                      { required: true, message: 'Username cannot be blank.' },
                      {
                        validator: this.usernameValidate,
                      },
                      {
                        min: 5, message: 'Username must have 5 or more characters.',
                      },
                    ],
                  })(<Input autoComplete="off" placeholder="Enter username" />)}
                </Form.Item>

                <Form.Item label="Password">
                  {getFieldDecorator('password', {
                    rules: [
                      { required: true, message: 'Password cannot be blank.' },
                      {
                        min: 7, message: 'Password must have 7 or more characters.',
                      },
                    ],
                  })(<Input.Password placeholder="Enter password" />)}
                </Form.Item>

                <Form.Item label="Outsource Group">
                  {getFieldDecorator('outsource_group_id', {
                    rules: [{ required: true, message: 'Outsource group cannot be blank' }],
                  })(<Select placeholder="Select outsource group" options={outsourceOptions} />)}
                </Form.Item>

                <Form.Item label="Branch">
                  {getFieldDecorator('branch_id', {
                    rules: [{ required: true, message: 'Branch cannot be blank' }],
                  })(<Select placeholder="Select branch" options={branchOptions} />)}
                </Form.Item>

                <Form.Item label="Role">
                  {getFieldDecorator('role', {
                    rules: [{ required: false, message: 'Role cannot be blank.' }],
                    initialValue: 'encoder',
                  })(<Select placeholder="Select role" options={roles} />)}
                </Form.Item>

                <Form.Item label="Contact">
                  {getFieldDecorator('contact', {
                    rules: [
                      { required: false, message: 'Contact cannot be blank.' },
                      { pattern: '^[0-9]*$', message: 'Contact must contain numbers only' },
                    ],
                  })(<Input type="tel" autoComplete="off" placeholder="Enter contact" />)}
                </Form.Item>

                <Form.Item label="Address">
                  {getFieldDecorator('address', {
                    rules: [{ required: false, message: 'Address cannot be blank.' }],
                  })(<Input autoComplete="off" placeholder="Enter address" />)}
                </Form.Item>

                <Form.Item>
                  <Button type="primary" htmlType="submit" disabled={isSubmitting} block>
                      Register
                  </Button>
                </Form.Item>
                <center>
                  Already have an account?
                  <NavLink to="/login"> Sign In</NavLink>
                </center>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

Register.propTypes = {
  doRegister: PropTypes.func.isRequired,
  findUsername: PropTypes.func.isRequired,
  doGetOutsourceSelect: PropTypes.func.isRequired,
  doGetBranchSelect: PropTypes.func.isRequired,
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const mapDispatchToProps = {
  doRegister: register,
  findUsername: getUsername,
  doGetOutsourceSelect: getOutsourceSelect,
  doGetBranchSelect: getBranchSelect,
};

const WrappedRegister = Form.create({ name: 'Register' })(Register);

export default connect(
  null,
  mapDispatchToProps,
)(WrappedRegister);
