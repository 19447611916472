import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Form, Col, Input, Button, Icon, Popconfirm, message,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus, faSave, faEdit, faTrash, faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';

import Row from '../../components/Row';
import PageHeader from '../../components/PageHeader';
import Card from '../../components/Card';
import Container from '../../components/Container';
import ReactTable from '../../components/ServerSideTable';
import Spacer from '../../components/Spacer';

import {
  addBranch, getBranches, updateBranch, deleteBranch,
} from './ducks';

/**
 * TODO: onFetchData catch, display error
 */
class Branch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      branchList: {
        rows: [],
        pages: 0,
        total: 0,
      },
      isFetching: false,
      fetchMessage: null,
      editedBranch: null,
      currentTable: {
        page: null,
        pageSize: null,
        filters: [],
        sorts: [],
      },
    };

    this.formSubmit = this.formSubmit.bind(this);
    this.onFetchData = this.onFetchData.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this.setToEdit = this.setToEdit.bind(this);
    this.cancelEdit = this.cancelEdit.bind(this);
    this.onUpdateSubmit = this.onUpdateSubmit.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
  }

  onFetchData(state) {
    const { doGetBranches } = this.props;

    const params = {
      page: state.page + 1,
      pageSize: state.pageSize,
      filters: state.filtered,
      sorts: state.sorted,
    };

    this.setState({
      currentTable: params,
      isFetching: true,
      editedBranch: null,
      fetchMessage: 'Fetching branch list...',
    });

    doGetBranches(params)
      .then((action) => {
        this.setState({
          branchList: {
            rows: action.payload.data.data,
            pages: action.payload.data.last_page,
            total: action.payload.data.total,
          },
          isFetching: false,
          fetchMessage: null,
        });
      })
      .catch(() => {
        this.setState({
          isFetching: false,
          fetchMessage: null,
        });
      });
  }

  onUpdateSubmit(e) {
    e.preventDefault();

    const { form, doUpdateBranch } = this.props;
    const { validateFields } = form;
    const { editedBranch } = this.state;

    validateFields(['name_update', 'code_update'], (err, values) => {
      const data = {
        name: values.name_update,
        code: values.code_update,
      };

      if (!err) {
        doUpdateBranch(data, editedBranch)
          .then(() => {
            const { currentTable } = this.state;

            const params = {
              page: currentTable.page - 1,
              pageSize: currentTable.pageSize,
              filtered: currentTable.filters,
              sorted: currentTable.sorts,
            };

            this.setState({
              editedBranch: null,
            });

            message.success('Successfully updated branch!', 3);
            this.onFetchData(params);
          })
          .catch((action) => {
            let errorMessage;
            if (action.error.response.status === 422) {
              const index = Object.values(action.error.response.data);
              // eslint-disable-next-line prefer-destructuring
              errorMessage = index[0][0];
            } else {
              errorMessage = 'Something went wrong, please try again later.';
            }
            message.error(errorMessage, 3);
          });
      }
    });
  }

  onEdit(cellInfo) {
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const { branchList, editedBranch } = this.state;

    if (editedBranch === cellInfo.original.id) {
      return (
        <Form.Item style={{ margin: '0px' }}>
          {getFieldDecorator(`${cellInfo.column.id}_update`, {
            rules: [{ required: false, message: `${cellInfo.column.Header} is required.` }],
            initialValue: branchList.rows[cellInfo.index][cellInfo.column.id],
          })(<Input autoComplete="off" />)}
        </Form.Item>
      );
    }

    return <div>{branchList.rows[cellInfo.index][cellInfo.column.id]}</div>;
  }

  setToEdit(branchId) {
    this.setState({
      editedBranch: branchId,
    });
  }

  cancelEdit() {
    this.setState({
      editedBranch: null,
    });
  }

  formSubmit(e) {
    e.preventDefault();

    const { form, doAddBranch } = this.props;
    const { validateFields } = form;

    validateFields(['name', 'code'], (err, values) => {
      if (!err) {
        const data = {
          name: values.name,
          code: values.code,
        };

        doAddBranch(data)
          .then(() => {
            const { currentTable } = this.state;

            const params = {
              page: currentTable.page - 1,
              pageSize: currentTable.pageSize,
              filtered: currentTable.filters,
              sorted: currentTable.sorts,
            };

            this.setState({
              editedBranch: null,
            });

            message.success('Successfully created branch!', 3);
            this.onFetchData(params);

            form.resetFields();
          }).catch((action) => {
            let errorMessage;
            if (action.error.response.status === 422) {
              const index = Object.values(action.error.response.data);
              // eslint-disable-next-line prefer-destructuring
              errorMessage = index[0][0];
            } else {
              errorMessage = 'Something went wrong, please try again later.';
            }
            message.error(errorMessage, 3);
          });
      }
    });
  }

  confirmDelete(branchId) {
    const { doDeleteBranch } = this.props;

    doDeleteBranch(branchId)
      .then((action) => {
        if (action.payload.data) {
          const { currentTable } = this.state;
          const params = {
            page: currentTable.page - 1,
            pageSize: currentTable.pageSize,
            filtered: currentTable.filters,
            sorted: currentTable.sorts,
          };

          this.setState({
            editedBranch: null,
          });

          message.success('Successfully deleted branch.', 3);

          this.onFetchData(params);
        } else {
          message.error('Unable to delete data that has 1 or more reference(s).', 3);
        }
      })
      .catch(() => {
        message.error('Something went wrong, please try again later.', 3);
      });
  }

  render() {
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const {
      branchList, isFetching, fetchMessage, editedBranch,
    } = this.state;

    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
    };
    const buttonLayout = {
      wrapperCol: { span: 14 },
    };

    return (
      <React.Fragment>
        <PageHeader title="Branch" />
        <Container>
          <Card title="Branch Information">
            <Form layout="horizontal" onSubmit={this.formSubmit}>
              <Row>
                <Col xs={24} sm={12} lg={6}>
                  <Form.Item label="Name" {...formItemLayout}>
                    {getFieldDecorator('name', {
                      rules: [{ required: true, message: 'Name cannot be blank.' }],
                    })(<Input autoComplete="off" placeholder="E.g. Tipas" />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={6}>
                  <Form.Item label="Code" {...formItemLayout}>
                    {getFieldDecorator('code', {
                      rules: [{ required: true, message: 'Code cannot be blank' }],
                    })(<Input autoComplete="off" placeholder="E.g. TPS" />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={6}>
                  <Form.Item {...buttonLayout}>
                    <Button type="primary" onClick={this.formSubmit} block>
                      <Icon viewBox="0 0 1024 1024">
                        <FontAwesomeIcon icon={faPlus} fixedWidth />
                      </Icon>
                      Create
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
          <Spacer />
          <Card title="Branch List">
            <Form onSubmit={this.onUpdateSubmit}>
              <ReactTable
                data={branchList.rows}
                pages={branchList.pages}
                loadingText={fetchMessage}
                loading={isFetching}
                onFetchData={this.onFetchData}
                columns={[
                  {
                    Header: 'Id',
                    accessor: 'id',
                  },
                  {
                    Header: 'Name',
                    accessor: 'name',
                    Cell: this.onEdit,
                  },
                  {
                    Header: 'Code',
                    accessor: 'code',
                    Cell: this.onEdit,
                  },
                  {
                    id: 'created_by',
                    Header: 'Created By',
                    accessor: data => data.user.full_name,
                  },
                  {
                    Header: 'Options',
                    filterable: false,
                    sortable: false,
                    Cell: data => (
                      <Row>
                        {editedBranch !== data.row.id ? (
                          <Row>
                            <Col span={12}>
                              <Button
                                type="link"
                                onClick={() => this.setToEdit(data.row.id)}
                                block
                              >
                                <Icon viewBox="0 0 1024 1024">
                                  <FontAwesomeIcon icon={faEdit} fixedWidth />
                                </Icon>
                                Edit
                              </Button>
                            </Col>
                            <Col span={12}>
                              <Popconfirm
                                placement="leftBottom"
                                title={`Are you sure you want to delete ${data.row.name} from the list?`}
                                okText="Yes"
                                onConfirm={() => this.confirmDelete(data.row.id)}
                                cancelText="No"
                              >
                                <Button type="link" block>
                                  <Icon viewBox="0 0 1024 1024">
                                    <FontAwesomeIcon icon={faTrash} fixedWidth />
                                  </Icon>
                                  Delete
                                </Button>
                              </Popconfirm>
                            </Col>
                          </Row>
                        ) : (
                          <div>
                            <Col span={12}>
                              <Button type="link" htmlType="submit" block>
                                <Icon viewBox="0 0 1024 1024">
                                  <FontAwesomeIcon icon={faSave} fixedWidth />
                                </Icon>
                                Save
                              </Button>
                            </Col>

                            <Col span={12}>
                              <Button type="link" onClick={this.cancelEdit} block>
                                <Icon viewBox="0 0 1024 1024">
                                  <FontAwesomeIcon icon={faTimes} fixedWidth />
                                </Icon>
                                Cancel
                              </Button>
                            </Col>
                          </div>
                        )}
                      </Row>
                    ),
                  },
                ]}
              />
            </Form>
          </Card>
        </Container>
      </React.Fragment>
    );
  }
}

Branch.propTypes = {
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
  doAddBranch: PropTypes.func.isRequired,
  doGetBranches: PropTypes.func.isRequired,
  doUpdateBranch: PropTypes.func.isRequired,
  doDeleteBranch: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  doAddBranch: addBranch,
  doGetBranches: getBranches,
  doUpdateBranch: updateBranch,
  doDeleteBranch: deleteBranch,
};

const WrappedBranch = Form.create({ name: 'Branch' })(Branch);

export default connect(
  null,
  mapDispatchToProps,
)(WrappedBranch);
