import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Col, Form, Input, Button, Icon, message,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import GroupSelect from '../../components/GroupSelect';
import Select from '../../components/Select';
import Row from '../../components/Row';
import PageHeader from '../../components/PageHeader';
import Card from '../../components/Card';
import Container from '../../components/Container';
import { getHaulerSelect } from '../hauler/ducks';
import { getBranchGates } from '../gate/ducks';
import { getVehiclesLike } from '../vehicle/ducks';
import { addOutbound } from './ducks';

class OutboundCreate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      gateOptions: {},
      haulerOptions: [],
      plateNumberOptions: [],
    };

    this.formSubmit = this.formSubmit.bind(this);
    this.setPlateNumber = this.setPlateNumber.bind(this);
    this.searchPlateNumber = this.searchPlateNumber.bind(this);
  }

  componentDidMount() {
    const { doGetBranchGates, branch, doGetHaulerSelect } = this.props;
    doGetBranchGates(branch.id, 'outbound').then((action) => {
      const usedGates = [];
      const availableGates = [];
      action.payload.data.forEach((gate) => {
        if (
          (
            gate.outbound.length
            || gate.inbound.length
          )
          && (
            gate.inbound.some(inbound => inbound.status === 'fresh')
            || gate.outbound.some(outbound => outbound.status === 'fresh')
          )
        ) {
          usedGates.push({
            value: gate.id,
            label: `${gate.gate_number} - #${gate.outbound.length ? gate.outbound[0].id : gate.inbound[0].id}`,
            disabled: true,
          });
        } else {
          availableGates.push({
            value: gate.id,
            label: gate.gate_number,
          });
        }
      });

      const gateOptions = {
        Available: {
          options: [
            ...availableGates,
          ],
        },
        Used: {
          options: [
            ...usedGates,
          ],
        },
      };

      this.setState({ gateOptions });
    });

    doGetHaulerSelect().then((action) => {
      this.setState({
        haulerOptions: action.payload.data.map(hauler => ({
          label: hauler.label,
          value: hauler.value,
          disabled: !hauler.is_active,
        })),
      });
    });
  }

  setPlateNumber(e) {
    const { plateNumberOptions } = this.state;
    const { form } = this.props;

    const selected = plateNumberOptions.filter(item => item.value === e);

    form.setFieldsValue({ truck_type: selected[0].type });
  }

  searchPlateNumber(e) {
    const { doGetVehiclesLike } = this.props;

    doGetVehiclesLike(e).then((action) => {
      this.setState({
        plateNumberOptions: action.payload.data.map(plateNumber => ({
          ...plateNumber,
          label: `${plateNumber.label} - ${plateNumber.type}`,
        })),
      });
    });
  }

  formSubmit(e) {
    e.preventDefault();
    const {
      form, doAddOutbound, history, branch,
    } = this.props;
    const { validateFields } = form;

    validateFields((err, values) => {
      if (!err) {
        doAddOutbound(values, branch.id).then((action) => {
          history.push(`/outbound/${action.payload.data.id}`);
        }).catch((action) => {
          let errorMessage;
          if (action.error.response.status === 422) {
            const index = Object.values(action.error.response.data);
            // eslint-disable-next-line prefer-destructuring
            errorMessage = index[0][0];
          } else {
            errorMessage = 'Something went wrong, please try again later.';
          }
          message.error(errorMessage, 3);
        });
      }
    });
  }

  render() {
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const { gateOptions, haulerOptions, plateNumberOptions } = this.state;

    return (
      <div className="OutboundCreate">
        <PageHeader title="Outbound" />
        <Container>
          <Card title="Create Outbound">
            <Form layout="horizontal">
              <Row>
                <Col xs={24} sm={12} lg={12}>
                  <Form.Item label="Driver">
                    {getFieldDecorator('driver', {
                      rules: [{ required: true, message: 'Driver cannot be blank' }],
                    })(<Input autoComplete="off" placeholder="Enter driver" />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={12}>
                  <Form.Item label="Helper">
                    {getFieldDecorator('helper', {
                      rules: [{ required: true, message: 'Helper cannot be blank' }],
                    })(<Input autoComplete="off" placeholder="Enter helper" />)}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={12} lg={12}>
                  <Form.Item label="Plate Number">
                    {getFieldDecorator('plate_number', {
                      rules: [{ required: true, message: 'Plate Number cannot be blank.' }],
                    })(<Select
                      key="plateNumber"
                      options={plateNumberOptions}
                      placeholder="Please type to search plate number"
                      filterOption={false}
                      onSearch={this.searchPlateNumber}
                      onChange={this.setPlateNumber}
                      showSearch
                    />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={12}>
                  <Form.Item label="Truck Type">
                    {getFieldDecorator('truck_type', {
                      rules: [{ required: false, message: 'Truck Type cannot be blank' }],
                    })(<Input placeholder="Truck type will reflect on selected plate number" disabled />)}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={12} lg={12}>
                  <Form.Item label="Seal Number">
                    {getFieldDecorator('seal_number', {
                      rules: [{ required: true, message: 'Seal Number cannot be blank.' }],
                    })(<Input autoComplete="off" placeholder="Enter seal number" />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={12}>
                  <Form.Item label="Destination">
                    {getFieldDecorator('destination', {
                      rules: [{ required: true, message: 'Destination Type cannot be blank' }],
                    })(<Input autoComplete="off" placeholder="Enter destination" />)}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={12} lg={12}>
                  <Form.Item label="Hauler">
                    {getFieldDecorator('hauler', {
                      rules: [{ required: true, message: 'Hauler cannot be blank' }],
                    })(<Select placeholder="Select hauler" options={haulerOptions} />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={12}>
                  <Form.Item label="Gate">
                    {getFieldDecorator('gate', {
                      rules: [{ required: true, message: 'Gate cannot be blank' }],
                    })(<GroupSelect placeholder="Select gate" options={gateOptions} />)}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={12} lg={12}>
                  <Form.Item label="Comment">
                    {getFieldDecorator('comment', {
                      rules: [{ required: false, message: 'Comment cannot be blank' }],
                    })(<Input autoComplete="off" placeholder="Enter comment" />)}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Item>
                    <Button type="primary" onClick={this.formSubmit} block>
                      <Icon viewBox="0 0 1024 1024">
                        <FontAwesomeIcon icon={faPlus} fixedWidth />
                      </Icon>
                        Create
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </Container>
      </div>
    );
  }
}

OutboundCreate.defaultProps = {
  branch: {
    id: null,
    name: null,
  },
};

const mapStateToProps = state => ({
  branch: state.auth.branch,
});


OutboundCreate.propTypes = {
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
  doGetVehiclesLike: PropTypes.func.isRequired,
  doGetHaulerSelect: PropTypes.func.isRequired,
  doGetBranchGates: PropTypes.func.isRequired,
  doAddOutbound: PropTypes.func.isRequired,
  history: PropTypes.oneOfType([PropTypes.object]).isRequired,
  branch: PropTypes.oneOfType([PropTypes.object]),
};

const mapDispatchToProps = {
  doGetVehiclesLike: getVehiclesLike,
  doGetHaulerSelect: getHaulerSelect,
  doGetBranchGates: getBranchGates,
  doAddOutbound: addOutbound,
};

const WrappedOutboundCreate = Form.create({ name: 'OutboundCreate' })(OutboundCreate);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WrappedOutboundCreate));
