import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';

const RouteComponent = (props) => {
  const { component: Component, ...rest } = props;
  return <Route {...rest} render={componentProps => <Component {...componentProps} />} />;
};

RouteComponent.propTypes = {
  component: PropTypes.oneOfType([PropTypes.elementType, PropTypes.object]).isRequired,
};

export default RouteComponent;
