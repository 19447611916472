import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Col, Form, Input, Button, Icon, Popconfirm, Spin,
  message, Statistic as Label, Collapse, Descriptions,
  Slider,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import Row from '../../components/Row';
import PageHeader from '../../components/PageHeader';
import Card from '../../components/Card';
import Container from '../../components/Container';
import ReactTable from '../../components/ReactTable';
import Spacer from '../../components/Spacer';
import Timer from '../../components/Timer';
import {
  validatePackage,
  getPackageDetails,
  addOutboundPackages,
  getOutbound,
  savePackageToState,
  deletePackageFromState,
} from './ducks';

class OutboundPackage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lastPackageScannedSize: 24,
      isUpdated: false,
      isFinalizing: false,
      isLoading: true,
      packagesErrors: [],
      deletedPackages: [],
      outboundDetails: {
        start: moment().format('YYYY-MM-DD HH:mm:ss'),
      },
    };
    this.addPackage = this.addPackage.bind(this);
    this.savePackages = this.savePackages.bind(this);
    this.confirmRemove = this.confirmRemove.bind(this);
    this.zoomLastPackageScanned = this.zoomLastPackageScanned.bind(this);
  }

  componentDidMount() {
    const {
      doGetOutbound, history, branch, match,
    } = this.props;
    doGetOutbound(`outbound/${match.params.id}`, branch, false).then((action) => {
      const timeDiff = moment(
        action.payload.data.scan_time, 'YYYY-MM-DD HH:mm:ss',
      ).diff(
        moment(action.payload.data.start, 'YYYY-MM-DD HH:mm:ss'),
      );
      this.setState({
        outboundDetails: {
          ...action.payload.data,
          gate: action.payload.data.gate.gate_number,
          hauler: action.payload.data.hauler_details.name,
          truck_type: action.payload.data.truck_type.replace(/_/g, ' '),
          plate_number: action.payload.data.vehicle.plate_number,
          existingPackages: action.payload.data.packages,
          user: action.payload.data.user.full_name,
          gate_time: moment.utc(moment.duration(timeDiff, 'ms').asMilliseconds()).format('HH:mm:ss'),
        },
        isLoading: false,
      });
    }).catch(() => {
      history.push('/404');
    });
  }

  addPackage(e) {
    e.preventDefault();
    const {
      form,
      doValidatePackage,
      branch,
      doGetPackageDetails,
      match,
      outbound,
      doSavePackageToState,
    } = this.props;
    const { packagesErrors, outboundDetails } = this.state;
    const { validateFields } = form;

    validateFields(['tracking_number'], (err, values) => {
      if (!err) {
        // get current packages tracking numbers
        const currentTrackingNumbers = outboundDetails.packages;

        // get scanned packages tracking numbers
        const scannedTrackingNumbers = _.has(outbound, match.params.id)
          ? outbound[match.params.id].scannedPackages : [];

        // get input tracking numbers
        let trackingNumbers = values.tracking_number.split('\n').filter(tn => tn !== '');
        trackingNumbers = trackingNumbers.map(trackingNumber => trackingNumber.toUpperCase());

        // get duplicates on current packages
        const duplicatesCurrent = _.intersection(
          trackingNumbers,
          currentTrackingNumbers.map((packageDetails => packageDetails.tracking_number)),
        );

        // get duplicates on scanned packages
        const duplicatesScanned = _.intersection(
          trackingNumbers,
          scannedTrackingNumbers.map((packageDetails => packageDetails.tracking_number)),
        );

        // get duplicates on input packages
        const groupped = _.groupBy(trackingNumbers, (tn => tn));
        const duplicatesInput = _.uniq(_.flatten(_.filter(groupped, (tn => tn.length > 1))));

        // remove duplicates with current packages and scanned packages
        const filteredTrackingNumbers = trackingNumbers.map((trackingNumber) => {
          if (!_.find(currentTrackingNumbers, { tracking_number: trackingNumber })
          && !_.find(scannedTrackingNumbers, { tracking_number: trackingNumber })) {
            return trackingNumber;
          }
          return null;
        });

        // chunk input to 100
        const batchTrackingNumbers = _.chunk(_.uniq(_.compact(filteredTrackingNumbers)), 100);

        const duplicates = _.concat(
          _.concat(duplicatesScanned, duplicatesCurrent),
          duplicatesInput,
        );
        if (duplicates.length) {
          const duplicatedTn = packagesErrors;
          duplicates.forEach((trackingNumber) => {
            duplicatedTn.push({
              message: 'Package already scanned',
              tracking_number: trackingNumber,
              issued_at: moment().format('YYYY-MM-DD HH:mm:ss'),
            });
          });
          message.error(`${duplicates.length} packages are already scanned!`);
          this.setState({
            packagesErrors: duplicatedTn,
          });
        }

        batchTrackingNumbers.forEach((batch) => {
          doValidatePackage({
            tracking_number: batch,
            movement_id: match.params.id,
            branch_id: branch.id,
            origin: null,
            remarks: null,
          }).then((action) => {
            const { success, errors } = action.payload.data;
            if (success) {
              let packages = Object.keys(success);
              // revalidate response for duplicate packages
              const revalidatedPackages = _.intersection(
                packages,
                scannedTrackingNumbers.map((packageDetails => packageDetails.tracking_number)),
              );
              if (revalidatedPackages.length) {
                const duplicatedTn = packagesErrors;
                revalidatedPackages.forEach((trackingNumber) => {
                  duplicatedTn.push({
                    message: 'Package already scanned',
                    tracking_number: trackingNumber,
                    issued_at: moment().format('YYYY-MM-DD HH:mm:ss'),
                  });
                });
                packages = _.difference(packages, revalidatedPackages);
                message.error(`${revalidatedPackages.length} packages are already scanned!`);
                this.setState({
                  packagesErrors: duplicatedTn,
                });
              }

              // parse validated packages
              doGetPackageDetails({ tracking_number: packages }).then((packageDetails) => {
                const details = packageDetails.payload.data;
                const parsedSuccessPackages = details.map(packageInfo => ({
                  location: branch.id,
                  tracking_number: packageInfo.tracking_number,
                  item_count: packageInfo.item_count,
                  package_type: packageInfo.package_type,
                  remarks: packageInfo.status === 'inbound'
                    ? packageInfo.inbound_remarks
                    : packageInfo.inbound_reprocess_remarks,
                }));
                const currentScannedPackages = parsedSuccessPackages.concat(
                  // eslint-disable-next-line react/destructuring-assignment
                  _.has(this.props.outbound, match.params.id)
                  // eslint-disable-next-line react/destructuring-assignment
                    ? this.props.outbound[match.params.id].scannedPackages
                    : [],
                );

                if (packages.length) {
                  doSavePackageToState(match.params.id, currentScannedPackages);

                  message.success(`Successfully inserted ${packages.length} packages `);
                }
              });
            }

            if (errors) {
              const packages = Object.keys(errors);
              const parsedErrorPackages = packages.map(trackingNumber => ({
                message: errors[trackingNumber].message,
                tracking_number: trackingNumber,
                issued_at: moment().format('YYYY-MM-DD HH:mm:ss'),
              }));
              this.setState(prevState => ({
                packagesErrors: parsedErrorPackages.concat(prevState.packagesErrors),
              }));
              message.error(`Failed to insert ${_.values(errors).length} packages`);
            }
          });
        });
        form.resetFields(['tracking_number']);
      }
    });
  }

  savePackages() {
    const {
      doAddOutboundPackages,
      history,
      match,
      branch,
      outbound,
    } = this.props;
    const { deletedPackages, isUpdated } = this.state;
    if (_.has(outbound, match.params.id)
      ? outbound[match.params.id].scannedPackages.length
      : false
    || isUpdated) {
      this.setState({
        isFinalizing: true,
      }, window.scrollTo(0, 0));
      doAddOutboundPackages(
        history.location.pathname,
        _.has(outbound, match.params.id) ? _.uniqBy(outbound[match.params.id].scannedPackages, 'tracking_number') : [],
        deletedPackages,
        branch.id,
      ).then((action) => {
        history.push(`/outbound/${match.params.id}/result`, action.payload.data);
      });
    } else {
      message.error('No changes detected');
    }
  }

  confirmRemove(packageIndex) {
    const { match, doDeletePackageFromState, outbound } = this.props;
    const updatedPackages = outbound[match.params.id].scannedPackages;
    updatedPackages.splice(packageIndex, 1);
    doDeletePackageFromState(match.params.id, updatedPackages);
    message.success('Package removed successfully!', 3);
  }

  confirmDelete(packageIndex) {
    const { outboundDetails } = this.state;
    this.setState(prevState => ({
      deletedPackages: [
        ...prevState.deletedPackages,
        outboundDetails.existingPackages[packageIndex],
      ],
    }), () => {
      outboundDetails.existingPackages.splice(packageIndex, 1);
      this.setState(prevState => ({
        outboundDetails: {
          ...prevState.outboundDetails,
        },
        isUpdated: true,
      }));
      message.info('Package is pending for deletion.', 3);
    });
  }

  zoomLastPackageScanned(e) {
    this.setState({
      lastPackageScannedSize: 24 + e,
    });
  }

  render() {
    const { Panel } = Collapse;
    const { form, match, outbound } = this.props;
    const { getFieldDecorator } = form;
    const { TextArea } = Input;
    const {
      packagesErrors,
      isFinalizing,
      isUpdated,
      outboundDetails,
      isLoading,
      lastPackageScannedSize,
    } = this.state;
    const packageFormLayout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 20 },
    };
    const loadingMessage = isFinalizing ? 'Preparing to checkout packages, please don`t refresh or leave this page. . .'
      : 'Fetching Outbound details . . .';
    return (
      <div className="OutboundPackage">
        <PageHeader title={`Outbound #${match.params.id}`} />
        <Spin spinning={isFinalizing || isLoading} tip={loadingMessage}>
          <Container>
            <Collapse defaultActiveKey={['1']}>
              <Panel
                header="Outbound Details"
                key="1"
                extra={<Timer label="Outbound Timer" startTime={outboundDetails.start} />}
              >
                <Descriptions title="" bordered>
                  <Descriptions.Item label="Gate">{outboundDetails.gate}</Descriptions.Item>
                  <Descriptions.Item label="Driver">{outboundDetails.driver}</Descriptions.Item>
                  <Descriptions.Item label="Destination">{outboundDetails.destination}</Descriptions.Item>
                  <Descriptions.Item label="Plate Number">
                    {outboundDetails.plate_number}
                  </Descriptions.Item>
                  <Descriptions.Item label="Seal Number">
                    {outboundDetails.seal_number}
                  </Descriptions.Item>
                  <Descriptions.Item label="Hauler">
                    {outboundDetails.hauler}
                  </Descriptions.Item>
                  <Descriptions.Item label="Truck Type">{outboundDetails.truck_type}</Descriptions.Item>
                  <Descriptions.Item label="Receiver">{outboundDetails.user}</Descriptions.Item>
                  <Descriptions.Item label="Comment">{outboundDetails.comment}</Descriptions.Item>
                </Descriptions>
              </Panel>
            </Collapse>
            <Spacer />
            <Card title="Outbound Package Information">
              <Row>
                <Col>
                  <Form layout="horizontal" onSubmit={this.addPackage}>
                    <Row>
                      <Col>
                        <Form.Item label="Tracking Number" {...packageFormLayout}>
                          {getFieldDecorator('tracking_number', {
                            rules: [{ required: true, message: 'Tracking Number cannot be blank.' }],
                          })(<TextArea autoComplete="off" onPressEnter={this.addPackage} placeholder="E.g. X0123" />)}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Button type="primary" htmlType="submit" disabled={isFinalizing} block>
                          <Icon viewBox="0 0 1024 1024">
                            <FontAwesomeIcon icon={faPlus} fixedWidth />
                          </Icon>
                      Add Tracking Number
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </Card>
            <Spacer />
            <Card title="">
              <Row>
                <center>
                  <Col
                    xs={24}
                    sm={lastPackageScannedSize > 44 ? 24 : 12}
                    lg={lastPackageScannedSize > 44 ? 24 : 12}
                  >
                    <Label
                      title={(
                        <React.Fragment>
                          Last Package Scanned
                          <Slider
                            defaultValue={0}
                            onAfterChange={this.zoomLastPackageScanned}
                            max={35}
                          />
                        </React.Fragment>
                      )}
                      value={_.has(outbound, match.params.id)
                        && outbound[match.params.id].scannedPackages.length
                        ? outbound[match.params.id].scannedPackages[0].tracking_number
                        : ''}
                      groupSeparator=""
                      valueStyle={{ fontSize: lastPackageScannedSize, wordWrap: 'break-word' }}
                    />
                  </Col>
                  <Col
                    xs={24}
                    sm={lastPackageScannedSize > 44 ? 24 : 12}
                    lg={lastPackageScannedSize > 44 ? 24 : 12}
                  >
                    <Label
                      title="Total Scanned Packages"
                      value={_.has(outbound, match.params.id)
                        ? outbound[match.params.id].scannedPackages.length
                        : 0}
                      valueStyle={{ fontSize: lastPackageScannedSize, wordWrap: 'break-word' }}
                    />
                  </Col>
                </center>
              </Row>
            </Card>
            <Spacer />
            <Card title="Scanned Packages">
              <ReactTable
                data={_.has(outbound, match.params.id)
                  ? outbound[match.params.id].scannedPackages
                  : []}
                columns={[
                  {
                    Header: 'Tracking Number',
                    accessor: 'tracking_number',
                  },
                  {
                    Header: 'Set of.',
                    accessor: 'item_count',
                  },
                  {
                    Header: 'Package Type',
                    accessor: 'package_type',
                  },
                  {
                    Header: 'Remarks',
                    accessor: 'remarks',
                  },
                  {
                    Header: 'Options',
                    sortable: false,
                    Cell: data => (
                      <Row>
                        <Col span={24}>
                          <Popconfirm
                            placement="leftBottom"
                            title={`Are you sure you want to delete ${data.row.tracking_number} from the list?`}
                            okText="Yes"
                            onConfirm={
                            () => this.confirmRemove(data.index)
                            }
                            cancelText="No"
                          >
                            <Button type="link" block>
                              <Icon viewBox="0 0 1024 1024">
                                <FontAwesomeIcon icon={faTrash} fixedWidth />
                              </Icon>
                            Remove
                            </Button>
                          </Popconfirm>
                        </Col>
                      </Row>
                    ),
                  },
                ]}
              />
            </Card>

            <Spacer />

            <Card title="Outbound Current Packages">
              <ReactTable
                data={outboundDetails.existingPackages}
                columns={[
                  {
                    Header: 'Tracking Number',
                    accessor: 'tracking_number',
                  },
                  {
                    Header: 'Set of.',
                    accessor: 'item_count',
                  },
                  {
                    Header: 'Package Type',
                    accessor: 'package_type',
                  },
                  {
                    Header: 'Remarks',
                    accessor: 'remarks',
                  },
                  {
                    Header: 'Options',
                    sortable: false,
                    Cell: data => (
                      <Row>
                        <Col span={24}>
                          <Popconfirm
                            placement="leftBottom"
                            title={`Are you sure you want to delete ${data.row.tracking_number} from the list?`}
                            okText="Yes"
                            onConfirm={
                            () => this.confirmDelete(data.index)
                            }
                            cancelText="No"
                          >
                            <Button type="link" block>
                              <Icon viewBox="0 0 1024 1024">
                                <FontAwesomeIcon icon={faTrash} fixedWidth />
                              </Icon>
                            Remove
                            </Button>
                          </Popconfirm>
                        </Col>
                      </Row>
                    ),
                  },

                ]}
              />
            </Card>

            <Spacer />

            {packagesErrors.length !== 0
              ? (
                <Card title="Error List">
                  <ReactTable
                    data={packagesErrors}
                    columns={[
                      {
                        Header: 'Tracking Number',
                        accessor: 'tracking_number',
                      },
                      {
                        Header: 'Error Message',
                        accessor: 'message',
                      },
                      {
                        Header: 'Issued At',
                        accessor: 'issued_at',
                      },
                    ]}
                  />
                </Card>
              ) : ''}

            <Spacer />

            <Popconfirm
              placement="top"
              title="Are you sure you want to finalize scanning for this movement?"
              okText="Yes"
              onConfirm={this.savePackages}
              cancelText="No"
              disabled={isFinalizing || (
                !_.has(outbound, match.params.id)
                && !isUpdated
              )}
            >
              <Button disabled={isFinalizing || (!_.has(outbound, match.params.id) && !isUpdated)} block type="primary">
                <Icon viewBox="0 0 1024 1024">
                  <FontAwesomeIcon icon={faCheckCircle} fixedWidth />
                </Icon>
                      Checkout
              </Button>
            </Popconfirm>
          </Container>
        </Spin>
      </div>
    );
  }
}

OutboundPackage.defaultProps = {
  outboundDetails: {},
};

OutboundPackage.propTypes = {
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
  outboundDetails: PropTypes.oneOfType([PropTypes.object]),
  branch: PropTypes.oneOfType([PropTypes.object]).isRequired,
  match: PropTypes.oneOfType([PropTypes.object]).isRequired,
  history: PropTypes.oneOfType([PropTypes.object]).isRequired,
  outbound: PropTypes.oneOfType([PropTypes.object]).isRequired,
  doValidatePackage: PropTypes.func.isRequired,
  doGetPackageDetails: PropTypes.func.isRequired,
  doAddOutboundPackages: PropTypes.func.isRequired,
  doGetOutbound: PropTypes.func.isRequired,
  doSavePackageToState: PropTypes.func.isRequired,
  doDeletePackageFromState: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  branch: state.auth.branch,
  outbound: state.outbound,
});

const mapDispatchToProps = {
  doValidatePackage: validatePackage,
  doGetPackageDetails: getPackageDetails,
  doAddOutboundPackages: addOutboundPackages,
  doGetOutbound: getOutbound,
  doSavePackageToState: savePackageToState,
  doDeletePackageFromState: deletePackageFromState,
};

const WrappedOutbounddPackageEdit = Form.create({ name: 'OutboundPackage' })(OutboundPackage);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(WrappedOutbounddPackageEdit),
);
