
import { apiRegister, apiGetUsername } from '../../services/apiEndpoints';

export const REGISTER = 'register/REGISTER';

export const register = values => ({
  type: REGISTER,
  payload: {
    client: 'open',
    request: {
      ...apiRegister,
      data: {
        ...values,
        status: 'pending',
      },
    },
  },
});

export const GET_USERNAME = 'register/GET_USERNAME';

export const getUsername = string => ({
  type: GET_USERNAME,
  payload: {
    client: 'open',
    request: {
      ...apiGetUsername,
      url: `${apiGetUsername.url}/${string}`,
    },
  },
});

const initialState = {};

function reducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}

export default reducer;
