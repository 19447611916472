import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Statistic as Label, Row, Col, Button,
} from 'antd';
import Card from '../../components/Card';
import ReactTable from '../../components/ReactTable';
import Spacer from '../../components/Spacer';
import Container from '../../components/Container';

class InboundResult extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.inboundRedirect = this.inboundRedirect.bind(this);
  }

  inboundRedirect() {
    const { match, history } = this.props;
    history.push(`/inbound/${match.params.id}`);
  }

  render() {
    const { history } = this.props;
    const {
      created,
      deleted,
      updated,
      unsaved,
    } = history.location.state;

    const createdGood = created.filter(scanned => scanned.package_details.remarks === 'good');

    const createdRefused = created.filter(scanned => scanned.package_details.remarks !== 'good');

    const refused = [
      ...createdRefused,
      ...unsaved,
    ];

    const totalResult = [
      ...createdGood,
      ...updated,
      ...refused,
      ...deleted,
    ];

    return (
      <Container>
        <Card title="Inbound Packages result">
          <Card title="">
            <Row>
              <center>
                <Col xs={24} sm={6} lg={4}>
                  <Label title="Good" value={createdGood.length} />
                </Col>
                <Col xs={24} sm={6} lg={4}>
                  <Label title="Updated" value={updated.length} />
                </Col>
                <Col xs={24} sm={6} lg={4}>
                  <Label title="Refused" value={createdRefused.length} />
                </Col>
                <Col xs={24} sm={6} lg={4}>
                  <Label title="Deleted" value={deleted.length} />
                </Col>
                <Col xs={24} sm={6} lg={4}>
                  <Label title="Failed" value={unsaved.length} />
                </Col>
                <Col xs={24} sm={6} lg={4}>
                  <Label title="Total Scans" value={created.length + unsaved.length} />
                </Col>
              </center>
            </Row>
          </Card>
          <Spacer />
          <ReactTable
            // eslint-disable-next-line no-unused-vars
            getTrProps={(state, rowData, instance) => {
              if (typeof rowData !== 'undefined') {
                if (rowData.row.status === 'refused'
                || rowData.row.status === 'failed'
                || rowData.row.status === 'deleted'
                ) {
                  return {
                    style: {
                      background: '#ffccc7',
                    },
                  };
                }

                if (rowData.row.status === 'updated') {
                  return {
                    style: {
                      background: '#bae7ff',
                    },
                  };
                }


                if (rowData.row.status === 'added') {
                  return {
                    style: {
                      background: '#b3ff99',
                    },
                  };
                }
              }

              return {
                style: {
                  background: 'white',
                },
              };
            }}
            data={totalResult}
            columns={[
              {
                id: 'tracking_number',
                Header: 'Tracking Number',
                accessor: data => data.package_details.tracking_number,
              },
              {
                id: 'item_count',
                Header: 'Item Count',
                accessor: data => data.package_details.item_count,
              },
              {
                id: 'package_type',
                Header: 'Package Type',
                accessor: data => data.package_details.package_type,
              },
              {
                id: 'client',
                Header: 'Client',
                accessor: data => data.package_details.client,
              },
              {
                id: 'remarks',
                Header: 'Package Remarks',
                accessor: data => data.package_details.remarks,
              },
              {
                id: 'section',
                Header: 'Section',
                accessor: data => data.package_details.section,
              },
              {
                Header: 'Status',
                accessor: 'status',
              },
              {
                Header: 'Message',
                accessor: 'message',
                width: 320,

              }]}
          />
          <Spacer />
          <Button onClick={this.inboundRedirect} block type="primary">View Inbound</Button>
        </Card>
      </Container>
    );
  }
}

InboundResult.propTypes = {
  match: PropTypes.oneOfType([PropTypes.object]).isRequired,
  history: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default connect(null, {})(InboundResult);
