export const apiLogin = {
  url: '/login',
  method: 'POST',
};

export const apiRefresh = {
  url: '/refresh',
  method: 'POST',
};

export const apiRegister = {
  url: '/register',
  method: 'POST',
};

export const apiGetUsername = {
  url: '/user/username',
  method: 'GET',
};

export const apiGetResetPassword = {
  url: '/user/resetPassword',
  method: 'PUT',
};

export const apiLogout = {
  url: '/logout',
  method: 'POST',
};

export const apiGetUsers = {
  url: '/user',
  method: 'GET',
};

export const apiGetForApprovalUsers = {
  url: '/user/approval',
  method: 'GET',
};

export const apiGetUserById = {
  url: '/user',
  method: 'GET',
};

export const apiUpdateUser = {
  url: '/user',
  method: 'PUT',
};

export const apiUpdateUserStatus = {
  url: '/user/approval/result',
  method: 'PUT',
};

export const apiGetBranches = {
  url: '/branch',
  method: 'GET',
};
export const apiUpdateBranch = {
  url: '/branch',
  method: 'PUT',
};
export const apiDeleteBranch = {
  url: '/branch',
  method: 'DELETE',
};

export const apiGetBranchSelect = {
  url: '/branch/select',
  method: 'GET',
};

export const apiGetBranchLogin = {
  url: '/branch/list',
  method: 'GET',
};

export const apiGetBranchGates = {
  url: '/gate',
  method: 'GET',
};

export const apiAddBranch = {
  url: '/branch',
  method: 'POST',
};

export const apiAddVehicle = {
  url: '/vehicle',
  method: 'POST',
};

export const apiGetVehicles = {
  url: '/vehicle',
  method: 'GET',
};

export const apiUpdateVehicle = {
  url: '/vehicle',
  method: 'PUT',
};

export const apiDeleteVehicle = {
  url: '/vehicle',
  method: 'DELETE',
};

export const apiGetVehicleType = {
  url: '/vehicle',
  method: 'GET',
};

export const apiGetVehiclesLike = {
  url: '/vehicle/like',
  method: 'GET',
};

export const apiAddHauler = {
  url: '/hauler',
  method: 'POST',
};

export const apiGetHaulers = {
  url: '/hauler',
  method: 'GET',
};

export const apiUpdateHauler = {
  url: '/hauler',
  method: 'PUT',
};

export const apiDeleteHauler = {
  url: '/hauler',
  method: 'DELETE',
};

export const apiGetHaulerSelect = {
  url: '/hauler/select',
  method: 'GET',
};

export const apiAddOutsource = {
  url: '/outsource',
  method: 'POST',
};

export const apiGetOutsources = {
  url: '/outsource',
  method: 'GET',
};

export const apiUpdateOutsource = {
  url: '/outsource',
  method: 'PUT',
};

export const apiDeleteOutsource = {
  url: '/outsource',
  method: 'DELETE',
};

export const apiGetOutsourceSelect = {
  url: '/outsource/select',
  method: 'GET',
};

export const apiAddGate = {
  url: '/gate',
  method: 'POST',
};

export const apiGetGates = {
  url: '/gate',
  method: 'GET',
};

export const apiAddInbound = {
  url: '/inbound',
  method: 'POST',
};

export const apiGetInbound = {
  url: '/inbound',
  method: 'GET',
};

export const apiValidateInboundPackage = {
  url: '/inbound/validatePackage',
  method: 'POST',
};

export const apiUpdateInbound = {
  url: '/inbound',
  method: 'PUT',
};

export const apiAddInboundPackages = {
  url: '/inbound/package',
  method: 'POST',
};

export const apiFinalizeInbound = {
  url: '/inbound/finalize',
  method: 'PUT',
};

export const apiExportInbound = {
  url: '/inbound/export',
  method: 'GET',
};

export const apiCancelInbound = {
  url: '/inbound',
  method: 'DELETE',
};

export const apiPrintInbound = {
  url: '/inbound/print',
  method: 'GET',
  responseType: 'blob',
};

export const apiUploadInboundFiles = id => ({
  url: `inbound/${id}/upload`,
  method: 'POST',
});

export const apiDownloadInboundFile = id => ({
  url: `inbound/file/${id}`,
  method: 'GET',
  responseType: 'blob',
});

export const apiDeleteInboundFile = id => ({
  url: `inbound/file/${id}`,
  method: 'DELETE',
});


export const apiUpdateGate = {
  url: '/gate',
  method: 'PUT',
};

export const apiDeleteGate = {
  url: '/gate',
  method: 'DELETE',
};
export const apiGetOutbounds = {
  url: '/outbound',
  method: 'GET',
};

export const apiAddOutbound = {
  url: '/outbound',
  method: 'POST',
};

export const apiGetOutbound = {
  url: '/outbound',
  method: 'GET',
};

export const apiUpdateOutbound = {
  url: '/outbound',
  method: 'PUT',
};

export const apiValidateOutboundPackage = {
  url: '/outbound/validatePackage',
  method: 'POST',
};

export const apiGetOutboundPackageDetails = {
  url: '/outbound/package',
  method: 'GET',
};

export const apiAddOutboundPackages = {
  url: '/outbound/package',
  method: 'POST',
};

export const apiFinalizeOutbound = {
  url: '/outbound/finalize',
  method: 'PUT',
};

export const apiExportOutbound = {
  url: '/outbound/export',
  method: 'GET',
};

export const apiCancelOutbound = {
  url: '/outbound',
  method: 'DELETE',
};

export const apiPrintOutbound = {
  url: '/outbound/print',
  method: 'GET',
  responseType: 'blob',
};

export const apiUploadOutboundFiles = id => ({
  url: `outbound/${id}/upload`,
  method: 'POST',
});

export const apiDownloadOutboundFile = id => ({
  url: `outbound/file/${id}`,
  method: 'GET',
  responseType: 'blob',
});

export const apiDeleteOutboundFile = id => ({
  url: `outbound/file/${id}`,
  method: 'DELETE',
});

export const apiValidateInboundReprocessPackage = {
  url: '/reprocess/inbound/validatePackage',
  method: 'POST',
};

export const apiGetInboundReprocess = {
  url: '/reprocess/inbound',
  method: 'GET',
};

export const apiGetInboundReprocessDetails = {
  url: '/reprocess/inbound',
  method: 'GET',
};

export const apiSaveInboundReprocessPackages = {
  url: '/reprocess/inbound',
  method: 'POST',
};

export const apiSaveInboundReprocessPackagesById = {
  url: '/reprocess/inbound',
  method: 'POST',
};

export const apieFinalizeInboundReprocess = {
  url: '/reprocess/inbound',
  method: 'PUT',
};

export const apiExportInboundReprocess = {
  url: '/reprocess/inbound/export',
  method: 'GET',
};

export const apiCancelInboundReprocess = {
  url: '/reprocess/inbound',
  method: 'DELETE',
};

export const apiPrintInboundReprocess = {
  url: '/reprocess/inbound/print',
  method: 'GET',
  responseType: 'blob',
};

export const apiValidateOutboundReprocessPackage = {
  url: '/reprocess/outbound/validatePackage',
  method: 'POST',
};

export const apiGetOutboundReprocess = {
  url: '/reprocess/outbound',
  method: 'GET',
};

export const apiGetOutboundReprocessDetails = {
  url: '/reprocess/outbound',
  method: 'GET',
};

export const apiSaveOutboundReprocessPackages = {
  url: '/reprocess/outbound',
  method: 'POST',
};

export const apiSaveOutboundReprocessPackagesById = {
  url: '/reprocess/outbound',
  method: 'POST',
};

export const apiUploadOutboundReprocessFiles = id => ({
  url: `reprocess/outbound/${id}/upload`,
  method: 'POST',
});

export const apiDownloadOutboundReprocessFile = id => ({
  url: `reprocess/outbound/file/${id}`,
  method: 'GET',
  responseType: 'blob',
});

export const apiDeleteOutboundReprocessFile = id => ({
  url: `reprocess/outbound/file/${id}`,
  method: 'DELETE',
});

export const apiFinalizeOutboundReprocess = {
  url: '/reprocess/outbound',
  method: 'PUT',
};

export const apiGetPackageDetails = {
  url: '/reprocess/outbound/package',
  method: 'GET',
};

export const apiExportOutboundReprocess = {
  url: '/reprocess/outbound/export',
  method: 'GET',
};

export const apiCancelOutboundReprocess = {
  url: '/reprocess/outbound',
  method: 'DELETE',
};

export const apiPrintOutboundReprocess = {
  url: '/reprocess/outbound/print',
  method: 'GET',
  responseType: 'blob',
};

export const apiGetWaybill = {
  url: '/package',
  method: 'GET',
};

export const apiGetBulkWaybill = {
  url: '/package',
  method: 'POST',
};

export const apiExportWaybill = {
  url: '/package/export',
  method: 'POST',
};

export const apiGetPackageWaybill = {
  url: '/package/package',
  method: 'GET',
};
export const apiGetRemarksOption = {
  url: '/options/remarks',
  method: 'GET',
};

export const apiGetTruckTypesOption = {
  url: '/options/truck_types',
  method: 'GET',
};

export const apiGetMovementTypes = {
  url: '/options/movement_types',
  method: 'GET',
};

export const apiGetOrigins = {
  url: '/options/origin',
  method: 'GET',
};

export const apiGetShippingTypes = {
  url: '/options/shipping_type',
  method: 'GET',
};

export const apiGetInboundAreas = {
  url: '/options/inbound_area',
  method: 'GET',
};

export const apiGetInventorySummary = {
  url: '/inventory',
  method: 'GET',
};

export const apiExportInventorySummary = {
  url: '/inventory/export',
  method: 'GET',
};

export const apiGetAgeingSummary = {
  url: '/ageing',
  method: 'GET',
};

export const apiExportAgeingSummary = {
  url: '/ageing/export',
  method: 'GET',
};

export const apiAddMerchant = {
  url: '/merchant',
  method: 'POST',
};

export const apiGetMerchants = {
  url: '/merchant',
  method: 'GET',
};

export const apiUpdateMerchant = {
  url: '/merchant',
  method: 'PUT',
};

export const apiDeleteMerchant = {
  url: '/merchant',
  method: 'DELETE',
};

export const apiGetMerchantsLike = {
  url: '/merchant/like',
  method: 'GET',
};

export const apiCreateIncidentREport = {
  url: '/incident/report',
  method: 'POST',
};

export const apiFindPackage = {
  url: '/incident/report/package',
  method: 'GET',
};

export const apiGetIncident = {
  url: '/incident/report',
  method: 'GET',
};

export const apiUpdateIncident = {
  url: '/incident/report',
  method: 'PUT',
};

export const apiGetClients = {
  url: '/client',
  method: 'GET',
};

export const apiAddClient = {
  url: '/client',
  method: 'POST',
};

export const apiUpdateClient = {
  url: '/client',
  method: 'PUT',
};

export const apiDeleteClient = {
  url: '/client',
  method: 'DELETE',
};

export const apiGetClientSelect = {
  url: '/client/select',
  method: 'GET',
};

export const apiGetSections = {
  url: '/section',
  method: 'GET',
};

export const apiAddSection = {
  url: '/section',
  method: 'POST',
};

export const apiUpdateSection = {
  url: '/section',
  method: 'PUT',
};

export const apiDeleteSection = {
  url: '/section',
  method: 'DELETE',
};

export const apiGetSectionSelect = {
  url: '/section/select',
  method: 'GET',
};

export const apiValidatePackageUpload = {
  url: '/package/upload/validate',
  method: 'POST',
};

export const apiUploadPackage = {
  url: '/package/upload',
  method: 'POST',
};

export const apiValidateMotorcycleUpload = {
  url: '/motorcycle/upload/validate',
  method: 'POST',
};

export const apiUploadMotorcycle = {
  url: '/motorcycle/upload',
  method: 'POST',
};

export const apiGetMotorcycles = {
  url: '/motorcycle',
  method: 'GET',
};

export const apiGetRequestedPackage = {
  url: '/package/requested',
  method: 'GET',
};

export const apiExportRequestedPackage = {
  url: '/package/requested/export',
  method: 'GET',
};
