import {
  apiGetHaulers, apiAddHauler, apiDeleteHauler, apiUpdateHauler, apiGetHaulerSelect,
} from '../../services/apiEndpoints';

export const ADD_HAULER = 'hauler/ADD_HAULER';
export const addHauler = hauler => ({
  type: ADD_HAULER,
  payload: {
    client: 'secure',
    request: {
      ...apiAddHauler,
      data: hauler,
    },
  },
});

export const FETCH_HAULER = 'hauler/FETCH_HAULER';
export const getHauler = data => ({
  type: FETCH_HAULER,
  payload: {
    client: 'secure',
    request: {
      ...apiGetHaulers,
      params: {
        ...data,
        filters: JSON.stringify(data.filters),
        sorts: JSON.stringify(data.sorts),
      },
    },
  },
});

const GET_HAULER_SELECT = 'hauler/GET_HAULER_SELECT';
export const getHaulerSelect = () => ({
  type: GET_HAULER_SELECT,
  payload: {
    client: 'secure',
    request: {
      ...apiGetHaulerSelect,
    },
  },
});

export const UPDATE_HAULER = 'hauler/UPDATE_HAULER';
export const updateHauler = (params, haulerId) => ({
  type: UPDATE_HAULER,
  payload: {
    client: 'secure',
    request: {
      ...apiUpdateHauler,
      url: `/hauler/${haulerId}`,
      params,
    },
  },
});

export const DELETE_HAULER = 'hauler/DELETE_HAULER';
export const deleteHauler = haulerId => ({
  type: DELETE_HAULER,
  payload: {
    client: 'secure',
    request: {
      ...apiDeleteHauler,
      url: `/hauler/${haulerId}`,
    },
  },
});

const initialState = {};

function reducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}

export default reducer;
