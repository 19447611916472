import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Form, Col, Input, Button, Icon, Popconfirm, message,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus, faSave, faEdit, faTrash, faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import Row from '../../components/Row';
import PageHeader from '../../components/PageHeader';
import Card from '../../components/Card';
import Container from '../../components/Container';
import ReactTable from '../../components/ServerSideTable';
import Spacer from '../../components/Spacer';
import {
  addClient, getClients, updateClient, deleteClient,
} from './ducks';


/**
 * TODO: onFetchData catch, display error
 */
class Client extends Component {
  constructor(props) {
    super(props);

    this.state = {
      clientList: {
        rows: [],
        pages: 0,
        total: 0,
      },
      currentTable: {
        page: null,
        pageSize: null,
        filters: [],
        sorts: [],
      },
      editedClient: null,
      isFetching: false,
      fetchMessage: null,
    };

    this.formSubmit = this.formSubmit.bind(this);
    this.onFetchData = this.onFetchData.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this.setToEdit = this.setToEdit.bind(this);
    this.cancelEdit = this.cancelEdit.bind(this);
    this.onUpdateSubmit = this.onUpdateSubmit.bind(this);
  }

  onFetchData(state) {
    const { doGetClients } = this.props;

    const params = {
      page: state.page + 1,
      pageSize: state.pageSize,
      filters: state.filtered,
      sorts: state.sorted,
    };

    this.setState({
      currentTable: params,
      isFetching: true,
      editedClient: null,
      fetchMessage: 'Fetching client list...',
    });
    doGetClients(params)
      .then((action) => {
        this.setState({
          clientList: {
            rows: action.payload.data.data,
            pages: action.payload.data.last_page,
            total: action.payload.data.total,
          },
          isFetching: false,
          fetchMessage: null,
        });
      })
      .catch(() => {
        this.setState({
          isFetching: false,
          fetchMessage: null,
        });
      });
  }

  onUpdateSubmit(e) {
    e.preventDefault();

    const { form, doUpdateClient } = this.props;
    const { validateFields } = form;
    const { editedClient } = this.state;

    validateFields(['name_update'], (err, values) => {
      const data = {
        name: values.name_update,
      };

      if (!err) {
        doUpdateClient(data, editedClient)
          .then(() => {
            const { currentTable } = this.state;

            const params = {
              page: currentTable.page - 1,
              pageSize: currentTable.pageSize,
              filtered: currentTable.filters,
              sorted: currentTable.sorts,
            };

            this.setState({
              editedClient: null,
            });

            message.success('Successfully updated client!', 3);
            this.onFetchData(params);
          })
          .catch((action) => {
            let errorMessage;
            if (action.error.response.status === 422) {
              const index = Object.values(action.error.response.data);
              // eslint-disable-next-line prefer-destructuring
              errorMessage = index[0][0];
            } else {
              errorMessage = 'Something went wrong, please try again later.';
            }
            message.error(errorMessage, 3);
          });
      }
    });
  }

  onEdit(cellInfo) {
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const { clientList, editedClient } = this.state;

    if (editedClient === cellInfo.original.id) {
      return (
        <Form.Item style={{ margin: '0px' }}>
          {getFieldDecorator(`${cellInfo.column.id}_update`, {
            rules: [{ required: false, message: `${cellInfo.column.Header} is required.` }],
            initialValue: clientList.rows[cellInfo.index][cellInfo.column.id],
          })(<Input autoComplete="off" />)}
        </Form.Item>
      );
    }

    return <div>{clientList.rows[cellInfo.index][cellInfo.column.id]}</div>;
  }

  setToEdit(clientId) {
    this.setState({
      editedClient: clientId,
    });
  }

  cancelEdit() {
    this.setState({
      editedClient: null,
    });
  }

  formSubmit(e) {
    e.preventDefault();
    const { form, doAddClient } = this.props;
    const { validateFields } = form;
    validateFields(['name'], (err, values) => {
      if (!err) {
        doAddClient(values).then(() => {
          const { currentTable } = this.state;

          const params = {
            page: currentTable.page - 1,
            pageSize: currentTable.pageSize,
            filtered: currentTable.filters,
            sorted: currentTable.sorts,
          };

          this.setState({
            editedClient: null,
          });
          message.success('Successfully created client!');
          this.onFetchData(params);
          form.resetFields();
        }).catch((action) => {
          let errorMessage;
          if (action.error.response.status === 422) {
            const index = Object.values(action.error.response.data);
            // eslint-disable-next-line prefer-destructuring
            errorMessage = index[0][0];
          } else {
            errorMessage = 'Something went wrong, please try again later.';
          }
          message.error(errorMessage, 3);
        });
      }
    });
  }

  confirmDelete(clientId) {
    const { doDeleteClient } = this.props;

    doDeleteClient(clientId)
      .then((action) => {
        if (action.payload.data) {
          const { currentTable } = this.state;
          const params = {
            page: currentTable.page - 1,
            pageSize: currentTable.pageSize,
            filtered: currentTable.filters,
            sorted: currentTable.sorts,
          };

          this.setState({
            editedClient: null,
          });

          message.success('Successfully deleted client.', 3);

          this.onFetchData(params);
        } else {
          message.error('Unable to delete data that has 1 or more reference(s).', 3);
        }
      })
      .catch(() => {
        message.error('Something went wrong, please try again later.', 3);
      });
  }

  render() {
    const { form } = this.props;
    const {
      editedClient, clientList, isFetching, fetchMessage,
    } = this.state;
    const { getFieldDecorator } = form;

    const nameLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
    };

    return (
      <div className="client">
        <PageHeader title="CLIENT" />
        <Container>
          <Card title="Client Information">
            <Form layout="horizontal" onSubmit={this.formSubmit}>
              <Row>
                <Col xs={24} sm={12} lg={6}>
                  <Form.Item label="Name" {...nameLayout}>
                    {getFieldDecorator('name', {
                      rules: [{ required: true, message: 'Name cannot be blank.' }],
                    })(<Input placeholder="E.g. XDE" autoComplete="off" />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={6}>
                  <Form.Item>
                    <Button type="primary" htmlType="submit" block>
                      <Icon viewBox="0 0 1024 1024">
                        <FontAwesomeIcon icon={faPlus} fixedWidth />
                      </Icon>
                    Create
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
          <Spacer />
          <Card title="Client List">
            <Form onSubmit={this.onUpdateSubmit}>
              <ReactTable
                data={clientList.rows}
                pages={clientList.pages}
                loadingText={fetchMessage}
                loading={isFetching}
                onFetchData={this.onFetchData}
                columns={[
                  {
                    Header: 'Id',
                    accessor: 'id',
                  },
                  {
                    Header: 'Name',
                    accessor: 'name',
                    Cell: this.onEdit,
                  },
                  {
                    id: 'created_by',
                    Header: 'Created by',
                    accessor: data => data.user.full_name,
                  },
                  {
                    Header: 'Options',
                    filterable: false,
                    sortable: false,
                    Cell: data => (
                      <Row>
                        {
                       (editedClient !== data.row.id)
                         ? (
                           <Row>
                             <Col span={12}>
                               <Button type="link" onClick={() => this.setToEdit(data.row.id)} block>
                                 <Icon viewBox="0 0 1024 1024">
                                   <FontAwesomeIcon icon={faEdit} fixedWidth />
                                 </Icon>
                            Edit
                               </Button>
                             </Col>
                             <Col span={12}>
                               <Popconfirm
                                 placement="leftBottom"
                                 title={`Are you sure you want to delete ${data.row.name} from the list?`}
                                 okText="Yes"
                                 onConfirm={() => this.confirmDelete(data.row.id)}
                                 cancelText="No"
                               >
                                 <Button type="link" block>
                                   <Icon viewBox="0 0 1024 1024">
                                     <FontAwesomeIcon icon={faTrash} fixedWidth />
                                   </Icon>
                            Delete
                                 </Button>
                               </Popconfirm>
                             </Col>
                           </Row>
                         )
                         : (
                           <div>
                             <Col span={12}>
                               <Button type="link" htmlType="submit" block>
                                 <Icon viewBox="0 0 1024 1024">
                                   <FontAwesomeIcon icon={faSave} fixedWidth />
                                 </Icon>
                                 Save
                               </Button>
                             </Col>

                             <Col span={12}>
                               <Button type="link" onClick={this.cancelEdit} block>
                                 <Icon viewBox="0 0 1024 1024">
                                   <FontAwesomeIcon icon={faTimes} fixedWidth />
                                 </Icon>
                                 Cancel
                               </Button>
                             </Col>
                           </div>
                         )
                        }
                      </Row>
                    ),
                  },
                ]}
              />
            </Form>
          </Card>
        </Container>
      </div>
    );
  }
}

Client.propTypes = {
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
  branch: PropTypes.oneOfType([PropTypes.object]).isRequired,
  doAddClient: PropTypes.func.isRequired,
  doGetClients: PropTypes.func.isRequired,
  doUpdateClient: PropTypes.func.isRequired,
  doDeleteClient: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  doAddClient: addClient,
  doGetClients: getClients,
  doUpdateClient: updateClient,
  doDeleteClient: deleteClient,
};

const mapStateToProps = state => ({
  branch: state.auth.branch,
});

const WrappedClient = Form.create({ name: 'Client' })(Client);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WrappedClient);
