import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Form, Row, Col, Input, Card, Icon, Button, message,
} from 'antd';
import { login, getBranchLogin } from './ducks';
import Select from '../../components/Select';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      branchList: [],
      isLoggingIn: false,
      error: false,
    };

    this.handleOnSubmit = this.handleOnSubmit.bind(this);
  }

  componentDidMount() {
    const { doGetBranchLogin, form } = this.props;
    doGetBranchLogin().then((action) => {
      this.setState({
        branchList: action.payload.data,
      }, (form.setFieldsValue({ branch: action.payload.data[0].value })));
    });
  }

  handleOnSubmit(e) {
    e.preventDefault();
    const { doLogin, form } = this.props;
    const { branchList } = this.state;
    const { validateFields } = form;

    validateFields((err, values) => {
      const branchDetails = branchList.filter(item => item.value === values.branch);

      const params = {
        ...values,
        branch: branchDetails[0],
      };

      if (!err) {
        this.setState({
          isLoggingIn: true,
        });
        doLogin(params).catch((action) => {
          if (action.error.response.status === 422) {
            message.error('This user is not registered on this branch', 4);
          } else if (action.error.response.status === 401) {
            // eslint-disable-next-line prefer-destructuring
            message.error(action.error.response.data.message, 4);
          }
          this.setState({
            isLoggingIn: false,
          });
        });
      }
    });
  }

  render() {
    const { form } = this.props;
    const { branchList, error, isLoggingIn } = this.state;
    const { getFieldDecorator } = form;

    let errorMessage = false;

    if (error) {
      errorMessage = <span style={{ color: 'red', textAlign: 'center' }}>{error}</span>;
    }

    return (
      <Row>
        <Col span={6} offset={9}>
          <Card style={{ marginTop: '15vh' }}>
            <div style={{ textAlign: 'center', marginBottom: '25px' }}>
              <img src="/img/logo-colored.png" alt="Logo" width="120" />
            </div>
            {errorMessage || null}
            <Form onSubmit={this.handleOnSubmit}>
              <Form.Item style={{ marginBottom: '15px' }}>
                {getFieldDecorator('username', {
                  rules: [{ required: true, message: 'Username cannot be blank.' }],
                })(<Input
                  autoComplete="off"
                  placeholder="Username"
                  prefix={<Icon type="user" />}
                />)}
              </Form.Item>
              <Form.Item style={{ marginBottom: '15px' }}>
                {getFieldDecorator('password', {
                  rules: [{ required: true, message: 'Password cannot be blank.' }],
                })(<Input.Password
                  autoComplete="off"
                  placeholder="Password"
                  prefix={<Icon type="lock" />}
                />)}
              </Form.Item>
              <Form.Item style={{ marginBottom: '15px' }}>
                {getFieldDecorator('branch', {
                  rules: [{ required: true, message: 'Branch cannot be blank.' }],
                })(<Select placeholder="Select branch..." options={branchList} />)}

              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  disabled={isLoggingIn}
                >
                  Login
                </Button>
              </Form.Item>
            </Form>
            <center>
              Need and account?
              <NavLink to="/register"> Sign Up</NavLink>
            </center>
          </Card>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
  branchList: state.auth.branchList,
});

const mapDispatchToProps = {
  doLogin: login,
  doGetBranchLogin: getBranchLogin,
};


Login.propTypes = {
  doLogin: PropTypes.func,
  doGetBranchLogin: PropTypes.func.isRequired,
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

Login.defaultProps = {
  doLogin: null,
};

const WrappedLogin = Form.create({ name: 'Login' })(Login);

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(WrappedLogin),
);
