import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Col, Form, Input, Button, Icon, message, DatePicker, Spin,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlus, faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import Row from '../../components/Row';
import PageHeader from '../../components/PageHeader';
import Card from '../../components/Card';
import Container from '../../components/Container';
import { getIncident, findPackage, update } from './ducks';

let id = 0;
class IncidentReportEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      irDetails: null,
      isFetching: false,
      isSubmitting: false,
      isLoading: true,
      deleted: [],
    };

    this.addInputField = this.addInputField.bind(this);
    this.removeInputField = this.removeInputField.bind(this);
    this.submitReport = this.submitReport.bind(this);
    this.packageValidate = this.packageValidate.bind(this);
    this.packageDuplicate = this.packageDuplicate.bind(this);
  }

  componentDidMount() {
    const { match, doGetIncident, history } = this.props;
    doGetIncident(match.params.id).then((action) => {
      this.setState({
        isLoading: false,
        irDetails: action.payload.data,
      });
    }).catch(() => {
      history.push('/404');
    });
  }

  addInputField() {
    const { form } = this.props;

    // can use data-binding to get
    const keys = form.getFieldValue('keys');
    const nextKeys = keys.concat(id += 1);
    // can use data-binding to set
    // important! notify form to detect changes
    form.setFieldsValue({
      keys: nextKeys,
    });
  }

  removeInputField(item, type, trackingNumber, description) {
    const { form } = this.props;
    const { irDetails } = this.state;
    // can use data-binding to get
    const keys = form.getFieldValue('keys');

    // We need at least one passenger
    // can use data-binding to set

    if (type === 'new') {
      form.setFieldsValue({
        keys: keys.filter(key => key !== item),
      });
    } else if (type === 'saved') {
      const newFields = irDetails.incident_report_package.filter((key, index) => index !== item);

      this.setState(prevState => ({
        irDetails: {
          ...prevState.irDetails,
          incident_report_package: [
            ...newFields,
          ],
        },
        deleted: [
          ...prevState.deleted,
          {
            tracking_number: trackingNumber,
            description,
            is_deleted: true,
          },
        ],
      }));
    }
  }

  submitReport(e) {
    e.preventDefault();

    // eslint-disable-next-line no-unused-vars
    const { form, doUpdate, match } = this.props;
    const { deleted } = this.state;

    const { validateFields } = form;

    this.setState({
      isSubmitting: true,
    });

    validateFields((err, values) => {
      if (!err) {
        const packageData = [];
        const duplicates = values.tracking_number.filter(
          (item, index) => values.tracking_number.indexOf(item) !== index,
        );

        if (duplicates.length === 0) {
          values.tracking_number.forEach((TN, index) => {
            packageData.push({
              tracking_number: TN,
              description: values.package_description[index],
            });
          });

          const params = {
            packages: [
              ...packageData,
              ...deleted,
            ],
            description: values.incident_description,
            incident_report_date: moment(values.incident_date).format('YYYY-MM-DD HH:mm:ss'),
          };

          doUpdate(params, match.params.id).then((action) => {
            message.success(action.payload.data);
            this.setState({
              deleted: [],
            });
            this.setState({
              isSubmitting: false,
            });
          }).catch((action) => {
            message.error(action.error.response.data.message);
            this.setState({
              isSubmitting: false,
            });
          });
        } else {
          message.error('You have entered tracking numbers of the same number!');
          this.setState({
            isSubmitting: false,
          });
        }
      } else {
        this.setState({
          isSubmitting: false,
        });
      }
    });
  }

  // eslint-disable-next-line no-unused-vars
  packageValidate(rule, value, callback) {
    const { doFindPackage, branch } = this.props;

    this.setState({
      isFetching: true,
    });

    doFindPackage(value, branch.id)
      .then(res => (res.payload.data.exists
        ? callback() : callback('Package does not exist.')));
  }

  packageDuplicate(rule, value, callback) {
    const { form } = this.props;

    const keys = form.getFieldValue('tracking_number');

    this.setState({
      isFetching: true,
    });

    const duplicates = keys.filter(
      (item, index) => keys.indexOf(item) !== index && typeof item !== 'undefined',
    );

    if (duplicates.length > 0) {
      callback('You have already added this tracking number.');
    } else {
      callback();
    }
  }

  render() {
    const {
      irDetails, isFetching, isSubmitting, isLoading,
    } = this.state;
    const { form } = this.props;
    const { getFieldDecorator, getFieldValue } = form;
    const packageFields = [];

    getFieldDecorator('keys', { initialValue: [] });
    const keys = getFieldValue('keys');

    const formItemLayout = {
      labelCol: {
        lg: { span: 6 },
      },
      wrapperCol: {
        lg: { span: 18 },
      },
    };

    if (irDetails) {
      irDetails.incident_report_package.forEach((item, index) => {
        packageFields.push(
          <Row key={item.id}>
            <Col xs={24} sm={24} lg={12}>
              <Form.Item
                label="Tracking Number"
                hasFeedback={isFetching}
                key={item.id}
                {...formItemLayout}
              >
                {getFieldDecorator(`tracking_number[${index}]`, {
                  rules: [
                    {
                      required: true,
                      message: 'Tracking number is required.',
                    },
                    {
                      validator: this.packageValidate,
                    },
                    {
                      validator: this.packageDuplicate,
                    },
                  ],
                  initialValue: item.tracking_number,
                })(<Input autoComplete="off" placeholder="E.g XYZ" style={{ width: '80%' }} />)}

              </Form.Item>
            </Col>
            <Col xs={24} sm={24} lg={12}>
              <Form.Item
                label="Description"
                key={item.tracking_number}
                {...formItemLayout}
              >
                {getFieldDecorator(`package_description[${index}]`, {
                  rules: [
                    {
                      required: false,
                      message: 'Package description is required.',
                    },
                  ],
                  initialValue: item.description,
                })(<Input autoComplete="off" placeholder="E.g Missing" style={{ width: '80%' }} />)}
                {irDetails.incident_report_package.length !== 1 ? (

                  <Icon
                    viewBox="0 0 1024 1024"
                    onClick={() => this.removeInputField(index, 'saved', item.tracking_number, item.description)}
                    style={{ marginLeft: '10px' }}
                  >
                    <FontAwesomeIcon icon={faMinusCircle} fixedWidth />
                  </Icon>

                ) : null}
              </Form.Item>
            </Col>
          </Row>,
        );
      });
    }

    // eslint-disable-next-line no-unused-vars
    const formItems = keys.map((item, index) => (
      <Row key={item + irDetails.incident_report_package.length}>
        <Col xs={24} sm={24} lg={12}>
          <Form.Item
            label="Tracking Number"
            key={item + irDetails.incident_report_package.length}
            hasFeedback={isFetching}
            {...formItemLayout}
          >
            {getFieldDecorator(`tracking_number[${item + irDetails.incident_report_package.length}]`, {
              rules: [
                {
                  required: true,
                  message: 'Tracking number is required.',
                },
                {
                  validator: this.packageValidate,
                },
                {
                  validator: this.packageDuplicate,
                },
              ],
            })(<Input autoComplete="off" placeholder="E.g XYZ" style={{ width: '80%' }} />)}
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} lg={12}>
          <Form.Item label="Description" key={item + irDetails.incident_report_package.length} {...formItemLayout}>
            {getFieldDecorator(`package_description[${item + irDetails.incident_report_package.length}]`, {
              rules: [
                {
                  required: false,
                  message: 'Package description is required.',
                },
              ],
            })(<Input autoComplete="off" placeholder="E.g Missing" style={{ width: '80%' }} />)}

            {keys.length > 0 ? (

              <Icon
                viewBox="0 0 1024 1024"
                onClick={() => this.removeInputField(item, 'new', null, null)}
                style={{ marginLeft: '10px' }}
              >
                <FontAwesomeIcon icon={faMinusCircle} fixedWidth />
              </Icon>

            ) : null}

          </Form.Item>
        </Col>

      </Row>
    ));

    return (

      <div className="IncidentReportEdit">
        <Spin spinning={isLoading} tip="Fetching report details. . .">

          <PageHeader title="Incident Report" />
          <Container>
            <Card title="Create Report">
              <Form onSubmit={this.submitReport}>
                <Row>
                  <Form.Item label="Incident Description">
                    {getFieldDecorator('incident_description', {
                      rules: [
                        {
                          required: true,
                          message: 'Tracking number is required.',
                        },
                      ],
                      initialValue: irDetails ? irDetails.description : '',

                    })(<Input.TextArea
                      autoComplete="off"
                      placeholder="E.g XYZ"
                    />)}
                  </Form.Item>
                  <Form.Item label="Incident Date">
                    {getFieldDecorator('incident_date', {
                      rules: [{ required: true, message: 'Incident date cannot be blank.' }],
                      initialValue: irDetails ? moment(irDetails.incident_report_date, 'YYYY-MM-DD') : moment(new Date()),
                    })(<DatePicker
                      format="YYYY-MM-DD"
                      disabledDate={date => new Date() <= date}
                      disabledTime
                      style={{ width: '100%' }}
                    />)}
                  </Form.Item>
                </Row>
                {packageFields}
                {formItems}
                <Row>
                  <center>
                    <Form.Item>
                      <Button type="dashed" onClick={this.addInputField} style={{ width: '60%' }}>
                        <Icon viewBox="0 0 1024 1024">
                          <FontAwesomeIcon icon={faPlus} fixedWidth />
                        </Icon>
                    Add Tracking Number
                      </Button>
                    </Form.Item>
                  </center>
                </Row>
                <Row>
                  <Button type="primary" htmlType="submit" disabled={isSubmitting} block>
                    <Icon viewBox="0 0 1024 1024">
                      <FontAwesomeIcon icon={faEdit} fixedWidth />
                    </Icon>
                        Update
                  </Button>
                </Row>
              </Form>
            </Card>
          </Container>
        </Spin>

      </div>
    );
  }
}

const mapDispatchToProps = {
  doGetIncident: getIncident,
  doFindPackage: findPackage,
  doUpdate: update,
};

IncidentReportEdit.propTypes = {
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
  match: PropTypes.oneOfType([PropTypes.object]).isRequired,
  history: PropTypes.oneOfType([PropTypes.object]).isRequired,
  branch: PropTypes.oneOfType([PropTypes.object]).isRequired,
  doGetIncident: PropTypes.func.isRequired,
  doFindPackage: PropTypes.func.isRequired,
  doUpdate: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  branch: state.auth.branch,
});

const WrappedIncidentReportEdit = Form.create({ name: 'IncidentReportEdit' })(IncidentReportEdit);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WrappedIncidentReportEdit));
