import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Col, Form, Input, Button, message,
  Icon, Popconfirm,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus, faSave, faEdit, faTrash, faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import Row from '../../components/Row';
import PageHeader from '../../components/PageHeader';
import Card from '../../components/Card';
import Container from '../../components/Container';
import ReactTable from '../../components/ServerSideTable';
import Spacer from '../../components/Spacer';

import {
  addOutsource, getOutsources, updateOutsource, deleteOutsource,
} from './ducks';


class Outsource extends Component {
  constructor(props) {
    super(props);

    this.state = {
      outsourceList: {
        rows: [],
        pages: 0,
        total: 0,
      },
      isFetching: false,
      fetchMessage: null,
      editedOutsource: null,
      currentTable: {
        page: null,
        pageSize: null,
        filters: [],
        sorts: [],
      },

    };

    this.formSubmit = this.formSubmit.bind(this);
    this.onFetchData = this.onFetchData.bind(this);
    this.setToEdit = this.setToEdit.bind(this);
    this.cancelEdit = this.cancelEdit.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this.onUpdateSubmit = this.onUpdateSubmit.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
  }

  // eslint-disable-next-line no-unused-vars
  onFetchData(state, instance) {
    const { doGetOutsources, branch } = this.props;
    const params = {
      page: state.page + 1,
      pageSize: state.pageSize,
      filters: state.filtered,
      sorts: state.sorted,
      branchId: branch.id,
    };

    this.setState({
      currentTable: params,
      isFetching: true,
      editedOutsource: null,
      fetchMessage: 'Fetching outsource list . . .',
    });

    doGetOutsources(params).then((action) => {
      this.setState({
        outsourceList: {
          rows: action.payload.data.data,
          pages: action.payload.data.last_page,
          total: action.payload.data.total,
        },
        isFetching: false,
        fetchMessage: null,
      });
    // eslint-disable-next-line no-unused-vars
    }).catch((action) => {
      this.setState({
        isFetching: false,
        fetchMessage: null,
      });
    });
  }

  onUpdateSubmit(e) {
    e.preventDefault();
    const { form, doUpdateOutsource } = this.props;
    const { validateFieldsAndScroll } = form;
    const { editedOutsource } = this.state;

    validateFieldsAndScroll(['name_update', 'contact_update'], (err, values) => {
      const data = {
        name: values.name_update,
        contact: values.contact_update,
      };
      if (!err) {
        doUpdateOutsource(data, editedOutsource)
          .then(() => {
            const { currentTable } = this.state;

            const params = {
              page: currentTable.page - 1,
              pageSize: currentTable.pageSize,
              filtered: currentTable.filters,
              sorted: currentTable.sorts,
            };

            this.setState({
              editedOutsource: null,
            });

            message.success('Successfully updated outsource group!', 3);

            this.onFetchData(params);
          })
          .catch((action) => {
            let errorMessage;
            if (action.error.response.status === 422) {
              const index = Object.values(action.error.response.data);
              // eslint-disable-next-line prefer-destructuring
              errorMessage = index[0][0];
            } else {
              errorMessage = 'Something went wrong, please try again later.';
            }
            message.error(errorMessage, 3);
          });
      }
    });
  }

  onEdit(cellInfo) {
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const { outsourceList, editedOutsource } = this.state;
    if (editedOutsource === cellInfo.original.id) {
      return (
        <Form.Item style={{ margin: '0px' }}>
          {getFieldDecorator(`${cellInfo.column.id}_update`, {
            rules: [{ required: true, message: `${cellInfo.column.Header} is required.` }],
            initialValue: outsourceList.rows[cellInfo.index][cellInfo.column.id],
          })(<Input autoComplete="off" />)}
        </Form.Item>
      );
    }

    return (
      <div>
        {outsourceList.rows[cellInfo.index][cellInfo.column.id]}
      </div>
    );
  }

  setToEdit(outsourceId) {
    this.setState({
      editedOutsource: outsourceId,
    });
  }

  formSubmit(e) {
    e.preventDefault();
    const { form, doAddOutsource, branch } = this.props;
    const { validateFieldsAndScroll } = form;
    validateFieldsAndScroll(['name', 'contact'], (err, values) => {
      if (!err) {
        const data = {
          name: values.name,
          contact: values.contact,
          branch_id: branch.id,
        };

        doAddOutsource(data).then(() => {
          const { currentTable } = this.state;

          const params = {
            page: currentTable.page - 1,
            pageSize: currentTable.pageSize,
            filtered: currentTable.filters,
            sorted: currentTable.sorts,
          };

          this.setState({
            editedOutsource: null,
          });

          message.success('Successfully created outsource group!', 3);
          this.onFetchData(params);

          form.resetFields();
        })
          // eslint-disable-next-line no-unused-vars
          .catch((action) => {
            let errorMessage;
            if (action.error.response.status === 422) {
              const index = Object.values(action.error.response.data);
              // eslint-disable-next-line prefer-destructuring
              errorMessage = index[0][0];
            } else {
              errorMessage = 'Something went wrong, please try again later.';
            }
            message.error(errorMessage, 3);
          });
      }
    });
  }

  cancelEdit() {
    this.setState({
      editedOutsource: null,
    });
  }

  confirmDelete(outsourceId) {
    const { doDeleteOutsource } = this.props;

    doDeleteOutsource(outsourceId).then((action) => {
      if (action.payload.data) {
        const { currentTable } = this.state;
        const params = {
          page: currentTable.page - 1,
          pageSize: currentTable.pageSize,
          filtered: currentTable.filters,
          sorted: currentTable.sorts,
        };
        this.setState({
          editedOutsource: null,
        });
        message.success('Successfully deleted outsource group!', 3);
        this.onFetchData(params);
      } else {
        message.error('Unable to delete data that has 1 or more reference(s).', 3);
      }
    }).catch(() => {
      message.error('Something went wrong, please try agian later.', 3);
    });
  }

  render() {
    const { form } = this.props;

    const { getFieldDecorator } = form;

    const {
      outsourceList, fetchMessage,
      isFetching, editedOutsource,
    } = this.state;

    const nameLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
    };

    const contactLayout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
    };

    const buttonLayout = {
      wrapperCol: { span: 14 },
    };
    return (
      <div className="Outsource">
        <PageHeader title="OUTSOURCE" />
        <Container>
          <Card title="Outsource Information">
            <Form layout="horizontal">
              <Row>
                <Col xs={24} sm={12} lg={6}>
                  <Form.Item label="Name" {...nameLayout}>
                    {getFieldDecorator('name', {
                      rules: [{ required: true, message: 'Name cannot be blank.' }],
                    })(<Input placeholder="E.g. XDE" autoComplete="off" />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={6}>
                  <Form.Item label="Contact" {...contactLayout}>
                    {getFieldDecorator('contact', {
                      rules: [
                        { required: true, message: 'Contact cannot be blank' },
                        { pattern: '^[0-9]*$', message: 'Contact must contain numbers only' },
                      ],
                    })(<Input placeholder="E.g. 123" autoComplete="off" />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={6}>
                  <Form.Item {...buttonLayout}>
                    <Button type="primary" onClick={this.formSubmit} block>
                      <Icon viewBox="0 0 1024 1024">
                        <FontAwesomeIcon icon={faPlus} fixedWidth />
                      </Icon>
                    Create
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
          <Spacer />
          <Card title="Outsource List">
            <Form onSubmit={this.onUpdateSubmit}>
              <ReactTable
                data={outsourceList.rows}
                pages={outsourceList.pages}
                columns={[
                  {
                    Header: 'Id',
                    accessor: 'id',
                  },
                  {
                    Header: 'Name',
                    accessor: 'name',
                    Cell: this.onEdit,
                  },
                  {
                    Header: 'Contact',
                    accessor: 'contact',
                    Cell: this.onEdit,
                  },
                  {
                    id: 'created_by',
                    Header: 'Created by',
                    accessor: data => data.user.full_name,
                  },
                  {
                    Header: 'Options',
                    filterable: false,
                    sortable: false,
                    Cell: data => (
                      <Row>
                        {
                       (editedOutsource !== data.row.id)
                         ? (
                           <Row>
                             <Col span={12}>
                               <Button type="link" onClick={() => this.setToEdit(data.row.id)} block>
                                 <Icon viewBox="0 0 1024 1024">
                                   <FontAwesomeIcon icon={faEdit} fixedWidth />
                                 </Icon>
                            Edit
                               </Button>
                             </Col>
                             <Col span={12}>
                               <Popconfirm
                                 placement="leftBottom"
                                 title={`Are you sure you want to delete ${data.row.name} from the list?`}
                                 okText="Yes"
                                 onConfirm={() => this.confirmDelete(data.row.id)}
                                 cancelText="No"
                               >
                                 <Button type="link" block>
                                   <Icon viewBox="0 0 1024 1024">
                                     <FontAwesomeIcon icon={faTrash} fixedWidth />
                                   </Icon>
                            Delete
                                 </Button>
                               </Popconfirm>
                             </Col>
                           </Row>
                         )
                         : (
                           <div>
                             <Col span={12}>
                               <Button type="link" htmlType="submit" block>
                                 <Icon viewBox="0 0 1024 1024">
                                   <FontAwesomeIcon icon={faSave} fixedWidth />
                                 </Icon>
                                 Save
                               </Button>
                             </Col>

                             <Col span={12}>
                               <Button type="link" onClick={this.cancelEdit} block>
                                 <Icon viewBox="0 0 1024 1024">
                                   <FontAwesomeIcon icon={faTimes} fixedWidth />
                                 </Icon>
                                 Cancel
                               </Button>
                             </Col>
                           </div>
                         )
                        }
                      </Row>
                    ),
                  },
                ]}
                loadingText={fetchMessage}
                loading={isFetching}
                onFetchData={this.onFetchData}
              />
            </Form>
          </Card>
        </Container>
      </div>
    );
  }
}

Outsource.propTypes = {
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
  branch: PropTypes.oneOfType([PropTypes.object]).isRequired,
  doAddOutsource: PropTypes.func.isRequired,
  doGetOutsources: PropTypes.func.isRequired,
  doUpdateOutsource: PropTypes.func.isRequired,
  doDeleteOutsource: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  branch: state.auth.branch,
});

const mapDispatchToProps = {
  doAddOutsource: addOutsource,
  doGetOutsources: getOutsources,
  doUpdateOutsource: updateOutsource,
  doDeleteOutsource: deleteOutsource,
};
const WrappedOutsource = Form.create({ name: 'Outsource' })(Outsource);

export default connect(mapStateToProps, mapDispatchToProps)(WrappedOutsource);
