import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Form, Col, Input, Button, Icon, Popconfirm, message,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus, faSave, faEdit, faTrash, faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import Row from '../../components/Row';
import PageHeader from '../../components/PageHeader';
import Card from '../../components/Card';
import Container from '../../components/Container';
import ReactTable from '../../components/ServerSideTable';
import Spacer from '../../components/Spacer';
import Select from '../../components/Select';
import {
  addHauler, getHauler, deleteHauler, updateHauler,
} from './ducks';

class Hauler extends Component {
  constructor(props) {
    super(props);
    this.state = {
      haulerList: {
        rows: [],
        pages: 0,
        total: 0,
      },
      isFetching: false,
      fetchMessage: null,
      editedHauler: null,
      currentTable: {
        page: null,
        pageSize: null,
        filters: [],
        sorts: [],
      },
    };
    this.formSubmit = this.formSubmit.bind(this);
    this.onUpdateSubmit = this.onUpdateSubmit.bind(this);
    this.onFetchData = this.onFetchData.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this.setToEdit = this.setToEdit.bind(this);
    this.cancelEdit = this.cancelEdit.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
  }


  // eslint-disable-next-line no-unused-vars
  onFetchData(state, instance) {
    const { doGetHauler, branch } = this.props;

    const params = {
      page: state.page + 1,
      pageSize: state.pageSize,
      filters: state.filtered,
      sorts: state.sorted,
      branchId: branch.id,
    };

    this.setState({
      currentTable: params,
      isFetching: true,
      editedHauler: null,
      fetchMessage: 'Fetching hauler list . . .',
    });

    doGetHauler(params).then((action) => {
      this.setState({
        haulerList: {
          rows: action.payload.data.data,
          pages: action.payload.data.last_page,
          total: action.payload.data.total,
        },
        isFetching: false,
        fetchMessage: null,
      });
    // eslint-disable-next-line no-unused-vars
    }).catch((action) => {
      this.setState({
        isFetching: false,
        fetchMessage: null,
      });
    });
  }

  onEdit(cellInfo) {
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const { haulerList, editedHauler } = this.state;

    const statusOptions = [
      {
        label: 'Active',
        value: 1,
      },
      {
        label: 'Inactive',
        value: 0,
      },
    ];
    if (editedHauler === cellInfo.original.id) {
      if (cellInfo.column.id === 'name') {
        return (
          <Form.Item style={{ margin: '0px' }}>
            {getFieldDecorator(`${cellInfo.column.id}_update`, {
              rules: [{ required: true, message: `${cellInfo.column.Header} is required.` }],
              initialValue: haulerList.rows[cellInfo.index][cellInfo.column.id],
            })(<Input autoComplete="off" />)}
          </Form.Item>
        );
      }

      if (cellInfo.column.id === 'is_active') {
        return (
          <Form.Item style={{ margin: '0px' }}>
            {getFieldDecorator(`${cellInfo.column.id}_update`, {
              rules: [{ required: true, message: `${cellInfo.column.Header} is required.` }],
              initialValue: haulerList.rows[cellInfo.index][cellInfo.column.id],
            })(<Select options={statusOptions} autoComplete="off" />)}
          </Form.Item>
        );
      }
    }
    let cell = (
      <div>
        {haulerList.rows[cellInfo.index][cellInfo.column.id] }
      </div>
    );
    if (cellInfo.column.id === 'is_active') {
      cell = haulerList.rows[cellInfo.index][cellInfo.column.id] ? ('Active') : ('Inactive');
    }

    return cell;
  }

  onUpdateSubmit(e) {
    e.preventDefault();
    const { form, doUpdateHauler } = this.props;
    const { validateFields } = form;
    const { editedHauler } = this.state;

    validateFields(['name_update', 'is_active_update'], (err, values) => {
      const data = {
        name: values.name_update,
        is_active: values.is_active_update,
      };
      if (!err) {
        doUpdateHauler(data, editedHauler).then(() => {
          const { currentTable } = this.state;

          const params = {
            page: currentTable.page - 1,
            pageSize: currentTable.pageSize,
            filtered: currentTable.filters,
            sorted: currentTable.sorts,
          };

          this.setState({
            editedHauler: null,
          });

          message.success('Successfully updated hauler!', 3);

          this.onFetchData(params);
        }).catch((action) => {
          let errorMessage;
          if (action.error.response.status === 422) {
            const index = Object.values(action.error.response.data);
            // eslint-disable-next-line prefer-destructuring
            errorMessage = index[0][0];
          } else {
            errorMessage = 'Something went wrong, please try again later.';
          }
          message.error(errorMessage, 3);
        });
      }
    });
  }

  setToEdit(haulerId) {
    this.setState({
      editedHauler: haulerId,
    });
  }

  formSubmit(e) {
    e.preventDefault();
    const { form, doAddHauler, branch } = this.props;
    const { validateFields } = form;
    validateFields(['name'], (err, values) => {
      if (!err) {
        const data = {
          name: values.name,
          branch_id: branch.id,
        };
        doAddHauler(data).then(() => {
          const { currentTable } = this.state;

          const params = {
            page: currentTable.page - 1,
            pageSize: currentTable.pageSize,
            filtered: currentTable.filters,
            sorted: currentTable.sorts,
          };

          this.setState({
            editedHauler: null,
          });

          message.success('Successfully created hauler!', 3);
          this.onFetchData(params);

          form.resetFields();
        // eslint-disable-next-line no-unused-vars
        }).catch((action) => {
          let errorMessage;
          if (action.error.response.status === 422) {
            const index = Object.values(action.error.response.data);
            // eslint-disable-next-line prefer-destructuring
            errorMessage = index[0][0];
          } else {
            errorMessage = 'Something went wrong, please try again later.';
          }
          message.error(errorMessage, 3);
        });
      }
    });
  }

  cancelEdit() {
    this.setState({
      editedHauler: null,
    });
  }

  confirmDelete(haulerId) {
    const { doDeleteHauler } = this.props;
    doDeleteHauler(haulerId).then((action) => {
      if (action.payload.data) {
        const { currentTable } = this.state;
        const params = {
          page: currentTable.page - 1,
          pageSize: currentTable.pageSize,
          filtered: currentTable.filters,
          sorted: currentTable.sorts,
        };
        this.setState({
          editedHauler: null,
        });
        message.success('Successfully deleted hauler!', 3);
        this.onFetchData(params);
      } else {
        message.error('Unable to delete data that has 1 or more reference(s).', 3);
      }
    }).catch(() => {
      message.error('Something went wrong, please try agian later.', 3);
    });
  }

  render() {
    const { form } = this.props;

    const { getFieldDecorator } = form;

    const {
      fetchMessage, isFetching, haulerList,
      editedHauler,
    } = this.state;

    const nameLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
    };
    const buttonLayout = {
      wrapperCol: { span: 14 },
    };
    return (
      <div className="hauler">
        <PageHeader title="Hauler" />
        <Container>
          <Card title="Hauler Information">
            <Form layout="horizontal" onSubmit={this.onSubmit}>
              <Row>
                <Col xs={24} sm={12} lg={6}>
                  <Form.Item label="Name" {...nameLayout}>
                    {getFieldDecorator('name', {
                      rules: [{ required: true, message: 'Name cannot be blank.' }],
                    })(<Input placeholder="E.g. XDE" autoComplete="off" />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={6}>
                  <Form.Item {...buttonLayout}>
                    <Button type="primary" onClick={this.formSubmit} block>
                      <Icon viewBox="0 0 1024 1024">
                        <FontAwesomeIcon icon={faPlus} fixedWidth />
                      </Icon>
                    Create
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
          <Spacer />
          <Card title="Hauler List">
            <Form onSubmit={this.onUpdateSubmit}>
              <ReactTable
                data={haulerList.rows}
                pages={haulerList.pages}
                columns={[
                  {
                    Header: 'Id',
                    accessor: 'id',
                  },
                  {
                    id: 'name',
                    Header: 'Name',
                    accessor: 'name',
                    Cell: this.onEdit,
                  },
                  {
                    id: 'created_by',
                    Header: 'Created by',
                    accessor: data => data.user.full_name,
                  },
                  {
                    id: 'is_active',
                    Header: 'Is Active',
                    Cell: this.onEdit,
                  },
                  {
                    Header: 'Options',
                    sortable: false,
                    filterable: false,
                    Cell: data => (
                      <Row>
                        {
                     (editedHauler !== data.row.id)
                       ? (
                         <Row>
                           <Col span={12}>
                             <Button type="link" onClick={() => this.setToEdit(data.row.id)} block>
                               <Icon viewBox="0 0 1024 1024">
                                 <FontAwesomeIcon icon={faEdit} fixedWidth />
                               </Icon>
                                Edit
                             </Button>
                           </Col>
                           <Col span={12}>
                             <Popconfirm
                               placement="leftBottom"
                               title={`Are you sure you want to delete ${data.row.name} from the list?`}
                               okText="Yes"
                               onConfirm={() => this.confirmDelete(data.row.id)}
                               cancelText="No"
                             >
                               <Button type="link" block>
                                 <Icon viewBox="0 0 1024 1024">
                                   <FontAwesomeIcon icon={faTrash} fixedWidth />
                                 </Icon>
                                Delete
                               </Button>
                             </Popconfirm>
                           </Col>
                         </Row>
                       )
                       : (
                         <div>
                           <Col span={12}>
                             <Button type="link" htmlType="submit" block>
                               <Icon viewBox="0 0 1024 1024">
                                 <FontAwesomeIcon icon={faSave} fixedWidth />
                               </Icon>
                               Save
                             </Button>
                           </Col>

                           <Col span={12}>
                             <Button type="link" onClick={this.cancelEdit} block>
                               <Icon viewBox="0 0 1024 1024">
                                 <FontAwesomeIcon icon={faTimes} fixedWidth />
                               </Icon>
                               Cancel
                             </Button>
                           </Col>
                         </div>
                       )
                      }
                      </Row>
                    ),
                  },
                ]}

                loadingText={fetchMessage}
                loading={isFetching}
                onFetchData={this.onFetchData}
              />
            </Form>
          </Card>

        </Container>
      </div>
    );
  }
}

Hauler.propTypes = {
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
  branch: PropTypes.oneOfType([PropTypes.object]).isRequired,
  doAddHauler: PropTypes.func.isRequired,
  doGetHauler: PropTypes.func.isRequired,
  doDeleteHauler: PropTypes.func.isRequired,
  doUpdateHauler: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  doAddHauler: addHauler,
  doGetHauler: getHauler,
  doDeleteHauler: deleteHauler,
  doUpdateHauler: updateHauler,
};

const mapStateToProps = state => ({
  branch: state.auth.branch,
});

const WrappedHauler = Form.create({ name: 'Hauler' })(Hauler);
export default connect(mapStateToProps, mapDispatchToProps)(WrappedHauler);
