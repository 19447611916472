import {
  apiAddOutsource, apiGetOutsources, apiGetOutsourceSelect,
  apiUpdateOutsource, apiDeleteOutsource,
} from '../../services/apiEndpoints';

export const ADD_OUTSOURCE = 'outsource/ADD_OUTSOURCE';

export const addOutsource = outsource => ({
  type: ADD_OUTSOURCE,
  payload: {
    client: 'secure',
    request: {
      ...apiAddOutsource,
      data: outsource,
    },
  },
});

export const GET_OUTSOURCES = 'outsource/GET_OUTSOURCES';
export const getOutsources = data => ({
  type: GET_OUTSOURCES,
  payload: {
    client: 'secure',
    request: {
      ...apiGetOutsources,
      params: {
        ...data,
        filters: JSON.stringify(data.filters),
        sorts: JSON.stringify(data.sorts),
      },
    },
  },
});

export const UPDATE_OUTSOURCE = 'outsource/UPDATE_OUTSOURCE';
export const updateOutsource = (params, outsourceId) => ({
  type: UPDATE_OUTSOURCE,
  payload: {
    client: 'secure',
    request: {
      ...apiUpdateOutsource,
      url: `/outsource/${outsourceId}`,
      params,
    },
  },
});

export const DELETE_OUTSOURCE = 'outsource/DELETE_OUTSOURCE';
export const deleteOutsource = outsourceId => ({
  type: DELETE_OUTSOURCE,
  payload: {
    client: 'secure',
    request: {
      ...apiDeleteOutsource,
      url: `/outsource/${outsourceId}`,
    },
  },
});

export const GET_SELECTED_OUTSOURCES = 'outsource/GET_SELECTED_OUTSOURCES';
export const getOutsourceSelect = () => ({
  type: GET_SELECTED_OUTSOURCES,
  payload: {
    client: 'secure',
    request: {
      ...apiGetOutsourceSelect,
    },
  },
});

const initialState = {};

function reducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}
export default reducer;
