import roles from '../services/roles';

import Inventory from '../pages/dashboard/Inventory';
import Ageing from '../pages/dashboard/Ageing';
import Waybill from '../pages/waybill/Waybill';
import WaybillView from '../pages/waybill/WaybillView';
import InboundMonitoring from '../pages/dashboard/InboundMonitoring';
import Outsource from '../pages/outsource/Outsource';
import Gate from '../pages/gate/Gate';
import Branch from '../pages/branch/Branch';
import Section from '../pages/section/Section';
import UserList from '../pages/user/UserList';
import UserCreate from '../pages/user/UserCreate';
import UserEdit from '../pages/user/UserEdit';
import UserApproval from '../pages/user/UserApproval';
import InboundList from '../pages/inbound/InboundList';
import InboundCreate from '../pages/inbound/InboundCreate';
import InboundView from '../pages/inbound/InboundView';
import InboundPackage from '../pages/inbound/InboundPackage';
import InboundResult from '../pages/inbound/InboundResult';
import OutboundList from '../pages/outbound/OutboundList';
import OutboundCreate from '../pages/outbound/OutboundCreate';
import OutboundView from '../pages/outbound/OutboundView';
import OutboundPackage from '../pages/outbound/OutboundPackage';
import OutboundResult from '../pages/outbound/OutboundResult';
import InboundReprocessList from '../pages/inboundReprocess/InboundReprocessList';
import InboundReprocessCreate from '../pages/inboundReprocess/InboundReprocessCreate';
import InboundReprocessView from '../pages/inboundReprocess/InboundReprocessView';
import InboundReprocessEdit from '../pages/inboundReprocess/InboundReprocessEdit';
import InboundReprocessResult from '../pages/inboundReprocess/InboundReprocessResult';
import OutboundReprocessList from '../pages/outboundReprocess/OutboundReprocessList';
import OutboundReprocessCreate from '../pages/outboundReprocess/OutboundReprocessCreate';
import OutboundReprocessView from '../pages/outboundReprocess/OutboundReprocessView';
import OutboundReprocessEdit from '../pages/outboundReprocess/OutboundReprocessEdit';
import OutboundReprocessResult from '../pages/outboundReprocess/OutboundReprocessResult';
import Hauler from '../pages/hauler/Hauler';
import Vehicle from '../pages/vehicle/Vehicle';
import Merchant from '../pages/merchant/Merchant';
import IncidentReportList from '../pages/incidentReport/IncidentReportList';
import IncidentReportCreate from '../pages/incidentReport/IncidentReportCreate';
import IncidentReportView from '../pages/incidentReport/IncidentReportView';
import IncidentReportEdit from '../pages/incidentReport/IncidentReportEdit';
import PackageUpload from '../pages/waybill/Upload';
import MotorcycleList from '../pages/motorcycle/MotorcycleList';
import MotorcycleUpload from '../pages/motorcycle/Upload';
import Client from '../pages/client/Client';

export default [
  {
    roles: roles.all,
    key: '/',
    exact: true,
    path: '/',
    component: Inventory,
  },
  {
    roles: roles.all,
    key: '/ageing',
    exact: true,
    path: '/ageing',
    component: Ageing,
  },
  {
    roles: roles.all,
    key: '/monitoring',
    exact: true,
    path: '/monitoring',
    component: InboundMonitoring,
  },
  {
    roles: roles.all,
    key: '/inbound',
    exact: true,
    path: '/inbound',
    component: InboundList,
  },
  {
    roles: roles.encoder,
    key: '/inbound/create',
    exact: true,
    path: '/inbound/create',
    component: InboundCreate,
    props: 'test',
  },
  {
    roles: roles.encoder,
    key: '/inbound/:id',
    exact: true,
    path: '/inbound/:id',
    component: InboundView,
  },
  {
    roles: roles.encoder,
    key: '/inbound/:id/package',
    exact: true,
    path: '/inbound/:id/package',
    component: InboundPackage,
  },
  {
    roles: roles.encoder,
    key: '/inbound/:id/result',
    exact: true,
    path: '/inbound/:id/result',
    component: InboundResult,
  },
  {
    roles: roles.encoder,
    key: '/inboundReprocess',
    exact: true,
    path: '/reprocess/inbound',
    component: InboundReprocessList,
  },
  {
    roles: roles.encoder,
    key: '/inboundReprocess/create',
    exact: true,
    path: '/reprocess/inbound/create',
    component: InboundReprocessCreate,
  },
  {
    roles: roles.encoder,
    key: '/inboundReprocess/result',
    exact: true,
    path: '/reprocess/inbound/result',
    component: InboundReprocessResult,
  },
  {
    roles: roles.encoder,
    key: '/inboundReprocess/:id',
    exact: true,
    path: '/reprocess/inbound/:id',
    component: InboundReprocessView,
  },
  {
    roles: roles.encoder,
    key: '/inboundReprocess/:id/edit',
    exact: true,
    path: '/reprocess/inbound/:id/edit',
    component: InboundReprocessEdit,
  },
  {
    roles: roles.encoder,
    key: '/outbound',
    exact: true,
    path: '/outbound',
    component: OutboundList,
  },
  {
    roles: roles.encoder,
    key: '/outbound/create',
    exact: true,
    path: '/outbound/create',
    component: OutboundCreate,
  },
  {
    roles: roles.encoder,
    key: '/outbound/:id',
    exact: true,
    path: '/outbound/:id',
    component: OutboundView,
  },
  {
    roles: roles.encoder,
    key: '/outbound/:id/package',
    exact: true,
    path: '/outbound/:id/package',
    component: OutboundPackage,
  },
  {
    roles: roles.encoder,
    key: '/outbound/:id/result',
    exact: true,
    path: '/outbound/:id/result',
    component: OutboundResult,
  },
  {
    roles: roles.encoder,
    key: '/reprocess/outbound',
    exact: true,
    path: '/reprocess/outbound',
    component: OutboundReprocessList,
  },
  {
    roles: roles.encoder,
    key: '/reprocess/outbound/create',
    exact: true,
    path: '/reprocess/outbound/create',
    component: OutboundReprocessCreate,
  },
  {
    roles: roles.encoder,
    key: '/reprocess/outbound/:id/edit',
    exact: true,
    path: '/reprocess/outbound/:id/edit',
    component: OutboundReprocessEdit,
  },
  {
    roles: roles.encoder,
    key: '/reprocess/outbound/result',
    exact: true,
    path: '/reprocess/outbound/result',
    component: OutboundReprocessResult,
  },
  {
    roles: roles.encoder,
    key: '/reprocess/outbound/:id',
    exact: true,
    path: '/reprocess/outbound/:id',
    component: OutboundReprocessView,
  },
  {
    roles: roles.admin,
    key: '/user',
    exact: true,
    path: '/user',
    component: UserList,
  },
  {
    roles: roles.admin,
    key: '/user/create',
    exact: true,
    path: '/user/create',
    component: UserCreate,
  },
  {
    roles: roles.admin,
    key: '/user/:id/edit',
    exact: true,
    path: '/user/:id/edit',
    component: UserEdit,
  },
  {
    roles: roles.admin,
    key: '/user/approval',
    exact: true,
    path: '/user/approval',
    component: UserApproval,
  },
  {
    roles: roles.admin,
    key: '/client',
    exact: true,
    path: '/client',
    component: Client,
  },
  {
    roles: roles.admin,
    key: '/outsource',
    exact: true,
    path: '/outsource',
    component: Outsource,
  },
  {
    roles: roles.admin,
    key: '/gate',
    exact: true,
    path: '/gate',
    component: Gate,
  },
  {
    roles: roles.superAdmin,
    key: '/branch',
    exact: true,
    path: '/branch',
    component: Branch,
  },
  {
    roles: roles.admin,
    key: '/hauler',
    exact: true,
    path: '/hauler',
    component: Hauler,
  },
  {
    roles: roles.admin,
    key: '/vehicle',
    exact: true,
    path: '/vehicle',
    component: Vehicle,
  },
  {
    roles: roles.admin,
    key: '/vehicle',
    exact: true,
    path: '/vehicle',
    component: Vehicle,
  },
  {
    roles: roles.admin,
    key: '/section',
    exact: true,
    path: '/section',
    component: Section,
  },
  {
    roles: roles.all,
    key: '/waybill',
    exact: true,
    path: '/waybill',
    component: Waybill,
  },
  {
    roles: roles.all,
    key: '/waybill/:tracking_number',
    exact: true,
    path: '/waybill/:tracking_number',
    component: WaybillView,
  },
  {
    roles: roles.admin,
    key: '/merchant',
    exact: true,
    path: '/merchant',
    component: Merchant,
  },
  {
    roles: roles.encoder,
    key: '/incidentReport',
    exact: true,
    path: '/incidentReport',
    component: IncidentReportList,
  },
  {
    roles: roles.encoder,
    key: '/incidentReport/create',
    exact: true,
    path: '/incidentReport/create',
    component: IncidentReportCreate,
  },
  {
    roles: roles.encoder,
    key: '/incidentReport/view/:id',
    exact: true,
    path: '/incidentReport/view/:id',
    component: IncidentReportView,
  },
  {
    roles: roles.encoder,
    key: '/incidentReport/view/:id',
    exact: true,
    path: '/incidentReport/view/:id/edit',
    component: IncidentReportEdit,
  },
  {
    roles: roles.encoder,
    key: '/package/upload',
    exact: true,
    path: '/package/upload',
    component: PackageUpload,
  },
  {
    roles: roles.encoder,
    key: '/motorcycle',
    exact: true,
    path: '/motorcycle',
    component: MotorcycleList,
  },
  {
    roles: roles.encoder,
    key: '/motorcycle/upload',
    exact: true,
    path: '/motorcycle/upload',
    component: MotorcycleUpload,
  },
];
