import moment from 'moment';
import {
  apiAddInbound,
  apiGetInbound,
  apiUpdateInbound,
  apiValidateInboundPackage,
  apiAddInboundPackages,
  apiFinalizeInbound,
  apiExportInbound,
  apiGetRemarksOption,
  apiGetOrigins,
  apiCancelInbound,
  apiPrintInbound,
  apiGetShippingTypes,
  apiGetInboundAreas,
  apiUploadInboundFiles,
  apiDownloadInboundFile,
  apiDeleteInboundFile,
} from '../../services/apiEndpoints';

export const GET_REMARKS_OPTION = 'inbound/GET_REMARKS_OPTION';
export const getRemarks = () => ({
  type: GET_REMARKS_OPTION,
  payload: {
    client: 'secure',
    request: {
      ...apiGetRemarksOption,
    },
  },
});

export const FETCH_INBOUND_LIST = 'inbound/FETCH_INBOUND_LIST';
export const FETCH_INBOUND_LIST_SUCCESS = 'inbound/FETCH_INBOUND_LIST_SUCCESS';
export const getInboundList = params => ({
  type: FETCH_INBOUND_LIST,
  payload: {
    client: 'secure',
    request: {
      ...apiGetInbound,
      params: {
        ...params,
        filters: JSON.stringify(params.filters),
        sorts: JSON.stringify(params.sorts),
      },
    },
  },
});

export const ADD_INBOUND = 'inbound/ADD_INBOUND';
export const ADD_INBOUND_SUCCESS = 'inbound/ADD_INBOUND_SUCCESS';
export const addInbound = (inbound, branchId) => ({
  type: ADD_INBOUND,
  payload: {
    client: 'secure',
    request: {
      ...apiAddInbound,
      data: {
        ...inbound,
        location: branchId,
      },
    },
  },
});

export const GET_INBOUND = 'inbound/GET_INBOUND';
export const GET_INBOUND_SUCCESS = 'inbound/GET_INBOUND_SUCCESS';
export const getInbound = (path, branch, deletedOnly) => ({
  type: GET_INBOUND,
  payload: {
    client: 'secure',
    request: {
      ...apiGetInbound,
      url: path,
      params: {
        branch_id: branch.id,
        deleted_only: deletedOnly,
      },
    },
  },
});

export const UPDATE_INBOUND_DETAILS = 'inbound/UPDATE_INBOUND_DETAILS';
export const updateInboundDetails = (path, details) => ({
  type: UPDATE_INBOUND_DETAILS,
  payload: {
    client: 'secure',
    request: {
      ...apiUpdateInbound,
      url: path,
      data: details,
    },
  },
});

export const VALIDATE_PACKAGE = 'inbound/VALIDATE_PACKAGE';
export const validatePackage = data => ({
  type: VALIDATE_PACKAGE,
  payload: {
    client: 'secure',
    request: {
      ...apiValidateInboundPackage,
      data,
    },
  },
});

export const SAVE_PACKAGE_TO_STATE = 'inbound/SAVE_PACKAGE_TO_STATE';
export const savePackageToState = (id, scannedPackages) => ({
  type: SAVE_PACKAGE_TO_STATE,
  payload: {
    data: {
      inboundId: id,
      scannedPackages,
    },
  },
});

export const UPDATE_PACKAGE_FROM_STATE = 'inbound/UPDATE_PACKAGE_FROM_STATE';
export const updatePackageFromState = (id, scannedPackages) => ({
  type: UPDATE_PACKAGE_FROM_STATE,
  payload: {
    data: {
      inboundId: id,
      scannedPackages,
    },
  },
});

export const DELETE_PACKAGE_FROM_STATE = 'inbound/DELETE_PACKAGE_FROM_STATE';
export const deletePackageFromState = (id, scannedPackages) => ({
  type: DELETE_PACKAGE_FROM_STATE,
  payload: {
    data: {
      inboundId: id,
      scannedPackages,
    },
  },
});

export const ADD_INBOUND_PACKAGES = 'inbound/ADD_INBOUND_PACKAGES';
export const ADD_INBOUND_PACKAGES_SUCCESS = 'inbound/ADD_INBOUND_PACKAGES_SUCCESS';
export const addInboundPackages = (
  id,
  createdPackages,
  updatedPackages,
  deletedPackages,
  branch,
  origin,
) => ({
  type: ADD_INBOUND_PACKAGES,
  payload: {
    client: 'secure',
    request: {
      ...apiAddInboundPackages,
      url: `inbound/${id}/package`,
      data: {
        update: updatedPackages,
        create: createdPackages,
        delete: deletedPackages,
        branch_id: branch.id,
        origin,
      },
    },
  },
});

export const FINALIZE_INBOUND = '/inbound/FINALIZE_INBOUND';
export const finalizeInbound = inboundId => ({
  type: FINALIZE_INBOUND,
  payload: {
    client: 'secure',
    request: {
      ...apiFinalizeInbound,
      url: `/inbound/${inboundId}/finalize`,
    },
  },
});


export const EXPORT_INBOUND = '/inbound/EXPORT_INBOUND';
export const exportInbound = inboundId => ({
  type: EXPORT_INBOUND,
  payload: {
    client: 'secure',
    request: {
      ...apiExportInbound,
      responseType: 'arraybuffer',
      url: `/inbound/export/${inboundId}`,
    },
  },
});

export const GET_ORIGINS = '/inbound/GET_ORIGINS';
export const getOrigins = () => ({
  type: GET_ORIGINS,
  payload: {
    client: 'secure',
    request: {
      ...apiGetOrigins,
    },
  },
});

export const GET_SHIPPING_TYPES = '/inbound/GET_SHIPPING_TYPES';
export const getShippingTypes = () => ({
  type: GET_SHIPPING_TYPES,
  payload: {
    client: 'secure',
    request: {
      ...apiGetShippingTypes,
    },
  },
});

export const GET_INBOUND_AREA = '/inbound/GET_INBOUND_AREA';
export const getInboundAreas = () => ({
  type: GET_INBOUND_AREA,
  payload: {
    client: 'secure',
    request: {
      ...apiGetInboundAreas,
    },
  },
});

export const CANCEL_INBOUND = '/inbound/CANCEL_INBOUND';
export const CANCEL_INBOUND_SUCCESS = '/inbound/CANCEL_INBOUND_SUCCESS';
export const cancelInbound = id => ({
  type: CANCEL_INBOUND,
  payload: {
    client: 'secure',
    inboundId: id,
    request: {
      ...apiCancelInbound,
      url: `/inbound/${id}`,
    },
  },
});

const PRINT_INBOUND = '/inbound/PRINT_INBOUND';
export const printInbound = (id, printType) => ({
  type: PRINT_INBOUND,
  payload: {
    client: 'secure',
    request: {
      ...apiPrintInbound,
      url: `/inbound/${id}/print/${printType}`,
    },
  },
});

const UPLOAD_INBOUND_FILES = '/outbound/UPLOAD_INBOUND_FILES';
export const uploadInboundFiles = (id, files) => ({
  type: UPLOAD_INBOUND_FILES,
  payload: {
    client: 'secure',
    request: {
      ...apiUploadInboundFiles(id, files),
      data: files,
    },
  },
});

const DOWNLOAD_INBOUND_FILE = '/outbound/DOWNLOAD_INBOUND_FILE';
export const downloadInboundFile = id => ({
  type: DOWNLOAD_INBOUND_FILE,
  payload: {
    client: 'secure',
    request: {
      ...apiDownloadInboundFile(id),
    },
  },
});

const DELETE_INBOUND_FILE = '/outbound/DELETE_INBOUND_FILE';
export const deleteInboundFile = id => ({
  type: DELETE_INBOUND_FILE,
  payload: {
    client: 'secure',
    request: {
      ...apiDeleteInboundFile(id),
    },
  },
});

export const UPDATE_INBOUNDS_FROM_STATE = '/inbound/UPDATE_INBOUNDS_FROM_STATE';

const initialState = {};
function reducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_PACKAGE_TO_STATE:
      return {
        ...state,
        [action.payload.data.inboundId]: {
          scannedPackages: action.payload.data.scannedPackages,
          lastPackageScanDate: moment().format('YYYY-MM-DD HH:mm:ss'),
        },
      };
    case DELETE_PACKAGE_FROM_STATE:
      return {
        ...state,
        [action.payload.data.inboundId]: {
          scannedPackages: action.payload.data.scannedPackages,
        },
      };
    case UPDATE_PACKAGE_FROM_STATE:
      return {
        ...state,
        [action.payload.data.inboundId]: {
          scannedPackages: action.payload.data.scannedPackages,
        },
      };
    case ADD_INBOUND_PACKAGES_SUCCESS: {
      const modifiedState = state;
      delete modifiedState[action.payload.data.id];
      return {
        ...modifiedState,
      };
    }
    case CANCEL_INBOUND_SUCCESS: {
      const modifiedState = state;
      delete modifiedState[state.inboundId];
      delete modifiedState.inboundId;
      return {
        ...modifiedState,
      };
    }
    case CANCEL_INBOUND:
      return {
        ...state,
        inboundId: action.payload.inboundId,
      };
    case ADD_INBOUND_SUCCESS:
      return {
        ...state,
      };

    case UPDATE_INBOUNDS_FROM_STATE:
      return {
        ...action.payload.inbounds,
      };
    default:
      return state;
  }
}

export default reducer;
