import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Col, Button, Row, Icon, Form, Input, DatePicker,
  Checkbox,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSearch, faFileExport, faEye,
} from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import FileDownload from 'js-file-download';
import PageHeader from '../../components/PageHeader';
import Card from '../../components/Card';
import Container from '../../components/Container';
import ReactTable from '../../components/ServerSideTable';
import Select from '../../components/Select';
import Spacer from '../../components/Spacer';
import {
  getWaybill, exportWaybill, getMovementTypes, getBulkWaybill,
} from './ducks';
import { getOrigins } from '../inbound/ducks';

class Waybill extends Component {
  constructor(props) {
    super(props);
    this.state = {
      waybillList: {
        rows: [],
        pages: 0,
        total: 0,
      },
      currentTable: {
        page: null,
        pageSize: 10,
        filters: [],
        sorts: [],
      },
      isFetching: false,
      fetchMessage: null,
      movementTypes: [],
      movementTypeSelected: null,
      dateSelected: [],
      isExporting: false,
      originOptions: [],
    };
    this.onSortedChangeHandler = this.onSortedChangeHandler.bind(this);
    this.onFilteredChangeHandler = this.onFilteredChangeHandler.bind(this);
    this.onPageSizeChangeHandler = this.onPageSizeChangeHandler.bind(this);
    this.onPageChangeHandler = this.onPageChangeHandler.bind(this);
    this.fetchWaybill = this.fetchWaybill.bind(this);
    this.exportWaybill = this.exportWaybill.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.selectMovement = this.selectMovement.bind(this);
    this.selectDate = this.selectDate.bind(this);
  }

  componentDidMount() {
    const { doGetMovementTypes, doGetOrigins } = this.props;
    doGetMovementTypes().then((action) => {
      this.setState({
        movementTypes: action.payload.data,
      });
    });

    doGetOrigins().then((action) => {
      this.setState({ originOptions: action.payload.data });
    });
  }

  onSubmit(e) {
    e.preventDefault();
    const { form, branch, user } = this.props;
    const { validateFields } = form;
    const { currentTable, movementTypeSelected, dateSelected } = this.state;

    validateFields((err, values) => {
      const packageTypes = values.package_type.map(packageType => packageType.replace('-', ''));
      const params = {
        page: currentTable.page - 1,
        pageSize: currentTable.pageSize,
        filtered: currentTable.filters,
        sorted: currentTable.sorts,
        formFilter: {
          tracking_number: values.tracking_number ? values.tracking_number : [],
          item_count: values.item_count ? values.item_count : null,
          package_type: packageTypes.length ? packageTypes : [],
          dateRange: dateSelected.length ? dateSelected : [],
          movement_type: movementTypeSelected !== null ? movementTypeSelected : null,
          origin: values.origin && movementTypeSelected === 'inbound' ? values.origin : null,
          branch_id: branch.id,
          user_role: user.role,
        },
      };
      if (!err) {
        this.fetchWaybill(params);
        this.setState({
          isFetching: true,
          fetchMessage: 'Fetching Waybill list . . .',
        });
      }
    });
  }

  onFilteredChangeHandler(state) {
    const { currentTable } = this.state;
    const params = {
      ...currentTable,
      filters: state,
    };
    this.setState({
      currentTable: params,
    }, () => {
      this.setState({
        isFetching: true,
        fetchMessage: 'Fetching Waybill list . . .',
      });
      this.fetchWaybill(params);
    });
  }

  onPageChangeHandler(state) {
    const { currentTable } = this.state;
    const params = {
      ...currentTable,
      page: state + 1,
    };
    this.setState({
      currentTable: params,
    }, () => {
      this.setState({
        isFetching: true,
        fetchMessage: 'Fetching Waybill list . . .',
      });
      this.fetchWaybill(params);
    });
  }

  onPageSizeChangeHandler(state) {
    const { currentTable } = this.state;
    const params = {
      ...currentTable,
      pageSize: state,
    };
    this.setState({
      currentTable: params,
    }, () => {
      this.setState({
        isFetching: true,
        fetchMessage: 'Fetching Waybill list . . .',
      });
      this.fetchWaybill(params);
    });
  }

  onSortedChangeHandler(state) {
    const { currentTable } = this.state;
    const params = {
      ...currentTable,
      sorts: state,
    };
    this.setState({
      currentTable: params,
    }, () => {
      this.setState({
        isFetching: true,
        fetchMessage: 'Fetching Waybill list . . .',
      });
      this.fetchWaybill(params);
    });
  }

  // eslint-disable-next-line no-unused-vars
  fetchWaybill(state, instance) {
    const {
      form,
      doGetWaybill,
      doGetBulkWaybill,
      branch,
      user,
    } = this.props;
    const { dateSelected, movementTypeSelected } = this.state;
    const { validateFields } = form;
    validateFields((err, values) => {
      const packageTypes = values.package_type.map(packageType => packageType.replace('-', ''));
      const trackingNumbers = values.tracking_number.length
        ? values.tracking_number.split('\n').filter(tn => tn !== '')
        : [];
      const params = {
        page: state.page,
        pageSize: state.pageSize,
        filters: state.filters,
        sorts: state.sorts,
        formFilter: {
          tracking_number: trackingNumbers,
          item_count: values.item_count ? values.item_count : null,
          package_type: packageTypes.length ? packageTypes : [],
          dateRange: dateSelected.length ? dateSelected : [],
          movement_type: values.movement ? values.movement : null,
          origin: values.origin && movementTypeSelected === 'inbound' ? values.origin : null,
          branch_id: branch.id,
          user_role: user.role,
        },
      };

      if (trackingNumbers.length > 100) {
        doGetBulkWaybill(params).then((action) => {
          this.setState({
            waybillList: {
              rows: action.payload.data.data,
              pages: action.payload.data.last_page,
              total: action.payload.data.total,
            },
            isFetching: false,
            fetchMessage: null,
          });
        // eslint-disable-next-line no-unused-vars
        }).catch((action) => {
          this.setState({
            isFetching: false,
            fetchMessage: null,
          });
        });
      } else {
        doGetWaybill(params).then((action) => {
          this.setState({
            waybillList: {
              rows: action.payload.data.data,
              pages: action.payload.data.last_page,
              total: action.payload.data.total,
            },
            isFetching: false,
            fetchMessage: null,
          });
          // eslint-disable-next-line no-unused-vars
        }).catch((action) => {
          this.setState({
            isFetching: false,
            fetchMessage: null,
          });
        });
      }
    });
  }

  selectMovement(e) {
    if (e !== null && typeof e !== 'undefined') {
      this.setState({
        movementTypeSelected: e,
      });
    } else {
      this.setState({
        movementTypeSelected: null,
      });
    }
  }

  selectDate(e) {
    if (e.length) {
      this.setState({
        dateSelected: [moment(e[0]).format('YYYY-MM-DD HH:mm:ss'), moment(e[1]).format('YYYY-MM-DD HH:mm:ss')],
      });
    } else {
      this.setState({
        dateSelected: [],
      });
    }
  }


  exportWaybill(e) {
    e.preventDefault();
    const {
      form,
      doExportWaybill,
      branch,
      user,
    } = this.props;
    const { currentTable, movementTypeSelected } = this.state;
    const { validateFields } = form;

    validateFields((err, values) => {
      if (!err) {
        this.setState({ isExporting: true });
        const trackingNumbers = values.tracking_number.length
          ? values.tracking_number.split('\n').filter(tn => tn !== '')
          : [];
        const packageTypes = values.package_type.map(packageType => packageType.replace('-', ''));
        const params = {
          tracking_number: trackingNumbers,
          item_count: values.item_count,
          package_type: values.package_type.length ? packageTypes : [],
          dateRange: values.dateRange,
          movement_type: values.movement ? values.movement : null,
          filters: currentTable.filters,
          origin: values.origin && movementTypeSelected === 'inbound' ? values.origin : null,
          branch_id: branch.id,
          user_role: user.role,
        };
        doExportWaybill(params).then((response) => {
          this.setState({ isExporting: false });
          FileDownload(new Blob([response.payload.data]), `WAYBILL-${moment().format('YYYY-MM-DD HH:mm:ss')}.xlsx`);
        }).catch(() => {
          this.setState({ isExporting: false });
        });
      }
    });
  }

  render() {
    const { form } = this.props;
    const CheckboxGroup = Checkbox.Group;
    const { TextArea } = Input;
    const {
      fetchMessage, isFetching, waybillList,
      movementTypes, movementTypeSelected, dateSelected,
      isExporting, originOptions,
    } = this.state;
    const { getFieldDecorator } = form;
    const { RangePicker } = DatePicker;
    const formItemLayout = {
      labelCol: { span: 5 },
      wrapperCol: { span: 19 },
    };
    const packageTypes = ['BULKY', 'M-BULKY', 'POUCH'];
    return (
      <div className="waybill">
        <PageHeader title="Waybill" />
        <Container>
          <Card title="Filter List">
            <Form onSubmit={this.onSubmit} className="ant-advanced-search-form">
              <Row gutter={24}>
                <Col xs={24} sm={12} lg={12}>
                  <Form.Item label="Tracking Number" {...formItemLayout}>
                    {getFieldDecorator('tracking_number', {
                      rules: [{ required: false }],
                      initialValue: [],
                    })(<TextArea />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={12}>
                  <Form.Item label="Origin" {...formItemLayout}>
                    {getFieldDecorator('origin', {
                      rules: [{ required: false }],
                    })(<Select key="origin" placeholder="Select origin" options={originOptions} disabled={movementTypeSelected !== 'inbound'} allowClear />)}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col xs={24} sm={12} lg={12}>
                  <Form.Item label="Set Of" {...formItemLayout}>
                    {getFieldDecorator('item_count', {
                      rules: [{ required: false }],
                      initialValue: null,
                    })(
                      <Input />,
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={12}>
                  <Form.Item label="Date Reference" {...formItemLayout} extra="Selects packages tagged with the specified movement during particular period">
                    {getFieldDecorator('movement', {
                      rules: [{ required: dateSelected.length, message: 'Movement type cannot be blank' }],
                    })(
                      <Select
                        options={movementTypes}
                        onChange={this.selectMovement}
                        allowClear
                      />,
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={12}>
                  <Form.Item label="Package Type" {...formItemLayout}>
                    {getFieldDecorator('package_type', {
                      rules: [{ required: false }],
                      initialValue: [],
                    })(
                      <CheckboxGroup
                        options={packageTypes}
                      />,
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={12}>
                  <Form.Item label="Date" {...formItemLayout}>
                    {getFieldDecorator('dateRange', {
                      rules: [{ required: movementTypeSelected !== null, message: 'Date Range cannot be blank' }],
                      initialValue: [],
                    })(
                      <RangePicker size="default" onChange={this.selectDate} style={{ width: '100%' }} />,
                    )}
                  </Form.Item>
                </Col>

              </Row>
              <Button type="primary" htmlType="submit" block>
                <Icon viewBox="0 0 1024 1024">
                  <FontAwesomeIcon icon={faSearch} fixedWidth />
                </Icon>
                    Search
              </Button>
            </Form>
          </Card>
          <Spacer />
          <Card
            title={`Waybill List (Total: ${waybillList.total})`}
            extra={(
              <React.Fragment>
                <Row>
                  <Col>
                    <Button type="primary" onClick={this.exportWaybill} block disabled={isExporting}>
                      <Icon viewBox="0 0 1024 1024">
                        <FontAwesomeIcon icon={faFileExport} fixedWidth />
                      </Icon>
                      {isExporting ? '...Exporting' : 'Export'}
                    </Button>
                  </Col>
                </Row>
              </React.Fragment>
          )}
          >
            <ReactTable
              data={waybillList.rows}
              pages={waybillList.pages}
              columns={[
                {
                  Header: 'Tracking_number',
                  accessor: 'tracking_number',
                },
                {
                  id: 'package_type',
                  Header: 'Package Type',
                  accessor: 'package_type',
                },
                {
                  id: 'item_count',
                  Header: 'Set Of',
                  accessor: 'item_count',
                },
                {
                  id: 'origin',
                  Header: 'Origin',
                  accessor: 'origin',
                },
                {
                  id: 'client_name',
                  Header: 'Client',
                  accessor: 'client_name',
                },
                {
                  id: 'status',
                  Header: 'Status',
                  accessor: 'current_status',
                },
                {
                  id: 'section',
                  Header: 'Section',
                  accessor: 'section',
                },
                {
                  Header: 'Updated At',
                  accessor: 'last_update',
                },
                {
                  id: 'inbound_date',
                  Header: 'Inbound Date',
                  accessor: 'inboundDate',
                },
                {
                  id: 'outbound_reprocess_date',
                  Header: 'Outbound Reprocess Date',
                  accessor: 'outboundReprocessDate',
                },
                {
                  id: 'inbound_reprocess_date',
                  Header: 'Inbound Reprocess Date',
                  accessor: 'inboundReprocessDate',
                },
                {
                  id: 'outbound_date',
                  Header: 'Outbound Date',
                  accessor: 'outboundDate',
                },
                {
                  Header: 'Age',
                  id: 'age',
                  Cell: (data) => {
                    let age = '';
                    let ageDuration = moment().format('YYYY-MM-DD HH:mm:ss');
                    if (data.row.outbound_date) {
                      ageDuration = moment(data.row.outbound_date).format('YYYY-MM-DD HH:mm:ss');
                    } else if (data.row.outbound_reprocess_date) {
                      ageDuration = moment(data.row.outbound_reprocess_date).format('YYYY-MM-DD HH:mm:ss');
                    }
                    if (data.row.inbound_date) {
                      const duration = moment(ageDuration).diff(moment(data.row.inbound_date, 'YYYY-MM-DD HH:mm:ss'));
                      age = `${Math.trunc(moment.duration(duration).asHours())} hours`;
                    } else if (data.row.inbound_reprocess_date) {
                      const duration = moment(ageDuration).diff(moment(data.row.inbound_reprocess_date, 'YYYY-MM-DD HH:mm:ss'));
                      age = `${Math.trunc(moment.duration(duration).asHours())} hours`;
                    }
                    return age;
                  },
                  filterable: false,
                  sortable: false,
                },
                {
                  Header: 'Options',
                  sortable: false,
                  filterable: false,
                  Cell: data => (
                    <center>
                      <NavLink to={`/waybill/${data.row.tracking_number}`}>
                        <Button type="link">
                          <Icon viewBox="0 0 1024 1024">
                            <FontAwesomeIcon icon={faEye} fixedWidth />
                          </Icon>
                        View
                        </Button>
                      </NavLink>
                    </center>
                  ),
                },
              ]}

              loadingText={fetchMessage}
              loading={isFetching}
              onFilteredChange={this.onFilteredChangeHandler}
              onSortedChange={this.onSortedChangeHandler}
              onPageChange={this.onPageChangeHandler}
              onPageSizeChange={this.onPageSizeChangeHandler}
              defaultSorted={[]}
            />
          </Card>

        </Container>
      </div>
    );
  }
}
Waybill.propTypes = {
  doGetWaybill: PropTypes.func.isRequired,
  doExportWaybill: PropTypes.func.isRequired,
  doGetMovementTypes: PropTypes.func.isRequired,
  doGetBulkWaybill: PropTypes.func.isRequired,
  doGetOrigins: PropTypes.func.isRequired,
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
  branch: PropTypes.oneOfType([PropTypes.object]).isRequired,
  user: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const mapDispatchToProps = {
  doGetWaybill: getWaybill,
  doGetBulkWaybill: getBulkWaybill,
  doExportWaybill: exportWaybill,
  doGetMovementTypes: getMovementTypes,
  doGetOrigins: getOrigins,
};

const mapStateToProps = state => ({
  branch: state.auth.branch,
  user: state.auth.user,
});

const WrappedWaybill = Form.create({ name: 'Waybill' })(Waybill);
export default connect(mapStateToProps, mapDispatchToProps)(WrappedWaybill);
