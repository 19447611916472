import {
  apiRegister, apiGetUsername, apiGetUsers, apiGetResetPassword, apiGetUserById,
  apiUpdateUser, apiGetForApprovalUsers, apiUpdateUserStatus,
} from '../../services/apiEndpoints';

export const CREATE_USER = 'user/CREATE_USER';
export const create = values => ({
  type: CREATE_USER,
  payload: {
    client: 'secure',
    request: {
      ...apiRegister,
      data: {
        ...values,
        status: 'approved',
      },
    },
  },
});

export const FETCH_USERNAME = 'user/FETCH_USERNAME';
export const getUsername = string => ({
  type: FETCH_USERNAME,
  payload: {
    client: 'secure',
    request: {
      ...apiGetUsername,
      url: `${apiGetUsername.url}/${string}`,
    },
  },
});

export const FETCH_USERS = 'user/FETCH_USERS';
export const getUsers = data => ({
  type: FETCH_USERS,
  payload: {
    client: 'secure',
    request: {
      ...apiGetUsers,
      params: {
        ...data,
        filters: JSON.stringify(data.filters),
        sorts: JSON.stringify(data.sorts),
      },
    },
  },
});

export const FETCH_FOR_APPROVAL_USERS = 'user/FETCH_FOR_APPROVAL_USERS';
export const getPendingUsers = data => ({
  type: FETCH_FOR_APPROVAL_USERS,
  payload: {
    client: 'secure',
    request: {
      ...apiGetForApprovalUsers,
      params: {
        ...data,
        filters: JSON.stringify(data.filters),
        sorts: JSON.stringify(data.sorts),
      },
    },
  },
});

export const PUT_RESET_PASSWORD = 'user/PUT_RESET_PASSWORD';
export const resetPassword = userId => ({
  type: PUT_RESET_PASSWORD,
  payload: {
    client: 'secure',
    request: {
      ...apiGetResetPassword,
      data: {
        id: userId,
      },
    },
  },
});

export const UPDATE_USER = 'user/UPDATE_USER';
export const updateUser = (values, id) => ({
  type: UPDATE_USER,
  payload: {
    client: 'secure',
    request: {
      ...apiUpdateUser,
      url: `/user/update/${id}`,
      data: {
        ...values,
      },
    },
  },
});

export const FETCH_USER = 'user/FETCH_USER';
export const getUser = (path, branch) => ({
  type: FETCH_USER,
  payload: {
    client: 'secure',
    request: {
      ...apiGetUserById,
      url: path,
      params: {
        branch_id: branch.id,
      },
    },
  },
});

export const UPDATE_STATUS = 'user/UPDATE_STATUS';
export const updateUserStatus = (userId, status) => ({
  type: UPDATE_STATUS,
  payload: {
    client: 'secure',
    request: {
      ...apiUpdateUserStatus,
      data: {
        type: status,
        users: userId,
      },
    },
  },
});

const initialState = {};

function reducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}

export default reducer;
