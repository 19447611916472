import {
  apiAddSection, apiGetSections, apiGetSectionSelect, apiUpdateSection, apiDeleteSection,
} from '../../services/apiEndpoints';

export const ADD_SECTION = 'section/ADD_SECTION';

export const addSection = section => ({
  type: ADD_SECTION,
  payload: {
    client: 'secure',
    request: {
      ...apiAddSection,
      data: section,
    },
  },
});

export const FETCH_SECTION = 'section/FETCH_SECTION';

export const getSections = data => ({
  type: FETCH_SECTION,
  payload: {
    client: 'secure',
    request: {
      ...apiGetSections,
      params: {
        ...data,
        filters: JSON.stringify(data.filters),
        sorts: JSON.stringify(data.sorts),
      },
    },
  },
});

export const UPDATE_SECTION = 'section/UPDATE_SECTION';

export const updateSection = (params, sectionId) => ({
  type: UPDATE_SECTION,
  payload: {
    client: 'secure',
    request: {
      ...apiUpdateSection,
      url: `/section/${sectionId}`,
      params,
    },
  },
});

export const DELETE_SECTION = 'section/DELETE_SECTION';
export const deleteSection = sectionId => ({
  type: DELETE_SECTION,
  payload: {
    client: 'secure',
    request: {
      ...apiDeleteSection,
      url: `/section/${sectionId}`,
    },
  },
});

export const GET_SECTION_SELECT = 'section/GET_SECTION_SELECT';

export const getSectionSelect = () => ({
  type: GET_SECTION_SELECT,
  payload: {
    client: 'secure',
    request: {
      ...apiGetSectionSelect,
    },
  },
});

const initialState = {};

function reducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}

export default reducer;
