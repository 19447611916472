import {
  apiGetInventorySummary,
  apiExportInventorySummary,
  apiGetAgeingSummary,
  apiExportAgeingSummary,
  apiGetRequestedPackage,
  apiExportRequestedPackage,
} from '../../services/apiEndpoints';

export const FETCH_INVENTORY = 'dashboard/FETCH_INVENTORY';
export const fetchSummary = (range, branchId) => ({
  type: FETCH_INVENTORY,
  payload: {
    client: 'secure',
    request: {
      ...apiGetInventorySummary,
      params: {
        last: range,
        branch_id: branchId,
      },
    },
  },
});

export const EXPORT_INVENTORY = 'dashboard/EXPORT_INVENTORY';
export const exportInventorySummary = (range, branchId) => ({
  type: EXPORT_INVENTORY,
  payload: {
    client: 'secure',
    request: {
      ...apiExportInventorySummary,
      responseType: 'arraybuffer',
      params: {
        movement_date: range,
        branch_id: branchId,
      },
    },
  },
});

export const FETCH_AGEING = 'dashboard/FETCH_AGEING';
export const fetchAgeingSummary = (branchId, mode) => ({
  type: FETCH_AGEING,
  payload: {
    client: 'secure',
    request: {
      ...apiGetAgeingSummary,
      params: {
        branch_id: branchId,
        mode,
      },
    },
  },
});

export const EXPORT_AGEING = 'dashboard/EXPORT_AGEING';
export const exportAgeingSummary = (type, age, branchId) => ({
  type: EXPORT_AGEING,
  payload: {
    client: 'secure',
    request: {
      ...apiExportAgeingSummary,
      responseType: 'arraybuffer',
      params: {
        movement_type: type,
        age,
        branch_id: branchId,
      },
    },
  },
});

export const GET_REQUESTED_PACKAGES = 'dashboard/GET_REQUESTED_PACKAGES';
export const getRequestedPackageSummary = (range, branchId) => ({
  type: GET_REQUESTED_PACKAGES,
  payload: {
    client: 'secure',
    request: {
      ...apiGetRequestedPackage,
      params: {
        dateRange: range,
        branch_id: branchId,
      },
    },
  },
});

export const EXPORT_REQUESTED_PACKAGES = 'dashboard/EXPORT_REQUESTED_PACKAGES';
export const exportRequestedPackageSummary = (movementDate, type) => ({
  type: EXPORT_REQUESTED_PACKAGES,
  payload: {
    client: 'secure',
    request: {
      ...apiExportRequestedPackage,
      responseType: 'arraybuffer',
      params: {
        movementDate,
        type,
      },
    },
  },
});

const initialState = {};

function reducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}

export default reducer;
