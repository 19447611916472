import React, { Component } from 'react';
import { Select as AntSelect } from 'antd';
import PropTypes from 'prop-types';


export class GroupSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.renderOptions = this.renderOptions.bind(this);
  }

  renderOptions() {
    const { options } = this.props;
    const { Option, OptGroup } = AntSelect;

    const selection = Object.keys(options).map(group => (
      <OptGroup key={group} label={group}>
        {
          options[group].options.map(item => (
            <Option
              disabled={item.disabled}
              key={item.value}
              value={item.value}
            >
              {item.label}
            </Option>
          ))
        }
      </OptGroup>
    ));

    return selection;
  }

  render() {
    return (
      <AntSelect key="select" {...this.props}>
        {this.renderOptions()}
      </AntSelect>
    );
  }
}

GroupSelect.propTypes = {
  options: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

GroupSelect.defaultProps = {
  options: [],
};

export default GroupSelect;
