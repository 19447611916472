import React, { Component } from 'react';
import {
  Button, Icon, Col, Popconfirm, message,
  Statistic as Label, Descriptions, Spin,
  Modal, notification, Form, Upload,
} from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileExport, faPrint, faSave, faBox, faBan, faTrash, faFileDownload,
} from '@fortawesome/free-solid-svg-icons';
import FileDownload from 'js-file-download';
import PageHeader from '../../components/PageHeader';
import Select from '../../components/Select';
import Card from '../../components/Card';
import Container from '../../components/Container';
import Spacer from '../../components/Spacer';
import ReactTable from '../../components/ReactTable';
import Row from '../../components/Row';
import {
  getOutboundReprocessDetails,
  exportOutboundReprocess,
  finalizeMovement,
  cancelOutboundReprocess,
  printOutboundReprocess,
  uploadOutboundReprocessFiles,
  downloadOutboundReprocessFile,
  deleteOutboundReprocessFile,
} from './ducks';

class OutboundReprocessView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      packages: [],
      deletedPackages: [],
      remarks: null,
      branch: null,
      createdBy: null,
      createdAt: null,
      outboundReproId: null,
      isFinalized: false,
      isCancelled: false,
      isLoading: true,
      isFetchingPackages: false,
      deletedOnly: false,
      loadMessage: 'Fetching Outbound-reprocess details. . .',
      uploadModalVisibility: false,
      fileList: [],
    };
    this.exportOutboundReprocess = this.exportOutboundReprocess.bind(this);
    this.editRedirect = this.editRedirect.bind(this);
    this.finalize = this.finalize.bind(this);
    this.cancelOutboundReprocess = this.cancelOutboundReprocess.bind(this);
    this.getOutboundReprocessDetails = this.getOutboundReprocessDetails.bind(this);
    this.print = this.print.bind(this);
    this.beforeUploadHandler = this.beforeUploadHandler.bind(this);
    this.removeUploadHandler = this.removeUploadHandler.bind(this);
    this.submitUploadHandler = this.submitUploadHandler.bind(this);
    this.downloadMovementFile = this.downloadMovementFile.bind(this);
  }

  componentDidMount() {
    this.getOutboundReprocessDetails();
  }

  getOutboundReprocessDetails() {
    const { doFetchOutboundReprocessDetails, history, branch } = this.props;
    const { deletedOnly } = this.state;
    doFetchOutboundReprocessDetails(
      history.location.pathname, branch, deletedOnly,
    ).then((action) => {
      this.setState({
        packages: action.payload.data.packages,
        remarks: action.payload.data.remarks,
        branch: typeof action.payload.data.location_details === 'undefined'
                || action.payload.data.location_details === null ? '' : action.payload.data.location_details.name,
        createdBy: typeof action.payload.data.user === 'undefined'
                  || action.payload.data.user === null ? '' : action.payload.data.user.full_name,
        createdAt: action.payload.data.created_at,
        stateUserId: action.payload.data.created_by,
        isFinalized: action.payload.data.status === 'finalized',
        isCancelled: action.payload.data.status === 'cancelled',
        outboundReproId: action.payload.data.id,
        deletedPackages: action.payload.data.deleted_packages,
        files: action.payload.data.files,
        isFetchingPackages: false,
        isLoading: false,
      });
    }).catch(() => {
      history.push('/404');
    });
  }

  finalize() {
    const { history, doFinalizeMovement } = this.props;
    const { packages } = this.state;

    if (packages.length !== 0) {
      this.setState({
        isLoading: true,
        loadMessage: 'Finalizing movement, please don`t refresh or leave this page. . .',
      });
      doFinalizeMovement(history.location.pathname).then(() => {
        this.setState({
          isLoading: false,
          isFinalized: true,
        }, () => {
          notification.success({
            message: 'Outbound reprocess successfully finalized!',
            duration: 0,
          });
        });
      }).catch((action) => {
        let errorMessage;
        if (action.error && action.error.response.status === 422) {
          const index = Object.values(action.error.response.data);
          // eslint-disable-next-line prefer-destructuring
          errorMessage = index[0][0];
        } else {
          errorMessage = 'Something went wrong, please try again later.';
        }
        notification.error({
          message: errorMessage,
          duration: 0,
        });
        this.setState({ isLoading: false });
      });
    } else {
      notification.error({
        message: 'Unable to finalize an empty movement!',
        duration: 5,
      });
      this.setState({
        isLoading: false,
      });
    }
  }

  editRedirect() {
    const { history, match } = this.props;
    history.push(`/reprocess/outbound/${match.params.id}/edit`);
  }

  exportOutboundReprocess() {
    const { doExportOutboundReprocess, match } = this.props;
    doExportOutboundReprocess(match.params.id, 'outbound_reproces').then((response) => {
      FileDownload(new Blob([response.payload.data]), `OUTBOUND-REPROCESS-${match.params.id}.xlsx`);
    });
  }

  print() {
    const { doPrintOutboundReprocess, match } = this.props;
    this.setState({ isLoading: true, loadMessage: 'Generating manifest print format. . . ' });
    doPrintOutboundReprocess(match.params.id).then((res) => {
      const file = new Blob(
        [res.payload.data],
        { type: 'application/pdf' },
      );
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      this.setState({ isLoading: false });
    });
  }

  cancelOutboundReprocess() {
    const { confirm } = Modal;
    const { doCancelOutboundReprocess, match, history } = this.props;
    confirm({
      title: (<strong>Are you sure?</strong>),
      content: 'Cancelling this movement will remove the movement and all the packages saved.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        doCancelOutboundReprocess(match.params.id).then(() => {
          message.success('Outbound reprocess successfully cancelled');
          history.push('/reprocess/outbound');
        });
      },
    });
  }

  beforeUploadHandler(file) {
    this.setState(prevState => ({
      fileList: [
        ...prevState.fileList,
        file,
      ],
    }));
    return false;
  }

  removeUploadHandler(e) {
    this.setState(prevState => ({
      fileList: prevState.fileList.filter(file => file.name !== e.name),
    }));
  }

  submitUploadHandler() {
    const { fileList } = this.state;
    const { match, doUploadOutboundReprocessFiles } = this.props;
    const formData = new FormData();
    let fileCount = 0;
    fileList.forEach((file) => {
      formData.set(`uploadedFiles[${fileCount}][file]`, file);
      fileCount += 1;
    });
    this.setState({ isLoading: true });
    doUploadOutboundReprocessFiles(match.params.id, formData).then((res) => {
      const { success } = res.payload.data;
      const { failed } = res.payload.data;

      success.forEach((file) => {
        message.success(`File ${file.upload_file_name} successfully uploaded.`);
      });
      failed.forEach((file) => {
        message.error(`File ${file.filename} failed to upload.`);
      });
      this.setState({ uploadModalVisibility: false, fileList: [] });
      this.getOutboundReprocessDetails();
    }).catch(() => {
      this.setState({
        isLoading: false,
      });
    });
  }

  downloadMovementFile(fileId, filename) {
    const { doDownloadOutboundReprocessFile } = this.props;
    this.setState({ isLoading: true, loadMessage: 'Downloading file. . .' });
    doDownloadOutboundReprocessFile(fileId).then((res) => {
      this.setState({ isLoading: false });
      FileDownload(new Blob([res.payload.data]), filename);
    });
  }

  deleteMovementFile(fileId) {
    const { doDeleteOutboundReprocessFile } = this.props;
    this.setState({ isLoading: true });
    doDeleteOutboundReprocessFile(fileId).then(() => {
      message.success('file successfully deleted');
      this.getOutboundReprocessDetails();
    }).catch(() => {
      this.setState({
        isLoading: false,
      });
    });
  }

  render() {
    const {
      files,
      packages,
      remarks,
      branch,
      createdBy,
      createdAt,
      isFinalized,
      isLoading,
      loadMessage,
      outboundReproId,
      stateUserId,
      isCancelled,
      deletedPackages,
      isFetchingPackages,
      deletedOnly,
      uploadModalVisibility,
      fileList,
    } = this.state;

    const { Dragger } = Upload;
    const { userId, userRole } = this.props;

    const packagesFilter = [
      {
        label: 'Scanned',
        value: false,
      },
      {
        label: 'Deleted',
        value: true,
      },
    ];

    return (
      <div className="OutboundReprocessView">
        <Spin spinning={isLoading} tip={loadMessage}>
          {/* { this.printView() }
          <iframe
            title="OutboundReprocessPrint"
            id="ifmcontentstoprint"
            style={{ height: '0px', width: '0px', position: 'absolute' }}
          /> */}
          <PageHeader title={`Outbound Reprocess #${outboundReproId}`} />
          <Container>
            <Card
              title="Outbound Reprocess Details"
              extra={(
                <React.Fragment>
                  <Row>
                    {!isCancelled ? (
                      <Col xs={24} sm={24} lg={!isFinalized ? 6 : 12}>
                        <Button
                          type="primary"
                          onClick={this.exportOutboundReprocess}
                          disabled={isLoading}
                          block
                        >
                          <Icon viewBox="0 0 1024 1024">
                            <FontAwesomeIcon icon={faFileExport} fixedWidth />
                          </Icon>
                                Export
                        </Button>
                      </Col>
                    ) : ''}
                    {!isCancelled ? (
                      <Col xs={24} sm={24} lg={!isFinalized ? 6 : 12}>
                        <Button type="primary" onClick={this.print} disabled={isLoading} block>
                          <Icon viewBox="0 0 1024 1024">
                            <FontAwesomeIcon icon={faPrint} fixedWidth />
                          </Icon>
                          Print
                        </Button>
                      </Col>
                    ) : ''}
                    {(stateUserId === userId || ['admin', 'super_admin'].includes(userRole))
                      && !isFinalized && !isCancelled ? (
                        <Col xs={24} sm={12} lg={6}>
                          <Button type="danger" onClick={this.cancelOutboundReprocess} block disabled={isLoading}>
                            <Icon viewBox="0 0 1024 1024">
                              <FontAwesomeIcon icon={faBan} fixedWidth />
                            </Icon>
                          Cancel
                          </Button>
                        </Col>
                      ) : ''}
                    {!isFinalized && !isCancelled ? (
                      <Col xs={24} sm={24} lg={6}>
                        <Popconfirm
                          placement="leftBottom"
                          title="Are you sure you want to finalize this movement?"
                          okText="Yes"
                          onConfirm={this.finalize}
                          cancelText="No"
                        >
                          <Button className="btn-success" disabled={isLoading} block>
                            <Icon viewBox="0 0 1024 1024">
                              <FontAwesomeIcon icon={faSave} fixedWidth />
                            </Icon>
                        Finalize
                          </Button>
                        </Popconfirm>
                      </Col>
                    ) : ''}
                  </Row>

                </React.Fragment>
  )}
            >
              <Descriptions
                bordered
                column={{
                  xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1,
                }}
              >
                <Descriptions.Item label="Branch: ">{branch}</Descriptions.Item>
                <Descriptions.Item label="Created By: ">{createdBy}</Descriptions.Item>
                <Descriptions.Item label="Created : ">{createdAt}</Descriptions.Item>
                <Descriptions.Item label="Remarks: ">{remarks}</Descriptions.Item>
              </Descriptions>
            </Card>
            <Spacer />
            <Card title="">
              <Row>
                <center>
                  <Col xs={24} sm={8} lg={8} />
                  <Col xs={24} sm={8} lg={8}>
                    <Label title="Total Scans" value={packages.length} />
                  </Col>
                  <Col xs={24} sm={8} lg={8} />
                </center>
              </Row>
            </Card>
            <Spacer />
            <Modal
              title="Upload Outbound Reprocess File"
              visible={uploadModalVisibility}
              onCancel={() => this.setState({ fileList: [], uploadModalVisibility: false })}
              onOk={this.submitUploadHandler}
              okText={isLoading ? 'Uploading . . .' : 'Submit'}
              okButtonProps={{
                disabled: isLoading,
              }}
            >
              <Form encType="multipart/form-data">
                <Dragger
                  multiple
                  fileList={fileList}
                  beforeUpload={this.beforeUploadHandler}
                  onRemove={this.removeUploadHandler}
                  accept=".doc,.docx,.pdf"
                >
                  <p className="ant-upload-drag-icon" />
                  <p className="ant-upload-text">Click or drag file to this area to upload</p>
                </Dragger>
              </Form>
            </Modal>
            <Card
              title="Files / Documents"
              extra={(
                <Button
                  type="primary"
                  onClick={() => this.setState({ uploadModalVisibility: true })}
                >
                  Upload
                </Button>
              )}
            >
              <ReactTable
                data={files}
                columns={[
                  {
                    Header: 'Filename',
                    accessor: 'upload_file_name',
                  },
                  {
                    Header: 'Uploaded At',
                    accessor: 'created_at',
                  },
                  {
                    Header: 'Uploaded By',
                    accessor: 'created_by',
                  },
                  {
                    Header: 'Actions',
                    Cell: data => (
                      <Row>
                        <Col span={12}>
                          <Button type="link" onClick={() => this.downloadMovementFile(data.original.id, data.original.upload_file_name)}>
                            <Icon viewBox="0 0 1024 1024">
                              <FontAwesomeIcon icon={faFileDownload} fixedWidth />
                            </Icon>
                            Download
                          </Button>
                        </Col>
                        <Col span={12}>
                          <Popconfirm
                            placement="leftBottom"
                            title={`Are you sure you want to delete ${data.row.upload_file_name}?`}
                            okText="Yes"
                            onConfirm={() => this.deleteMovementFile(data.original.id)}
                          >
                            <Button type="link" block>
                              <Icon viewBox="0 0 1024 1024">
                                <FontAwesomeIcon icon={faTrash} fixedWidth />
                              </Icon>
                            Delete
                            </Button>
                          </Popconfirm>

                        </Col>
                      </Row>
                    ),
                  },
                ]}
                minRows={2}
              />
            </Card>
            <Spacer />
            <Card
              title="Outbound Reprocess Packages"
              extra={(
                <React.Fragment>
                  <Row>
                    <Col xs={24} sm={12} lg={12}>
                      <Select
                        options={packagesFilter}
                        style={{ width: '150px' }}
                        defaultValue={false}
                        onChange={this.filterPackages}
                      />
                    </Col>
                    {!isFinalized && !isCancelled ? (
                      <Col xs={24} sm={12} lg={12}>
                        <Button type="primary" block onClick={this.editRedirect} disabled={isLoading}>
                          <Icon viewBox="0 0 1024 1024">
                            <FontAwesomeIcon icon={faBox} fixedWidth />
                          </Icon>
                        Edit Packages
                        </Button>
                      </Col>
                    ) : ''}
                  </Row>
                </React.Fragment>
              )}
            >
              <Spin spinning={isFetchingPackages} tip="Fetching packages...">
                <ReactTable
                  data={deletedOnly ? deletedPackages : packages}
                  columns={[
                    {
                      Header: 'Tracking Number',
                      accessor: 'tracking_number',
                    },
                    {
                      Header: 'Set of.',
                      accessor: 'item_count',
                    },
                    {
                      Header: 'Package Type',
                      accessor: 'package_type',
                    },
                    {
                      Header: 'Remarks',
                      accessor: 'remarks',
                    },
                    {
                      id: 'created_by',
                      Header: 'Received By',
                      accessor: data => data.user.full_name,
                    },
                  ]}
                  loadingText="Fetching Inbound Reprocess Package List. . ."
                  loading
                />
              </Spin>
            </Card>
          </Container>
        </Spin>
      </div>
    );
  }
}

OutboundReprocessView.propTypes = {
  doFetchOutboundReprocessDetails: PropTypes.func.isRequired,
  doExportOutboundReprocess: PropTypes.func.isRequired,
  doFinalizeMovement: PropTypes.func.isRequired,
  doCancelOutboundReprocess: PropTypes.func.isRequired,
  doPrintOutboundReprocess: PropTypes.func.isRequired,
  doUploadOutboundReprocessFiles: PropTypes.func.isRequired,
  doDownloadOutboundReprocessFile: PropTypes.func.isRequired,
  doDeleteOutboundReprocessFile: PropTypes.func.isRequired,
  history: PropTypes.oneOfType([PropTypes.object]).isRequired,
  match: PropTypes.oneOfType([PropTypes.object]).isRequired,
  branch: PropTypes.oneOfType([PropTypes.object]).isRequired,
  userId: PropTypes.number.isRequired,
  userRole: PropTypes.string.isRequired,
};

const mapStatetoProps = state => ({
  userId: state.auth.user.sub,
  userRole: state.auth.user.role,
  branch: state.auth.branch,
});

const mapDispatchToProps = {
  doFetchOutboundReprocessDetails: getOutboundReprocessDetails,
  doExportOutboundReprocess: exportOutboundReprocess,
  doFinalizeMovement: finalizeMovement,
  doCancelOutboundReprocess: cancelOutboundReprocess,
  doPrintOutboundReprocess: printOutboundReprocess,
  doUploadOutboundReprocessFiles: uploadOutboundReprocessFiles,
  doDownloadOutboundReprocessFile: downloadOutboundReprocessFile,
  doDeleteOutboundReprocessFile: deleteOutboundReprocessFile,
};

export default withRouter(connect(mapStatetoProps, mapDispatchToProps)(OutboundReprocessView));
