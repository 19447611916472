import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Col, Form, Input, Button, Icon,
  Modal, Result, Radio, Spin, message,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEdit, faRedo,
} from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Row from '../../components/Row';
import PageHeader from '../../components/PageHeader';
import Card from '../../components/Card';
import Container from '../../components/Container';
import Select from '../../components/Select';
import {
  updateUser, resetPassword, getUser,
} from './ducks';
import { getBranchSelect } from '../branch/ducks';

import { getOutsourceSelect } from '../outsource/ducks';

class UserEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      isSubmitting: false,
      newPassword: null,
      loadResetPassword: false,
      userDetails: {},
      isLoading: true,
      outsourceOptions: [],
      branchOptions: [],
      userBranches: [],
    };

    this.formSubmit = this.formSubmit.bind(this);
    this.showModal = this.showModal.bind(this);
    this.handleOk = this.handleOk.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.doclearPassword = this.doclearPassword.bind(this);
  }

  componentDidMount() {
    const {
      doFetch,
      history,
      doGetOutsourceSelect,
      doGetBranchSelect,
      branch,
    } = this.props;
    doFetch(history.location.pathname, branch).then((initialAction) => {
      doGetOutsourceSelect().then((action) => {
        this.setState({
          userDetails: initialAction.payload.data,
          isLoading: false,
          outsourceOptions: action.payload.data,
          userBranches: initialAction.payload.data.branches.map(userBranch => userBranch.branch_id),
        });
      });
    }).catch(() => {
      history.push('/404');
    });

    doGetBranchSelect().then((action) => {
      this.setState({
        branchOptions: action.payload.data,
      });
    });
  }

  showModal(userId) {
    this.setState({
      showModal: true,
      resetPasswordId: userId,
    });
  }

  formSubmit(e) {
    e.preventDefault();
    const { form, doUpdate } = this.props;
    const { userDetails } = this.state;
    const { validateFields } = form;

    validateFields((err, values) => {
      this.setState({
        isSubmitting: true,
      });
      if (!err) {
        const params = {
          ...values,
          branch_id: values.branch_id ? values.branch_id : [],
        };

        doUpdate(params, userDetails.id).then(() => {
          this.setState({
            isSubmitting: false,
          });
          message.success('Successfully updated user!');
        }).catch((action) => {
          let errorMessage;
          if (action.error.response.status === 422) {
            const index = Object.values(action.error.response.data);
            // eslint-disable-next-line prefer-destructuring
            errorMessage = index[0][0];
          } else {
            errorMessage = 'Something went wrong, please try again later.';
          }
          message.error(errorMessage, 3);
          this.setState({
            isSubmitting: false,
          });
        });
      } else {
        this.setState({
          isSubmitting: false,
        });
      }
    });
  }

  // eslint-disable-next-line no-unused-vars
  handleOk(e) {
    const { resetPasswordId } = this.state;
    const { doReset } = this.props;

    this.setState({
      loadResetPassword: true,
    });

    doReset(resetPasswordId).then((action) => {
      this.setState({
        loadResetPassword: false,
        newPassword: action.payload.data.password,
      });
    // eslint-disable-next-line no-unused-vars
    }).catch((action) => {
      this.setState({
        loadResetPassword: false,
        newPassword: null,
      });
    });
  }

  // eslint-disable-next-line no-unused-vars
  handleCancel(e) {
    this.setState({
      showModal: false,
    });
  }

  doclearPassword() {
    this.setState({
      newPassword: null,
    });
  }

  render() {
    const {
      form,
      userRole,
    } = this.props;

    const {
      showModal,
      loadResetPassword,
      newPassword,
      userDetails,
      isSubmitting,
      isLoading,
      outsourceOptions,
      branchOptions,
      userBranches,
    } = this.state;

    const { getFieldDecorator } = form;
    const roles = [
      {
        value: 'admin',
        label: 'Admin',
      },
      {
        value: 'documentation',
        label: 'Documentation',
      },
      {
        value: 'encoder',
        label: 'Encoder',
      },
    ];

    const activity = [
      {
        value: 1,
        label: 'Active',
      },
      {
        value: 0,
        label: 'Inactive',
      },
    ];

    return (

      <div className="UserEdit">
        <Spin spinning={isLoading} tip="Fetching user details. . .">
          <PageHeader title="User" />
          <Container>
            <Modal
              title="Reset Password"
              closable={false}
              visible={showModal}
              afterClose={this.doclearPassword}
              footer={[
                <Button key="cancel" type="default" onClick={this.handleCancel} disabled={!!loadResetPassword}>
                  Cancel
                </Button>,
                <Button key="reset" type="primary" onClick={this.handleOk} loading={loadResetPassword}>
                  Reset
                </Button>,

              ]}
            >
              {
                  !newPassword
                    ? (
                      <Result
                        title="Are you sure you want to reset this users password ?"
                      />
                    )
                    : (
                      <Result
                        status="success"
                        title="Successfully generated a new password"
                        subTitle={`Here is the new password of the user: ${newPassword}`}
                      />

                    )
                }

            </Modal>
            <Card title="Edit User">
              <Form layout="horizontal">
                <Row>
                  <Col xs={24} sm={12} lg={12}>
                    <Form.Item label="First Name">
                      {getFieldDecorator('first_name', {
                        rules: [{ required: true, message: 'First Name cannot be blank.' }],
                        initialValue: userDetails.first_name,

                      })(<Input autoComplete="off" placeholder="Enter first name" />)}
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} lg={12}>
                    <Form.Item label="Last Name">
                      {getFieldDecorator('last_name', {
                        rules: [{ required: true, message: 'Last Name cannot be blank' }],
                        initialValue: userDetails.last_name,
                      })(<Input autoComplete="off" placeholder="Enter last name" />)}
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} sm={12} lg={12}>
                    <Form.Item label="Username">
                      {getFieldDecorator('username', {
                        rules: [{ required: true, message: 'Username cannot be blank.' }],
                        initialValue: userDetails.username,
                      })(<Input readOnly />)}
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} lg={12}>
                    <Form.Item label="Contact">
                      {getFieldDecorator('contact', {
                        rules: [{ required: false, message: 'Contact cannot be blank' }],
                        initialValue: userDetails.contact,
                      })(<Input autoComplete="off" placeholder="Enter contact" />)}
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} sm={12} lg={12}>
                    <Form.Item label="Role">
                      {getFieldDecorator('role', {
                        rules: [{ required: true, message: 'Role cannot be blank' }],
                        initialValue: userDetails.role,
                      })(<Select options={roles} placeholder="Select role" />)}
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} lg={12}>
                    <Form.Item label="Outsource Group">
                      {getFieldDecorator('outsource_group_id', {
                        rules: [{ required: true, message: 'Outsource Group cannot be blank' }],
                        initialValue: userDetails.outsource
                          ? userDetails.outsource.id : undefined,
                      })(<Select
                        placeholder="Select outsource group"
                        options={outsourceOptions}
                      />)}
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} sm={12} lg={12}>
                    <Form.Item label="Address">
                      {getFieldDecorator('address', {
                        rules: [{ required: false, message: 'Address cannot be blank' }],
                        initialValue: userDetails.address,
                      })(<Input autoComplete="off" placeholder="Enter address" />)}
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} lg={12}>
                    <Form.Item label="Is Active">
                      {getFieldDecorator('is_active', {
                        initialValue: userDetails.is_active === 'Active' ? 1 : 0,
                      })(<Radio.Group options={activity} />)}
                    </Form.Item>

                  </Col>
                </Row>
                {userRole === 'super_admin' ? (
                  <Row>
                    <Col xs={24} sm={12} lg={12}>
                      <Form.Item label="Branch">
                        {getFieldDecorator('branch_id', {
                          rules: [{ required: true, message: 'Branch cannot be blank' }],
                          initialValue: userDetails.branches
                            ? userBranches : undefined,
                        })(<Select
                          placeholder="Select outsource group"
                          options={branchOptions}
                          mode="multiple"
                        />)}
                      </Form.Item>
                    </Col>
                  </Row>
                ) : ''}
                <Row>
                  <Col xs={24} sm={12} lg={12}>
                    <Form.Item>
                      <Button type="default" onClick={() => this.showModal(userDetails.id)} block>
                        <Icon viewBox="0 0 1024 1024">
                          <FontAwesomeIcon icon={faRedo} fixedWidth />
                        </Icon>
                        Reset Password
                      </Button>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} lg={12}>
                    <Form.Item>
                      <Button type="primary" onClick={this.formSubmit} disabled={isSubmitting} block>
                        <Icon viewBox="0 0 1024 1024">
                          <FontAwesomeIcon icon={faEdit} fixedWidth />
                        </Icon>
                        Update
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Container>
        </Spin>

      </div>
    );
  }
}

const mapDispatchToProps = {
  doFetch: getUser,
  doUpdate: updateUser,
  doReset: resetPassword,
  doGetOutsourceSelect: getOutsourceSelect,
  doGetBranchSelect: getBranchSelect,
};

const mapStateToProps = state => ({
  userRole: state.auth.user.role,
  branch: state.auth.branch,
});

UserEdit.propTypes = {
  doFetch: PropTypes.func.isRequired,
  doUpdate: PropTypes.func.isRequired,
  doReset: PropTypes.func.isRequired,
  doGetBranchSelect: PropTypes.func.isRequired,
  userRole: PropTypes.string.isRequired,
  doGetOutsourceSelect: PropTypes.func.isRequired,
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
  history: PropTypes.oneOfType([PropTypes.object]).isRequired,
  branch: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const WrappedUserEdit = Form.create({ name: 'UserEdit' })(UserEdit);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WrappedUserEdit));
