import React, { Component } from 'react';
import {
  Button, Icon, Col, message, Popconfirm, Modal,
  Statistic as Label, Descriptions, Spin, notification,
} from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSave, faFileExport, faPrint, faBox, faBan,
} from '@fortawesome/free-solid-svg-icons';
import FileDownload from 'js-file-download';
import PageHeader from '../../components/PageHeader';
import Card from '../../components/Card';
import Container from '../../components/Container';
import Spacer from '../../components/Spacer';
import ReactTable from '../../components/ReactTable';
import Row from '../../components/Row';
import Select from '../../components/Select';
import {
  getInboundReprocessDetails,
  finalizeMovement,
  exportInboundReprocess,
  cancelInboundReprocess,
  printInboundReprocess,
} from './ducks';

class InboundReprocessView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      packages: [],
      deletedPackages: [],
      isFetchingPackages: false,
      deletedOnly: false,
      isFinalized: false,
      isCancelled: false,
      isLoading: true,
      releasedBy: null,
      status: null,
      branch: null,
      createdBy: null,
      createdAt: null,
      inboundReprocessId: null,
      stateUserId: null,
      loadMessage: 'Fetching Inbound-reprocess details. . .',
    };
    this.finalize = this.finalize.bind(this);
    this.exportPackages = this.exportPackages.bind(this);
    this.editRedirect = this.editRedirect.bind(this);
    this.cancelInboundReprocess = this.cancelInboundReprocess.bind(this);
    this.filterPackages = this.filterPackages.bind(this);
    this.getInboundReprocessDetails = this.getInboundReprocessDetails.bind(this);
    this.print = this.print.bind(this);
  }

  componentDidMount() {
    this.getInboundReprocessDetails();
  }

  getInboundReprocessDetails() {
    const { doFetchInboundReproDetails, history, branch } = this.props;
    const { deletedOnly } = this.state;
    doFetchInboundReproDetails(history.location.pathname, branch, deletedOnly).then((action) => {
      this.setState({
        packages: action.payload.data.packages,
        releasedBy: action.payload.data.released_by,
        status: action.payload.data.status,
        branch: action.payload.data.location_details.name,
        createdBy: action.payload.data.user.full_name,
        createdAt: action.payload.data.created_at,
        stateUserId: action.payload.data.created_by,
        inboundReprocessId: action.payload.data.id,
        isLoading: false,
        isFinalized: action.payload.data.status === 'finalized',
        isCancelled: action.payload.data.status === 'cancelled',
        isFetchingPackages: false,
        deletedPackages: action.payload.data.deleted_packages,
      });
    }).catch(() => {
      history.push('/404');
    });
  }

  finalize() {
    const { packages } = this.state;
    const { history, doFinalizeMovement } = this.props;
    if (packages.length) {
      this.setState({
        isLoading: true,
        loadMessage: 'Finalizing movement, please don`t refresh or leave this page. . .',
      });
      doFinalizeMovement(history.location.pathname).then(() => {
        this.setState({
          isLoading: false,
          status: 'finalized',
          isFinalized: true,
        }, () => {
          notification.success({
            message: 'Inbound reprocess successfully finalized!',
            duration: 0,
          });
        });
      }).catch((action) => {
        let errorMessage;
        if (action.error && action.error.response.status === 422) {
          const index = Object.values(action.error.response.data);
          // eslint-disable-next-line prefer-destructuring
          errorMessage = index[0][0];
        } else {
          errorMessage = 'Something went wrong, please try again later.';
        }
        notification.error({
          message: errorMessage,
          duration: 0,
        });
        this.setState({ isLoading: false });
      });
    } else {
      this.setState({
        isLoading: false,
      });
      notification.error({
        message: 'Unable to finalize an empty movement!',
        duration: 5,
      });
      this.setState({
        isLoading: false,
      });
    }
  }

  exportPackages() {
    const { doExportInboundReprocess, match } = this.props;
    doExportInboundReprocess(match.params.id).then((response) => {
      FileDownload(new Blob([response.payload.data]), `INBOUNDREPROCESS-${match.params.id}-PACKAGES.xlsx`);
    });
  }

  print() {
    const { doPrintInboundReprocess } = this.props;
    const {
      inboundReprocessId,
    } = this.state;
    this.setState({ isLoading: true, loadMessage: 'Generating manifest print format. . . ' });
    doPrintInboundReprocess(inboundReprocessId).then((res) => {
      const file = new Blob(
        [res.payload.data],
        { type: 'application/pdf' },
      );
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      this.setState({ isLoading: false });
    });
  }

  editRedirect() {
    const { history, match } = this.props;
    history.push(`/reprocess/inbound/${match.params.id}/edit`);
  }

  cancelInboundReprocess() {
    const { confirm } = Modal;
    const { doCancelInboundReprocess, match, history } = this.props;
    confirm({
      title: (<strong>Are you sure?</strong>),
      content: 'Cancelling this movement will remove the movement and all the packages saved.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        doCancelInboundReprocess(match.params.id).then(() => {
          message.success('Inbound reprocess successfully cancelled');
          history.push('/reprocess/inbound');
        });
      },
    });
  }

  filterPackages(e) {
    this.setState({
      deletedOnly: e,
      isFetchingPackages: true,
    }, () => {
      this.getInboundReprocessDetails();
    });
  }

  render() {
    const {
      packages,
      releasedBy,
      status,
      branch,
      createdBy,
      createdAt,
      isLoading,
      loadMessage,
      inboundReprocessId,
      stateUserId,
      isFinalized,
      isCancelled,
      isFetchingPackages,
      deletedOnly,
      deletedPackages,
    } = this.state;

    const { userId, userRole } = this.props;

    const packagesFilter = [
      {
        label: 'Scanned',
        value: false,
      },
      {
        label: 'Deleted',
        value: true,
      },
    ];

    return (
      <div className="InboundReprocessView">
        <Spin spinning={isLoading} tip={loadMessage}>
          <PageHeader title={`Inbound Reprocess #${inboundReprocessId}`} />
          <Container>
            <Card
              title="Inbound Reprocess Details"
              extra={(
                <React.Fragment>
                  <Row>
                    {!isCancelled ? (
                      <Col xs={24} sm={12} lg={!isFinalized ? 6 : 12}>
                        <Button type="primary" block disabled={isLoading} onClick={this.exportPackages}>
                          <Icon viewBox="0 0 1024 1024">
                            <FontAwesomeIcon icon={faFileExport} fixedWidth />
                          </Icon>
                                Export
                        </Button>
                      </Col>
                    ) : ''}
                    {!isCancelled ? (
                      <Col xs={24} sm={12} lg={!isFinalized ? 6 : 12}>
                        <Button
                          type="primary"
                          onClick={this.print}
                          disabled={isLoading}
                          block
                        >
                          <Icon viewBox="0 0 1024 1024">
                            <FontAwesomeIcon icon={faPrint} fixedWidth />
                          </Icon>
                        Print
                        </Button>
                      </Col>
                    ) : ''}
                    {(stateUserId === userId || ['admin', 'super_admin'].includes(userRole))
                    && !isFinalized && !isCancelled ? (
                      <Col xs={24} sm={12} lg={6}>
                        <Button type="danger" onClick={this.cancelInboundReprocess} block disabled={isLoading}>
                          <Icon viewBox="0 0 1024 1024">
                            <FontAwesomeIcon icon={faBan} fixedWidth />
                          </Icon>
                          Cancel
                        </Button>
                      </Col>
                      ) : ''}
                    {!isFinalized && !isCancelled ? (
                      <Col xs={24} sm={12} lg={6}>
                        <Popconfirm
                          placement="leftBottom"
                          title="Are you sure you want to finalize this movement?"
                          okText="Yes"
                          onConfirm={this.finalize}
                          cancelText="No"
                        >
                          <Button className="btn-success" disabled={isLoading} block>
                            <Icon viewBox="0 0 1024 1024">
                              <FontAwesomeIcon icon={faSave} fixedWidth />
                            </Icon>
                        Finalize
                          </Button>
                        </Popconfirm>
                      </Col>
                    ) : ''}
                  </Row>
                </React.Fragment>
  )}
            >
              <Descriptions
                bordered
                column={{
                  xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1,
                }}
              >
                <Descriptions.Item label="Status: ">{status}</Descriptions.Item>
                <Descriptions.Item label="Created By: ">{createdBy}</Descriptions.Item>
                <Descriptions.Item label="Created : ">{createdAt}</Descriptions.Item>
                <Descriptions.Item label="Branch: ">{branch}</Descriptions.Item>
                <Descriptions.Item label="Released By: ">{releasedBy}</Descriptions.Item>
              </Descriptions>
            </Card>
            <Spacer />
            <Card title="">
              <Row>
                <center>
                  <Col xs={24} sm={8} lg={8} />
                  <Col xs={24} sm={8} lg={8}>
                    <Label title="Total Scans" value={packages.length} />
                  </Col>
                  <Col xs={24} sm={8} lg={8} />
                </center>
              </Row>
            </Card>
            <Spacer />
            <Card
              title="Inbound Reprocess Packages"
              extra={(
                <React.Fragment>
                  <Row>
                    <Col xs={24} sm={12} lg={12}>
                      <Select
                        options={packagesFilter}
                        style={{ width: '150px' }}
                        defaultValue={false}
                        onChange={this.filterPackages}
                      />
                    </Col>
                    {!isFinalized && !isCancelled ? (
                      <Col xs={24} sm={12} lg={12}>
                        <Button type="primary" block onClick={this.editRedirect} disabled={isLoading}>
                          <Icon viewBox="0 0 1024 1024">
                            <FontAwesomeIcon icon={faBox} fixedWidth />
                          </Icon>
                        Edit Packages
                        </Button>
                      </Col>
                    ) : ''}
                  </Row>
                </React.Fragment>
              )}
            >
              <Spin spinning={isFetchingPackages} tip="Fetching packages...">
                <ReactTable
                  data={deletedOnly ? deletedPackages : packages}
                  columns={[
                    {
                      Header: 'Tracking Number',
                      accessor: 'tracking_number',
                    },
                    {
                      Header: 'Set of.',
                      accessor: 'item_count',
                    },
                    {
                      Header: 'Package Type',
                      accessor: 'package_type',
                    },
                    {
                      Header: 'Remarks',
                      accessor: 'remarks',
                    },
                    {
                      Header: 'Section',
                      accessor: 'section',
                    },
                    {
                      id: 'created_by',
                      Header: 'Received By',
                      accessor: data => data.user.full_name,
                    },
                  ]}
                  loadingText="Fetching Inbound Reprocess Package List. . ."
                  loading
                />
              </Spin>
            </Card>
          </Container>
        </Spin>
      </div>
    );
  }
}

InboundReprocessView.propTypes = {
  doFetchInboundReproDetails: PropTypes.func.isRequired,
  doFinalizeMovement: PropTypes.func.isRequired,
  doExportInboundReprocess: PropTypes.func.isRequired,
  doCancelInboundReprocess: PropTypes.func.isRequired,
  doPrintInboundReprocess: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired,
  userRole: PropTypes.string.isRequired,
  match: PropTypes.oneOfType([PropTypes.object]).isRequired,
  history: PropTypes.oneOfType([PropTypes.object]).isRequired,
  branch: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const mapStateToProps = state => ({
  userId: state.auth.user.sub,
  userRole: state.auth.user.role,
  branch: state.auth.branch,
});

const mapDispatchToProps = {
  doFetchInboundReproDetails: getInboundReprocessDetails,
  doFinalizeMovement: finalizeMovement,
  doExportInboundReprocess: exportInboundReprocess,
  doCancelInboundReprocess: cancelInboundReprocess,
  doPrintInboundReprocess: printInboundReprocess,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InboundReprocessView));
