import {
  apiCreateIncidentREport, apiFindPackage, apiGetIncident, apiUpdateIncident,
} from '../../services/apiEndpoints';

export const CREATE_INCIDENT_REPORT = 'incidentReport/CREATE_INCIDENT_REPORT';
export const create = values => ({
  type: CREATE_INCIDENT_REPORT,
  payload: {
    client: 'secure',
    request: {
      ...apiCreateIncidentREport,
      data: values,
    },
  },
});

export const FETCH_PACKAGE = 'incidentReport/FETCH_PACKAGE';
export const findPackage = (trackingNumber, branchId) => ({
  type: FETCH_PACKAGE,
  payload: {
    client: 'secure',
    request: {
      ...apiFindPackage,
      url: `${apiFindPackage.url}/${branchId}/${trackingNumber}`,
    },
  },
});

export const FETCH_REPORT = 'incidentReport/FETCH_REPORT';
export const getIncident = (id, branch) => ({
  type: FETCH_REPORT,
  payload: {
    client: 'secure',
    request: {
      ...apiGetIncident,
      url: `${apiGetIncident.url}/${id}`,
      params: {
        branch_id: branch.id,
      },
    },
  },
});

export const FETCH_REPORT_LIST = 'incidentReport/FETCH_REPORT_LIST';
export const getReports = data => ({
  type: FETCH_REPORT_LIST,
  payload: {
    client: 'secure',
    request: {
      ...apiGetIncident,
      params: {
        ...data,
        filters: JSON.stringify(data.filters),
        sorts: JSON.stringify(data.sorts),
      },
    },
  },
});

export const UPDATE_REPORT = 'incidentReport/UPDATE_REPORT';
export const update = (params, id) => ({
  type: UPDATE_REPORT,
  payload: {
    client: 'secure',
    request: {
      ...apiUpdateIncident,
      url: `${apiUpdateIncident.url}/${id}`,
      data: params,
    },
  },
});

export const UPDATE_REPORT_STATUS = 'incidentReport/UPDATE_REPORT_STATUS';
export const updateStatus = (data, id) => ({
  type: UPDATE_REPORT_STATUS,
  payload: {
    client: 'secure',
    request: {
      ...apiUpdateIncident,
      url: `${apiUpdateIncident.url}/${id}/validate`,
      params: data,
    },
  },
});

const initialState = {};

function reducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}

export default reducer;
