import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Col, Form, Input, Button, Icon,
  Statistic as Label, Popconfirm, message, Modal, Spin,
  notification, Upload,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTimes, faEdit, faSave, faPrint, faFileExport, faBox, faBan, faTrash, faFileDownload,
} from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import FileDownload from 'js-file-download';
import moment from 'moment';
import GroupSelect from '../../components/GroupSelect';
import Select from '../../components/Select';
import Row from '../../components/Row';
import PageHeader from '../../components/PageHeader';
import Card from '../../components/Card';
import Container from '../../components/Container';
import ReactTable from '../../components/ReactTable';
import Spacer from '../../components/Spacer';
import {
  getOutbound,
  updateOutboundDetails,
  finalizeOutbound,
  exportOutbound,
  cancelOutbound,
  printOutbound,
  uploadOutboundFiles,
  downloadOutboundFile,
  deleteOutboundFile,
} from './ducks';
import { getBranchGates } from '../gate/ducks';
import { getVehiclesLike } from '../vehicle/ducks';
import { getHaulerSelect } from '../hauler/ducks';

class OutboundView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      outboundDetails: {},
      gateOptions: {},
      haulerOptions: [],
      deletedPackages: [],
      plateNumberOptions: [],
      isDisabled: true,
      isFinalized: false,
      isCancelled: false,
      isLoading: true,
      deletedOnly: false,
      isFetchingPackages: false,
      type: '',
      loadMessage: 'Fetching Outbound details. . .',
      uploadModalVisibility: false,
      fileList: [],
    };
    this.getOutbound = this.getOutbound.bind(this);
    this.getBranchGates = this.getBranchGates.bind(this);
    this.editRedirect = this.editRedirect.bind(this);
    this.editDetails = this.editDetails.bind(this);
    this.cancelEdit = this.cancelEdit.bind(this);
    this.saveDetails = this.saveDetails.bind(this);
    this.finalizeOutbound = this.finalizeOutbound.bind(this);
    this.exportOutbound = this.exportOutbound.bind(this);
    this.setPlateNumber = this.setPlateNumber.bind(this);
    this.searchPlateNumber = this.searchPlateNumber.bind(this);
    this.cancelOutbound = this.cancelOutbound.bind(this);
    this.filterPackages = this.filterPackages.bind(this);
    this.print = this.print.bind(this);
    this.beforeUploadHandler = this.beforeUploadHandler.bind(this);
    this.removeUploadHandler = this.removeUploadHandler.bind(this);
    this.submitUploadHandler = this.submitUploadHandler.bind(this);
    this.downloadMovementFile = this.downloadMovementFile.bind(this);
  }

  componentDidMount() {
    this.getOutbound();
    this.getBranchGates();
    this.getHaulers();
  }

  setPlateNumber(e) {
    const { plateNumberOptions } = this.state;
    const { form } = this.props;

    const selected = plateNumberOptions.filter(item => item.value === e);

    form.setFieldsValue({ truck_type: selected[0].type });
  }

  getHaulers() {
    const { doGetHaulerSelect } = this.props;

    doGetHaulerSelect().then((action) => {
      this.setState({
        haulerOptions: action.payload.data.map(hauler => ({
          label: hauler.label,
          value: hauler.value,
          disabled: !hauler.is_active,
        })),
      });
    });
  }

  getOutbound() {
    const { doGetOutbound, history, branch } = this.props;
    const { deletedOnly } = this.state;
    doGetOutbound(history.location.pathname, branch, deletedOnly).then((action) => {
      const timeDiff = moment(
        action.payload.data.scan_time, 'YYYY-MM-DD HH:mm:ss',
      ).diff(
        moment(action.payload.data.start, 'YYYY-MM-DD HH:mm:ss'),
      );
      this.setState({
        isFinalized: action.payload.data.status === 'finalized',
        isCancelled: action.payload.data.status === 'cancelled',
        isFetchingPackages: false,
        outboundDetails: {
          ...action.payload.data,
          gate: {
            label: action.payload.data.gate_details.gate_number,
            value: action.payload.data.gate_details.id,
          },
          hauler: {
            label: action.payload.data.hauler_details.name,
            value: action.payload.data.hauler_details.id,
          },
          truck_type: action.payload.data.truck_type.replace(/_/g, ' '),
          plate_number: {
            label: action.payload.data.vehicle.plate_number,
            value: action.payload.data.vehicle.id,
          },
          packages: action.payload.data.packages,
          files: action.payload.data.files,
          user: action.payload.data.user,
          gate_time: moment.utc(moment.duration(timeDiff, 'ms').asMilliseconds()).format('HH:mm:ss'),
        },
        isLoading: false,
        plateNumberOptions: [
          {
            label: `${action.payload.data.vehicle.plate_number} - ${action.payload.data.vehicle.truck_type}`,
            value: action.payload.data.vehicle.id,
          },
        ],
        deletedPackages: action.payload.data.deleted_packages,
      });
    }).catch(() => {
      history.push('/404');
    });
  }

  getBranchGates() {
    const { doGetBranchGates, branch } = this.props;
    doGetBranchGates(branch.id, 'outbound').then((action) => {
      const usedGates = [];
      const availableGates = [];
      action.payload.data.forEach((gate) => {
        if (
          (
            gate.outbound.length
            || gate.inbound.length
          )
          && (
            gate.inbound.some(inbound => inbound.status === 'fresh')
            || gate.outbound.some(outbound => outbound.status === 'fresh')
          )
        ) {
          usedGates.push({
            value: gate.id,
            label: `${gate.gate_number} - #${gate.outbound.length ? gate.outbound[0].id : gate.inbound[0].id}`,
            disabled: true,
          });
        } else {
          availableGates.push({
            value: gate.id,
            label: gate.gate_number,
          });
        }
      });

      const gateOptions = {
        Available: {
          options: [
            ...availableGates,
          ],
        },
        Used: {
          options: [
            ...usedGates,
          ],
        },
      };

      this.setState({ gateOptions });
    });
  }

  searchPlateNumber(e) {
    const { doGetVehiclesLike } = this.props;

    doGetVehiclesLike(e).then((action) => {
      this.setState({
        plateNumberOptions: action.payload.data.map(plateNumber => ({
          ...plateNumber,
          label: `${plateNumber.label} - ${plateNumber.type}`,
        })),
      });
    });
  }

  saveDetails() {
    const {
      form, history, doUpdateOutboundDetails, match,
      branch,
    } = this.props;
    const { validateFields } = form;
    validateFields((err, values) => {
      if (!err) {
        doUpdateOutboundDetails(history.location.pathname, {
          ...values,
          gate: values.gate,
          hauler: values.hauler,
          plate_number: values.plate_number,
          truck_type: values.truck_type,
          location: branch.id,
          id: match.params.id,

        }).then(() => {
          this.setState({ isDisabled: true });
          message.success('Outbound details successfully updated!');
        }).catch((action) => {
          let errorMessage;
          if (action.error.response.status === 422) {
            const index = Object.values(action.error.response.data);
            // eslint-disable-next-line prefer-destructuring
            errorMessage = index[0][0];
          } else {
            errorMessage = 'Something went wrong, please try again later.';
          }
          message.error(errorMessage, 3);
        });
      }
    });
  }

  editDetails() {
    this.setState({ isDisabled: false });
  }

  cancelEdit() {
    this.setState({
      isDisabled: true, type: '',
    });
    const { form } = this.props;
    form.resetFields();
  }

  editRedirect() {
    const { history, match } = this.props;
    history.push(`/outbound/${match.params.id}/package`);
  }

  finalizeOutbound() {
    const { doFinalizeOutbound, match } = this.props;
    const { outboundDetails } = this.state;
    if (outboundDetails.packages.length) {
      this.setState({
        isLoading: true,
        loadMessage: 'Finalizing movement, please don`t refresh or leave this page. . .',
      });
      doFinalizeOutbound(match.params.id).then(() => {
        this.setState({
          isLoading: false,
          isFinalized: true,
        }, () => {
          notification.success({
            message: 'Outbound successfully finalized!',
            duration: 0,
          });
        });
      }).catch((action) => {
        let errorMessage;
        if (action.error && action.error.response.status === 422) {
          const index = Object.values(action.error.response.data);
          // eslint-disable-next-line prefer-destructuring
          errorMessage = index[0][0];
        } else {
          errorMessage = 'Something went wrong, please try again later.';
        }
        notification.error({
          message: errorMessage,
          duration: 0,
        });
        this.setState({ isLoading: false });
      });
    } else {
      notification.error({
        message: 'Unable to finalize an empty movement!',
        duration: 5,
      });
      this.setState({ isLoading: false });
    }
  }

  exportOutbound() {
    const { doExportOutbound, match } = this.props;
    doExportOutbound(match.params.id).then((response) => {
      FileDownload(new Blob([response.payload.data]), `OUTBOUND-${match.params.id}.xlsx`);
    });
  }

  print() {
    const { doPrintOutbound, match } = this.props;
    this.setState({ isLoading: true, loadMessage: 'Generating manifest print format. . . ' });
    doPrintOutbound(match.params.id).then((res) => {
      const file = new Blob(
        [res.payload.data],
        { type: 'application/pdf' },
      );
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      this.setState({ isLoading: false });
    });
  }

  cancelOutbound() {
    const { confirm } = Modal;
    const { doCancelOutbound, match, history } = this.props;
    confirm({
      title: (<strong>Are you sure?</strong>),
      content: 'Cancelling this movement will remove the movement and all the packages saved.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        doCancelOutbound(match.params.id).then(() => {
          message.success('Outbound successfully cancelled');
          history.push('/outbound');
        });
      },
    });
  }

  filterPackages(e) {
    this.setState({
      deletedOnly: e,
      isFetchingPackages: true,
    }, () => {
      this.getOutbound();
    });
  }

  beforeUploadHandler(file) {
    this.setState(prevState => ({
      fileList: [
        ...prevState.fileList,
        file,
      ],
    }));
    return false;
  }

  removeUploadHandler(e) {
    this.setState(prevState => ({
      fileList: prevState.fileList.filter(file => file.name !== e.name),
    }));
  }

  submitUploadHandler() {
    const { fileList } = this.state;
    const { match, doUploadOutboundFiles } = this.props;
    const formData = new FormData();
    let fileCount = 0;
    fileList.forEach((file) => {
      formData.set(`uploadedFiles[${fileCount}][file]`, file);
      fileCount += 1;
    });
    this.setState({ isLoading: true });
    doUploadOutboundFiles(match.params.id, formData).then((res) => {
      const { success } = res.payload.data;
      const { failed } = res.payload.data;

      success.forEach((file) => {
        message.success(`File ${file.upload_file_name} successfully uploaded.`);
      });
      failed.forEach((file) => {
        message.error(`File ${file.filename} failed to upload.`);
      });
      this.setState({ uploadModalVisibility: false, fileList: [] });
      this.getOutbound();
    }).catch(() => {
      this.setState({
        isLoading: false,
      });
    });
  }

  downloadMovementFile(fileId, filename) {
    const { doDownloadOutboundFile } = this.props;
    this.setState({ isLoading: true, loadMessage: 'Downloading file. . .' });
    doDownloadOutboundFile(fileId).then((res) => {
      this.setState({ isLoading: false });
      FileDownload(new Blob([res.payload.data]), filename);
    });
  }

  deleteMovementFile(fileId) {
    const { doDeleteOutboundFile } = this.props;
    this.setState({ isLoading: true });
    doDeleteOutboundFile(fileId).then(() => {
      message.success('file successfully deleted');
      this.getOutbound();
    }).catch(() => {
      this.setState({
        isLoading: false,
      });
    });
  }

  render() {
    const {
      outboundDetails,
      gateOptions,
      isDisabled,
      isFinalized,
      type,
      loadMessage,
      isLoading,
      haulerOptions,
      plateNumberOptions,
      isCancelled,
      deletedOnly,
      isFetchingPackages,
      deletedPackages,
      uploadModalVisibility,
      fileList,
    } = this.state;
    const {
      form,
      userId,
      match,
      userRole,
    } = this.props;
    const { Dragger } = Upload;
    const { getFieldDecorator } = form;
    const packagesFilter = [
      {
        label: 'Scanned',
        value: 0,
      },
      {
        label: 'Deleted',
        value: 1,
      },
    ];
    const timeDiff = moment(outboundDetails.end, 'YYYY-MM-DD HH:mm:ss').diff(moment(outboundDetails.start, 'YYYY-MM-DD HH:mm:ss'));
    const outboundTime = moment.utc(moment.duration(timeDiff, 'ms').asMilliseconds()).format('HH:mm:ss');
    return (
      <div className="OutboundView">
        <Spin spinning={isLoading} tip={loadMessage}>
          <PageHeader title={`Outbound #${match.params.id}`} />
          <Container>
            <Card
              title={(
                <React.Fragment>
                  Outbound Details
                  { isDisabled && !isFinalized && !isCancelled ? (
                    <Button type="link" onClick={this.editDetails} disabled={isLoading}>
                      <Icon viewBox="0 0 1024 1024">
                        <FontAwesomeIcon icon={faEdit} fixedWidth />
                      </Icon>
                      Edit
                    </Button>
                  ) : (
                    ''
                  )}
                </React.Fragment>
              )}
              extra={(
                <React.Fragment>
                  <Row>
                    {!isDisabled && !isFinalized ? (
                      <Col xs={24} sm={12} lg={12}>
                        <Button type="primary" onClick={this.saveDetails} block>
                          <Icon viewBox="0 0 1024 1024">
                            <FontAwesomeIcon icon={faSave} fixedWidth />
                          </Icon>
                          Save
                        </Button>
                      </Col>
                    ) : ''}
                    {!isDisabled ? (
                      <Col xs={24} sm={12} lg={12}>
                        <Button type="primary" onClick={this.cancelEdit} block>
                          <Icon viewBox="0 0 1024 1024">
                            <FontAwesomeIcon icon={faTimes} fixedWidth />
                          </Icon>
                          Cancel
                        </Button>
                      </Col>
                    ) : (
                      ''
                    )}
                    {isDisabled && !isCancelled ? (
                      <Col xs={24} sm={12} lg={isFinalized ? 12 : 6}>
                        <Button type="primary" onClick={this.exportOutbound} block disabled={isLoading}>
                          <Icon viewBox="0 0 1024 1024">
                            <FontAwesomeIcon icon={faFileExport} fixedWidth />
                          </Icon>
                        Export
                        </Button>
                      </Col>
                    ) : ''}
                    {isDisabled && !isCancelled ? (
                      <Col xs={24} sm={12} lg={isFinalized ? 12 : 6}>
                        <Button type="primary" onClick={this.print} block disabled={isLoading}>
                          <Icon viewBox="0 0 1024 1024">
                            <FontAwesomeIcon icon={faPrint} fixedWidth />
                          </Icon>
                        Print
                        </Button>
                      </Col>
                    ) : '' }
                    {(userId === outboundDetails.created_by || ['admin', 'super_admin'].includes(userRole))
                    && isDisabled
                    && !isFinalized
                    && !isCancelled
                      ? (
                        <Col xs={24} sm={12} lg={6}>
                          <Button type="danger" onClick={this.cancelOutbound} block disabled={isLoading}>
                            <Icon viewBox="0 0 1024 1024">
                              <FontAwesomeIcon icon={faBan} fixedWidth />
                            </Icon>
                            Cancel
                          </Button>
                        </Col>
                      )
                      : ''
                    }
                    {!isFinalized && isDisabled && !isCancelled ? (
                      <Col xs={24} sm={12} lg={6}>
                        <Popconfirm
                          placement="leftBottom"
                          title="Are you sure you want to finalize this outbound?"
                          okText="Yes"
                          onConfirm={this.finalizeOutbound}
                          cancelText="No"
                        >
                          <Button className="btn-success" block disabled={isLoading}>
                            <Icon viewBox="0 0 1024 1024">
                              <FontAwesomeIcon icon={faSave} fixedWidth disabled={isLoading} />
                            </Icon>
                                Finalize
                          </Button>
                        </Popconfirm>

                      </Col>
                    ) : ''}
                  </Row>
                </React.Fragment>
              )}
            >
              <Form layout="horizontal">
                <Row>
                  <Col xs={24} sm={24} lg={6}>
                    <Form.Item label="Driver">
                      {getFieldDecorator('driver', {
                        rules: [{ required: true, message: 'Driver cannot be blank' }],
                        initialValue: outboundDetails.driver,
                      })(<Input disabled={isDisabled} />)}
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} lg={6}>
                    <Form.Item label="Helper">
                      {getFieldDecorator('helper', {
                        rules: [{ required: true, message: 'Gate cannot be blank' }],
                        initialValue: outboundDetails.helper,
                      })(<Input disabled={isDisabled} />)}
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} lg={6}>
                    <Form.Item label="Gate">
                      {getFieldDecorator('gate', {
                        rules: [{ required: true, message: 'Gate cannot be blank' }],
                        initialValue: outboundDetails.gate ? outboundDetails.gate.value : '',
                      })(<GroupSelect options={gateOptions} disabled={isDisabled} />)}
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} lg={6}>
                    <Form.Item label="Destination">
                      {getFieldDecorator('destination', {
                        rules: [{ required: true, message: 'Destination cannot be blank' }],
                        initialValue: outboundDetails.destination,
                      })(<Input disabled={isDisabled} />)}
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} sm={24} lg={6}>
                    <Form.Item label="Plate Number">
                      {getFieldDecorator('plate_number', {
                        rules: [{ required: true, message: 'Plate number cannot be blank' }],
                        initialValue: outboundDetails.plate_number ? outboundDetails.plate_number.value : '',
                      })(<Select
                        disabled={isDisabled}
                        key="plateNumber"
                        options={plateNumberOptions}
                        placeholder="Please type to search plate number"
                        filterOption={false}
                        onSearch={this.searchPlateNumber}
                        onChange={this.setPlateNumber}
                        showSearch
                      />)}
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} lg={6}>
                    <Form.Item label="Truck Type">
                      {getFieldDecorator('truck_type', {
                        rules: [{ required: true, message: 'Truck type cannot be blank' }],
                        initialValue: type === '' ? outboundDetails.truck_type : type,
                      })(<Input disabled />)}
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} lg={6}>
                    <Form.Item label="Seal Number">
                      {getFieldDecorator('seal_number', {
                        rules: [{ required: true, message: 'Seal number cannot be blank' }],
                        initialValue: outboundDetails.seal_number,
                      })(<Input disabled={isDisabled} />)}
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} lg={6}>
                    <Form.Item label="Hauler">
                      {getFieldDecorator('hauler', {
                        rules: [{ required: true, message: 'Gate cannot be blank' }],
                        initialValue: outboundDetails.hauler ? outboundDetails.hauler.value : '',
                      })(<Select options={haulerOptions} disabled={isDisabled} />)}
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} sm={24} lg={6}>
                    <Form.Item label="Comment">
                      {getFieldDecorator('comment', {
                        rules: [{ required: false, message: 'Gate cannot be blank' }],
                        initialValue: outboundDetails.comment,
                      })(<Input disabled={isDisabled} />)}
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Card>
            <Spacer />
            <Card title="">
              <center>
                <Row style={{ marginBottom: '50px' }}>
                  <Col xs={24} sm={24} lg={12}>
                    <Label title="Outbound Start Time" value={outboundDetails.start} />
                  </Col>
                  <Col xs={24} sm={24} lg={12}>
                    <Label title="Gate Time Initial Checkout" value={outboundDetails.scan_time ? outboundDetails.scan_time : '---'} />
                  </Col>

                </Row>
                <Row style={{ marginBottom: '50px' }}>
                  <Col xs={24} sm={24} lg={12}>
                    <Label title="Outbound End Time" value={outboundDetails.end ? outboundDetails.end : '---'} />
                  </Col>
                  <Col xs={24} sm={24} lg={12}>
                    <Label title="Initial Gate Time Duration" value={outboundDetails.scan_time ? outboundDetails.gate_time : '---'} />
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} sm={24} lg={12}>
                    <Label title="Outbound Total Hours" value={outboundDetails.end ? outboundTime : '---'} />
                  </Col>
                </Row>
              </center>
            </Card>
            <Spacer />
            <Modal
              title="Upload Outbound File"
              visible={uploadModalVisibility}
              onCancel={() => this.setState({ fileList: [], uploadModalVisibility: false })}
              onOk={this.submitUploadHandler}
              okText={isLoading ? 'Uploading . . .' : 'Submit'}
              okButtonProps={{
                disabled: isLoading,
              }}
            >
              <Form encType="multipart/form-data">
                <Dragger
                  multiple
                  fileList={fileList}
                  beforeUpload={this.beforeUploadHandler}
                  onRemove={this.removeUploadHandler}
                  accept=".doc,.docx,.pdf"
                >
                  <p className="ant-upload-drag-icon" />
                  <p className="ant-upload-text">Click or drag file to this area to upload</p>
                </Dragger>
              </Form>
            </Modal>
            <Card
              title="Files / Documents"
              extra={(
                <Button
                  type="primary"
                  onClick={() => this.setState({ uploadModalVisibility: true })}
                >
                  Upload
                </Button>
              )}
            >
              <ReactTable
                data={outboundDetails.files}
                columns={[
                  {
                    Header: 'Filename',
                    accessor: 'upload_file_name',
                  },
                  {
                    Header: 'Uploaded At',
                    accessor: 'created_at',
                  },
                  {
                    Header: 'Uploaded By',
                    accessor: 'created_by',
                  },
                  {
                    Header: 'Actions',
                    Cell: data => (
                      <Row>
                        <Col span={12}>
                          <Button type="link" onClick={() => this.downloadMovementFile(data.original.id, data.original.upload_file_name)}>
                            <Icon viewBox="0 0 1024 1024">
                              <FontAwesomeIcon icon={faFileDownload} fixedWidth />
                            </Icon>
                            Download
                          </Button>
                        </Col>
                        <Col span={12}>
                          <Popconfirm
                            placement="leftBottom"
                            title={`Are you sure you want to delete ${data.row.upload_file_name}?`}
                            okText="Yes"
                            onConfirm={() => this.deleteMovementFile(data.original.id)}
                          >
                            <Button type="link" block>
                              <Icon viewBox="0 0 1024 1024">
                                <FontAwesomeIcon icon={faTrash} fixedWidth />
                              </Icon>
                            Delete
                            </Button>
                          </Popconfirm>

                        </Col>
                      </Row>
                    ),
                  },
                ]}
                minRows={2}
              />
            </Card>
            <Spacer />
            <Card title="">
              <Row>
                <center>
                  <Col>
                    <Label title="Total Scans" value={typeof outboundDetails.packages !== 'undefined' ? (outboundDetails.packages.length) : 0} />
                  </Col>
                </center>
              </Row>
            </Card>
            <Spacer />
            <Card
              title="Outbound Packages"
              extra={(
                <React.Fragment>
                  <Row>
                    <Col xs={24} sm={12} lg={12}>
                      {getFieldDecorator('packageFilter', {
                        initialValue: 0,
                      })(<Select
                        options={packagesFilter}
                        style={{ width: '150px' }}
                        onChange={this.filterPackages}
                      />)}
                    </Col>
                    {!isFinalized && !isCancelled ? (
                      <Col xs={24} sm={12} lg={12}>
                        <Button type="primary" block onClick={this.editRedirect} disabled={isLoading}>
                          <Icon viewBox="0 0 1024 1024">
                            <FontAwesomeIcon icon={faBox} fixedWidth />
                          </Icon>
                        Edit Packages
                        </Button>
                      </Col>
                    ) : ''}
                  </Row>
                </React.Fragment>
              )}

            >
              <Spin spinning={isFetchingPackages} tip="Fetching packages...">
                <ReactTable
                  data={deletedOnly ? deletedPackages : outboundDetails.packages}
                  columns={[
                    {
                      Header: 'Tracking Number',
                      accessor: 'tracking_number',
                    },
                    {
                      Header: 'Set of.',
                      accessor: 'item_count',
                    },
                    {
                      Header: 'Package Type',
                      accessor: 'package_type',
                    },
                    {
                      Header: 'Remarks',
                      accessor: 'remarks',
                    },
                    {
                      Header: 'Created By',
                      accessor: 'user.full_name',
                    },
                    {
                      Header: 'Created On',
                      accessor: 'created_at',
                    },
                  ]}
                />
              </Spin>
            </Card>
          </Container>
        </Spin>
      </div>
    );
  }
}

OutboundView.defaultProps = {
  branch: {
    id: null,
    name: null,
  },
};

OutboundView.propTypes = {
  doGetOutbound: PropTypes.func.isRequired,
  doGetBranchGates: PropTypes.func.isRequired,
  doGetVehiclesLike: PropTypes.func.isRequired,
  doGetHaulerSelect: PropTypes.func.isRequired,
  doExportOutbound: PropTypes.func.isRequired,
  doUpdateOutboundDetails: PropTypes.func.isRequired,
  doPrintOutbound: PropTypes.func.isRequired,
  doFinalizeOutbound: PropTypes.func.isRequired,
  doCancelOutbound: PropTypes.func.isRequired,
  doUploadOutboundFiles: PropTypes.func.isRequired,
  doDownloadOutboundFile: PropTypes.func.isRequired,
  doDeleteOutboundFile: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired,
  userRole: PropTypes.string.isRequired,
  history: PropTypes.oneOfType([PropTypes.object]).isRequired,
  match: PropTypes.oneOfType([PropTypes.object]).isRequired,
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
  branch: PropTypes.oneOfType([PropTypes.object]),
};

const mapDispatchToProps = {
  doGetOutbound: getOutbound,
  doGetBranchGates: getBranchGates,
  doGetVehiclesLike: getVehiclesLike,
  doGetHaulerSelect: getHaulerSelect,
  doUpdateOutboundDetails: updateOutboundDetails,
  doFinalizeOutbound: finalizeOutbound,
  doExportOutbound: exportOutbound,
  doCancelOutbound: cancelOutbound,
  doPrintOutbound: printOutbound,
  doUploadOutboundFiles: uploadOutboundFiles,
  doDownloadOutboundFile: downloadOutboundFile,
  doDeleteOutboundFile: deleteOutboundFile,
};

const mapStateToProps = state => ({
  branch: state.auth.branch,
  userId: state.auth.user.sub,
  userRole: state.auth.user.role,
});

const WrappedOutboundView = Form.create({ name: 'OutboundView' })(OutboundView);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WrappedOutboundView));
