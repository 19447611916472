import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import auth from '../pages/auth/ducks';
import inbound from '../pages/inbound/ducks';
import outbound from '../pages/outbound/ducks';

export const history = createBrowserHistory();
const router = connectRouter(history);

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['router', 'register', 'user'],
};

export default persistReducer(
  persistConfig,
  combineReducers({
    router,
    auth,
    inbound,
    outbound,
  }),
);
