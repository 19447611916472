import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Col, Form, Input, Button, Icon, DatePicker, message, Spin,
} from 'antd';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle, faPlus } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import Row from '../../components/Row';
import PageHeader from '../../components/PageHeader';
import Card from '../../components/Card';
import Container from '../../components/Container';
import { create, findPackage } from './ducks';

let id = 0;

class IncidentReportCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      isLoading: true,
    };

    this.addInputField = this.addInputField.bind(this);
    this.removeInputField = this.removeInputField.bind(this);
    this.submitReport = this.submitReport.bind(this);
    this.packageValidate = this.packageValidate.bind(this);
    this.packageDuplicate = this.packageDuplicate.bind(this);
  }

  componentDidMount() {
    this.addInputField();
    this.setState({
      isLoading: false,
    });
  }

  addInputField() {
    const { form } = this.props;
    // can use data-binding to get
    const keys = form.getFieldValue('keys');
    const nextKeys = keys.concat(id += 1);
    // can use data-binding to set
    // important! notify form to detect changes
    form.setFieldsValue({
      keys: nextKeys,
    });
  }

  removeInputField(item) {
    const { form } = this.props;
    // can use data-binding to get
    const keys = form.getFieldValue('keys');
    // We need at least one passenger
    if (keys.length === 1) {
      return;
    }

    // can use data-binding to set
    form.setFieldsValue({
      keys: keys.filter(key => key !== item),
    });
  }

  submitReport(e) {
    e.preventDefault();

    // eslint-disable-next-line no-unused-vars
    const {
      form, doCreate, history, branch,
    } = this.props;

    const { validateFields } = form;

    validateFields((err, values) => {
      if (!err) {
        const packageData = [];
        const duplicates = values.tracking_number.filter(
          (item, index) => values.tracking_number.indexOf(item) !== index,
        );

        if (duplicates.length === 0) {
          values.tracking_number.forEach((TN, index) => {
            packageData.push({
              tracking_number: TN,
              description: values.package_description[index],
            });
          });

          const params = {
            packages: [
              ...packageData,
            ],
            description: values.incident_description,
            incident_report_date: moment(values.incident_date).format('YYYY-MM-DD HH:mm:ss'),
            branch_id: branch.id,
          };


          doCreate(params).then((action) => {
            history.push(`/incidentReport/view/${action.payload.data}`);
          }).catch((action) => {
            message.error(action.error.response.data.message);
          });
        } else {
          message.error('You have entered tracking numbers of the same number!');
        }
      }
    });
  }

  // eslint-disable-next-line no-unused-vars
  packageValidate(rule, value, callback) {
    const { doFindPackage, branch } = this.props;

    this.setState({
      isFetching: true,
    });

    doFindPackage(value, branch.id)
      .then(res => (res.payload.data.exists
        ? callback() : callback('Package does not exist.')));
  }

  packageDuplicate(rule, value, callback) {
    const { form } = this.props;

    const keys = form.getFieldValue('tracking_number');

    this.setState({
      isFetching: true,
    });

    const duplicates = keys.filter(
      (item, index) => keys.indexOf(item) !== index && typeof item !== 'undefined',
    );

    if (duplicates.length > 0) {
      callback('You have already added this tracking number.');
    } else {
      callback();
    }
  }

  render() {
    const { isFetching, isLoading } = this.state;
    const { form } = this.props;
    const { getFieldDecorator, getFieldValue } = form;

    getFieldDecorator('keys', { initialValue: [] });

    const keys = getFieldValue('keys');
    const formItemLayout = {
      labelCol: {
        lg: { span: 6 },
      },
      wrapperCol: {
        lg: { span: 18 },
      },
    };
    // eslint-disable-next-line no-unused-vars
    const formItems = keys.map((item, index) => (
      <Row key={item}>
        <Col xs={24} sm={24} lg={12}>
          <Form.Item
            label="Tracking Number"
            key={item}
            hasFeedback={isFetching}
            {...formItemLayout}
          >
            {getFieldDecorator(`tracking_number[${item}]`, {
              rules: [
                {
                  required: true,
                  message: 'Tracking number is required.',
                },
                {
                  validator: this.packageValidate,
                },
                {
                  validator: this.packageDuplicate,
                },
              ],
            })(<Input autoComplete="off" placeholder="E.g XYZ" style={{ width: '80%' }} />)}

          </Form.Item>
        </Col>
        <Col xs={24} sm={24} lg={12}>
          <Form.Item label="Description" key={item} {...formItemLayout}>
            {getFieldDecorator(`package_description[${item}]`, {
              rules: [
                {
                  required: false,
                  message: 'Package description is required.',
                },
              ],
            })(<Input autoComplete="off" placeholder="E.g Missing" style={{ width: '80%' }} />)}

            {keys.length > 1 ? (

              <Icon
                viewBox="0 0 1024 1024"
                onClick={() => this.removeInputField(item)}
                style={{ marginLeft: '10px' }}
              >
                <FontAwesomeIcon icon={faMinusCircle} fixedWidth />
              </Icon>

            ) : null}

          </Form.Item>
        </Col>

      </Row>
    ));


    return (
      <div className="IncidentReportCreate">
        <Spin spinning={isLoading} tip="Generating form . . .">
          <PageHeader title="Incident Report" />
          <Container>
            <Card title="Create Report">
              <Form onSubmit={this.submitReport}>
                <Row>
                  <Form.Item label="Incident Description">
                    {getFieldDecorator('incident_description', {
                      rules: [
                        {
                          required: true,
                          message: 'Tracking number is required.',
                        },
                      ],
                    })(<Input.TextArea autoComplete="off" placeholder="E.g XYZ" />)}
                  </Form.Item>
                  <Form.Item label="Incident Date">
                    {getFieldDecorator('arrival_time', {
                      rules: [{ required: true, message: 'Arrival time cannot be blank' }],
                    })(<DatePicker
                      format="YYYY-MM-DD HH:mm:ss"
                      showTime={{ dateFormat: 'HH:mm' }}
                      disabledDate={date => new Date() <= date}
                      style={{ width: '100%' }}
                    />)}
                  </Form.Item>
                </Row>
                {formItems}
                <Row>
                  <center>
                    <Form.Item>
                      <Button type="dashed" onClick={this.addInputField} style={{ width: '60%' }}>
                        <Icon viewBox="0 0 1024 1024">
                          <FontAwesomeIcon icon={faPlus} fixedWidth />
                        </Icon>
                      Add Tracking Number
                      </Button>
                    </Form.Item>
                  </center>
                </Row>
                <Row>
                  <Button type="primary" htmlType="submit" block>
                    <Icon viewBox="0 0 1024 1024">
                      <FontAwesomeIcon icon={faPlus} fixedWidth />
                    </Icon>
                        Create
                  </Button>
                </Row>
              </Form>
            </Card>
          </Container>
        </Spin>
      </div>
    );
  }
}

IncidentReportCreate.propTypes = {
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
  branch: PropTypes.oneOfType([PropTypes.object]).isRequired,
  history: PropTypes.oneOfType([PropTypes.object]).isRequired,
  doCreate: PropTypes.func.isRequired,
  doFindPackage: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  doCreate: create,
  doFindPackage: findPackage,
};

const mapStateToProps = state => ({
  branch: state.auth.branch,
});

const WrappedIncidentReportCreate = Form.create({ name: 'IncidentReportCreate' })(IncidentReportCreate);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WrappedIncidentReportCreate);
