import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import {
  Button, Icon,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import PageHeader from '../../components/PageHeader';
import Card from '../../components/Card';
import Container from '../../components/Container';
import ServerSideTable from '../../components/ServerSideTable';
import { getInboundList } from './ducks';


class InboundList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inboundList: {
        rows: [],
        pages: 0,
        total: 0,
      },
      isFetching: false,
      fetchMessage: null,
    };

    this.onFetchData = this.onFetchData.bind(this);
  }

  // eslint-disable-next-line no-unused-vars
  onFetchData(state, instance) {
    const { doFetchInboundList, branch } = this.props;

    const params = {
      page: state.page + 1,
      pageSize: state.pageSize,
      filters: state.filtered,
      sorts: state.sorted,
      branchId: branch.id,
    };

    this.setState({
      isFetching: true,
      fetchMessage: 'Fetching inbound list . . .',
    });

    doFetchInboundList(params).then((action) => {
      this.setState({
        inboundList: {
          rows: action.payload.data.data,
          pages: action.payload.data.last_page,
          total: action.payload.data.total,
        },
        isFetching: false,
        fetchMessage: null,
      });
    }).catch(() => {

    });
  }

  render() {
    const {
      inboundList,
      isFetching, fetchMessage,
    } = this.state;

    return (
      <div className="InboundList">
        <PageHeader title="Inbound" />
        <Container>
          <Card title={`Inbound List (Total: ${inboundList.total})`}>
            <ServerSideTable
              data={inboundList.rows}
              pages={inboundList.pages}
              columns={[
                {
                  Header: 'Id',
                  accessor: 'id',
                },
                {
                  Header: 'Gate',
                  accessor: 'gate_number',
                },
                {
                  Header: 'Hauler',
                  accessor: 'hauler',
                },
                {
                  Header: 'Truck Batch',
                  accessor: 'truck_batch',
                },
                {
                  Header: 'Created By',
                  accessor: 'created_by',
                },
                {
                  Header: 'Start',
                  accessor: 'created_at',
                },
                {
                  Header: 'End',
                  accessor: 'end',
                },
                {
                  Header: 'Attached Files',
                  accessor: 'file_count',
                },
                {
                  Header: 'Status',
                  accessor: 'status',
                  Filter: ({ filter, onChange }) => (
                    <select
                      onChange={event => onChange(event.target.value)}
                      value={filter ? filter.value : 'All'}
                      style={{ width: '100%' }}
                    >
                      <option value="">All</option>
                      <option value="fresh">fresh</option>
                      <option value="modified">modified</option>
                      <option value="finalized">finalized</option>
                      <option value="cancelled">cancelled</option>
                    </select>
                  ),
                },
                {
                  Header: 'Options',
                  sortable: false,
                  filterable: false,
                  Cell: data => (
                    <center>
                      <NavLink to={`/inbound/${data.row.id}`}>
                        <Button type="link">
                          <Icon viewBox="0 0 1024 1024">
                            <FontAwesomeIcon icon={faEye} fixedWidth />
                          </Icon>
                        View
                        </Button>
                      </NavLink>
                    </center>
                  ),
                },
              ]}
              loadingText={fetchMessage}
              loading={isFetching}
              onFetchData={this.onFetchData}
            />
          </Card>
        </Container>
      </div>
    );
  }
}

InboundList.propTypes = {
  doFetchInboundList: PropTypes.func.isRequired,
  branch: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const mapDispatchToProps = {
  doFetchInboundList: getInboundList,
};

const mapStateToProps = state => ({
  branch: state.auth.branch,
});

export default connect(mapStateToProps, mapDispatchToProps)(InboundList);
