import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Result, Spin } from 'antd';

import { logout } from './ducks';

class Logout extends Component {
  componentDidMount() {
    const { doLogout } = this.props;
    doLogout();
  }

  render() {
    return (
      <Result
        icon={<Spin size="large" />}
        title="Logging out..."
      />
    );
  }
}

const mapDispatchToProps = {
  doLogout: logout,
};

Logout.propTypes = {
  doLogout: PropTypes.func.isRequired,
};

export default connect(
  null,
  mapDispatchToProps,
)(Logout);
