import moment from 'moment';
import {
  apiGetOutbounds,
  apiAddOutbound,
  apiUpdateOutbound,
  apiGetOutbound,
  apiValidateOutboundPackage,
  apiGetOutboundPackageDetails,
  apiAddOutboundPackages,
  apiFinalizeOutbound,
  apiExportOutbound,
  apiCancelOutbound,
  apiPrintOutbound,
  apiUploadOutboundFiles,
  apiDownloadOutboundFile,
  apiDeleteOutboundFile,
} from '../../services/apiEndpoints';

export const FETCH_OUTBOUND_LIST = 'outbound/FETCH_OUTBOUND_LIST';
export const FETCH_OUTBOUND_LIST_SUCCESS = 'outbound/FETCH_OUTBOUND_LIST_SUCCESS';
export const geOutboundList = params => ({
  type: FETCH_OUTBOUND_LIST,
  payload: {
    client: 'secure',
    request: {
      ...apiGetOutbounds,
      params: {
        ...params,
        filters: JSON.stringify(params.filters),
        sorts: JSON.stringify(params.sorts),
      },
    },
  },
});
export const ADD_OUTBOUND = 'outbound/ADD_OUTBOUND';
export const ADD_OUTBOUND_SUCCESS = 'outbound/ADD_OUTBOUND_SUCCESS';
export const addOutbound = (params, branch) => ({
  type: ADD_OUTBOUND,
  payload: {
    client: 'secure',
    request: {
      ...apiAddOutbound,
      data: {
        ...params,
        location: branch,
      },
    },
  },
});

export const GET_OUTBOUND = 'outbound/GET_OUTBOUND';
export const GET_OUTBOUND_SUCCESS = 'outbound/GET_OUTBOUND_SUCCESS';
export const getOutbound = (path, branch, deletedOnly) => ({
  type: GET_OUTBOUND,
  payload: {
    client: 'secure',
    request: {
      ...apiGetOutbound,
      url: path,
      params: {
        branch_id: branch.id,
        deleted_only: deletedOnly,
      },
    },
  },
});

export const UPDATE_OUTBOUND_DETAILS = 'outbound/UPDATE_OUTBOUND_DETAILS';
export const updateOutboundDetails = (path, details) => ({
  type: UPDATE_OUTBOUND_DETAILS,
  payload: {
    client: 'secure',
    request: {
      ...apiUpdateOutbound,
      url: path,
      data: details,
    },
  },
});

export const VALIDATE_PACKAGE = 'outbound/VALIDATE_PACKAGE';
export const validatePackage = data => ({
  type: VALIDATE_PACKAGE,
  payload: {
    client: 'secure',
    request: {
      ...apiValidateOutboundPackage,
      data,
    },
  },
});

export const GET_PACKAGE_DETAILS = 'outbound/GET_PACKAGE_DETAILS';
export const getPackageDetails = (trackingNumber, outboundId) => ({
  type: GET_PACKAGE_DETAILS,
  payload: {
    client: 'secure',
    request: {
      ...apiGetOutboundPackageDetails,
      params: trackingNumber,
      id: outboundId,
    },
  },
});

export const SAVE_PACKAGE_TO_STATE = 'outbound/SAVE_PACKAGE_TO_STATE';
export const savePackageToState = (id, scannedPackages) => ({
  type: SAVE_PACKAGE_TO_STATE,
  payload: {
    data: {
      outboundId: id,
      scannedPackages,
    },
  },
});

export const DELETE_PACKAGE_FROM_STATE = 'outbound/DELETE_PACKAGE_FROM_STATE';
export const deletePackageFromState = (id, scannedPackages) => ({
  type: DELETE_PACKAGE_FROM_STATE,
  payload: {
    data: {
      outboundId: id,
      scannedPackages,
    },
  },
});

export const ADD_OUTBOUND_PACKAGES = 'outbound/ADD_OUTBOUND_PACKAGES';
export const ADD_OUTBOUND_PACKAGES_SUCCESS = 'outbound/ADD_OUTBOUND_PACKAGES_SUCCESS';
export const addOutboundPackages = (path, packageList, deletedPackages, branchId) => ({
  type: ADD_OUTBOUND_PACKAGES,
  payload: {
    client: 'secure',
    request: {
      ...apiAddOutboundPackages,
      url: path,
      data: {
        create: packageList,
        update: [],
        delete: deletedPackages,
        branch_id: branchId,
        origin: null,
      },
    },
  },
});

const FINALIZE_OUTBOUND = '/outbound/FINALIZE_OUTBOUND';
export const finalizeOutbound = outboundId => ({
  type: FINALIZE_OUTBOUND,
  payload: {
    client: 'secure',
    request: {
      ...apiFinalizeOutbound,
      url: `/outbound/${outboundId}/finalize`,
    },
  },
});

const EXPORT_OUTBOUND = '/outbound/EXPORT_OUTBOUND';
export const exportOutbound = outboundId => ({
  type: EXPORT_OUTBOUND,
  payload: {
    client: 'secure',
    request: {
      ...apiExportOutbound,
      responseType: 'arraybuffer',
      url: `/outbound/export/${outboundId}`,
    },
  },
});

export const CANCEL_OUTBOUND = '/outbound/CANCEL_OUTBOUND';
export const CANCEL_OUTBOUND_SUCCESS = '/outbound/CANCEL_OUTBOUND_SUCCESS';
export const cancelOutbound = id => ({
  type: CANCEL_OUTBOUND,
  payload: {
    client: 'secure',
    outboundId: id,
    request: {
      ...apiCancelOutbound,
      url: `/outbound/${id}`,
    },
  },
});

const PRINT_OUTBOUND = '/outbound/PRINT_OUTBOUND';
export const printOutbound = id => ({
  type: PRINT_OUTBOUND,
  payload: {
    client: 'secure',
    request: {
      ...apiPrintOutbound,
      url: `/outbound/${id}/print`,
    },
  },
});

const UPLOAD_OUTBOUND_FILES = '/outbound/UPLOAD_OUTBOUND_FILES';
export const uploadOutboundFiles = (id, files) => ({
  type: UPLOAD_OUTBOUND_FILES,
  payload: {
    client: 'secure',
    request: {
      ...apiUploadOutboundFiles(id, files),
      data: files,
    },
  },
});

const DOWNLOAD_OUTBOUND_FILE = '/outbound/DOWNLOAD_OUTBOUND_FILE';
export const downloadOutboundFile = id => ({
  type: DOWNLOAD_OUTBOUND_FILE,
  payload: {
    client: 'secure',
    request: {
      ...apiDownloadOutboundFile(id),
    },
  },
});

const DELETE_OUTBOUND_FILE = '/outbound/DELETE_OUTBOUND_FILE';
export const deleteOutboundFile = id => ({
  type: DELETE_OUTBOUND_FILE,
  payload: {
    client: 'secure',
    request: {
      ...apiDeleteOutboundFile(id),
    },
  },
});

export const UPDATE_OUTBOUNDS_FROM_STATE = '/outbound/UPDATE_OUTBOUNDS_FROM_STATE';

const initialState = {};
function reducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_PACKAGE_TO_STATE:
      return {
        ...state,
        [action.payload.data.outboundId]: {
          scannedPackages: action.payload.data.scannedPackages,
          lastPackageScanDate: moment().format('YYYY-MM-DD HH:mm:ss'),
        },
      };
    case DELETE_PACKAGE_FROM_STATE:
      return {
        ...state,
        [action.payload.data.outboundId]: {
          scannedPackages: action.payload.data.scannedPackages,
        },
      };
    case ADD_OUTBOUND_PACKAGES_SUCCESS: {
      const modifiedState = state;
      delete modifiedState[action.payload.data.id];
      return {
        ...modifiedState,
      };
    }
    case CANCEL_OUTBOUND_SUCCESS: {
      const modifiedState = state;
      delete modifiedState[state.outboundId];
      delete modifiedState.outboundId;
      return {
        ...modifiedState,
      };
    }
    case CANCEL_OUTBOUND:
      return {
        ...state,
        outboundId: action.payload.outboundId,
      };
    case UPDATE_OUTBOUNDS_FROM_STATE:
      return {
        ...action.payload.outbounds,
      };
    default:
      return state;
  }
}
export default reducer;
