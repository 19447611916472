import {
  apiValidateInboundReprocessPackage,
  apiSaveInboundReprocessPackages,
  apiGetInboundReprocess,
  apiGetInboundReprocessDetails,
  apieFinalizeInboundReprocess,
  apiExportInboundReprocess,
  apiGetRemarksOption,
  apiSaveInboundReprocessPackagesById,
  apiCancelInboundReprocess,
  apiPrintInboundReprocess,
}
  from '../../services/apiEndpoints';

export const GET_REMARKS_OPTION = 'inbound/GET_REMARKS_OPTION';
export const getRemarks = () => ({
  type: GET_REMARKS_OPTION,
  payload: {
    client: 'secure',
    request: {
      ...apiGetRemarksOption,
    },
  },
});

export const VALIDATE_PACKAGE = 'inboundReprocess/VALIDATE_PACKAGE';
export const validatePackage = trackingNumber => ({
  type: VALIDATE_PACKAGE,
  payload: {
    client: 'secure',
    request: {
      ...apiValidateInboundReprocessPackage,
      data: trackingNumber,
    },
  },
});

export const FETCH_INBOUND_REPROCESS_LIST = 'inboundReprocess/FETCH_INBOUND_REPROCESS_LIST';
export const getInboundReprocessList = params => ({
  type: FETCH_INBOUND_REPROCESS_LIST,
  payload: {
    client: 'secure',
    request: {
      ...apiGetInboundReprocess,
      params: {
        ...params,
        filters: JSON.stringify(params.filters),
        sorts: JSON.stringify(params.sorts),
      },
    },
  },
});

export const FETCH_INBOUND_REPROCESS_DETAILS = 'inboundReprocess/FETCH_INBOUND_REPROCESS_DETAILS';
export const getInboundReprocessDetails = (path, branch, deletedOnly) => ({
  type: FETCH_INBOUND_REPROCESS_DETAILS,
  payload: {
    client: 'secure',
    request: {
      ...apiGetInboundReprocessDetails,
      url: path,
      params: {
        branch_id: branch.id,
        deleted_only: deletedOnly,
      },
    },
  },
});

export const SAVE_PACKAGES = 'inboundReprocess/SAVE_PACKAGES';
export const storePackages = details => ({
  type: SAVE_PACKAGES,
  payload: {
    client: 'secure',
    request: {
      ...apiSaveInboundReprocessPackages,
      data: details,
    },
  },
});

export const SAVE_PACKAGES_BY_ID = 'inboundReprocess/SAVE_PACKAGES_BY_ID';
export const storePackageById = (details, path) => ({
  type: SAVE_PACKAGES,
  payload: {
    client: 'secure',
    request: {
      ...apiSaveInboundReprocessPackagesById,
      url: path,
      data: details,
    },
  },
});

export const FINALIZE_MOVEMENT = 'inboundReprocess/FINALIZE_MOVEMENT';
export const finalizeMovement = path => ({
  type: SAVE_PACKAGES,
  payload: {
    client: 'secure',
    request: {
      ...apieFinalizeInboundReprocess,
      url: `${path}/finalize`,
    },
  },
});
const EXPORT_INBOUND_REPROCESS = '/inbound_reprocess/EXPORT_INBOUND_REPROCESS';
export const exportInboundReprocess = inboundReprocessId => ({
  type: EXPORT_INBOUND_REPROCESS,
  payload: {
    client: 'secure',
    request: {
      ...apiExportInboundReprocess,
      responseType: 'arraybuffer',
      url: `/reprocess/inbound/export/${inboundReprocessId}`,
    },
  },
});

const CANCEL_INBOUND_REPROCESS = '/inbound_reprocess/CANCEL_INBOUND_REPROCESS';
export const cancelInboundReprocess = $id => ({
  type: CANCEL_INBOUND_REPROCESS,
  payload: {
    client: 'secure',
    request: {
      ...apiCancelInboundReprocess,
      url: `/reprocess/inbound/${$id}`,
    },
  },
});

const PRINT_INBOUND_REPROCESS = '/inbound_reprocess/PRINT_INBOUND_REPROCESS';
export const printInboundReprocess = id => ({
  type: PRINT_INBOUND_REPROCESS,
  payload: {
    client: 'secure',
    request: {
      ...apiPrintInboundReprocess,
      url: `/reprocess/inbound/${id}/print`,
    },
  },
});

const initialState = {};

function reducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}

export default reducer;
