import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Form, Col, Input, Button, Icon, Popconfirm, message,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import Row from '../../components/Row';
import PageHeader from '../../components/PageHeader';
import Card from '../../components/Card';
import Container from '../../components/Container';
import ReactTable from '../../components/ServerSideTable';
import Spacer from '../../components/Spacer';
import {
  addMerchant, getMerchant, updateMerchant, deleteMerchant,
} from './ducks';


class Merchant extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      isEditable: false,
      editableRow: null,
      editedMerchant: null,
      fetchMessage: '',
      merchantList: {
        rows: [],
        pages: 0,
        total: 0,
      },
      currentTable: {
        page: null,
        pageSize: null,
        filters: [],
        sorts: [],
      },
    };

    this.formSubmit = this.formSubmit.bind(this);
    this.onFetchData = this.onFetchData.bind(this);
    this.setToEdit = this.setToEdit.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this.onUpdateSubmit = this.onUpdateSubmit.bind(this);
    this.cancelEdit = this.cancelEdit.bind(this);
  }

  onFetchData(state) {
    const { doGetMerchant } = this.props;

    const params = {
      page: state.page + 1,
      pageSize: state.pageSize,
      filters: state.filtered,
      sorts: state.sorted,
    };

    this.setState({
      currentTable: params,
      isFetching: true,
      isEditable: false,
      editableRow: null,
      editedMerchant: null,
      fetchMessage: 'Fetching merchant list . . .',
    });

    doGetMerchant(params).then((action) => {
      this.setState({
        merchantList: {
          rows: action.payload.data.data,
          pages: action.payload.data.last_page,
          total: action.payload.data.total,
        },
        isFetching: false,
        fetchMessage: null,
      });
    // eslint-disable-next-line no-unused-vars
    }).catch((action) => {
      this.setState({
        isFetching: false,
        fetchMessage: null,
      });
    });
  }

  onEdit(cellInfo) {
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const { merchantList, editableRow } = this.state;
    if (editableRow === cellInfo.index) {
      return (
        <Form.Item style={{ margin: '0px' }}>
          {getFieldDecorator(`${cellInfo.column.id}_update`, {
            rules: [{ required: true, message: `${cellInfo.column.Header} is required.` }],
            initialValue: merchantList.rows[cellInfo.index][cellInfo.column.id],
          })(<Input />)}
        </Form.Item>
      );
    }

    return (
      <div>
        { merchantList.rows[cellInfo.index][cellInfo.column.id] }
      </div>
    );
  }

  onUpdateSubmit(e) {
    e.preventDefault();
    const { form, doUpdateMerchant } = this.props;
    const { validateFields } = form;
    const { editedMerchant } = this.state;

    validateFields(['name_update', 'contact_update', 'address_update'], (err, values) => {
      const data = {
        name: values.name_update,
        contact: values.contact_update,
        address: values.address_update,
      };
      if (!err) {
        doUpdateMerchant(data, editedMerchant).then(() => {
          const { currentTable } = this.state;

          const params = {
            page: currentTable.page - 1,
            pageSize: currentTable.pageSize,
            filtered: currentTable.filters,
            sorted: currentTable.sorts,
          };

          this.setState({
            editableRow: null,
            isEditable: false,
            editedMerchant: null,
          });

          message.success('Successfully updated merchant!', 3);

          this.onFetchData(params);
        }).catch((action) => {
          let errorMessage;
          if (action.error.response.status === 422) {
            const index = Object.values(action.error.response.data);
            // eslint-disable-next-line prefer-destructuring
            errorMessage = index[0][0];
          } else {
            errorMessage = 'Something went wrong, please try again later.';
          }
          message.error(errorMessage, 3);
        });
      }
    });
  }

  setToEdit(rowId, merchantId) {
    this.setState({
      isEditable: true,
      editableRow: rowId,
      editedMerchant: merchantId,
    });
  }

  cancelEdit() {
    this.setState({
      editableRow: null,
      isEditable: false,
      editedMerchant: null,
    });
  }

  formSubmit() {
    const { doAddMerchant, form } = this.props;
    const { validateFields } = form;
    validateFields(['name', 'contact', 'address'], (err, values) => {
      if (!err) {
        doAddMerchant(values).then(() => {
          const { currentTable } = this.state;

          const params = {
            page: currentTable.page - 1,
            pageSize: currentTable.pageSize,
            filtered: currentTable.filters,
            sorted: currentTable.sorts,
          };

          this.setState({
            editableRow: null,
            isEditable: false,
            editedMerchant: null,
          });

          message.success('Successfully created merchant!', 3);
          this.onFetchData(params);

        // eslint-disable-next-line no-unused-vars
        }).catch((action) => {
          let errorMessage;
          if (action.error.response.status === 422) {
            const index = Object.values(action.error.response.data);
            // eslint-disable-next-line prefer-destructuring
            errorMessage = index[0][0];
          } else {
            errorMessage = 'Something went wrong, please try again later.';
          }
          message.error(errorMessage, 3);
        });
      }
    });
  }

  confirmDelete(merchantId) {
    const { doDeleteMerchant } = this.props;
    doDeleteMerchant(merchantId).then((action) => {
      if (action.payload.data) {
        const { currentTable } = this.state;
        const params = {
          page: currentTable.page - 1,
          pageSize: currentTable.pageSize,
          filtered: currentTable.filters,
          sorted: currentTable.sorts,
        };
        this.setState({
          editableRow: null,
          isEditable: false,
          editedMerchant: null,
        });
        message.success('Successfully deleted merchant!', 3);
        this.onFetchData(params);
      } else {
        message.error('Unable to delete data that has 1 or more reference(s).', 3);
      }
    }).catch(() => {
      message.error('Something went wrong, please try agian later.', 3);
    });
  }


  render() {
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const {
      merchantList, isEditable, editableRow, fetchMessage, isFetching,
    } = this.state;

    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
    };
    const buttonItemLayout = {
      wrapperCol: { span: 18 },
    };
    return (
      <div className="merchant">
        <PageHeader title="Merchant" />
        <Container>
          <Card title="Create Merchant">
            <Form layout="horizontal">
              <Row>
                <Col xs={24} sm={12} lg={6}>
                  <Form.Item label="Name" {...formItemLayout}>
                    {getFieldDecorator('name', {
                      rules: [{ required: true, message: 'Name cannot be blank.' }],
                    })(<Input />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={6}>
                  <Form.Item label="Address" {...formItemLayout}>
                    {getFieldDecorator('address', {
                      rules: [{ required: false, message: 'Address cannot be blank.' }],
                    })(<Input />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={6}>
                  <Form.Item label="Contact" {...formItemLayout}>
                    {getFieldDecorator('contact',
                      {
                        rules: [
                          { required: false, message: 'Contact cannot be blank.' },
                          { pattern: '^[0-9]*$', message: 'Contact must contain numbers only' },
                        ],
                      })(<Input />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={6}>
                  <Form.Item {...buttonItemLayout}>
                    <Button type="primary" onClick={this.formSubmit} block>
                      <Icon viewBox="0 0 1024 1024">
                        <FontAwesomeIcon icon="plus" fixedWidth />
                      </Icon>
                    Create
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
          <Spacer />
          <Card title="Merchant List">
            <Form onSubmit={this.onUpdateSubmit}>
              <ReactTable
                data={merchantList.rows}
                pages={merchantList.pages}
                columns={[
                  {
                    Header: 'Id',
                    accessor: 'id',
                  },
                  {
                    Header: 'Name',
                    accessor: 'name',
                    Cell: this.onEdit,
                  },
                  {
                    Header: 'Address',
                    accessor: 'address',
                    Cell: this.onEdit,
                  },
                  {
                    Header: 'Contact',
                    accessor: 'contact',
                    Cell: this.onEdit,
                  },
                  {
                    id: 'created_by',
                    Header: 'Created by',
                    accessor: data => data.user.full_name,
                  },
                  {
                    Header: 'Options',
                    sortable: false,
                    filterable: false,
                    Cell: data => (
                      <Row>
                        {
                     (!isEditable || data.index !== editableRow)
                       ? (
                         <Row>
                           <Col span={12}>
                             <Button type="link" onClick={() => this.setToEdit(data.index, data.row.id)} block>
                               <Icon type="edit" />
                                Edit
                             </Button>
                           </Col>
                           <Col span={12}>
                             <Popconfirm
                               placement="leftBottom"
                               title={`Are you sure you want to delete ${data.row.name} from the list?`}
                               okText="Yes"
                               onConfirm={() => this.confirmDelete(data.row.id)}
                               cancelText="No"
                             >
                               <Button type="link" block>
                                 <Icon type="delete" />
                                  Delete
                               </Button>
                             </Popconfirm>
                           </Col>
                         </Row>
                       )
                       : (
                         <div>
                           <Col span={12}>
                             <Button type="link" htmlType="submit" block>
                               <Icon type="save" />
                               Save
                             </Button>
                           </Col>

                           <Col span={12}>
                             <Button type="link" onClick={this.cancelEdit} block>
                               <Icon type="close" />
                               Cancel
                             </Button>
                           </Col>
                         </div>
                       )
                      }
                      </Row>
                    ),
                  },
                ]}

                loadingText={fetchMessage}
                loading={isFetching}
                onFetchData={this.onFetchData}
              />
            </Form>
          </Card>

        </Container>
      </div>
    );
  }
}

Merchant.propTypes = {
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
  doAddMerchant: PropTypes.func.isRequired,
  doGetMerchant: PropTypes.func.isRequired,
  doUpdateMerchant: PropTypes.func.isRequired,
  doDeleteMerchant: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  doAddMerchant: addMerchant,
  doGetMerchant: getMerchant,
  doUpdateMerchant: updateMerchant,
  doDeleteMerchant: deleteMerchant,
};


const WrappedMerchant = Form.create({ name: 'Merchant' })(Merchant);
export default connect(null, mapDispatchToProps)(WrappedMerchant);
