import {
  apiAddVehicle, apiGetVehicles, apiUpdateVehicle,
  apiDeleteVehicle, apiGetVehicleType, apiGetVehiclesLike, apiGetTruckTypesOption,
} from '../../services/apiEndpoints';

export const ADD_VEHICLE = 'vehicle/ADD_VEHICLE';
export const addVehicle = vehicle => ({
  type: ADD_VEHICLE,
  payload: {
    client: 'secure',
    request: {
      ...apiAddVehicle,
      data: vehicle,
    },
  },
});

export const GET_VEHICLES = 'vehicle/GET_VEHICLES';
export const getVehicles = data => ({
  type: GET_VEHICLES,
  payload: {
    client: 'secure',
    request: {
      ...apiGetVehicles,
      params: {
        ...data,
        filters: JSON.stringify(data.filters),
        sorts: JSON.stringify(data.sorts),
      },
    },
  },
});

export const UPDATE_VEHICLES = 'vehicle/UPDATE_VEHICLES';
export const updateVehicle = (params, vehicleId) => ({
  type: UPDATE_VEHICLES,
  payload: {
    client: 'secure',
    request: {
      ...apiUpdateVehicle,
      url: `/vehicle/${vehicleId}`,
      params,
    },
  },
});

export const GET_VEHICLE_TYPES = 'vehicle/GET_VEHICLE_TYPES';
export const getVehicleType = type => ({
  type: GET_VEHICLE_TYPES,
  payload: {
    client: 'secure',
    request: {
      ...apiGetVehicleType,
      url: `/vehicle/${type}`,
    },
  },
});
export const GET_VEHICLE_LIKE = 'vehicle/GET_VEHICLE_LIKE';
export const getVehiclesLike = plate => ({
  type: GET_VEHICLE_TYPES,
  payload: {
    client: 'secure',
    request: {
      ...apiGetVehiclesLike,
      url: `/vehicle/like/${plate}`,
    },
  },
});


export const DELETE_VEHICLES = 'vehicle/DELETE_VEHICLES';
export const deletVehicle = vehicleId => ({
  type: ADD_VEHICLE,
  payload: {
    client: 'secure',
    request: {
      ...apiDeleteVehicle,
      url: `/vehicle/${vehicleId}`,
    },
  },
});


export const GET_TRUCK_TYPES = 'vehicle/GET_TRUCK_TYPES';
export const getTruckTypes = () => ({
  type: ADD_VEHICLE,
  payload: {
    client: 'secure',
    request: {
      ...apiGetTruckTypesOption,
    },
  },
});

const initialState = {};

function reducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}

export default reducer;
