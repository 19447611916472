import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Layout, Menu, Icon, BackTop, Popover, Button,
} from 'antd';
import {
  withRouter, NavLink, Switch, Redirect, Route,
} from 'react-router-dom';
import PerfectScrollbar from '@opuscapita/react-perfect-scrollbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import sidebarItems from '../navigation/sidebarItems';
import appRoutes from '../navigation/appRoutes';
import MissingPage from '../pages/errors/Missing';
import RouteComponent from '../components/routes/RouteComponent';

const {
  Header, Sider, Content, Footer,
} = Layout;
const { SubMenu, Item, ItemGroup } = Menu;

class MainLayout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: false,
    };

    this.getOpenKey = this.getOpenKey.bind(this);
  }

  /**
   * Get what sidebar item root to open
   */
  getOpenKey() {
    const { location } = this.props;
    const { pathname } = location;
    const path = pathname.split('/');
    if (path.length > 2) {
      return path[1];
    }
    return null;
  }

  render() {
    const { collapsed } = this.state;
    const { location, user, branch } = this.props;
    const { pathname } = location;

    return (
      <Layout className="app-outer-layout">
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          collapsedWidth={0}
          width={220}
          className="app-sider"
        >
          <div className="app-logo" style={{ padding: '16px 40px' }}>
            <img src="/img/logo-colored.png" width={collapsed ? 0 : 110} alt="Logo" />
          </div>
          <PerfectScrollbar>
            <Menu
              mode="inline"
              className="app-menu"
              defaultSelectedKeys={[pathname]}
              defaultOpenKeys={[this.getOpenKey()]}
            >
              { Object.keys(sidebarItems).map((group) => {
                if (!sidebarItems[group].roles.includes(user.role)) {
                  return null;
                }

                // Render menu links
                const renderedItems = sidebarItems[group].routes.map((route) => {
                  if (!route.roles.includes(user.role)) {
                    return null;
                  }
                  if (route.branch && !route.branch.includes(branch.id)) {
                    return null;
                  }
                  // Render menu link directly
                  if (route.path) {
                    return (
                      <Item key={route.key}>
                        <NavLink to={route.path}>
                          <Icon viewBox="0 0 1024 1024">
                            <FontAwesomeIcon icon={route.icon} fixedWidth />
                          </Icon>
                          <span>
                            {route.text}
                          </span>
                        </NavLink>
                      </Item>
                    );
                  }

                  return (
                    <SubMenu
                      key={route.key}
                      /* eslint-disable react/jsx-wrap-multilines */
                      title={
                        <span>
                          <Icon viewBox="0 0 1024 1024">
                            <FontAwesomeIcon icon={route.icon} fixedWidth />
                          </Icon>
                          <span>{route.text}</span>
                        </span>
                      }
                      /* eslint-enable react/jsx-wrap-multilines */
                    >
                      { route.items.map(item => (item.roles.includes(user.role) ? (
                        <Item key={item.key}>
                          <NavLink
                            to={{ pathname: item.path }}
                          >
                            <Icon viewBox="0 0 1024 1024">
                              <FontAwesomeIcon icon={item.icon} fixedWidth />
                            </Icon>
                            <span>{item.text}</span>
                          </NavLink>
                        </Item>
                      ) : null))}
                    </SubMenu>
                  );
                });

                // Render menu group name
                return (
                  <ItemGroup key={group}>
                    <ItemGroup className="item-group" key={group} title={group} />
                    {renderedItems}
                  </ItemGroup>
                );
              })}
            </Menu>
          </PerfectScrollbar>
        </Sider>
        <Layout className="app-inner-layout" style={{ marginLeft: collapsed ? 0 : 220 }}>
          <Header className="app-header">
            <button
              type="button"
              className="app-trigger"
              onClick={() => {
                this.setState(prevState => ({
                  collapsed: !prevState.collapsed,
                }));
              }}
            >
              <Icon type={collapsed ? 'menu-unfold' : 'menu-fold'} />
            </button>
            <div className="app-index-right">
              <Popover
                placement="bottom"
                title={<strong>Account Information</strong>}
                /* eslint-disable react/jsx-wrap-multilines */
                content={(
                  <div>
                    <p>
                      <span style={{ fontWeight: 600 }}>Username: </span>
                      <span>{user.username}</span>
                    </p>
                    <p>
                      <span style={{ fontWeight: 600 }}>Role: </span>
                      <span>{user.role}</span>
                    </p>
                    <p>
                      <span style={{ fontWeight: 600 }}>Branch: </span>
                      <span>{branch.name}</span>
                    </p>
                    <Button type="danger" block>
                      <NavLink to="/logout">Logout</NavLink>
                    </Button>
                  </div>
                )}
                /* eslint-enable react/jsx-wrap-multilines */
                trigger="click"
              >
                <div className="user-menu">
                  <Icon style={{ marginRight: '8px' }} type="user" />
                  <span style={{ fontSize: '12px' }}>{user.name}</span>
                  <Icon style={{ marginLeft: '8px', fontSize: '10px' }} type="caret-down" />
                </div>
              </Popover>
            </div>
          </Header>
          <Content className="app-content">
            <Switch>
              {appRoutes.map(route => (route.roles.includes(user.role) ? (
                <RouteComponent
                  key={route.path}
                  exact={route.exact}
                  path={route.path}
                  component={route.component}
                />
              ) : null))}
              <Route path="/404" component={MissingPage} />
              <Redirect to="/404" />
            </Switch>
            <BackTop />
          </Content>
          <Footer className="app-footer">
            <p className="app-footer-title">Ximex Delivery Express Inc.</p>
            <p className="app-footer-subtitle">
Created By: IT Development Team -
              {' '}
              { process.env.REACT_APP_VERSION ? process.env.REACT_APP_VERSION : 'Development' }
            </p>
          </Footer>
        </Layout>
      </Layout>
    );
  }
}

MainLayout.propTypes = {
  location: PropTypes.oneOfType([PropTypes.object]).isRequired,
  user: PropTypes.oneOfType([PropTypes.object]).isRequired,
  branch: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const mapStateToProps = state => ({
  user: state.auth.user,
  branch: state.auth.branch,
});

export default withRouter(
  connect(
    mapStateToProps,
    null,
  )(MainLayout),
);
