import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { notification } from 'antd';

import { get, store } from './localStorage';

import { REFRESH_TOKEN_SUCCESS, LOGOUT_SUCCESS } from '../pages/auth/ducks';

const openAxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 600000,
  headers: {
    'Content-Type': 'application/json',
  },
});

const secureAxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 600000,
  headers: {
    'Content-Type': 'application/json',
  },
});

const retryAxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 600000,
  headers: {
    'Content-Type': 'application/json',
  },
});

const secureRequestMiddlewareOptions = {
  returnRejectedPromiseOnError: true,
  interceptors: {
    request: [
      {
        success: ({ getState }, req) => {
          req.headers = {
            Authorization: `bearer ${getState().auth.accessToken}`,
          };

          return req;
        },
      },
    ],
    response: [
      {
        error: async ({ getState, dispatch }, res) => {
          // If error is 401, retry the request again
          if (res.response && res.response.status === 401) {
            // Since it is an auth error, refresh token
            const result = await openAxiosInstance
              .post('/refresh', {
                user_id: `${getState().auth.user.sub}`,
                refresh_token: await get('refresh_token'),
              })
              .then(({ data }) => {
                if (typeof data.refresh_token !== 'undefined') {
                  store('refresh_token', data.refresh_token);
                }

                dispatch({
                  type: REFRESH_TOKEN_SUCCESS,
                  payload: {
                    accessToken: data.access_token,
                    user: jwtDecode(data.access_token),
                  },
                });

                return data;
              })
              // If refresh token is successful, retry the request
              .then(async (data) => {
                const newRequest = res.config;
                newRequest.headers.Authorization = `bearer ${data.access_token}`;

                // Resolve response and error
                const retryResult = await retryAxiosInstance
                  .request(newRequest)
                  .then(retryResponse => retryResponse)
                  .catch(error => Promise.reject(error));

                return retryResult;
              })
              // Finish resolving response
              .then(finalResponse => finalResponse)
              .catch((error) => {
                // If attempt resulted into 401, logout
                if (error.response.status === 401) {
                  dispatch({
                    type: LOGOUT_SUCCESS,
                  });
                }

                return Promise.reject(error);
              });

            return result;
          }
          if (res.response && res.response.status === 500) {
            notification.error({
              message: 'Server Error',
              description: 'A server error occured, please contact XDE IT immediately.',
            });
          }
          return Promise.reject(res);
        },
      },
    ],
  },
};

const openRequestMiddlewareOptions = {
  returnRejectedPromiseOnError: true,
};

export const apiClients = {
  secure: {
    client: secureAxiosInstance,
    options: secureRequestMiddlewareOptions,
  },
  open: {
    client: openAxiosInstance,
    options: openRequestMiddlewareOptions,
  },
};

export default apiClients;
