import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Card, Form, Steps, Upload, Icon, Col, Button, Row, message, Result,
} from 'antd';
import { connect } from 'react-redux';
import PageHeader from '../../components/PageHeader';
import Spacer from '../../components/Spacer';
import Container from '../../components/Container';
import { validateMotorcycleUpload, uploadMotorcycle } from './ducks';
import ReactTable from '../../components/ReactTable';

class UploadPackage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentStep: 0,
      fileContentPreview: [],
      fileList: [],
      isUploading: false,
      isValidating: false,
    };

    this.submitFile = this.submitFile.bind(this);
    this.beforeUploadHandler = this.beforeUploadHandler.bind(this);
    this.removeUploadHandler = this.removeUploadHandler.bind(this);
    this.previousStep = this.previousStep.bind(this);
    this.confirmUpload = this.confirmUpload.bind(this);
    this.restart = this.restart.bind(this);
  }

  submitFile(e) {
    e.preventDefault();
    const { doValidateUpload, form } = this.props;
    const { fileList } = this.state;
    const { validateFields } = form;

    validateFields((err) => {
      if (!err) {
        this.setState({ isValidating: true });
        const formData = new FormData();
        formData.append('uploadFile', fileList[0]);
        doValidateUpload(formData).then((action) => {
          this.setState(prevState => ({
            fileContentPreview: action.payload.data,
            currentStep: prevState.currentStep + 1,
            fileList: [],
            isValidating: false,
          }));
        }).catch(() => {
          this.setState({
            isValidating: false,
          });
        });
      }
    });
  }

  beforeUploadHandler(file) {
    this.setState({
      fileList: [file],
    });
    return false;
  }

  removeUploadHandler() {
    this.setState({
      fileList: [],
    });
  }

  previousStep() {
    this.setState(prevState => ({
      currentStep: prevState.currentStep - 1,
    }));
  }

  restart() {
    this.setState({
      currentStep: 0,
    });
  }

  confirmUpload() {
    const { fileContentPreview } = this.state;
    const { doUploadPackage } = this.props;
    if (fileContentPreview.valid.length) {
      this.setState({
        isUploading: true,
      });
      doUploadPackage(fileContentPreview.valid).then(() => {
        this.setState(prevState => ({
          currentStep: prevState.currentStep + 1,
          isUploading: false,
        }));
      }).catch(() => {
        this.setState({
          isUploading: false,
        });
      });
    } else {
      message.error('No data to upload', 3);
    }
  }

  render() {
    const { Step } = Steps;
    const { Dragger } = Upload;
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const {
      currentStep, fileList, fileContentPreview, isUploading, isValidating,
    } = this.state;
    return (
      <div className="UploadPackage">
        <PageHeader title="Upload Motorcycle" />
        <Spacer />
        <Container>
          <Card>
            <Steps current={currentStep}>
              <Step title="Select File" />
              <Step title="File Preview" />
              <Step title="Done" />
            </Steps>
          </Card>
          <Spacer />
          <Card>
            {currentStep === 0 ? (
              <Form onSubmit={this.submitFile}>
                <Form.Item>
                  {getFieldDecorator('uploadFile', {
                    rules: [{ required: true, message: 'Please insert value' }],
                  })(
                    <Dragger
                      fileList={fileList}
                      beforeUpload={this.beforeUploadHandler}
                      onRemove={this.removeUploadHandler}
                      multiple={false}
                      accept=".xlsx"
                    >
                      <p className="ant-upload-drag-icon">
                        <Icon type="inbox" />
                      </p>
                      <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    </Dragger>,
                  )}
                </Form.Item>
                <Button type="primary" htmlType="submit" style={{ float: 'right' }} disabled={isValidating}>
                  {isValidating ? '...Validating' : 'Submit'}
                </Button>
              </Form>
            ) : ''}

            {currentStep === 1 ? (
              <React.Fragment>
                <Card
                  title={`Success (${fileContentPreview.valid.length})`}
                >
                  <ReactTable
                    data={fileContentPreview.valid}
                    columns={[
                      {
                        Header: 'Engine Number',
                        accessor: 'engine_number',
                      },
                      {
                        Header: 'Chassis Number',
                        accessor: 'chassis_number',
                      },
                    ]}
                    minRows={0}
                  />
                </Card>
                <Spacer />
                <Card
                  title={`Errors (${fileContentPreview.invalid.length})`}
                >
                  <ReactTable
                    data={fileContentPreview.invalid}
                    columns={[
                      {
                        Header: 'Engine Number',
                        accessor: 'engine_number',
                      },
                      {
                        Header: 'Chassis Number',
                        accessor: 'chassis_number',
                      },
                    ]}
                    minRows={0}
                  />

                </Card>
                <Spacer />
                <Row gutter={5}>
                  <Col>
                    <Button type="primary" onClick={this.confirmUpload} style={{ float: 'right' }} disabled={isUploading}>
                      {isUploading ? '...Uploading' : 'Confirm'}
                    </Button>
                  </Col>
                  <Col>
                    <Button onClick={this.previousStep} type="primary" style={{ float: 'right', marginRight: '5px' }} disabled={isUploading}>
                    Back
                    </Button>
                  </Col>
                </Row>

              </React.Fragment>
            ) : ''}

            {currentStep === 2 ? (
              <Result
                status="success"
                title="File successfully uploaded"
                extra={[
                  <Button type="primary" key="console" onClick={this.restart}>
                    Finish
                  </Button>,
                ]}
              />
            ) : ''}
          </Card>
        </Container>
      </div>
    );
  }
}
UploadPackage.propTypes = {
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
  doValidateUpload: PropTypes.func.isRequired,
  doUploadPackage: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  doValidateUpload: validateMotorcycleUpload,
  doUploadPackage: uploadMotorcycle,
};

const WrappedUploadPackage = Form.create({ name: 'UploadPackage' })(UploadPackage);
export default connect(null, mapDispatchToProps)(WrappedUploadPackage);
