import {
  apiAddBranch, apiGetBranches, apiGetBranchSelect, apiUpdateBranch, apiDeleteBranch,
} from '../../services/apiEndpoints';

export const ADD_BRANCH = 'branch/ADD_BRANCH';

export const addBranch = branch => ({
  type: ADD_BRANCH,
  payload: {
    client: 'secure',
    request: {
      ...apiAddBranch,
      data: branch,
    },
  },
});

export const FETCH_BRANCHES = 'branch/FETCH_BRANCHES';

export const getBranches = data => ({
  type: FETCH_BRANCHES,
  payload: {
    client: 'secure',
    request: {
      ...apiGetBranches,
      params: {
        ...data,
        filters: JSON.stringify(data.filters),
        sorts: JSON.stringify(data.sorts),
      },
    },
  },
});

export const UPDATE_BRANCH = 'branch/UPDATE_BRANCH';

export const updateBranch = (params, branchId) => ({
  type: UPDATE_BRANCH,
  payload: {
    client: 'secure',
    request: {
      ...apiUpdateBranch,
      url: `/branch/${branchId}`,
      params,
    },
  },
});

export const DELETE_BRANCH = 'branch/DELETE_BRANCH';
export const deleteBranch = branchId => ({
  type: DELETE_BRANCH,
  payload: {
    client: 'secure',
    request: {
      ...apiDeleteBranch,
      url: `/branch/${branchId}`,
    },
  },
});

export const GET_BRANCH_SELECT = 'branch/GET_BRANCH_SELECT';

export const getBranchSelect = () => ({
  type: GET_BRANCH_SELECT,
  payload: {
    client: 'secure',
    request: {
      ...apiGetBranchSelect,
    },
  },
});


const initialState = {};

function reducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}

export default reducer;
