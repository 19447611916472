import {
  apiGetOutboundReprocess,
  apiGetOutboundReprocessDetails,
  apiSaveOutboundReprocessPackages,
  apiValidateOutboundReprocessPackage,
  apiGetPackageDetails,
  apiExportOutboundReprocess,
  apiSaveOutboundReprocessPackagesById,
  apiFinalizeOutboundReprocess,
  apiCancelOutboundReprocess,
  apiPrintOutboundReprocess,
  apiUploadOutboundReprocessFiles,
  apiDownloadOutboundReprocessFile,
  apiDeleteOutboundReprocessFile,
} from '../../services/apiEndpoints';


export const VALIDATE_PACKAGE = 'outboundReprocess/VALIDATE_PACKAGE';
export const validatePackage = data => ({
  type: VALIDATE_PACKAGE,
  payload: {
    client: 'secure',
    request: {
      ...apiValidateOutboundReprocessPackage,
      data,
    },
  },
});

export const FETCH_OUTBOUND_REPROCESS_LIST = 'outboundReprocess/FETCH_OUTBOUND_REPROCESS_LIST';
export const getOutboundReprocessList = params => ({
  type: FETCH_OUTBOUND_REPROCESS_LIST,
  payload: {
    client: 'secure',
    request: {
      ...apiGetOutboundReprocess,
      params: {
        ...params,
        filters: JSON.stringify(params.filters),
        sorts: JSON.stringify(params.sorts),
      },
    },
  },
});

export const FETCH_OUTBOUND_REPROCESS_DETAILS = 'outboundReprocess/FETCH_OUTBOUND_REPROCESS_DETAILS';
export const getOutboundReprocessDetails = (path, branch, deletedOnly) => ({
  type: FETCH_OUTBOUND_REPROCESS_DETAILS,
  payload: {
    client: 'secure',
    request: {
      ...apiGetOutboundReprocessDetails,
      url: path,
      params: {
        branch_id: branch.id,
        deleted_only: deletedOnly,
      },
    },
  },
});

export const SAVE_PACKAGES = 'outboundReprocess/SAVE_PACKAGES';
export const storePackages = details => ({
  type: SAVE_PACKAGES,
  payload: {
    client: 'secure',
    request: {
      ...apiSaveOutboundReprocessPackages,
      data: details,
    },
  },
});

export const SAVE_PACKAGES_BY_ID = 'outboundReprocess/SAVE_PACKAGES_BY_ID';
export const storePackageById = (details, path) => ({
  type: SAVE_PACKAGES,
  payload: {
    client: 'secure',
    request: {
      ...apiSaveOutboundReprocessPackagesById,
      url: path,
      data: details,
    },
  },
});

export const FINALIZE_MOVEMENT = 'outboundReprocess/FINALIZE_MOVEMENT';
export const finalizeMovement = path => ({
  type: SAVE_PACKAGES,
  payload: {
    client: 'secure',
    request: {
      ...apiFinalizeOutboundReprocess,
      url: `${path}/finalize`,
    },
  },
});

export const GET_PACKAGE_DETAILS = 'outboundReprocess/GET_PACKAGE_DETAILS';
export const getPackageDetails = trackingNumber => ({
  type: GET_PACKAGE_DETAILS,
  payload: {
    client: 'secure',
    request: {
      ...apiGetPackageDetails,
      params: trackingNumber,
    },
  },
});

const EXPORT_OUTBOUND_REPROCESS = '/outbound_reprocess/EXPORT_OUTBOUND_REPROCESS';
export const exportOutboundReprocess = outboundReprocessId => ({
  type: EXPORT_OUTBOUND_REPROCESS,
  payload: {
    client: 'secure',
    request: {
      ...apiExportOutboundReprocess,
      responseType: 'arraybuffer',
      url: `/reprocess/outbound/export/${outboundReprocessId}`,
    },
  },
});

const CANCEL_OUTBOUND_REPROCESS = '/outbound_reprocess/CANCEL_OUTBOUND_REPROCESS';
export const cancelOutboundReprocess = $id => ({
  type: CANCEL_OUTBOUND_REPROCESS,
  payload: {
    client: 'secure',
    request: {
      ...apiCancelOutboundReprocess,
      url: `/reprocess/outbound/${$id}`,
    },
  },
});

const PRINT_OUTBOUND_REPROCESS = '/outbound_reprocess/PRINT_OUTBOUND_REPROCESS';
export const printOutboundReprocess = id => ({
  type: PRINT_OUTBOUND_REPROCESS,
  payload: {
    client: 'secure',
    request: {
      ...apiPrintOutboundReprocess,
      url: `/reprocess/outbound/${id}/print`,
    },
  },
});

const UPLOAD_OUTBOUND_REPROCESS_FILES = '/outbound_reprocess/UPLOAD_OUTBOUND_REPROCESS_FILES';
export const uploadOutboundReprocessFiles = (id, files) => ({
  type: UPLOAD_OUTBOUND_REPROCESS_FILES,
  payload: {
    client: 'secure',
    request: {
      ...apiUploadOutboundReprocessFiles(id, files),
      data: files,
    },
  },
});

const DOWNLOAD_OUTBOUND_REPROCESS_FILE = '/outbound_reprocess/DOWNLOAD_OUTBOUND_REPROCESS_FILE';
export const downloadOutboundReprocessFile = id => ({
  type: DOWNLOAD_OUTBOUND_REPROCESS_FILE,
  payload: {
    client: 'secure',
    request: {
      ...apiDownloadOutboundReprocessFile(id),
    },
  },
});

const DELETE_OUTBOUND_REPROCESS_FILE = '/outbound_reprocess/DELETE_OUTBOUND_REPROCESS_FILE';
export const deleteOutboundReprocessFile = id => ({
  type: DELETE_OUTBOUND_REPROCESS_FILE,
  payload: {
    client: 'secure',
    request: {
      ...apiDeleteOutboundReprocessFile(id),
    },
  },
});

const initialState = {};

function reducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}

export default reducer;
