import {
  faBoxes, faWarehouse, faList, faDolly,
  faStream, faFolderPlus, faTruckLoading, faRedo,
  faExclamationCircle, faUser, faUserPlus, faUserShield,
  faUsers, faMapMarkerAlt, faDoorOpen, faLayerGroup, faTruck,
  faSync, faUserFriends, faUpload, faSignal, faMapMarkedAlt,
  faMotorcycle,
} from '@fortawesome/free-solid-svg-icons';

import roles from '../services/roles';

export default {
  Dashboard: {
    roles: roles.all,
    routes: [
      {
        roles: roles.all,
        key: '/',
        text: 'Inventory',
        icon: faBoxes,
        path: '/',
      },
      {
        roles: roles.all,
        key: '/ageing',
        text: 'Ageing',
        icon: faWarehouse,
        path: '/ageing',
      },
      {
        roles: roles.all,
        key: '/waybill',
        text: 'Waybill',
        icon: faList,
        path: '/waybill',
      },
      {
        roles: roles.all,
        key: '/monitoring',
        text: 'Inbound Monitoring',
        icon: faSignal,
        path: '/monitoring',
      },
    ],
  },
  Movements: {
    roles: roles.encoder,
    routes: [
      {
        roles: roles.encoder,
        key: 'inbound',
        text: 'Inbound',
        icon: faDolly,
        items: [
          {
            roles: roles.encoder,
            key: '/inbound',
            text: 'List',
            icon: faStream,
            path: '/inbound',
          },
          {
            roles: roles.encoder,
            key: '/inbound/create',
            text: 'Create',
            icon: faFolderPlus,
            path: '/inbound/create',
            props: 'test',
          },
        ],
      },
      {
        roles: roles.encoder,
        key: '/outbound',
        text: 'Outbound',
        icon: faTruckLoading,
        items: [
          {
            roles: roles.encoder,
            key: '/outbound',
            text: 'List',
            icon: faStream,
            path: '/outbound',
          },
          {
            roles: roles.encoder,
            key: '/outbound/create',
            text: 'Create',
            icon: faFolderPlus,
            path: '/outbound/create',
          },
        ],
      },
      {
        roles: roles.encoder,
        key: '/reprocess/inbound',
        text: 'Inbound-reprocess',
        icon: faSync,
        items: [
          {
            roles: roles.encoder,
            key: '/reprocess/inbound',
            text: 'List',
            icon: faStream,
            path: '/reprocess/inbound',
          },
          {
            roles: roles.encoder,
            key: '/reprocess/inbound/create',
            text: 'Create',
            icon: faFolderPlus,
            path: '/reprocess/inbound/create',
          },
        ],
      },
      {
        roles: roles.encoder,
        key: '/reprocess/outbound',
        text: 'Outbound-reprocess',
        icon: faRedo,
        items: [
          {
            roles: roles.encoder,
            key: '/reprocess/outbound',
            text: 'List',
            icon: faStream,
            path: '/reprocess/outbound',
          },
          {
            roles: roles.encoder,
            key: '/reprocess/outbound/create',
            text: 'Create',
            icon: faFolderPlus,
            path: '/reprocess/outbound/create',
          },
        ],
      },
      {
        roles: roles.encoder,
        key: '/incidentReport',
        text: 'Incident Report',
        icon: faExclamationCircle,
        items: [
          {
            roles: roles.encoder,
            key: '/incidentReport',
            text: 'List',
            icon: faStream,
            path: '/incidentReport',
          },
          {
            roles: roles.encoder,
            key: '/incidentReport/create',
            text: 'Create',
            icon: faFolderPlus,
            path: '/incidentReport/create',
          },
        ],
      },
      {
        roles: roles.encoder,
        key: '/package/upload',
        text: 'Upload Package',
        icon: faUpload,
        path: '/package/upload',
      },
      {
        roles: roles.encoder,
        key: '/motorcycle',
        text: 'Motorcycle',
        icon: faMotorcycle,
        branch: [4],
        items: [
          {
            roles: roles.encoder,
            key: '/motorcycle',
            text: 'List',
            icon: faList,
            path: '/motorcycle',
          },
          {
            roles: roles.encoder,
            key: '/motorcycle/upload',
            text: 'Upload',
            icon: faUpload,
            path: '/motorcycle/upload',
          },
        ],
      },
    ],
  },
  Admin: {
    roles: roles.admin,
    routes: [
      {
        roles: roles.admin,
        key: '/user',
        text: 'User',
        icon: faUser,
        items: [
          {
            roles: roles.admin,
            key: '/user',
            text: 'List',
            icon: faStream,
            path: '/user',
          },
          {
            roles: roles.admin,
            key: '/user/create',
            text: 'Create',
            icon: faUserPlus,
            path: '/user/create',
          },
          {
            roles: roles.admin,
            key: '/user/approval',
            text: 'For Approval',
            icon: faUserShield,
            path: '/user/approval',
          },
        ],
      },
      {
        roles: roles.admin,
        key: '/client',
        text: 'Client',
        icon: faUserFriends,
        path: '/client',
      },
      {
        roles: roles.admin,
        key: '/outsource',
        text: 'Outsource',
        icon: faUsers,
        path: '/outsource',
      },
      {
        roles: roles.superAdmin,
        key: '/branch',
        text: 'Branch',
        icon: faMapMarkerAlt,
        path: '/branch',
      },
      {
        roles: roles.admin,
        key: '/gate',
        text: 'Gate',
        icon: faDoorOpen,
        path: '/gate',
      },
      {
        roles: roles.admin,
        key: '/hauler',
        text: 'Hauler',
        icon: faLayerGroup,
        path: '/hauler',
      },
      {
        roles: roles.admin,
        key: '/vehicle',
        text: 'Vehicle',
        icon: faTruck,
        path: '/vehicle',
      },
      {
        roles: roles.admin,
        key: '/section',
        text: 'Section',
        icon: faMapMarkedAlt,
        path: '/section',
      },
      // {
      //   roles: roles.superAdmin,
      //   key: '/merchant',
      //   text: 'Merchant',
      //   icon: faUserFriends,
      //   path: '/merchant',
      // },
    ],
  },
};
