import React from 'react';
import { Row as AntRow } from 'antd';
import PropTypes from 'prop-types';

const Row = ({ children, style }) => <AntRow style={style} gutter={16}>{children}</AntRow>;

Row.defaultProps = {
  style: {},
};

Row.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.oneOfType([PropTypes.object]),
};

export default Row;
