import {
  apiGetWaybill, apiExportWaybill, apiGetMovementTypes,
  apiGetPackageWaybill, apiGetBulkWaybill,
  apiValidatePackageUpload,
  apiUploadPackage,
} from '../../services/apiEndpoints';

export const GET_WAYBILL = 'waybill/GET_WAYBILL';
export const getWaybill = data => ({
  type: GET_WAYBILL,
  payload: {
    client: 'secure',
    request: {
      ...apiGetWaybill,
      params: {
        ...data,
        filters: JSON.stringify(data.filters),
        sorts: JSON.stringify(data.sorts),
      },
    },
  },
});

export const GET_BULK_WAYBILL = 'waybill/GET_BULK_WAYBILL';
export const getBulkWaybill = data => ({
  type: GET_BULK_WAYBILL,
  payload: {
    client: 'secure',
    request: {
      ...apiGetBulkWaybill,
      data: {
        ...data,
        filters: data.filters,
        sorts: data.sorts,
      },
    },
  },
});

export const GET_MOVEMENT_TYPES = 'waybill/GET_MOVEMENT_TYPES';
export const getMovementTypes = () => ({
  type: GET_WAYBILL,
  payload: {
    client: 'secure',
    request: {
      ...apiGetMovementTypes,
    },
  },
});

export const EXPORT_WAYBILL = 'waybill/EXPORT_WAYBILL';
export const exportWaybill = filter => ({
  type: EXPORT_WAYBILL,
  payload: {
    client: 'secure',
    request: {
      ...apiExportWaybill,
      data: filter,
      responseType: 'arraybuffer',
    },
  },
});

export const GET_PACKAGE_WAYBILL = 'waybill/GET_PACKAGE_WAYBILL';
export const getPackageWaybill = trackingNumber => ({
  type: GET_PACKAGE_WAYBILL,
  payload: {
    client: 'secure',
    request: {
      ...apiGetPackageWaybill,
      url: `/package/${trackingNumber}`,
    },
  },
});

export const VALIDATE_UPLOAD_PACKAGE = 'waybill/VALIDATE_UPLOAD_PACKAGE';
export const validatePackageUpload = uploadFile => ({
  type: VALIDATE_UPLOAD_PACKAGE,
  payload: {
    client: 'secure',
    request: {
      ...apiValidatePackageUpload,
      data: uploadFile,
    },
  },
});

export const UPLOAD_PACKAGE = 'waybill/UPLOAD_PACKAGE';
export const uploadPackage = parsedContent => ({
  type: UPLOAD_PACKAGE,
  payload: {
    client: 'secure',
    request: {
      ...apiUploadPackage,
      data: {
        valid_packages: parsedContent,
      },
    },
  },
});

const initialState = {};

function reducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}

export default reducer;
