import {
  apiAddGate, apiGetGates,
  apiUpdateGate,
  apiDeleteGate,
  apiGetBranchGates,
} from '../../services/apiEndpoints';

export const ADD_GATE = 'gate/ADD_GATE';
export const addGate = gate => ({
  type: ADD_GATE,
  payload: {
    client: 'secure',
    request: {
      ...apiAddGate,
      data: gate,
    },
  },
});

export const GET_BRANCH_GATES = 'gate/GET_BRANCH_GATES';
export const getBranchGates = (id, type) => ({
  type: GET_BRANCH_GATES,
  payload: {
    client: 'secure',
    request: {
      ...apiGetBranchGates,
      url: `/gate/type/${type}/branch/${id}`,
    },
  },
});

export const FETCH_GATES = 'gate/FETCH_GATES';
export const getGates = data => ({
  type: FETCH_GATES,
  payload: {
    client: 'secure',
    request: {
      ...apiGetGates,
      params: {
        ...data,
        filters: JSON.stringify(data.filters),
        sorts: JSON.stringify(data.sorts),
      },
    },
  },
});

export const DELETE_GATE = 'gate/DELETE_GATE';
export const deleteGate = gateId => ({
  type: DELETE_GATE,
  payload: {
    client: 'secure',
    request: {
      ...apiDeleteGate,
      url: `/gate/${gateId}`,
    },
  },
});

export const UPDATE_GATE = 'gate/UPDATE_GATE';
export const updateGate = (params, gateId) => ({
  type: DELETE_GATE,
  payload: {
    client: 'secure',
    request: {
      ...apiUpdateGate,
      url: `/gate/${gateId}`,
      params,
    },
  },
});

const initialState = {};

function reducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}
export default reducer;
