import { all, fork } from 'redux-saga/effects';
import authSaga from '../pages/auth/saga';
import inboundSaga from '../pages/inbound/saga';
import outboundSaga from '../pages/outbound/saga';

export default function* rootSaga() {
  yield all([
    fork(authSaga),
    fork(inboundSaga),
    fork(outboundSaga),
  ]);
}
