import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

export class Timer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timer: null,
    };
  }

  componentDidMount() {
    this.interval = setInterval(() => this.timer(), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  timer() {
    const { startTime } = this.props;
    const timeDiff = moment(moment(), 'YYYY-MM-DD HH:mm:ss').diff(moment(startTime, 'YYYY-MM-DD HH:mm:ss'));
    const timer = moment.utc(moment.duration(timeDiff, 'ms').asMilliseconds()).format('HH:mm:ss');
    this.setState({ timer });
  }

  render() {
    const { timer } = this.state;
    const { label } = this.props;
    return (
      <React.Fragment>
        <strong>
          {label}
          {' '}
          {timer}
        </strong>
      </React.Fragment>
    );
  }
}

Timer.defaultProps = {
  startTime: null,
  label: null,
};

Timer.propTypes = {
  startTime: PropTypes.string,
  label: PropTypes.string,
};

export default Timer;
