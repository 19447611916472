import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Col, Form, Input, Button, Icon, Popconfirm, message,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus, faSave, faEdit, faTrash, faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import Select from '../../components/Select';
import Row from '../../components/Row';
import PageHeader from '../../components/PageHeader';
import Card from '../../components/Card';
import Container from '../../components/Container';
import ReactTable from '../../components/ServerSideTable';
import Spacer from '../../components/Spacer';

import {
  addGate, getGates, deleteGate, updateGate,
} from './ducks';

const typeOptions = [
  {
    value: 'inbound',
    label: 'Inbound',
  },
  {
    value: 'outbound',
    label: 'Outbound',
  },
];

class Gate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      gateList: {
        rows: [],
        pages: 0,
        total: 0,
      },
      isFetching: false,
      fetchMessage: null,
      editedGate: null,
      currentTable: {
        page: null,
        pageSize: null,
        filters: [],
        sorts: [],
      },
      branchOption: [],

    };

    this.formSubmit = this.formSubmit.bind(this);
    this.onFetchData = this.onFetchData.bind(this);
    this.onUpdateSubmit = this.onUpdateSubmit.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this.setToEdit = this.setToEdit.bind(this);
    this.cancelEdit = this.cancelEdit.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
  }

  // eslint-disable-next-line no-unused-vars
  onFetchData(state, instance) {
    const { doGetGates, branch } = this.props;

    const params = {
      page: state.page + 1,
      pageSize: state.pageSize,
      filters: state.filtered,
      sorts: state.sorted,
      branchId: branch.id,
    };

    this.setState({
      currentTable: params,
      isFetching: true,
      editedGate: null,
      fetchMessage: 'Fetching gate list . . .',
    });

    doGetGates(params).then((action) => {
      this.setState({
        gateList: {
          rows: action.payload.data.data,
          pages: action.payload.data.last_page,
          total: action.payload.data.total,
        },
        isFetching: false,
        fetchMessage: null,
      });
    // eslint-disable-next-line no-unused-vars
    }).catch((action) => {
      this.setState({
        isFetching: false,
        fetchMessage: null,
      });
    });
  }

  onUpdateSubmit(e) {
    e.preventDefault();
    const { form, doUpdateGate } = this.props;
    const { validateFields } = form;
    const { editedGate } = this.state;

    validateFields(['gate_number_update', 'branch_id_update', 'type_update'], (err, values) => {
      const data = {
        gate_number: values.gate_number_update,
        branch_id: values.branch_id_update,
        type: values.type_update,
      };
      if (!err) {
        doUpdateGate(data, editedGate).then(() => {
          const { currentTable } = this.state;

          const params = {
            page: currentTable.page - 1,
            pageSize: currentTable.pageSize,
            filtered: currentTable.filters,
            sorted: currentTable.sorts,
          };

          this.setState({
            editedGate: null,
          });

          message.success('Successfully updated gate!', 3);

          this.onFetchData(params);
        }).catch((action) => {
          let errorMessage;
          if (action.error.response.status === 422) {
            const index = Object.values(action.error.response.data);
            // eslint-disable-next-line prefer-destructuring
            errorMessage = index[0][0];
          } else {
            errorMessage = 'Something went wrong, please try again later.';
          }
          message.error(errorMessage, 3);
        });
      }
    });
  }

  onEdit(cellInfo) {
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const { gateList, editedGate, branchOption } = this.state;

    if (editedGate === cellInfo.original.id) {
      if (cellInfo.column.id === 'branch_id') {
        return (
          <Form.Item style={{ margin: '0px' }}>
            {getFieldDecorator(`${cellInfo.column.id}_update`, {
              rules: [{ required: true, message: 'Branch cannot be blank' }],
              initialValue: gateList.rows[cellInfo.index].branch ? gateList.rows[cellInfo.index].branch.id : '',
            })(<Select options={branchOption} placeholder="E.g. Tipas" />)}
          </Form.Item>

        );
      }

      if (cellInfo.column.id === 'type') {
        return (
          <Form.Item style={{ margin: '0px' }}>
            {getFieldDecorator(`${cellInfo.column.id}_update`, {
              rules: [{ required: true, message: 'Branch cannot be blank' }],
              initialValue: gateList.rows[cellInfo.index][cellInfo.column.id],
            })(<Select options={typeOptions} placeholder="E.g. Inbound" />)}
          </Form.Item>

        );
      }
      return (
        <Form.Item style={{ margin: '0px' }}>
          {getFieldDecorator(`${cellInfo.column.id}_update`, {
            rules: [{ required: true, message: `${cellInfo.column.Header} is required.` }],
            initialValue: gateList.rows[cellInfo.index][cellInfo.column.id],
          })(<Input autoComplete="off" placeholder="E.g. Gate-1" />)}
        </Form.Item>
      );
    }

    return (
      <div>
        { cellInfo.column.id === 'branch_id' ? cellInfo.row.branch_id
          : gateList.rows[cellInfo.index][cellInfo.column.id]
        }
      </div>
    );
  }

  setToEdit(gateId) {
    this.setState({
      editedGate: gateId,
    });
  }

  formSubmit(e) {
    e.preventDefault();
    const { form, doAddGate, branch } = this.props;
    const { validateFields } = form;
    validateFields(['gate_number', 'branch_id', 'type'], (err, values) => {
      if (!err) {
        const gate = {
          gate_number: values.gate_number,
          branch_id: branch.id,
          type: values.type,
        };
        doAddGate(gate).then(() => {
          const { currentTable } = this.state;

          const params = {
            page: currentTable.page - 1,
            pageSize: currentTable.pageSize,
            filtered: currentTable.filters,
            sorted: currentTable.sorts,
          };

          this.setState({
            editedGate: null,
          });

          message.success('Successfully created gate!', 3);

          this.onFetchData(params);

          form.resetFields();
        // eslint-disable-next-line no-unused-vars
        }).catch((action) => {
          let errorMessage;
          if (action.error.response.status === 422) {
            const index = Object.values(action.error.response.data);
            // eslint-disable-next-line prefer-destructuring
            errorMessage = index[0][0];
          } else {
            errorMessage = 'Something went wrong, please try again later.';
          }
          message.error(errorMessage, 3);
        });
      }
    });
  }

  cancelEdit() {
    this.setState({
      editedGate: null,
    });
  }

  confirmDelete(gateId) {
    const { doDeleteGate } = this.props;
    doDeleteGate(gateId).then((action) => {
      if (action.payload.data) {
        const { currentTable } = this.state;
        const params = {
          page: currentTable.page - 1,
          pageSize: currentTable.pageSize,
          filtered: currentTable.filters,
          sorted: currentTable.sorts,
        };
        this.setState({
          editedGate: null,
        });
        message.success('Successfully deleted gate!', 3);

        this.onFetchData(params);
      } else {
        message.error('Unable to delete data that has 1 or more reference(s).', 3);
      }
    }).catch(() => {
      message.error('Something went wrong, please try again.', 3);
    });
  }

  render() {
    const { form } = this.props;

    const { getFieldDecorator } = form;

    const {
      isFetching, fetchMessage, gateList,
      editedGate,
    } = this.state;

    /** Form input alignment */
    const gateLayout = {
      labelCol: { span: 11 },
      wrapperCol: { span: 13 },
    };

    const typeLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
    };

    const buttonLayout = {
      wrapperCol: { span: 14 },
    };

    return (
      <div className="Gate">
        <PageHeader title="GATE" />
        <Container>
          <Card title="Gate Information">
            <Form layout="horizontal">
              <Row>
                <Col xs={24} sm={12} lg={6}>
                  <Form.Item label="Gate Number" {...gateLayout}>
                    {getFieldDecorator('gate_number', {
                      rules: [{ required: true, message: 'Gate Number cannot be blank.' }],
                    })(<Input autoComplete="off" placeholder="E.g. Gate-1" />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={6}>
                  <Form.Item label="Type" {...typeLayout}>
                    {getFieldDecorator('type', {
                      rules: [{ required: false, message: 'Type cannot be blank' }],
                    })(
                      <Select options={typeOptions} placeholder="E.g. Inbound" />,
                    )
                  }
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={6}>
                  <Form.Item {...buttonLayout}>
                    <Button type="primary" onClick={this.formSubmit} block>
                      <Icon viewBox="0 0 1024 1024">
                        <FontAwesomeIcon icon={faPlus} fixedWidth />
                      </Icon>
                    Create
                    </Button>
                  </Form.Item>
                </Col>

              </Row>
            </Form>
          </Card>
          <Spacer />
          <Card title="Gate List">
            <Form onSubmit={this.onUpdateSubmit}>
              <ReactTable
                data={gateList.rows}
                pages={gateList.pages}
                columns={[
                  {
                    Header: 'Id',
                    accessor: 'id',
                  },
                  {
                    Header: 'Gate Number',
                    accessor: 'gate_number',
                    Cell: this.onEdit,
                  },
                  {
                    id: 'branch_id',
                    Header: 'Branch',
                    accessor: data => (typeof data.branch === 'undefined' || data.branch === null ? '' : data.branch.name),
                    Cell: this.onEdit,
                  },
                  {
                    Header: 'Type',
                    accessor: 'type',
                    Cell: this.onEdit,
                    Filter: ({ filter, onChange }) => (
                      <Select
                        options={[{
                          value: '',
                          label: 'All',
                        },
                        ...typeOptions,
                        ]}
                        onChange={status => onChange(status)}
                        value={filter ? filter.value : 'All'}
                      />
                    ),
                  },
                  {
                    id: 'created_by',
                    Header: 'Created by',
                    accessor: data => data.user.full_name,
                  },
                  {
                    Header: 'Options',
                    sortable: false,
                    filterable: false,
                    width: 210,
                    Cell: data => (
                      <Row>
                        {
                       (editedGate !== data.row.id)
                         ? (
                           <Row>
                             <Col span={12}>
                               <Button type="link" onClick={() => this.setToEdit(data.row.id)} block>
                                 <Icon viewBox="0 0 1024 1024">
                                   <FontAwesomeIcon icon={faEdit} fixedWidth />
                                 </Icon>
                                  Edit
                               </Button>
                             </Col>
                             <Col span={12}>
                               <Popconfirm
                                 placement="leftBottom"
                                 title={`Are you sure you want to delete ${data.row.gate_number} from the list?`}
                                 okText="Yes"
                                 onConfirm={() => this.confirmDelete(data.row.id)}
                                 cancelText="No"
                               >
                                 <Button type="link" block>
                                   <Icon viewBox="0 0 1024 1024">
                                     <FontAwesomeIcon icon={faTrash} fixedWidth />
                                   </Icon>
                                    Delete
                                 </Button>
                               </Popconfirm>
                             </Col>
                           </Row>
                         )
                         : (
                           <Row>
                             <Col span={12}>
                               <Button type="link" htmlType="submit" block>
                                 <Icon viewBox="0 0 1024 1024">
                                   <FontAwesomeIcon icon={faSave} fixedWidth />
                                 </Icon>
                                 Save
                               </Button>
                             </Col>

                             <Col span={12}>
                               <Button type="link" onClick={this.cancelEdit} block>
                                 <Icon viewBox="0 0 1024 1024">
                                   <FontAwesomeIcon icon={faTimes} fixedWidth />
                                 </Icon>
                                 Cancel
                               </Button>
                             </Col>
                           </Row>
                         )
                        }
                      </Row>
                    ),
                  },
                ]}

                loading={isFetching}
                loadingText={fetchMessage}
                onFetchData={this.onFetchData}
              />
            </Form>
          </Card>
        </Container>
      </div>
    );
  }
}

Gate.propTypes = {
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
  branch: PropTypes.oneOfType([PropTypes.object]).isRequired,
  doAddGate: PropTypes.func.isRequired,
  doGetGates: PropTypes.func.isRequired,
  doDeleteGate: PropTypes.func.isRequired,
  doUpdateGate: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  doAddGate: addGate,
  doGetGates: getGates,
  doDeleteGate: deleteGate,
  doUpdateGate: updateGate,
};

const mapStateToProps = state => ({
  branch: state.auth.branch,
});

const WrappedGate = Form.create({ name: 'Gate' })(Gate);

export default connect(mapStateToProps, mapDispatchToProps)(WrappedGate);
