import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Descriptions, Spin, Icon, Button,
} from 'antd';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import PageHeader from '../../components/PageHeader';
import Card from '../../components/Card';
import Container from '../../components/Container';
import Spacer from '../../components/Spacer';
import ReactTable from '../../components/ReactTable';
import { getPackageWaybill } from './ducks';

class WaybillView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      waybillDetails: {},
      isLoading: true,
    };
  }

  componentDidMount() {
    const { match, doGetPackageWaybill } = this.props;
    doGetPackageWaybill(match.params.tracking_number).then((action) => {
      this.setState({
        waybillDetails: action.payload.data,
        isLoading: false,
      });
    });
  }

  render() {
    const { waybillDetails, isLoading } = this.state;
    return (
      <div className="waybill">
        <Spin spinning={isLoading} tip="Fetching tracking number details. . .">
          <PageHeader title="Waybill " />
          <Container>
            <Card title="Package Details">
              <Descriptions title="" bordered>
                <Descriptions.Item label="Tracking Number">
                  {waybillDetails ? waybillDetails.tracking_number : ''}
                </Descriptions.Item>
                <Descriptions.Item label="Set Of">
                  {waybillDetails ? waybillDetails.item_count : ''}
                </Descriptions.Item>
                <Descriptions.Item label="Package Type">
                  {waybillDetails ? waybillDetails.package_type : ''}
                </Descriptions.Item>
                <Descriptions.Item label="Client">
                  {waybillDetails.client ? waybillDetails.client.name : ''}
                </Descriptions.Item>
                <Descriptions.Item label="Current Status" span={2}>
                  {waybillDetails ? waybillDetails.status : ''}
                </Descriptions.Item>
                <Descriptions.Item label="Section" span={3}>
                  {waybillDetails ? waybillDetails.section : ''}
                </Descriptions.Item>
              </Descriptions>
            </Card>
            <Spacer />
            <Card title="Package History">
              <ReactTable
                data={waybillDetails.package_history}
                columns={[
                  {
                    Header: 'Reference ID',
                    accessor: 'reference',
                    sortable: false,
                    filterable: false,
                  },
                  {
                    Header: 'Branch',
                    accessor: 'branch.name',
                    sortable: false,
                    filterable: false,
                  },
                  {
                    Header: 'Status',
                    accessor: 'status',
                    sortable: false,
                    filterable: false,
                  },
                  {
                    Header: 'Date',
                    accessor: 'updated_at',
                    sortable: false,
                    filterable: false,
                  },
                  {
                    Header: 'Options',
                    sortable: false,
                    filterable: false,
                    Cell: (data) => {
                      let url = '';
                      switch (data.row.status) {
                        case 'inbound':
                        case 'refused':
                          url = `/inbound/${data.original.reference}`;
                          break;
                        case 'outbound':
                          url = `/outbound/${data.original.reference}`;
                          break;
                        case 'inbound_reprocess':
                          url = `/reprocess/inbound/${data.original.reference}`;
                          break;
                        case 'outbound_reprocess':
                          url = `/reprocess/outbound/${data.original.reference}`;
                          break;
                        default:
                          break;
                      }
                      return (
                        <center>
                          <NavLink to={`${url}`}>
                            <Button type="link">
                              <Icon viewBox="0 0 1024 1024">
                                <FontAwesomeIcon icon={faEye} fixedWidth />
                              </Icon>
                                    View
                            </Button>
                          </NavLink>
                        </center>
                      );
                    },
                  },
                ]}
              />
            </Card>
          </Container>
        </Spin>
      </div>
    );
  }
}

WaybillView.propTypes = {
  match: PropTypes.oneOfType([PropTypes.object]).isRequired,
  doGetPackageWaybill: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  doGetPackageWaybill: getPackageWaybill,
};

export default connect(null, mapDispatchToProps)(WaybillView);
