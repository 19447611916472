import React, { Component } from 'react';
import {
  Button, Icon,
} from 'antd';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import PageHeader from '../../components/PageHeader';
import Card from '../../components/Card';
import Container from '../../components/Container';
import ServerSideTable from '../../components/ServerSideTable';
import { getInboundReprocessList } from './ducks';


class InboundReprocessList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inboundReprocessList: {
        rows: [],
        pages: 0,
        total: 0,
      },
      isFetching: false,
      fetchMessage: null,
    };

    this.onFetchData = this.onFetchData.bind(this);
  }

  // eslint-disable-next-line no-unused-vars
  onFetchData(state, instance) {
    const { doFetchInboundReprocessList, branch } = this.props;

    const params = {
      page: state.page + 1,
      pageSize: state.pageSize,
      filters: state.filtered,
      sorts: state.sorted,
      branchId: branch.id,
    };

    this.setState({
      isFetching: true,
      fetchMessage: 'Fetching inbound reprocess list . . .',
    });

    doFetchInboundReprocessList(params).then((action) => {
      this.setState({
        inboundReprocessList: {
          rows: action.payload.data.data,
          pages: action.payload.data.last_page,
          total: action.payload.data.total,
        },
        isFetching: false,
        fetchMessage: null,
      });
    }).catch(() => {

    });
  }

  render() {
    const { fetchMessage, isFetching, inboundReprocessList } = this.state;
    return (
      <div className="InboundReprocessList">
        <PageHeader title="Inbound Reprocess" />
        <Container>
          <Card title={`Inbound Reprocess List (Total: ${inboundReprocessList.total})`}>
            <ServerSideTable
              data={inboundReprocessList.rows}
              pages={inboundReprocessList.pages}
              columns={[
                {
                  Header: 'Id',
                  accessor: 'id',
                },
                {
                  Header: 'Created By',
                  accessor: 'created_by',
                },
                {
                  Header: 'Created On',
                  accessor: 'created_at',
                },
                {
                  Header: 'Status',
                  accessor: 'status',
                  Filter: ({ filter, onChange }) => (
                    <select
                      onChange={event => onChange(event.target.value)}
                      value={filter ? filter.value : 'All'}
                      style={{ width: '100%' }}
                    >
                      <option value="">All</option>
                      <option value="fresh">fresh</option>
                      <option value="modified">modified</option>
                      <option value="finalized">finalized</option>
                      <option value="cancelled">cancelled</option>
                    </select>
                  ),
                },

                {
                  Header: 'Options',
                  sortable: false,
                  filterable: false,
                  Cell: data => (
                    <center>
                      <NavLink to={`/reprocess/inbound/${data.row.id}`}>
                        <Button type="link">
                          <Icon type="eye" />
                        View
                        </Button>
                      </NavLink>
                    </center>
                  ),
                },
              ]}
              loadingText={fetchMessage}
              loading={isFetching}
              onFetchData={this.onFetchData}
            />
          </Card>
        </Container>
      </div>
    );
  }
}

InboundReprocessList.propTypes = {
  branch: PropTypes.oneOfType([PropTypes.object]).isRequired,
  doFetchInboundReprocessList: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  doFetchInboundReprocessList: getInboundReprocessList,
};

const mapStateToProps = state => ({
  branch: state.auth.branch,
});

export default connect(mapStateToProps, mapDispatchToProps)(InboundReprocessList);
