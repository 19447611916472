import React, { Component } from 'react';
import Table from 'react-table';
import 'react-table/react-table.css';
import PropTypes from 'prop-types';

class ReactTable extends Component {
  constructor(props) {
    super(props);

    this.data = [];
    this.columns = [
      {
        Header: 'Tracking Number',
        accessor: 'tracking_number',
      },
      {
        Header: 'Current Status',
        accessor: 'current_status',
      },
      {
        Header: 'Target Status',
        accessor: 'target_status',
      },
      {
        Header: 'Reference',
        accessor: 'reference',
      },
      {
        Header: 'Comment',
        accessor: 'comment',
      },
      {
        Header: 'Options',
        accessor: 'options',
      },
    ];
  }

  render() {
    const {
      columns, data, pages, onFetchData, loadingText, loading, defaultSorted,
      onFilteredChange, onSortedChange, onPageChange, onPageSizeChange,
    } = this.props;

    return (
      <Table
        filterable
        sortable
        manual
        getTdProps={() => ({
          style: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          },
        })}
        data={data}
        pages={pages}
        columns={columns || this.columns}
        loading={loading}
        loadingText={loadingText}
        className="-highlight"
        defaultSorted={defaultSorted}
        defaultPageSize={10}
        onFetchData={onFetchData}
        onSortedChange={onSortedChange}
        onFilteredChange={onFilteredChange}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
      />
    );
  }
}

ReactTable.defaultProps = {
  data: [],
  defaultSorted: [{
    id: 'id',
    desc: true,
  },
  {
    id: 'last_update',
    desc: true,
  }],
  pages: 1,
  loadingText: null,
  loading: false,
  onFilteredChange: () => {},
  onSortedChange: () => {},
  onPageChange: () => {},
  onPageSizeChange: () => {},
  onFetchData: () => {},
};

ReactTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  defaultSorted: PropTypes.arrayOf(PropTypes.object),
  data: PropTypes.arrayOf(PropTypes.object),
  pages: PropTypes.number,
  onFetchData: PropTypes.func,
  onFilteredChange: PropTypes.func,
  onSortedChange: PropTypes.func,
  onPageChange: PropTypes.func,
  onPageSizeChange: PropTypes.func,
  loadingText: PropTypes.string,
  loading: PropTypes.bool,
};

export default ReactTable;
