import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Col, Form, Input, Button, Icon, Spin,
  Radio, Popconfirm, message, Statistic as Label,
  Descriptions, Slider,
  Collapse,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus, faCheckCircle, faEdit, faTrash, faSave, faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import _ from 'lodash';

import moment from 'moment';
import Select from '../../components/Select';
import Row from '../../components/Row';
import PageHeader from '../../components/PageHeader';
import Card from '../../components/Card';
import Container from '../../components/Container';
import Spacer from '../../components/Spacer';
import ReactTable from '../../components/ReactTable';
import Timer from '../../components/Timer';

import {
  validatePackage,
  addInboundPackages,
  getInbound,
  savePackageToState,
  deletePackageFromState,
  updatePackageFromState,
  getRemarks,
} from './ducks';

import {
  getClientSelect,
} from '../client/ducks';

import {
  getSectionSelect,
} from '../section/ducks';

class InboundPackage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lastPackageScannedSize: 24,
      isEditable: false,
      isFinalizing: false,
      isUpdated: false,
      isLoading: true,
      editableRow: null,
      editableExistingRow: null,
      editableTn: null,
      packagesErrors: [],
      sectionOptions: [],
      modifiedExistingPackages: [],
      deletedPackages: [],
      clientOptions: [],
      remarks: [],
      inboundDetails: {
        start: moment().format('YYYY-MM-DD HH:mm:ss'),
      },
    };
    this.validatePackage = this.validatePackage.bind(this);
    this.savePackages = this.savePackages.bind(this);
    this.confirmRemove = this.confirmRemove.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this.onUpdateSubmit = this.onUpdateSubmit.bind(this);
    this.onUpdateSubmitExisting = this.onUpdateSubmitExisting.bind(this);
    this.cancelEdit = this.cancelEdit.bind(this);
    this.cancelEditExisting = this.cancelEditExisting.bind(this);
    this.onEditExistingPackages = this.onEditExistingPackages.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
    this.getInbound = this.getInbound.bind(this);
    this.zoomLastPackageScanned = this.zoomLastPackageScanned.bind(this);
  }

  componentDidMount() {
    const {
      doGetSectionSelect,
      doGetClientSelect,
      doGetRemarks,
    } = this.props;

    doGetSectionSelect().then((action) => {
      this.setState({
        sectionOptions: action.payload.data,
      });
    });
    doGetClientSelect().then((action) => {
      this.setState({
        clientOptions: action.payload.data,
      });
    });
    doGetRemarks().then((action) => {
      this.setState({
        remarks: action.payload.data,
      });
    });
    this.getInbound();
  }


  onUpdateSubmitExisting(e) {
    e.preventDefault();
    const { form, branch } = this.props;
    const { validateFields } = form;
    const { editableTn, inboundDetails, modifiedExistingPackages } = this.state;

    validateFields(['item_count_update', 'package_type_update', 'remarks_update', 'client_update', 'section_update'], (err, values) => {
      if (!err) {
        const scannedPackages = inboundDetails.existingPackages.map((existingPackage) => {
          if (existingPackage.tracking_number === editableTn) {
            return {
              ...existingPackage,
              item_count: values.item_count_update,
              package_type: values.package_type_update,
              remarks: values.remarks_update,
              client: values.client_update.label,
              client_id: values.client_update.key,
              section: values.section_update,
            };
          }
          return existingPackage;
        });

        const editedScannedPackage = _.find(
          inboundDetails.existingPackages,
          { tracking_number: editableTn },
        );

        if (!_.isEqual(editedScannedPackage, {
          ...editedScannedPackage,
          item_count: values.item_count_update,
          package_type: values.package_type_update,
          remarks: values.remarks_update,
          client_id: values.client_update.key,
          section: values.section_update,
        })) {
          const data = {
            ...editedScannedPackage,
            item_count: values.item_count_update,
            package_type: values.package_type_update,
            remarks: values.remarks_update,
            client_id: values.client_update.key,
            client: values.client_update.label,
            location: branch.id,
            section: values.section_update,
          };

          let packageExistsInModifiedPackages = false;
          const modifiedPackages = modifiedExistingPackages.map((modifiedExistingPackage) => {
            if (modifiedExistingPackage.tracking_number === editableTn) {
              packageExistsInModifiedPackages = true;
              return data;
            }
            return modifiedExistingPackage;
          });

          if (!packageExistsInModifiedPackages) {
            modifiedPackages.push(data);
          }

          this.setState(prevState => ({
            modifiedExistingPackages: modifiedPackages,
            isUpdated: true,
            inboundDetails: {
              ...prevState.inboundDetails,
              existingPackages: scannedPackages,
            },
          }));
          this.setState({
          });
          message.success('Package updated successfully!', 5);
        }
        this.setState({
          editableExistingRow: null,
          isEditable: false,
        });
      }
    });
  }

  onEdit(cellInfo) {
    const { form, inbound, match } = this.props;
    const { getFieldDecorator } = form;
    const {
      editableRow, remarks, clientOptions, sectionOptions,
    } = this.state;
    const packageTypes = [
      { value: 'pouch', label: 'Pouch' },
      { value: 'bulky', label: 'Bulky' },
      { value: 'm-bulky', label: 'M-Bulky' },
    ];


    if (editableRow === cellInfo.index) {
      if (cellInfo.column.id === 'package_type') {
        return (
          <Form.Item style={{ margin: '0px' }}>
            {getFieldDecorator(`${cellInfo.column.id}_update`, {
              rules: [{ required: true, message: `${cellInfo.column.Header} is required.` }],
              // eslint-disable-next-line max-len
              initialValue: inbound[match.params.id].scannedPackages[cellInfo.index][cellInfo.column.id],
            })(<Select options={packageTypes} />)}
          </Form.Item>
        );
      } if (cellInfo.column.id === 'item_count') {
        return (
          <Form.Item style={{ margin: '0px' }}>
            {getFieldDecorator(`${cellInfo.column.id}_update`, {
              rules: [{ required: true, message: `${cellInfo.column.Header} is required.` }],
              // eslint-disable-next-line max-len
              initialValue: inbound[match.params.id].scannedPackages[cellInfo.index][cellInfo.column.id],
            })(<Input />)}
          </Form.Item>
        );
      } if (cellInfo.column.id === 'remarks') {
        return (
          <Form.Item style={{ margin: '0px' }}>
            {getFieldDecorator(`${cellInfo.column.id}_update`, {
              rules: [{ required: true, message: `${cellInfo.column.Header} is required.` }],
              // eslint-disable-next-line max-len
              initialValue: inbound[match.params.id].scannedPackages[cellInfo.index][cellInfo.column.id],
            })(<Select options={remarks} />)}
          </Form.Item>
        );
      } if (cellInfo.column.id === 'client') {
        return (
          <Form.Item style={{ margin: '0px' }}>
            {getFieldDecorator(`${cellInfo.column.id}_update`, {
              rules: [{ required: true, message: `${cellInfo.column.Header} is required.` }],
              // eslint-disable-next-line max-len
              initialValue: clientOptions.length ? {
                key: inbound[match.params.id].scannedPackages[cellInfo.index].client_id,
                label: inbound[match.params.id].scannedPackages[cellInfo.index].client,
              } : { key: null },
            })(<Select
              options={clientOptions}
              filterOption={false}
              labelInValue
            />)}
          </Form.Item>
        );
      } if (cellInfo.column.id === 'section') {
        return (
          <Form.Item style={{ margin: '0px' }}>
            {getFieldDecorator(`${cellInfo.column.id}_update`, {
              // eslint-disable-next-line max-len
              initialValue: inbound[match.params.id].scannedPackages[cellInfo.index].section,
            })(<Select options={sectionOptions} />)}
          </Form.Item>
        );
      }
    }

    return (
      <div>
        {inbound[match.params.id].scannedPackages[cellInfo.index][cellInfo.column.id]}
      </div>
    );
  }

  onUpdateSubmit(e) {
    e.preventDefault();
    const {
      form,
      inbound,
      match,
      doUpdatePackageFromState,
    } = this.props;
    const { validateFields } = form;
    const { editableTn } = this.state;

    validateFields(['item_count_update', 'package_type_update', 'remarks_update', 'client_update', 'section_update'], (err, values) => {
      if (!err) {
        const updatedScannedPackages = inbound[match.params.id].scannedPackages.map((item) => {
          if (item.tracking_number === editableTn) {
            return {
              ...item,
              item_count: values.item_count_update,
              package_type: values.package_type_update,
              remarks: values.remarks_update,
              is_refused: values.remarks_update !== 'good',
              client: values.client_update.label,
              client_id: values.client_update.key,
              section: values.section_update,
            };
          }
          return item;
        });
        doUpdatePackageFromState(match.params.id, updatedScannedPackages);
        this.setState({
          editableRow: null,
          isEditable: false,
          isUpdated: true,
        });
        message.success('Package updated successfully!', 5);
      }
    });
  }

  onEditExistingPackages(cellInfo) {
    const {
      form,
    } = this.props;
    const { getFieldDecorator } = form;
    const {
      editableExistingRow,
      remarks,
      inboundDetails,
      clientOptions,
      sectionOptions,
    } = this.state;

    const packageTypes = [
      { value: 'pouch', label: 'Pouch' },
      { value: 'bulky', label: 'Bulky' },
      { value: 'm-bulky', label: 'M-Bulky' },
    ];


    if (editableExistingRow === cellInfo.index) {
      if (cellInfo.column.id === 'package_type') {
        return (
          <Form.Item style={{ margin: '0px' }}>
            {getFieldDecorator(`${cellInfo.column.id}_update`, {
              rules: [{ required: true, message: `${cellInfo.column.Header} is required.` }],
              initialValue: inboundDetails.existingPackages[cellInfo.index][cellInfo.column.id],
            })(<Select options={packageTypes} />)}
          </Form.Item>
        );
      } if (cellInfo.column.id === 'item_count') {
        return (
          <Form.Item style={{ margin: '0px' }}>
            {getFieldDecorator(`${cellInfo.column.id}_update`, {
              rules: [{ required: true, message: `${cellInfo.column.Header} is required.` }],
              initialValue: inboundDetails.existingPackages[cellInfo.index][cellInfo.column.id],
            })(<Input />)}
          </Form.Item>
        );
      } if (cellInfo.column.id === 'remarks') {
        return (
          <Form.Item style={{ margin: '0px' }}>
            {getFieldDecorator(`${cellInfo.column.id}_update`, {
              rules: [{ required: true, message: `${cellInfo.column.Header} is required.` }],
              initialValue: inboundDetails.existingPackages[cellInfo.index][cellInfo.column.id],
            })(<Select options={remarks} />)}
          </Form.Item>
        );
      } if (cellInfo.column.id === 'client') {
        return (
          <Form.Item style={{ margin: '0px' }}>
            {getFieldDecorator(`${cellInfo.column.id}_update`, {
              rules: [{ required: true, message: `${cellInfo.column.Header} is required.` }],
              // eslint-disable-next-line max-len
              initialValue: clientOptions.length ? {
                key: inboundDetails.existingPackages[cellInfo.index].client_id,
                label: inboundDetails.existingPackages[cellInfo.index].client,
              } : { key: null },
            })(<Select
              options={clientOptions}
              filterOption={false}
              labelInValue
            />)}
          </Form.Item>
        );
      } if (cellInfo.column.id === 'section') {
        return (
          <Form.Item style={{ margin: '0px' }}>
            {getFieldDecorator(`${cellInfo.column.id}_update`, {
              rules: [{ required: true, message: `${cellInfo.column.Header} is required.` }],
              // eslint-disable-next-line max-len
              initialValue: inboundDetails.existingPackages[cellInfo.index][cellInfo.column.id],
            })(<Select options={sectionOptions} />)}
          </Form.Item>
        );
      }
    }

    return (
      <div>
        {inboundDetails.existingPackages[cellInfo.index][cellInfo.column.id]}
      </div>
    );
  }

  getInbound() {
    const {
      doGetInbound,
      history,
      branch,
      match,
    } = this.props;
    doGetInbound(`inbound/${match.params.id}`, branch, false).then((action) => {
      const timeDiff = moment(
        action.payload.data.scan_time, 'YYYY-MM-DD HH:mm:ss',
      ).diff(
        moment(action.payload.data.start, 'YYYY-MM-DD HH:mm:ss'),
      );

      this.setState({
        isLoading: false,
        inboundDetails: {
          ...action.payload.data,
          gate: action.payload.data.gate_details.gate_number,
          hauler: action.payload.data.hauler_details.name,
          truck_type: action.payload.data.truck_type.replace(/_/g, ' '),
          plate_number: action.payload.data.vehicle.plate_number,
          location: action.payload.data.location_details.name,
          existingPackages: action.payload.data.packages,
          user: action.payload.data.user.full_name,
          gate_time: moment.utc(moment.duration(timeDiff, 'ms').asMilliseconds()).format('HH:mm:ss'),
        },
      });
    }).catch(() => {
      history.push('/404');
    });
  }

  setToEdit(rowId, rowTn) {
    this.setState({
      isEditable: true,
      editableRow: rowId,
      editableTn: rowTn,
    });
  }

  setToEditExisting(rowId, rowTn) {
    this.setState({
      isEditable: true,
      editableExistingRow: rowId,
      editableTn: rowTn,
    });
  }

  savePackages() {
    const {
      doAddInboundPackages,
      history,
      match,
      branch,
      inbound,
    } = this.props;
    const {
      isUpdated,
      modifiedExistingPackages,
      deletedPackages,
      inboundDetails,
    } = this.state;

    if (_.has(inbound, match.params.id)
      ? inbound[match.params.id].scannedPackages.length
      : false
    || isUpdated) {
      this.setState({
        isFinalizing: true,
      }, window.scrollTo(0, 0));
      const modifiedPackages = modifiedExistingPackages.map((modifiedPackage) => {
        if (modifiedPackage.remarks !== 'good') {
          return {
            ...modifiedPackage,
            is_refused: true,
          };
        }
        return {
          ...modifiedPackage,
          is_refused: false,
        };
      });
      doAddInboundPackages(
        match.params.id,
        _.has(inbound, match.params.id) ? _.uniqBy(inbound[match.params.id].scannedPackages, 'tracking_number') : [],
        modifiedPackages,
        deletedPackages,
        branch,
        inboundDetails.origin,
      ).then((action) => {
        history.push(`/inbound/${match.params.id}/result`, action.payload.data);
      }).catch(() => {
        message.error('Something went wrong, please try again later.', 3);
        this.setState({
          isFinalizing: false,
        });
      });
    } else {
      message.error('No changes detected');
    }
  }

  confirmRemove(packageIndex) {
    const { match, doDeletePackageFromState, inbound } = this.props;
    const updatedPackages = inbound[match.params.id].scannedPackages;
    updatedPackages.splice(packageIndex, 1);
    doDeletePackageFromState(match.params.id, updatedPackages);
    message.success('Package removed successfully!', 3);
  }

  confirmDelete(packageIndex) {
    const { inboundDetails } = this.state;
    this.setState(prevState => ({
      deletedPackages: [
        ...prevState.deletedPackages,
        inboundDetails.existingPackages[packageIndex],
      ],
    }), () => {
      inboundDetails.existingPackages.splice(packageIndex, 1);
      this.setState(prevState => ({
        inboundDetails: {
          ...prevState.inboundDetails,
        },
        isUpdated: true,
      }));
      message.info('Package is pending for deletion.', 3);
    });
  }

  cancelEdit() {
    this.setState({
      editableRow: null,
      isEditable: false,
    });
  }

  cancelEditExisting() {
    this.setState({
      editableExistingRow: null,
      isEditable: false,
    });
  }

  validatePackage(e) {
    e.preventDefault();
    const {
      form,
      doValidatePackage,
      match,
      branch,
      doSavePackageToState,
      inbound,
    } = this.props;

    const { packagesErrors, inboundDetails } = this.state;
    const { validateFields } = form;
    validateFields((err, values) => {
      if (!err) {
        // get current packages tracking numbers
        const currentTrackingNumbers = inboundDetails.packages;

        // get scanned packages tracking numbers
        const scannedTrackingNumbers = _.has(inbound, match.params.id)
          ? inbound[match.params.id].scannedPackages : [];

        // get input tracking numbers
        let trackingNumbers = values.tracking_number.split('\n').filter(tn => tn !== '');
        trackingNumbers = trackingNumbers.map(trackingNumber => trackingNumber.toUpperCase());

        // get duplicates on current packages
        const duplicatesCurrent = _.intersection(
          trackingNumbers,
          currentTrackingNumbers.map((packageDetails => packageDetails.tracking_number)),
        );

        // get duplicates on scanned packages
        const duplicatesScanned = _.intersection(
          trackingNumbers,
          scannedTrackingNumbers.map((packageDetails => packageDetails.tracking_number)),
        );
        // get duplicates on input packages
        const groupped = _.groupBy(trackingNumbers, (tn => tn));
        const duplicatesInput = _.uniq(_.flatten(_.filter(groupped, (tn => tn.length > 1))));

        // remove duplicates with current packages and scanned packages
        const filteredTrackingNumbers = trackingNumbers.map((trackingNumber) => {
          if (!_.find(currentTrackingNumbers, { tracking_number: trackingNumber })
          && !_.find(scannedTrackingNumbers, { tracking_number: trackingNumber })) {
            return trackingNumber;
          }
          return null;
        });

        // chunk input to 100
        const batchTrackingNumbers = _.chunk(_.uniq(_.compact(filteredTrackingNumbers)), 100);

        const duplicates = _.concat(
          _.concat(duplicatesScanned, duplicatesCurrent),
          duplicatesInput,
        );
        if (duplicates.length) {
          const duplicatedTn = packagesErrors;
          duplicates.forEach((trackingNumber) => {
            duplicatedTn.push({
              message: 'Package already scanned',
              tracking_number: trackingNumber,
              issued_at: moment().format('YYYY-MM-DD HH:mm:ss'),
            });
          });
          message.error(`${duplicates.length} packages are already scanned!`);
          this.setState({
            packagesErrors: duplicatedTn,
          });
        }
        batchTrackingNumbers.forEach((batch) => {
          doValidatePackage({
            tracking_number: batch,
            movement_id: match.params.id,
            branch_id: branch.id,
            origin: inboundDetails.origin,
            remarks: values.remarks,
          }).then((action) => {
            const { success, errors } = action.payload.data;
            if (success) {
              let packages = Object.keys(success);

              // revalidate response for duplicate packages
              const revalidatedPackages = _.intersection(
                packages,
                scannedTrackingNumbers.map((packageDetails => packageDetails.tracking_number)),
              );
              if (revalidatedPackages.length) {
                const duplicatedTn = packagesErrors;
                revalidatedPackages.forEach((trackingNumber) => {
                  duplicatedTn.push({
                    message: 'Package already scanned',
                    tracking_number: trackingNumber,
                    issued_at: moment().format('YYYY-MM-DD HH:mm:ss'),
                  });
                });
                packages = _.difference(packages, revalidatedPackages);
                message.error(`${revalidatedPackages.length} packages are already scanned!`);
                this.setState({
                  packagesErrors: duplicatedTn,
                });
              }

              // parse validated packages
              const parsedSuccessPackages = packages.map(trackingNumber => ({
                tracking_number: trackingNumber,
                item_count: values.item_count,
                package_type: values.package_type,
                remarks: values.remarks,
                is_refused: values.remarks !== 'good',
                location: branch.id,
                client_id: values.client.key,
                client: values.client.label,
                section: values.section,
              }));
              const currentScannedPackages = parsedSuccessPackages.concat(
                // eslint-disable-next-line react/destructuring-assignment
                _.has(this.props.inbound, match.params.id)
                // eslint-disable-next-line react/destructuring-assignment
                  ? this.props.inbound[match.params.id].scannedPackages
                  : [],
              );
              if (packages.length) {
                doSavePackageToState(match.params.id, currentScannedPackages);
                message.success(`Successfully inserted ${packages.length} packages `);
              }
            }

            if (errors) {
              const packages = Object.keys(errors);
              const parsedErrorPackages = packages.map(trackingNumber => ({
                message: errors[trackingNumber].message,
                tracking_number: trackingNumber,
                issued_at: moment().format('YYYY-MM-DD HH:mm:ss'),
              }));
              this.setState(prevState => ({
                packagesErrors: parsedErrorPackages.concat(prevState.packagesErrors),
              }));
              message.error(`Failed to insert ${_.values(errors).length} packages`);
            }
          });
        });
        form.resetFields(['tracking_number']);
      }
    });
  }

  zoomLastPackageScanned(e) {
    this.setState({
      lastPackageScannedSize: 24 + e,
    });
  }

  render() {
    const { Panel } = Collapse;
    const {
      form, match, inbound, branch,
    } = this.props;
    const {
      isEditable,
      editableRow,
      editableExistingRow,
      isFinalizing,
      remarks,
      packagesErrors,
      isUpdated,
      clientOptions,
      isLoading,
      inboundDetails,
      lastPackageScannedSize,
      sectionOptions,
    } = this.state;

    const { getFieldDecorator } = form;
    const { TextArea } = Input;

    const packageFormLayout = {
      labelCol: { span: 3 },
      wrapperCol: { span: 21 },
    };

    const loadingMessage = isFinalizing ? 'Preparing to checkout packages, please don`t refresh or leave this page. . .'
      : 'Fetching Inbound details . . .';
    return (
      <div className="InboundPackage">
        <Spin spinning={isFinalizing || isLoading} tip={loadingMessage}>
          <PageHeader title={`Inbound #${match.params.id}`} />
          <Container>
            <Collapse defaultActiveKey={['1']}>
              <Panel
                header="Inbound Details"
                key="1"
                extra={<Timer label="Inbound Timer" startTime={inboundDetails.start} />}
              >
                <Descriptions bordered>
                  <Descriptions.Item label="Gate">{inboundDetails.gate}</Descriptions.Item>
                  <Descriptions.Item label="Driver">{inboundDetails.driver}</Descriptions.Item>
                  <Descriptions.Item label="Origin">{inboundDetails.origin}</Descriptions.Item>
                  <Descriptions.Item label="Shipping Type">{inboundDetails.origin}</Descriptions.Item>
                  <Descriptions.Item label="Plate Number">
                    {inboundDetails.plate_number}
                  </Descriptions.Item>
                  <Descriptions.Item label="Hauler">
                    {inboundDetails.hauler}
                  </Descriptions.Item>
                  <Descriptions.Item label="Merchants">
                    {inboundDetails.merchant}
                  </Descriptions.Item>
                  <Descriptions.Item label="Truck Type">{inboundDetails.truck_type}</Descriptions.Item>
                  <Descriptions.Item label="Arrival Time">{inboundDetails.arrival_time}</Descriptions.Item>
                  <Descriptions.Item label="Pickup Date">{inboundDetails.pickup_date}</Descriptions.Item>
                  <Descriptions.Item label="Inbound Area">{inboundDetails.inbound_area}</Descriptions.Item>
                  <Descriptions.Item label="Receiver">{inboundDetails.user}</Descriptions.Item>
                </Descriptions>
              </Panel>
            </Collapse>
            <Spacer />
            <Card title="Inbound Package Information">
              <Row>
                <Form onSubmit={this.validatePackage} layout="vertical">
                  <Row>
                    <Col>
                      <Form.Item label="Client" {...packageFormLayout}>
                        {getFieldDecorator('client', {
                          rules: [{ required: true, message: 'Client cannot be blank.' }],
                          initialValue: clientOptions.length && branch.id === 2 ? {
                            key: clientOptions[0].value,
                            label: clientOptions[0].label,
                          } : '',
                        })(<Select
                          options={clientOptions}
                          placeholder="Please type to search client"
                          filterOption={false}
                          labelInValue
                        />)}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Item label="Remarks" {...packageFormLayout}>
                        {getFieldDecorator('remarks', {
                          rules: [{ required: true, message: 'Remarks cannot be blank' }],
                          initialValue: 'good',
                        })(<Select options={remarks} />)}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Item label="Section" {...packageFormLayout}>
                        {getFieldDecorator('section', {
                        })(<Select options={sectionOptions} />)}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Item label="Set Of." {...packageFormLayout}>
                        {getFieldDecorator('item_count', {
                          rules: [{ required: true, message: 'Set Of cannot be blank' }],
                          initialValue: 1,
                        })(<Input type="number" />)}

                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Item label="Package Type" {...packageFormLayout}>
                        {getFieldDecorator('package_type', {
                          rules: [{ required: true, message: 'Package type cannot be blank' }],
                          initialValue: 'bulky',
                        })(
                          <Radio.Group>
                            <Radio value="bulky">BULKY</Radio>
                            <Radio value="mbulky">M-BULKY</Radio>
                            <Radio value="pouch">POUCH</Radio>
                          </Radio.Group>,
                        )}

                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Item label="Tracking Number" {...packageFormLayout}>
                        {getFieldDecorator('tracking_number', {
                          rules: [{ required: true, message: 'Tracking number cannot be blank' }],
                          initialValue: '',
                        })(<TextArea autoComplete="off" onPressEnter={this.validatePackage} placeholder="E.g. X0123" />)}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Button type="primary" htmlType="submit" block>
                        <Icon viewBox="0 0 1024 1024">
                          <FontAwesomeIcon icon={faPlus} fixedWidth />
                        </Icon>
                      Add Tracking Number
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Row>

            </Card>
            <Spacer />
            <Card title="">
              <Row>
                <center>
                  <Col
                    xs={24}
                    sm={lastPackageScannedSize > 44 ? 24 : 12}
                    lg={lastPackageScannedSize > 44 ? 24 : 12}
                  >
                    <Label
                      title={(
                        <React.Fragment>
                          Last Package Scanned
                          <Slider
                            defaultValue={0}
                            onAfterChange={this.zoomLastPackageScanned}
                            max={35}
                          />
                        </React.Fragment>
                      )}
                      value={_.has(inbound, match.params.id)
                          && inbound[match.params.id].scannedPackages.length
                        ? inbound[match.params.id].scannedPackages[0].tracking_number
                        : ''
                    }
                      groupSeparator=""
                      valueStyle={{ fontSize: lastPackageScannedSize, wordWrap: 'break-word' }}
                    />
                  </Col>
                  <Col
                    xs={24}
                    sm={lastPackageScannedSize > 44 ? 24 : 12}
                    lg={lastPackageScannedSize > 44 ? 24 : 12}
                  >
                    <Label
                      title="Total Scanned Packages"
                      value={
                        _.has(inbound, match.params.id)
                          ? inbound[match.params.id].scannedPackages.length
                          : 0
                      }
                      valueStyle={{ fontSize: lastPackageScannedSize, wordWrap: 'break-word' }}
                    />
                  </Col>
                </center>
              </Row>
            </Card>
            <Spacer />
            <Card title="Scanned Packages">
              <Form onSubmit={this.onUpdateSubmit}>
                <ReactTable
                  data={
                    _.has(inbound, match.params.id)
                      ? inbound[match.params.id].scannedPackages
                      : []
                  }
                  columns={[
                    {
                      Header: 'Tracking Number',
                      accessor: 'tracking_number',
                    },
                    {
                      Header: 'Set of.',
                      accessor: 'item_count',
                      Cell: this.onEdit,

                    },
                    {
                      Header: 'Package Type',
                      accessor: 'package_type',
                      Cell: this.onEdit,
                    },
                    {
                      Header: 'Remarks',
                      accessor: 'remarks',
                      Cell: this.onEdit,
                    },
                    {
                      Header: 'Client',
                      accessor: 'client',
                      Cell: this.onEdit,
                    },
                    {
                      Header: 'Section',
                      accessor: 'section',
                      Cell: this.onEdit,
                    },
                    {
                      Header: 'Options',
                      sortable: false,
                      filterable: false,
                      Cell: data => (
                        <Row>
                          {
                    (!isEditable || data.index !== editableRow)
                      ? (
                        <Row>
                          <Col span={12}>
                            <Button type="link" onClick={() => this.setToEdit(data.index, data.row.tracking_number)} block>
                              <Icon viewBox="0 0 1024 1024">
                                <FontAwesomeIcon icon={faEdit} fixedWidth />
                              </Icon>
                              Edit
                            </Button>
                          </Col>
                          <Col span={12}>
                            <Popconfirm
                              placement="leftBottom"
                              title={`Are you sure you want to delete ${data.row.tracking_number} from the list?`}
                              okText="Yes"
                              onConfirm={() => this.confirmRemove(data.index)}
                              cancelText="No"
                            >
                              <Button type="link" block>
                                <Icon viewBox="0 0 1024 1024">
                                  <FontAwesomeIcon icon={faTrash} fixedWidth />
                                </Icon>
                                Delete
                              </Button>
                            </Popconfirm>
                          </Col>
                        </Row>
                      )
                      : (
                        <div>
                          <Col span={12}>
                            <Button type="link" htmlType="submit" block>
                              <Icon viewBox="0 0 1024 1024">
                                <FontAwesomeIcon icon={faSave} fixedWidth />
                              </Icon>
                              Save
                            </Button>
                          </Col>

                          <Col span={12}>
                            <Button type="link" onClick={this.cancelEdit} block>
                              <Icon viewBox="0 0 1024 1024">
                                <FontAwesomeIcon icon={faTimes} fixedWidth />
                              </Icon>
                              Cancel
                            </Button>
                          </Col>
                        </div>
                      )
                      }
                        </Row>
                      ),
                    },

                  ]}
                />

              </Form>
            </Card>
            <Spacer />
            <Card title="Inbound Current Packages">
              <Form onSubmit={this.onUpdateSubmitExisting}>
                <ReactTable
                  data={inboundDetails.existingPackages}
                  columns={[
                    {
                      Header: 'Tracking Number',
                      accessor: 'tracking_number',
                    },
                    {
                      Header: 'Set of.',
                      accessor: 'item_count',
                      Cell: this.onEditExistingPackages,

                    },
                    {
                      Header: 'Package Type',
                      accessor: 'package_type',
                      Cell: this.onEditExistingPackages,

                    },
                    {
                      Header: 'Remarks',
                      accessor: 'remarks',
                      Cell: this.onEditExistingPackages,
                    },
                    {
                      Header: 'Client',
                      accessor: 'client',
                      Cell: this.onEditExistingPackages,
                    },
                    {
                      Header: 'Section',
                      accessor: 'section',
                      Cell: this.onEditExistingPackages,
                    },
                    {
                      Header: 'Created By',
                      accessor: 'user.first_name',
                    },
                    {
                      Header: 'Options',
                      sortable: false,
                      Cell: data => (
                        <Row>
                          {
                  (!isEditable || data.index !== editableExistingRow)
                    ? (
                      <Row>
                        <Col span={12}>
                          <Button type="link" onClick={() => this.setToEditExisting(data.index, data.row.tracking_number)} block>
                            <Icon viewBox="0 0 1024 1024">
                              <FontAwesomeIcon icon={faEdit} fixedWidth />
                            </Icon>
                        Edit
                          </Button>
                        </Col>
                        <Col span={12}>
                          <Popconfirm
                            placement="leftBottom"
                            title={`Are you sure you want to delete ${data.row.tracking_number} from the list?`}
                            okText="Yes"
                            onConfirm={
                              () => this.confirmDelete(data.index, data.row.tracking_number)
                              }
                            cancelText="No"
                          >
                            <Button type="link" block>
                              <Icon viewBox="0 0 1024 1024">
                                <FontAwesomeIcon icon={faTrash} fixedWidth />
                              </Icon>
                        Delete
                            </Button>
                          </Popconfirm>
                        </Col>
                      </Row>
                    )
                    : (
                      <div>
                        <Col span={12}>
                          <Button type="link" htmlType="submit" block>
                            <Icon viewBox="0 0 1024 1024">
                              <FontAwesomeIcon icon={faSave} fixedWidth />
                            </Icon>
                            Save
                          </Button>
                        </Col>

                        <Col span={12}>
                          <Button type="link" onClick={this.cancelEditExisting} block>
                            <Icon viewBox="0 0 1024 1024">
                              <FontAwesomeIcon icon={faTimes} fixedWidth />
                            </Icon>
                            Cancel
                          </Button>
                        </Col>
                      </div>
                    )
                    }
                        </Row>
                      ),
                    },

                  ]}
                />
              </Form>
            </Card>
            <Spacer />
            {packagesErrors.length !== 0
              ? (
                <Card title="Error List">
                  <ReactTable
                    data={packagesErrors}
                    columns={[
                      {
                        Header: 'Tracking Number',
                        accessor: 'tracking_number',
                      },
                      {
                        Header: 'Error Message',
                        accessor: 'message',
                      },
                      {
                        Header: 'Issued At',
                        accessor: 'issued_at',
                      },
                    ]}
                  />
                </Card>
              ) : ''}
            <Spacer />
            <Popconfirm
              placement="top"
              title="Are you sure you want to finalize scanning for this movement?"
              okText="Yes"
              onConfirm={this.savePackages}
              cancelText="No"
              disabled={
                isFinalizing || (!_.has(inbound, match.params.id) && !isUpdated)
              }
            >
              <Button disabled={isFinalizing || (!_.has(inbound, match.params.id) && !isUpdated)} block type="primary">
                <Icon viewBox="0 0 1024 1024">
                  <FontAwesomeIcon icon={faCheckCircle} fixedWidth />
                </Icon>
                Checkout
              </Button>
            </Popconfirm>
          </Container>
        </Spin>
      </div>
    );
  }
}

InboundPackage.defaultProps = {
  inboundDetails: {},
};

InboundPackage.propTypes = {
  history: PropTypes.oneOfType([PropTypes.object]).isRequired,
  branch: PropTypes.oneOfType([PropTypes.object]).isRequired,
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
  inboundDetails: PropTypes.oneOfType([PropTypes.object]),
  match: PropTypes.oneOfType([PropTypes.object]).isRequired,
  inbound: PropTypes.oneOfType([PropTypes.object]).isRequired,
  doValidatePackage: PropTypes.func.isRequired,
  doAddInboundPackages: PropTypes.func.isRequired,
  doGetSectionSelect: PropTypes.func.isRequired,
  doGetInbound: PropTypes.func.isRequired,
  doGetClientSelect: PropTypes.func.isRequired,
  doSavePackageToState: PropTypes.func.isRequired,
  doDeletePackageFromState: PropTypes.func.isRequired,
  doUpdatePackageFromState: PropTypes.func.isRequired,
  doGetRemarks: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  branch: state.auth.branch,
  inbound: state.inbound,
});

const mapDispatchToProps = {
  doValidatePackage: validatePackage,
  doAddInboundPackages: addInboundPackages,
  doGetInbound: getInbound,
  doGetSectionSelect: getSectionSelect,
  doGetClientSelect: getClientSelect,
  doSavePackageToState: savePackageToState,
  doDeletePackageFromState: deletePackageFromState,
  doUpdatePackageFromState: updatePackageFromState,
  doGetRemarks: getRemarks,
};

const WrappedInboundPackageEdit = Form.create({ name: 'InboundPackage' })(InboundPackage);
export default connect(mapStateToProps, mapDispatchToProps)(WrappedInboundPackageEdit);
