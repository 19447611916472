import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Button, Icon, Col, Row, Modal, Result,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEdit, faRedo,
} from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import PageHeader from '../../components/PageHeader';
import Card from '../../components/Card';
import Container from '../../components/Container';
import ServerSideTable from '../../components/ServerSideTable';
import Select from '../../components/Select';
import { getUsers, resetPassword } from './ducks';

class UserList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      resetPasswordId: null,
      firstName: null,
      lastName: null,
      userList: {
        rows: [],
        pages: 0,
        total: 0,
      },
      isFetching: false,
      fetchMessage: null,
      loadResetPassword: false,
      newPassword: null,
    };
    this.onFetchData = this.onFetchData.bind(this);
    this.showModal = this.showModal.bind(this);
    this.handleOk = this.handleOk.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.doclearPassword = this.doclearPassword.bind(this);
  }

  // eslint-disable-next-line no-unused-vars
  onFetchData(state, instance) {
    const { fetchUsers, branch } = this.props;

    const params = {
      page: state.page + 1,
      pageSize: state.pageSize,
      filters: state.filtered,
      sorts: state.sorted,
      branchId: branch.id,
    };

    this.setState({
      isFetching: true,
      fetchMessage: 'Fetching user list . . .',
    });
    fetchUsers(params).then((action) => {
      this.setState({
        userList: {
          rows: action.payload.data.data,
          pages: action.payload.data.last_page,
          total: action.payload.data.total,
        },
        isFetching: false,
        fetchMessage: null,
      });
    // eslint-disable-next-line no-unused-vars
    }).catch((action) => {
      this.setState({
        isFetching: false,
        fetchMessage: null,
      });
    });
  }

  showModal(userId, userFirstName, userLastName) {
    this.setState({
      showModal: true,
      resetPasswordId: userId,
      firstName: userFirstName,
      lastName: userLastName,
    });
  }

  // eslint-disable-next-line no-unused-vars
  handleOk(e) {
    const { resetPasswordId } = this.state;
    const { doReset } = this.props;

    this.setState({
      loadResetPassword: true,
    });

    doReset(resetPasswordId).then((action) => {
      this.setState({
        loadResetPassword: false,
        newPassword: action.payload.data.password,
      });
    // eslint-disable-next-line no-unused-vars
    }).catch((action) => {
      this.setState({
        loadResetPassword: false,
        newPassword: null,
      });
    });
  }

  // eslint-disable-next-line no-unused-vars
  handleCancel(e) {
    this.setState({
      showModal: false,
    });
  }

  doclearPassword() {
    this.setState({
      newPassword: null,
    });
  }

  render() {
    const {
      showModal, firstName, lastName, userList,
      isFetching, fetchMessage, newPassword,
      loadResetPassword,
    } = this.state;

    return (
      <div className="UserList">
        <PageHeader title="User" />
        <Container>
          <Card title="User List">

            <Modal
              title="Reset Password"
              closable={false}
              visible={showModal}
              afterClose={this.doclearPassword}
              footer={[
                <Button key="submit" type="default" onClick={this.handleCancel} disabled={!!loadResetPassword}>
                  Cancel
                </Button>,
                <Button key="submit" type="primary" onClick={this.handleOk} loading={loadResetPassword}>
                  Reset
                </Button>,

              ]}
            >
              {
                  !newPassword
                    ? (
                      <Result
                        title={`Are you sure you want to reset ${firstName} ${lastName}'s password ?`}
                      />
                    )
                    : (
                      <Result
                        status="success"
                        title="Successfully generated a password"
                        subTitle={`Here is ${firstName} ${lastName}'s new password: ${newPassword}`}
                      />

                    )
                }

            </Modal>

            <ServerSideTable
              data={userList.rows}
              pages={userList.pages}
              columns={[
                {
                  Header: 'Id',
                  accessor: 'id',
                  width: 55,
                },
                {
                  Header: 'Username',
                  accessor: 'username',
                },
                {
                  Header: 'First Name',
                  accessor: 'first_name',
                },
                {
                  Header: 'Last Name',
                  accessor: 'last_name',
                },
                {
                  Header: 'Role',
                  accessor: 'role',
                },
                {
                  id: 'outsource_group_id',
                  Header: 'Outsource Group',
                  width: 120,
                  accessor: data => (data.outsource === null ? '-' : data.outsource.name),
                },
                {
                  Header: 'Contact',
                  accessor: 'contact',
                },
                {
                  Header: 'Status',
                  accessor: 'is_active',
                  Filter: ({ filter, onChange }) => (
                    <Select
                      options={[
                        {
                          value: '',
                          label: 'All',
                        },
                        {
                          value: 'inactive',
                          label: 'Inactive',
                        },
                        {
                          value: 'active',
                          label: 'Active',
                        },
                      ]}
                      style={{ width: 100 }}
                      onChange={status => onChange(status)}
                      value={filter ? filter.value : 'All'}
                    />
                  ),
                },
                {
                  Header: 'Options',
                  sortable: false,
                  filterable: false,
                  width: 210,
                  Cell: data => (
                    <Row>
                      <Col span={8}>
                        <NavLink to={`/user/${data.row.id}/edit`}>
                          <Button type="link">
                            <Icon viewBox="0 0 1024 1024">
                              <FontAwesomeIcon icon={faEdit} fixedWidth />
                            </Icon>
                        Edit
                          </Button>
                        </NavLink>
                      </Col>
                      <Col span={16}>
                        <Button type="link" onClick={() => this.showModal(data.row.id, data.row.first_name, data.row.last_name)}>
                          <Icon viewBox="0 0 1024 1024">
                            <FontAwesomeIcon icon={faRedo} fixedWidth />
                          </Icon>
                        Reset Password
                        </Button>
                      </Col>
                    </Row>

                  ),
                },
              ]}
              loadingText={fetchMessage}
              loading={isFetching}
              onFetchData={this.onFetchData}
            />
          </Card>
        </Container>
      </div>
    );
  }
}

UserList.propTypes = {
  branch: PropTypes.oneOfType([PropTypes.object]).isRequired,
  fetchUsers: PropTypes.func.isRequired,
  doReset: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  fetchUsers: getUsers,
  doReset: resetPassword,
};

const mapStateToProps = state => ({
  branch: state.auth.branch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserList);
